import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagas'
import createRootReducer from './reducers'
import { DEVELOPMENT } from './consts/env'

export const history = createBrowserHistory()

// redux-saga middleware
const sagaMiddleware = createSagaMiddleware()

const configureStore = () => {
  const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)]

  if (process.env.NODE_ENV === DEVELOPMENT) {
    // middlewares.push(createLogger());
  }

  // compose single-argument functions from right to left.
  // 如需啟用 debug 工具 `__REDUX_DEVTOOLS_EXTENSION_COMPOSE__` 需安裝 chrome extension https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=zh-TW
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  return createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(...middlewares)),
  )
}

const store = configureStore()

sagaMiddleware.run(rootSaga)

export default store
