import React from 'react'
import {
  TableBody,
  CircularProgress,
} from '@material-ui/core'

const MaybeLoading = () => {
  return (
    <TableBody>
      <tr>
        <td colSpan="12" align="center">
          <div>
            <CircularProgress />
          </div>
        </td>
      </tr>
    </TableBody>
  )
}

export default MaybeLoading
