// 注單查詢
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment'

import a from '../../actions'
import {
  getUser,
  getReportByFilter,
  getGamesWithoutLottoAndSport,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
  getBrandInfo,
} from '../../reducers'
import { qsParse, formatDate, formatMoney } from '../../helpers'
import language from '../../locale/playerOrder'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import ReportSummaryCard from '../../components/ReportSummaryCard'
import GridItemFull from '../../components/grids/GridItemFull'
import PlayerOrderForOwnerForm from '../../components/forms/PlayerOrderForOwnerForm'
import DataTable from '../../components/tables/DataTable'
import MoreButton from '../../components/MoreButton'

const styles = theme => ({
  overrides: {
    MUIDataTableHeadCell: {
      tooltip: {
        color: 'red',
        backgroundColor: 'blue',
      },
      popper: {
        color: 'red',
        backgroundColor: 'blue',
      },
    },
  },
  true: {
    color: green[500],
    fontWeight: 900,
  },
  false: {
    color: grey[500],
    fontWeight: 900,
  },
})

class PlayerOrderForOwner extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      formatSummaryReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'playerOrder', language.en)
    i18n.addResourceBundle('cn', 'playerOrder', language.cn)
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲名稱」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }
  }

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setColumns = () => {
    const { t } = this.props
    const { payload } = this.state
    const displayFreeTicket = payload?.withfreeticket === 'true'

    const columns = [
      {
        name: t('roundid'),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: t('bettime'),
        options: {
          filter: false,
          sort: false,
          // 把 this.props 拿掉會有 scope 問題喔
          customBodyRender: value => <div style={{ minWidth: '70px' }}>{moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}</div>,
        },
      },
      {
        name: t('finaltime'),
        options: {
          filter: false,
          sort: false,
          // 把 this.props 拿掉會有 scope 問題喔
          customBodyRender: value => <div style={{ minWidth: '70px' }}>{moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}</div>,
        },
      },
      {
        name: t('gamehall'),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: t('gamename'),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: t('currency'),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: t('bet'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('ticketbet'),
        options: {
          filter: false,
          sort: false,
          display: displayFreeTicket,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('win'),
        options: {
          hint: t('winHint'),
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('jackpot'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('extrabuy'),
        options: {
          hint: t('extrabuyHint'),
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('bonus'),
        options: {
          hint: t('bonusHint'),
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('rake'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('validbet'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('cardwin'),
        options: {
          filter: false,
          sort: false,
          hint: t('usecard'),
          customBodyRender: value => {
            const { cardwin, usecard } = value
            return <div style={{ color: usecard && '#94e185' }}>{formatMoney(cardwin)}</div>
          },
        },
      },
      {
        name: t('roomfee'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: t('gamerole'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => {
            // 需求：https://trello.com/c/qrwFg1tg
            let v = '-'

            switch (value) {
              case 'banker':
                v = 'B'
                break
              case 'player':
                v = 'P'
                break
              default:
            }

            return <span>{v}</span>
          },
        },
      },
      {
        name: t('adjust'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => formatMoney(value),
        },
      },
      {
        name: 'B / F',
        options: {
          hint: 'Bonus/Free Game',
          sort: false,
          filter: false,
          // 把 this.props 拿掉會有 scope 問題喔
          customBodyRender: ({ bonus, freegame }) => (
            <div>
              <span className={this.props.classes[bonus > 0]}>B</span>
              <span className={this.props.classes.false}> | </span>
              <span className={this.props.classes[freegame > 0]}>F</span>
            </div>
          ),
        },
      },
      {
        name: t('ticketid'),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'PvP',
        options: {
          hint: t('PvPHint'),
          filter: false,
          sort: false,
          customBodyRender: value => (
            <div
              className={`status ${value && value === 'human' ? 'true' : 'false'
                  }`}
            />
          ),
        },
      },
      {
        name: t('singlerowbet'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => <div className={`status ${value}`} />,
        },
      },
      {
        name: t('more'),
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: value => {
            return (
              <MoreButton
                items={[
                  {
                    label: t('gameDetail'),
                    onClick: () => {
                      this.handleGameLink({
                        host: '0', // 預設中國線路
                        ...value,
                      })
                    },
                  },
                ]}
              />
            )
          },
        },
      },
    ]

    this.setState({ columns })
  };

  setFormatReports = (data = []) => {
    const { payload } = this.state
    const displayFreeTicket = payload?.withfreeticket === 'true'

    this.setState({
      formatReports: data.map(item => {
        return [
          item.roundid,
          item.bettime,
          item.finaltime,
          item.gamehall,
          item.gamename,
          item.currency,
          item.bet,
          displayFreeTicket ? item.ticketbet : delete item.ticketbet,
          item.win,
          item.jackpot,
          item.article,
          item.reward,
          item.rake,
          item.validbet,
          { cardwin: item.cardwin, usecard: item.usecard },
          item.roomfee,
          item.gamerole,
          item.adjust,
          item, // B/F
          item.ticketid,
          item.bankertype, // PvP
          item.singlerowbet, // 再旋轉
          { roundid: item.roundid, gamehall: item.gamehall },
        ]
      }),
    })
  };

  setFormatSummaryReports = data => {
    this.setState({
      formatSummaryReports:
        data && data.account
          ? [
            {
              name: 'slot'.toUpperCase(),
              data: Object.keys(data.slot).map(key => ({
                key,
                value: data.slot[key],
              })),
            },
            {
              name: 'table'.toUpperCase(),
              data: Object.keys(data.table).map(key => ({
                key,
                value: data.table[key],
              })),
            },
            {
              name: 'live'.toUpperCase(),
              data: Object.keys(data.live).map(key => ({
                key,
                value: data.live[key],
              })),
            },
          ]
          : [],
    })
  };

  handleGameLink = async values => {
    return this.props.fetchGameDetailLink(values, { gameType: 'slotAndTable' })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'playersOrder',
      fileName: t('orderSearch', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const gameType = values.gameType === 'all' ? undefined : values.gameType === 'allGame' ? ['slot', 'fish', 'arcade'] : values.gameType

    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      gamehall: values.gamehall === 'all' ? '' : values.gamehall,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      gameType: gameType,
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) { }
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props

    return fetchReport({
      reportPayload: { ...payload, timezone },
      summaryReportPayload: { ...payload, timezone },
    }, { filter: 'playersOrder' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      agentAccount,
      filterPlayerAccount,
      filterAgentAccount,
      filterGameHall,
      filterGamesOptions,
      canDownload,
      isFetching,
      timezone,
      brandInfo,
      dateRange,
      filterGameType,
    } = this.props
    const {
      formatReports,
      formatSummaryReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <PlayerOrderForOwnerForm
              specialRange
              agentAcc={filterAgentAccount}
              playerAcc={filterPlayerAccount}
              startDate={startDate}
              endDate={endDate}
              gamehall={filterGameHall}
              gamecode=""
              gameType={filterGameType}
              roundid=""
              withfreeticket="true"
              sortKey="bettime"
              sortValue="desc"
              nowpage={1}
              pagesize={rowsPerPage}
              filterGamesOptions={() => filterGamesOptions}
              onSubmit={this.handleSubmit}
              isFetching={isFetching}
              getDefaultAgent={() => agentAccount}
              brand={brandInfo.brand}
              dateRange={dateRange}
            />
          </Paper>
        </GridItemFull>

        {isSearched &&
          <GridItemFull>
            {/* 用hiddenList隱藏指定不顯示的欄位 */}
            <ReportSummaryCard data={formatSummaryReports} hiddenList={['totalticketbets']} />
          </GridItemFull>
        }

        {isSearched &&
          <GridItemFull>
            <DataTable
              data={formatReports}
              columns={columns}
              pagination={reportPagination}
              payload={payload}
              canDownload={canDownload}
              downloadReport={this.handleDownload}
              options={{
                rowsPerPage,
                onChangePage: this.handleChangePage,
                onChangeRowsPerPage: this.handleChangeRowsPerPage,
              }}
            />
          </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const user = getUser(state)
  const language = getLanguage(state)
  const allGamesOptions = getGamesWithoutLottoAndSport(state, language)
  const reports = getReportByFilter(state, 'playersOrder')
  const summaryReports = getReportByFilter(state, 'playersOrderSummary')

  const agentAccount = user.parentAcc
  const filterAgentAccount = search.agent ? search.agent : ''
  const filterPlayerAccount = search.player ? search.player : ''
  const filterGameHall = search.gameHall ? search.gameHall : 'all'
  const filterGamesOptions = getGamesWithoutLottoAndSport(state, language)
  const filterGameType = search.gameType ? search.gameType : 'all'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'playersOrder') || getIsFetchingByFilter(state, 'playersOrderSummary')

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  // brand
  const brandInfo = getBrandInfo(state)

  // order
  const dateRange = state.order?.order ? `${state.order.order}day` : '1day'

  return {
    canDownload,
    games: state.games,
    language,
    agentAccount,
    filterAgentAccount,
    filterPlayerAccount,
    filterGameHall,
    filterGamesOptions,
    filterGameType,
    allGamesOptions,
    search,
    reports,
    summaryReports,
    timezone,
    isFetching,
    brandInfo,
    dateRange,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchGameDetailLink: a.game.fetchGameDetailLink,
    downloadReport: a.report.download,
    fetchReport: a.report.fetchPlayerOrder,
  },
)(withTranslation('playerOrder')(withStyles(styles)(PlayerOrderForOwner)))
