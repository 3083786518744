import React from 'react'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FileCopy from '@material-ui/icons/FileCopy'

const styles = theme => ({
  root: {
    //
  },
})

function TextComponent ({
  variant = 'outlined',
  margin = 'normal',
  InputLabelProps = {},
  classes,
  field,
  form,
  InputProps,
  onCopied,
  ...props
}) {
  return (
    <TextField
      className="copy"
      onClick={ele => {
        const inputEle = ele.currentTarget.getElementsByTagName('input')[0]
        inputEle.select()
        const isCopy = document.execCommand('copy')
        if (isCopy) {
          onCopied()
        }
      }}
      variant={variant}
      margin={margin}
      autoComplete="off"
      InputLabelProps={InputLabelProps}
      InputProps={{
        className: classes.root,
        margin: 'dense',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>{<FileCopy />}</IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...field}
      {...props}
    />
  )
}

function OutlinedCopyTextField (props) {
  return <Field {...props} component={TextComponent} />
}

export default withStyles(styles)(OutlinedCopyTextField)
