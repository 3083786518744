import React, { Component } from 'react'

import VisibilityIcon from '@material-ui/icons/Visibility'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { formatMoney } from '../helpers'

const styles = theme => ({
  iconButton: {
    height: 24,
    width: 24,
    padding: 0,
  },
  show: {
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

// 需求：https://trello.com/c/Tf604zuz
const IconOrNumber = (value) => {
  if (value === null) {
    return <VisibilityIcon />
  }

  return value >= 0 ? formatMoney(value) : '-'
}

class DisplayButton extends Component {
  state = { displayValue: null };

  handleClick = () => {
    const { item, handleFetch } = this.props
    this.setState({ displayValue: '-' })
    handleFetch(item).then(value => this.setState({ displayValue: value }))
  };

  render () {
    const { classes } = this.props
    const { displayValue } = this.state

    return (
      <IconButton
        buttonRef={node => {
          this.anchorEls = node
        }}
        onClick={this.handleClick}
        className={`${classes.iconButton} ${displayValue !== null &&
          classes.show}`}
        disableRipple={displayValue !== null}
      >
        { IconOrNumber(displayValue) }
      </IconButton>
    )
  }
}

export default withStyles(styles)(DisplayButton)
