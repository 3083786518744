import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
})

const Paper = ({ children, classes, ...props }) => (
  <MuiPaper className={classes.root} {...props}>{children}</MuiPaper>
)

export default withStyles(styles)(Paper)
