export const getTree = list => {
  const nodes = []
  const toplevelNodes = []
  const lookupList = {}

  // 篩選資料：排除 manger only 功能
  const filtered = list.filter(i => {
    return !i.managerOnly
  })

  for (let i = 0; i < filtered.length; i++) {
    const n = {
      ...filtered[i],
      id: filtered[i].id,
      title: '',
      subtitle: '',
      parentId: filtered[i].parentId === '0' ? null : filtered[i].parentId,
      children: [],
      expanded: true,
      root: true,
    }
    lookupList[n.id] = n
    nodes.push(n)
    if (n.parentId === null) {
      toplevelNodes.push(n)
    }
  }

  for (let i = 0; i < nodes.length; i++) {
    const n = nodes[i]
    if (n.parentId !== null && lookupList[n.parentId] && lookupList[n.parentId].children) {
      lookupList[n.parentId].children = lookupList[n.parentId].children.concat([
        n,
      ])
    }
  }

  return toplevelNodes
}
