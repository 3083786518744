import { useCallback, useMemo } from 'react'
import moment from 'moment'
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, lastDayOfMonth } from 'date-fns'

const useDateTime = ({ timezone }) => {
  const startOfToday = useCallback(() => new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss')), [timezone])
  const endOfToday = useCallback(() => new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss')), [timezone])
  const startOfYesterday = useCallback(() => new Date(new Date(new Date().setDate(startOfToday().getDate() - 1)).setHours(0, 0, 0, 0)), [startOfToday])
  const endOfYesterday = useCallback(() => new Date(new Date(new Date().setDate(startOfToday().getDate() - 1)).setHours(23, 59, 59, 999)), [startOfToday])
  const startOfThisWeek = useCallback(() => startOfWeek(new Date(), { weekStartsOn: 1 }), [])
  const endOfThisWeek = useCallback(() => endOfWeek(new Date(), { weekStartsOn: 1 }), [])
  const startOfLastWeek = useCallback(() => startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 1 }), [])
  const endOfLastWeek = useCallback(() => endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 1 }), [])
  const startOfThisMonth = useCallback(() => startOfMonth(new Date()), [])
  const endOfThisMonth = useCallback(() => endOfMonth(new Date()), [])
  const startOfLastMonth = useCallback(() => new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), [])
  const endOfLastMonth = useCallback(() => lastDayOfMonth(startOfLastMonth()), [startOfLastMonth])
  const dateList = useMemo(() => ([
    { day: 'today', startDate: startOfToday(), endDate: endOfToday() },
    { day: 'yesterday', startDate: startOfYesterday(), endDate: endOfYesterday() },
    { day: 'thisWeek', startDate: startOfThisWeek(), endDate: endOfThisWeek() },
    { day: 'lastWeek', startDate: startOfLastWeek(), endDate: endOfLastWeek() },
    { day: 'thisMonth', startDate: startOfThisMonth(), endDate: endOfThisMonth() },
    { day: 'lastMonth', startDate: startOfLastMonth(), endDate: endOfLastMonth() },
  ]), [endOfLastMonth, endOfLastWeek, endOfThisMonth, endOfThisWeek, endOfToday, endOfYesterday, startOfLastMonth, startOfLastWeek, startOfThisMonth, startOfThisWeek, startOfToday, startOfYesterday])
  return ({
    dateList,
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    startOfThisWeek,
    endOfThisWeek,
    startOfLastWeek,
    endOfLastWeek,
    startOfThisMonth,
    endOfThisMonth,
    startOfLastMonth,
    endOfLastMonth,
  })
}

export default useDateTime
