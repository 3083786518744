import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to set cobrand style
 */
export function * setStyle ({ payload }) {
  try {
    // 1. Set cobrand request started style
    yield put(actions.cobrand.setStyleRequest())

    // 2. Call API: set cobrand style
    yield call(api.put, api.BO, '/game/cobrand', payload)

    // 3. Set cobrand style success
    yield put(actions.cobrand.setStyleSuccess())

    // 4. Announce set cobrand style success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Set cobrand style failure
    yield put(actions.cobrand.setStyleFailure())
    // Announce API error
    yield put(actions.msgs.add({
      replaceWithI18nText: 'inputError',
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.cobrand.setStyle, setStyle),
]

export default effects
