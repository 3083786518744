import {
  call,
  takeLatest,
} from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { saveState, storageTypes } from '../helpers/storage'

/**
 * Effect to set timezone to localStorage
 */
export function * setTimezone (action) {
  yield call(saveState, storageTypes.TIMEZONE, action.payload.timezone)
}

const effects = [
  takeLatest(actionType.time.setTimezone, setTimezone),
]

export default effects
