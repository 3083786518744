import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import LanguageIcon from '@material-ui/icons/Language'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textColor: {
    color: '#ffffff',
  },
  popper: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: '-6px',
  },
})

class Language extends Component {
  constructor (props) {
    super(props)

    this.state = { anchorEl: null, language: props.language }
  }

  handleClick = event => {
    const { currentTarget } = event
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }))
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  };

  handleSwitch = value => {
    const { i18n, handleChangeLanguage } = this.props
    this.setState({ language: value })
    handleChangeLanguage(value, i18n)
  };

  render () {
    const { classes, theme } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton
          aria-owns={open ? 'i18n-popover' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.textColor}
          aria-label="i18n"
        >
          <LanguageIcon />
        </IconButton>
        <Popper
          id="timezone-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleClick}>
              <div>
                <Button
                  variant={this.state.language === 'cn' ? 'contained' : 'outlined'}
                  color={theme.customer.AppBar.Language.ButtonColor}
                  className={classes.button}
                  onClick={() => {
                    this.handleSwitch('cn')
                  }}
                >
                  中文
                </Button>
                <Button
                  variant={this.state.language === 'en' ? 'contained' : 'outlined'}
                  color={theme.customer.AppBar.Language.ButtonColor}
                  className={classes.button}
                  onClick={() => {
                    this.handleSwitch('en')
                  }}
                >
                  English
                </Button>
              </div>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation()(Language))
