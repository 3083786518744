import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import AccountCircle from '@material-ui/icons/AccountCircle'

const styles = theme => ({
  white: {
    color: '#ffffff',
  },
  iconContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
  },
  icon: {
    height: 36,
    width: 36,
    cursor: 'pointer',
  },
  popper: {
    zIndex: theme.zIndex.drawer + 1,
  },
  card: {
    width: 330,
    marginRight: theme.spacing.unit * 2,
  },
  actions: {
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    padding: 0,
    margin: 20,
    maxHeight: 96,
  },
  avatar: {
    wWidth: 96,
    height: 96,
    marginRight: 20,
    padding: 0,
  },
  photo: {
    width: 96,
    height: 'auto',
    color: theme.palette.primary.light,
  },
  details: {
    width: 174,
    flexDirection: 'column',
  },
  link: {
    display: 'inline',
    borderRadius: 0,
    fontWeight: 900,
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit * 1.5,
  },
  actionButton: {
    marginRight: theme.spacing.unit * 1.5,
  },
  username: {
    maxHeight: 40,
    overflow: 'auto',
    wordBreak: 'break-all',
    marginBottom: theme.spacing.unit * 1,
    lineHeight: '1.25rem',
  },
})

class UserButton extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    const { currentTarget } = event
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }))
  };

  renderRole = user => {
    const { t } = this.props

    switch (user.role) {
      case 'admin':
      case 'owner':
      case 'parent':
        return t(user.role)
      case 'cs':
        return user.permit === 'admin' ? t('adminCs') : t('cs')
      default:
        return 'Unknown'
    }
  };

  render () {
    const { user, classes, handleLogout, t } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const id = open ? 'no-transition-popper' : null

    return (
      <div>
        <div className={classes.iconContent}>
          <AccountCircle className={classes.icon} onClick={this.handleClick} />
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
          disablePortal
        >
          <ClickAwayListener onClickAway={this.handleClick}>
            <Card square className={classes.card}>
              <CardContent className={classes.content}>
                <div className={classes.avatar}>
                  <AccountCircle className={classes.photo} />
                </div>
                <div className={classes.details}>
                  <Typography color="textSecondary">
                    {this.renderRole(user)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.username}
                    gutterBottom
                  >
                    {user.account}
                  </Typography>
                  <Button
                    component={Link}
                    to={{
                      pathname: '/userProfile',
                      state: { show: 0 },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.link}
                    onClick={this.handleClick}
                  >
                    {t('accountManager')}
                  </Button>
                </div>
                {
                // 若有切換 theme 的需求時打開
                /* <div>
                  <IconButton
                    onClick={handleSwitchTheme}
                    color="inherit"
                  >
                    { theme.customer.AppBar.UserButton.SwitchThemeButtonIcon }
                  </IconButton>
                </div> */}
              </CardContent>

              <CardActions className={classes.actions}>
                <div />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleLogout}
                >
                  {t('logout')}
                </Button>
              </CardActions>
            </Card>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
}

export default withTranslation('common')(withStyles(styles, { withTheme: true })(UserButton))
