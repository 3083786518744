export const saveState = (key, state) => {
  window.localStorage.setItem(key, JSON.stringify(state))
}

export const getSavedState = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return null
  }
}

export const removeState = key => {
  try {
    return window.localStorage.removeItem(key)
  } catch (error) {
    return null
  }
}

export const storageTypes = {
  TIMEZONE: 'timezone',
}
