import React, { useState, useRef, useEffect, useCallback } from 'react'
import Select from 'react-select'
import BetLimitForm from './BetLimitForm/BetLimitForm'
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowRightAltOutlined'

import Paper from '../../components/Paper'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import GridContainer from '../../components/grids/GridContainer'

import { useTranslation } from 'react-i18next'
import language from '../../locale/gkSetting'
import { api } from '../../services'
import useStyles from './BetLimitSetting.style'
import { useDispatch } from 'react-redux'
import actions from '../../actions'

const BetLimitSetting = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation('gkSetting')

  const [agentId, setAgentId] = useState('')
  const [playerId, setPlayerId] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({})
  const [tablelist, setTablelist] = useState([])
  const [tableId, setTableId] = useState([])
  const [betitems, setBetitems] = useState([])
  const [ceilingitems, setCeilingitems] = useState([])
  const [isSubmittable, setIsSubmittable] = useState({
    default: false,
    floor: false,
    ceiling: false,
    productOfPointFive: false,
  })
  const [copyIdFrom, setCopyIdFrom] = useState('')
  const [copyIdTo, setCopyIdTo] = useState('')
  const [copiedData, setCopiedData] = useState({})
  const idsRef = useRef({})
  const all = 'All'

  const convertOptionsFormat = (options) =>
    options.map((item) => ({ label: item, value: item }))
  const convertValueFormat = (value) => ({ label: value, value: value })

  const handleAgentIdChange = useCallback((e) => setAgentId(e.target.value), [])
  const handlePlayerIdChange = useCallback(
    (e) => setPlayerId(e.target.value),
    [],
  )
  const handleSearchClick = useCallback(async () => {
    try {
      const fetchTablelist = await api.get(
        api.BO,
        `/live/gk/tablelist?ssaccount=${agentId}&playerAccount=${playerId}`,
      )
      if (fetchTablelist.data?.status?.code === '0') {
        setTablelist(fetchTablelist.data?.data?.tablelist || null)
        setTableId([fetchTablelist.data?.data?.tablelist?.[0]] || '')
        idsRef.current = { agentId: agentId, playerId: playerId }
      }
    } catch (err) {
      setTablelist([])
      setData({})
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }, [agentId, playerId, dispatch])

  const handleTableChange = useCallback((e) => setTableId([e.value]), [])
  const handleEdit = () => setIsEdit(true)
  // 當tableId更新時，更新資料
  const fetchSelectedTable = useCallback(async () => {
    try {
      const res = await api.get(
        api.BO,
        `/live/gk/tablelimit?ssaccount=${idsRef.current.agentId}&playerAccount=${idsRef.current.playerId}&tableid=${tableId[0]}`,
      )
      if (res.data?.status?.code === '0') {
        setData(res.data.data || {})
        setBetitems(res.data.data.betitems || [])
        setCeilingitems(res.data.data.ceilingitems || [])
        setCopyIdFrom('')
        setCopyIdTo('')
      }
    } catch (err) {
      console.error(err)
    }
  }, [tableId])
  const handleSubmit = useCallback(async () => {
    try {
      const res = await api.post(api.BO2, '/live/gk/tablelimit', {
        userid: data.userid,
        tableid: data.tableid,
        tabletype: data.tabletype,
        betitems: betitems,
      })
      if (res.data?.status?.code === '0') {
        setIsEdit(false)
        fetchSelectedTable()
        dispatch(
          actions.msgs.add({
            replaceWithI18nText: 'modifySuccess',
            variant: 'success',
          }),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, data, betitems, fetchSelectedTable])

  const handleCancel = useCallback(
    (e) => {
      setIsEdit(false)
      setBetitems(data.betitems)
    },
    [data],
  )

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'gkSetting', language.en)
    i18n.addResourceBundle('cn', 'gkSetting', language.cn)
  }, [i18n])

  // tableId更新時，取得更新限額資料
  useEffect(() => {
    if (tableId[0]) {
      fetchSelectedTable()
    }
  }, [tableId, fetchSelectedTable])

  // 當copyIdFrom更新時，更新複製限額資料
  useEffect(() => {
    if (copyIdFrom) {
      ;(async () => {
        try {
          const res = await api.get(
            api.BO,
            `/live/gk/tablelimit?ssaccount=${idsRef.current.agentId}&playerAccount=${idsRef.current.playerId}&tableid=${copyIdFrom}`,
          )
          if (res.data?.status?.code === '0') {
            setCopiedData(res.data?.data)
          }
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [copyIdFrom])

  const handleIdOneChange = useCallback((e) => setCopyIdFrom(e.value), [])
  const handleIdTwoChange = useCallback((e) => setCopyIdTo(e.value), [])
  const handleCopy = async () => {
    const arr = []
    const copyTable = (tableIdArr) =>
      api.post(api.BO2, '/live/gk/tablelimit', {
        userid: copiedData.userid,
        tableid: tableIdArr,
        tabletype: copiedData.tabletype,
        betitems: copiedData.betitems,
      })
    copyIdTo === all
      ? tablelist.map((item) => arr.push(copyTable(item)))
      : arr.push(copyTable(copyIdTo))
    Promise.allSettled(arr).then((results) =>
      results.forEach((result) =>
        result.status === 'fulfilled'
          ? dispatch(
            actions.msgs.add({
              replaceWithI18nText: 'copiedSuccess',
              variant: 'success',
            }),
          )
          : dispatch(
            actions.msgs.add({
              replaceWithI18nText: 'copiedFailure',
              variant: 'error',
            }),
          ),
      ),
    )
    setBetitems(data.betitems)
    setIsEdit(false)
  }
  return (
    <>
      <Container>
        {/* 搜索 */}
        <GridItemFull>
          <Paper>
            <div className={classes.searchBar}>
              <div>
                <TextField
                  required
                  disabled={isEdit}
                  autoComplete={'off'}
                  name="agentId"
                  label={t('agentId')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={handleAgentIdChange}
                  className={classes.textfield}
                />
                <TextField
                  disabled={isEdit}
                  autoComplete={'off'}
                  name="playerId"
                  label={t('playerId')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={handlePlayerIdChange}
                  className={classes.textfield}
                />
                <Button
                  disabled={isEdit || !agentId}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSearchClick}>
                  {t('apply')}
                </Button>
              </div>
              {/* 編輯、確認、取消 */}
              {tablelist === null ? (
                <Button
                  className={classes.button}
                  disabled={true}
                  variant="contained"
                  color="primary">
                  {t('edit')}
                </Button>
              ) : data.account ? (
                <div className={classes.buttonGroup}>
                  {!isEdit ? (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}>
                      {t('edit')}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className={classes.button}
                        disabled={!Object.values(isSubmittable).every(item => item)}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}>
                        {t('save')}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}>
                        {t('cancel')}
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </Paper>
        </GridItemFull>

        {/* 取得資料，顯示限額設定 */}
        {tablelist === null ? (
          <Paper className={classes.fullWidth}>
            <Typography color="error">＊{t('noTableOpen')}</Typography>
          </Paper>
        ) : !data.account ? (
          ''
        ) : (
          <div className={classes.seperator}>
            <div>
              <Paper className={classes.tableBar}>
                <Typography variant="subtitle1">{t('tableId')} :</Typography>
                <Select
                  name="color"
                  className={classes.selectField}
                  isDisabled={isEdit}
                  options={convertOptionsFormat(tablelist)}
                  value={convertValueFormat(tableId[0])}
                  onChange={handleTableChange}
                />
              </Paper>
              <Paper className={classes.formWrapper}>
                <BetLimitForm
                  t={t}
                  rows={betitems}
                  ceilings={ceilingitems}
                  setBetitems={setBetitems}
                  disabled={isEdit}
                  currency={data.currency}
                  isSubmittable={isSubmittable}
                  setIsSubmittable={setIsSubmittable}
                />
              </Paper>
            </div>

            {/* 複製設定 */}
            <div>
              <Paper>
                <Typography variant="subtitle1" className={classes.quickSetup}>
                  {t('quickSetup')}：
                </Typography>
                <GridContainer className={classes.copyWrapper}>
                  <Select
                    name="color"
                    className={classes.copyField}
                    isDisabled={!isEdit}
                    options={convertOptionsFormat(tablelist)}
                    value={convertValueFormat(copyIdFrom)}
                    onChange={handleIdOneChange}
                  />
                  <ArrowBack fontSize="large" className={classes.arrow} />
                  <Select
                    name="color"
                    className={classes.copyField}
                    isDisabled={!isEdit}
                    options={convertOptionsFormat([all, ...tablelist])}
                    value={convertValueFormat(copyIdTo)}
                    onChange={handleIdTwoChange}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isEdit || !copyIdFrom || !copyIdTo}
                    className={classes.copyButton}
                    onClick={handleCopy}>
                    {t('copy')}
                  </Button>
                </GridContainer>
              </Paper>
            </div>
          </div>
        )}
      </Container>
    </>
  )
}

export default BetLimitSetting
