import { Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './index.style'
import classNames from 'classnames'
import moment from 'moment'

const RoundDetailTable = props => {
  const {
    timezone,
    t,
    currentAgent,
    result,
    roundnumber,
    time,
  } = props

  const classes = useStyles()

  const tableColumn = [t('agent'), t('player'), t('order'), t('currency'), 'IP']

  return (
    <>
      <Typography variant="caption" style={{ fontSize: '1rem' }}>
        {t('roundNumber')}:{roundnumber} {t('datetime')}:
        {moment(new Date(time * 1000)).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')}
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            {tableColumn.map((item) => <th key={item} className={classes.row}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {result.map((row, index) => {
            const { parentAcc, account, livebetdetail, currency, ip } = row
            const detailLength = livebetdetail.length
            const isSelfAgent = currentAgent === parentAcc
            return (
              <tr key={`${account}-${index}`}>
                <td className={classNames(classes.row, isSelfAgent && classes.selfAgent)}>{parentAcc}</td>
                <td className={classNames(classes.row, isSelfAgent && classes.selfAgent)}>{account}</td>
                <td className={classes.row}>
                  {livebetdetail.map((type, index) => {
                    const { bettype, bet } = type
                    const isLast = detailLength === index + 1
                    return (
                      <React.Fragment key={`${bettype}-${index}`}>{t(bettype)}:{bet}{!isLast && ' | '}</React.Fragment>
                    )
                  })}
                </td>
                <td className={classes.row}>{currency}</td>
                <td className={classes.row}>{ip}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

RoundDetailTable.propTypes = {}

export default RoundDetailTable
