import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
// import { NavLink } from 'react-router-dom';

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  get: {
    color: 'green',
  },
  post: {
    color: 'orange',
  },
  delete: {
    color: 'red',
  },
  patch: {
    color: 'block',
  },
  put: {
    color: 'blue',
  },
})

class SwitchList extends PureComponent {
  render () {
    const { classes, items = [], handleSelect } = this.props
    return (
      <div className={classes.root}>
        <List component="nav">
          {items &&
            items.map(i => (
              <ListItem key={i.id}>
                <Switch
                  checked={i.checked}
                  onChange={() => handleSelect(i)}
                  color="primary"
                />
                <ListItemText primary={i.title} secondary={i.subTitle} />
              </ListItem>
            ))}
        </List>
      </div>
    )
  }
}

SwitchList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SwitchList)
