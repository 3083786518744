// 注單查詢
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import a from '../../actions'
import {
  getReportByFilter,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
  getPromoList,
  getPromoPeriod,
} from '../../reducers'
import { formatDate } from '../../helpers'
import language from '../../locale/playerPayoff'
import { getSavedState } from '../../helpers/storage'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import PlayerPayoffForParentForm from '../../components/forms/PlayerPayoffForParentForm'
import DataTable from '../../components/tables/DataTable'
import ReportSummaryCard from '../../components/ReportSummaryCard'

class PlayerPayoffForParent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
      selectedPromoID: '',
      formatSummaryPayoff: [],
    }
  }

  componentDidMount () {
    const { i18n } = this.props
    // todo build payoff i18n
    i18n.addResourceBundle('en', 'playerPayoff', language.en)
    i18n.addResourceBundle('cn', 'playerPayoff', language.cn)

    this.setColumns()
    this.setFormatSummaryPayoff()
    this.fetchPromoList()
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
      promoPeriod,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
      this.setFormatSummaryPayoff(reports.totalAmount)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲類型、遊戲名稱、遊戲項目」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
      this.setFormatSummaryPayoff()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }

    if (this.state.selectedPromoID !== prevState.selectedPromoID) {
      this.fetchPromoPeriod(this.state.selectedPromoID)
    }

    if (props.promoPeriod !== promoPeriod && promoPeriod) {
      this.setGotPeriod(promoPeriod)
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('no'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('promoname'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('ranking'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('period'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('ownerAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('account'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('amount'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('mtcode'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('sendtime'),
          options: {
            filter: false,
            sort: false,
          },
        },
      ],
    })
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state
    // todo get data
    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) { }
  };

  handleSelectedPromo = promoID => {
    this.setState({
      selectedPromoID: promoID,
    })
  };

  setGotPeriod = period => {
    this.setState({
      gotPeriod: period,
    })
  }

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    const { t } = this.props

    this.setState({
      formatReports: data.map(item => {
        // 因應壓碼王總榜的id為9999, 需替換為可識別文字
        if (item.periodid === 9999) item.periodid = t('totalRanking')
        return [
          item.no,
          item.promoname,
          item.rank,
          item.periodid ? item.periodid : '_',
          item.owneracc,
          item.parentacc,
          item.account,
          item.amount,
          item.mtcode,
          item.sendtime,
        ]
      }),
    })
  };

  setFormatSummaryPayoff = (total) => {
    this.setState({
      formatSummaryPayoff: [
        {
          name: 'TOTAL',
          data: [
            {
              key: 'totalpayoff',
              value: total,
            },
          ],
        },
      ],
    })
  }

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) { }
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'playersPayoff',
      fileName: t('playersPayoff', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  }

  handlePromoSelected = id => {
    this.setState({ selectedPromoID: id })
  }

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'playersPayoff' })
  };

  fetchPromoList = payload => {
    this.props.fetchPromoList({ ...payload })
  }

  fetchPromoPeriod = promoid => {
    this.props.fetchPromoPeriod({ promoID: promoid })
  }

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
      this.setFormatSummaryPayoff()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      canDownload,
      isFetching,
      timezone,
      promoList,
      promoPeriod,
    } = this.props
    const {
      formatReports,
      columns,
      payload,
      isSearched,
      rowsPerPage,
      formatSummaryPayoff,
      reportPagination,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <PlayerPayoffForParentForm
              promoID=""
              playerAcc=""
              startDate={startDate}
              endDate={endDate}
              pagesize={rowsPerPage}
              nowpage={1}
              onSubmit={this.handleSubmit}
              isFetching={() => isFetching}
              promoList={() => promoList}
              promoPeriod={() => promoPeriod}
              selectedPromo={this.handleSelectedPromo}
            />
          </Paper>
        </GridItemFull>

        {isSearched &&
          <GridItemFull>
            <ReportSummaryCard data={formatSummaryPayoff} />
          </GridItemFull>
        }

        {isSearched &&
          <GridItemFull>
            <DataTable
              data={formatReports}
              columns={columns}
              pagination={reportPagination}
              payload={payload}
              canDownload={canDownload}
              downloadReport={this.handleDownload}
              options={{
                rowsPerPageOptions: [30, 50, 100],
                rowsPerPage,
                onChangePage: this.handleChangePage,
                onChangeRowsPerPage: this.handleChangeRowsPerPage,
              }}
            />
          </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'playersPayoff')
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'playersPayoff')
  const promoList = getPromoList(state)
  const promoPeriod = getPromoPeriod(state)

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  return {
    canDownload,
    language,
    reports,
    timezone,
    isFetching,
    promoList,
    promoPeriod,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchReport,
    fetchPromoList: a.promo.fetchPromoList,
    fetchPromoPeriod: a.promo.fetchPromoPeriod,
  },
)(withTranslation('playerPayoff')(PlayerPayoffForParent))
