import React from 'react'
import MUIDataTable from 'mui-datatables'
import { TableFooter, TableRow, TablePagination } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import DownloadIcon from '../DownloadIcon'
import GotoIcon from '../GotoIcon'
import TablePaginationActions from './TablePaginationActions'

function BaseDataTable ({
  goto = null,
  downloadReport = () => { },
  payload,
  options,
  t,
  noPage,
  ...other
}) {
  const {
    rowsPerPageOptions = [30, 100, 500, 1000],
    page,
  } = options

  return (
    <MUIDataTable
      options={{
        textLabels: {
          body: {
            noMatch: t('noMatch'),
          },
          toolbar: {
            search: t('search'),
          },
        },
        selectableRows: 'none',
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        search: false,
        customToolbar: () => {
          return (
            <>
              {payload && other.data && other.data.length > 0 && other.canDownload && (
                <DownloadIcon
                  onClick={() => {
                    if (payload) {
                      downloadReport(payload)
                    }
                  }}
                />
              )}
              {goto && <GotoIcon label={goto.label} url={goto.url} />}
            </>
          )
        },
        customFooter: (
          count,
          poorPage,
          rowsPerPage,
          changeRowsPerPage,
          changePage,
        ) => noPage ? <TableFooter /> : (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                rowsPerPage={rowsPerPage}
                // mui-datatables 此版有 bug 有時不會傳最新的 page (要升上3.14才有解)
                // 因此後端處理分頁的改用 props.options.page
                // 沒傳 page 才由 mui-datatables 處理分頁(poorPage)
                page={page ?? poorPage}
                onChangePage={(e, page) => {
                  // NOTE: material-ui issue 13995
                  // without this check, there is an issue with "material-ui" TablePagination component
                  // which triggers changePage() with e = null
                  if (e !== null) {
                    changePage(page)
                  }
                }}
                onChangeRowsPerPage={event =>
                  changeRowsPerPage(event.target.value)
                }
                rowsPerPageOptions={rowsPerPageOptions}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('rowsPerPage')}
              />
            </TableRow>
          </TableFooter>
        ),
        ...options,
      }}
      {...other}
    />
  )
}

export default withTranslation('common')(BaseDataTable)
