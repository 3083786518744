import React from 'react'
import './fixStyles'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns' // choose your lib
import enUS from 'date-fns/locale/en-US'
import zhCN from 'date-fns/locale/zh-CN'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'

import CssBaseline from '@material-ui/core/CssBaseline'

import { getTheme } from '../../reducers'
import { switchMode } from '../../themes/bo'
import { history } from '../../configureStore'
import App from '../App'
import './index.scss'

const styles = {
  success: { color: 'white', backgroundColor: '#4caf50' }, // green
  error: { color: 'white', backgroundColor: '#e53935' }, // red
  warning: { color: 'white', backgroundColor: '#ff8f00' }, // yellow
  info: { color: 'white', backgroundColor: '#0288d1' }, // blue
}

const localeMap = {
  en: enUS,
  cn: zhCN,
}

const Root = ({ store, ...props }) => {
  const themeMode = getTheme(store.getState())
  const { i18n } = useTranslation()

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={switchMode(themeMode)}>
          <CssBaseline /> {/* initialize css */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
            <SnackbarProvider
              classes={{
                variantSuccess: props.classes.success,
                variantError: props.classes.error,
                variantWarning: props.classes.warning,
                variantInfo: props.classes.info,
              }}
              maxSnack={9}>
              <Route render={p => <App {...p}/>} />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default withStyles(styles)(Root)
