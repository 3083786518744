import React, { useCallback, useState } from 'react'
import {
  Table as DefaultTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Switch,
} from '@material-ui/core'

import useStyles from './EnhancedDataTable.style'
import TablePaginationActions from './TablePaginationActions'
import { TABLE_COLUMN_DATA as columns } from '../constant'
import classNames from 'classnames'

const EnhancedDataTable = ({
  t,
  isAllSwitchOn,
  isAllSwitchTouched,
  disabled = true,
  total = 0,
  rows = [],
  rowsPerPage = 30,
  onRowsPerPageChange,
  onPageChange = () => {},
  onSwitchChange = () => {},
  onAllSwitchChange = () => {},
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)

  const handleSwitchChange = useCallback((e) => { onSwitchChange(e.target.dataset.data) }, [onSwitchChange])
  const handleAllSwitchChange = useCallback((e) => { onAllSwitchChange(e.target.checked) }, [onAllSwitchChange])

  const handlePageChange = useCallback((_, newPage) => {
    setPage(newPage)
    onPageChange(newPage + 1, rowsPerPage, false)
  }, [onPageChange, rowsPerPage])

  const handleRowsPerPageChange = useCallback((e) => {
    onRowsPerPageChange(parseInt(e.target.value, 10))
    setPage(0)
    onPageChange(0, e.target.value, false)
  }, [onPageChange, onRowsPerPageChange])

  return (
    <DefaultTable>
      <TableHead>
        <TableRow className={classes.headerRow}>
          {
            columns.map((column) => (
              <TableCell key={column.label} align={column.align} className={classes.tableCell}>
                {t(column.label)}
                {column.switch &&
                  <Switch
                    color="primary"
                    className={classNames(
                      isAllSwitchOn ? classes.enabled : classes.disabled,
                      disabled && classes.notAllowed,
                    )}
                    disabled={disabled}
                    checked={isAllSwitchOn}
                    onClick={handleAllSwitchChange}/>}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          const { userid, overunder, isowner } = row
          return <TableRow key={userid} className={classes.border}>
            <TableCell align="center" className={classes.tableCell}>
              <span className={classNames(isowner && classes.ownerIcon)}>
                {row.account}
              </span>
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              {columns[1].renderOption({
                isAllSwitchTouched,
                classes,
                userid,
                checked: overunder,
                disabled,
                onChange: handleSwitchChange,
              })}
            </TableCell>
          </TableRow>
        })}
      </TableBody>
      <TableFooter>
        {<TableRow>
          <TablePagination
            count={total}
            page={page}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            rowsPerPageOptions={[30, 100, 500, 1000]}
            backIconButtonProps={{ color: 'secondary' }}
            nextIconButtonProps={{ color: 'secondary' }}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>}
      </TableFooter>
    </DefaultTable>
  )
}

export default EnhancedDataTable
