import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
  pageNumber: {
    width: 24,
    height: 24,
    fontSize: 10,
    lineHeight: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

/**
 * @ignore - internal component.
 */
class TablePaginationActions extends React.Component {
  state = {
    items: [],
  };

  componentDidMount () {
    this.handlePageNumber(this.props.page)
  }

  componentDidUpdate (props) {
    const { count, page, rowsPerPage } = this.props
    if (count !== props.count || page !== props.page) {
      this.handlePageNumber(page)
    }

    if (rowsPerPage !== props.rowsPerPage) {
      this.handlePageNumber(0)
    }
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
    this.handlePageNumber(this.props.page - 1)
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
    this.handlePageNumber(this.props.page + 1)
  };

  handleNumberButtonClick = page => event => {
    this.props.onChangePage(event, page)
    this.handlePageNumber(page)
  };

  handlePageNumber = currentPage => {
    const { classes, rowsPerPage, count } = this.props

    const items = []
    const totalPage = Math.ceil(count / rowsPerPage)
    let startPage = 0

    // 向後推x頁
    if (totalPage - currentPage >= 5 && currentPage >= 5) {
      startPage = currentPage - 5
    }

    // 向前推x頁
    if (totalPage - currentPage <= 5 && currentPage >= 5) {
      const flag = totalPage < 10 ? totalPage : 10
      startPage = currentPage - (flag - (totalPage - currentPage))
    }

    for (let i = startPage; i < totalPage; i++) {
      items.push(
        <IconButton
          key={i}
          onClick={this.handleNumberButtonClick(i)}
          color="inherit"
          disabled={i === currentPage}
          className={classes.pageNumber}
        >
          {i + 1}
        </IconButton>,
      )

      if (items.length >= 10) {
        break
      }
    }
    this.setState({ items })
  };

  render () {
    const {
      classes,
      backIconButtonProps,
      count,
      nextIconButtonProps,
      onChangePage,
      page,
      rowsPerPage,
      theme,
      ...other
    } = this.props
    const { items } = this.state

    return (
      <div className={classes.root} {...other}>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          color="inherit"
          {...backIconButtonProps}
        >
          {theme.direction === 'rtl'
            ? <KeyboardArrowRight />
            : <KeyboardArrowLeft />
          }
        </IconButton>

        {items}

        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          color="inherit"
          {...nextIconButtonProps}
        >
          {theme.direction === 'rtl'
            ? <KeyboardArrowLeft />
            : <KeyboardArrowRight />
          }
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  /**
   * Properties applied to the back arrow [`IconButton`](/api/icon-button/) element.
   */
  backIconButtonProps: PropTypes.object,
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Properties applied to the next arrow [`IconButton`](/api/icon-button/) element.
   */
  nextIconButtonProps: PropTypes.object,
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback
   * @param {number} page The page selected
   */
  onChangePage: PropTypes.func.isRequired,
  /**
   * The zero-based index of the current page.
   */
  page: PropTypes.number.isRequired,
  /**
   * The number of rows per page.
   */
  rowsPerPage: PropTypes.number.isRequired,
  /**
   * @ignore
   */
  theme: PropTypes.object.isRequired,
}

export default withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
)
