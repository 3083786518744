const rawData = {
  title: ['Open particular games', '开启特殊游戏'],
  // form
  brand: ['Brand', '品牌'],
  info: ['* Settings for Particular game’s blacklists.', '＊游戏黑名单特殊设定'],
  owner: ['Owner account', '总代理'],
  gamecode: ['Game Code', '游戏代号'],
  agent: ['Parent', '子代理'],
  none: ['None', '无'],
  all: ['All', '全部'],
  part: ['Select By User', '手动输入'],
  parentSelectByUser: ['Select By User', '手动输入子代理'],
  check: ['Follow-up sub-agent accounts open particular games by default', '未来新建子代理预设开启游戏'],
  set: ['Set', '设定'],

  // dialog
  dialogTitle: ['Open particular games', '开启特殊游戏'],
  dialogDescription_none: ['Only the general account opens a particular game. ', '仅有总代开启游戏'],
  dialogDescription_all: ['The general account and all of the sub-agent accounts open a particular game. ', '总代与其所有子代皆开启游戏'],
  dialogDescription_part: ['The general and the chosen sub-agent accounts open a particular game.', '总代与手动输入的子代皆开启游戏'],
  dialogDescriptionChecked: [', follow-up sub-agent accounts open a particular game by default.', '，未来新建代理预设开启游戏'],
  dialogDisagree: ['Cancel', '取消'],
  dialogAgree: ['Confirm', '执行'],

  // input error,
  required: ['This is a required field.', '此为必填栏位'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
