import actionType from '../../actions/actionType'

// defaultState: {
//  isEventAAAFetching: true
// }
const defaultState = {}

// requestState 用於管理請求事件狀態
const requestState = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionType.event.requestStart:
      return {
        ...state,
        [action.payload.eventName]: true,
      }
    case actionType.event.requestEnd:
      return {
        ...state,
        [action.payload.eventName]: false,
      }
    default:
      return state
  }
}

export const getIsRequesting = (state, eventName) => {
  return state[eventName] || false
}

export default requestState
