import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'

import SelectTextField from '../text-fields/SelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'

import GridContainer from '../grids/GridContainer'
import { renderFieldsWithGridItem } from './helper'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    gamecode: Yup.string().required('required'),
    period: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'LottoPeriodGameForm',
})

const LottoPeriodGameForm = props => {
  const { isSubmitting, classes, filterGamesOptions, t, isFetching } = props

  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'gamecode',
        label: t('gamename'),
        fullWidth: true,
        options: filterGamesOptions(),
        isClearable: true,
      },
      {
        name: 'period',
        label: t('period'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withTranslation('playerOrder')(withStyles(styles)(LottoPeriodGameForm)),
)
