import React from 'react'
import MUIDataTable from 'mui-datatables'
import { withTranslation } from 'react-i18next'

function BaseDataTable ({
  goto = null,
  payload,
  options,
  t,
  ...other
}) {
  const columns = [
    {
      name: 'owner',
      label: t('owner'),
    },
    {
      name: 'agent',
      label: t('agent'),
    },
    {
      name: 'gamehall',
      label: t('gamehall'),
    },
    {
      name: 'gametype',
      label: t('gametype'),
      options: {
        customBodyRender: gametype => {
          return gametype.join(', ')
        },
      },
    },
    {
      name: 'gamenames',
      label: t('gamenames'),
      options: {
        customBodyRender: gamenames => {
          return gamenames.join(', ')
        },
      },
    },
  ]

  return (
    <MUIDataTable
      options={{
        textLabels: {
          body: {
            noMatch: t('noMatch', { ns: 'common' }),
          },
        },
        selectableRows: 'none',
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        search: false,
        sort: false,
        pagination: false,
        ...options,
      }}
      columns={columns}
      {...other}
    />
  )
}

export default withTranslation('getBlackList')(BaseDataTable)
