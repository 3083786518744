import { DEVELOPMENT } from '../consts/env'

// 10 s
const hearBeatTimeout = 10000

function getURL (token) {
  if (process.env.NODE_ENV === DEVELOPMENT) {
    return `${process.env.REACT_APP_RP_WS_HOST}?Authorization=${token}`
  }

  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
  const url = `${protocol}${window.location.host}${process.env.REACT_APP_RP_WS_HOST}`

  return `${url}?Authorization=${token}`
}

class WS {
    Connect = (token) => {
      const wsUrl = getURL(token)
      this.ws = new WebSocket(wsUrl)

      this.ws.addEventListener('message', this.onMessage)
      this.ws.addEventListener('open', () => {
        this.heartbeat()
      })
    }

    Disconnect = () => {
      this.cancelheartbeat()
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.close()
      }
    }

    Register = (listens) => {
      this.listens = listens
    }

    onMessage = e => {
      let response = null

      try {
        response = JSON.parse(e.data)
      } catch (error) {
        console.log('WS Receview message failed, parse data to json error: ', error)
        return
      }

      if (response.data) {
        const listen = this.listens.find(i => {
          return i.name === response.data
        })
        listen && listen.event && listen.event()
      }
    };

    heartbeat = () => {
      if (this.ws && this.ws.readyState === this.ws.OPEN) {
        this.ws.send(0x9)
      }

      this.timer = setTimeout(this.heartbeat, hearBeatTimeout)
    };

    cancelheartbeat = () => {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    };
}

export default new WS()
