import React, { Component } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import OutlinedTextField from '../text-fields/OutlinedTextField'

import { renderFields } from './helper'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
      props.handleCancel()
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'UserInfoForAdmin',
  enableReinitialize: true,
})

class UserInfoForAdmin extends Component {
  render () {
    const { theme, t } = this.props

    const fields = [
      [
        {
          name: 'account',
          label: t('account'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
      ],
      [
        {
          name: 'signUpAt',
          label: t('signUpAt'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
        {
          name: 'loginAt',
          label: t('loginAt'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
      ],
    ]

    return (
      <Form>
        <h3 style={ theme.customer.H3Style }>{t('basicInfo')}</h3>
        {fields.map(field => renderFields(field, this.props))}
      </Form>
    )
  }
}

const userInfoForAdmin = formikEnhancer(
  withStyles(styles, { withTheme: true })(
    withTranslation('userProfile')(UserInfoForAdmin),
  ),
)

export default userInfoForAdmin
