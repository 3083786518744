import { combineReducers } from 'redux'
import produce from 'immer'
import actionType from '../../actions/actionType'

const defaultState = {
  noFeatures: false,
  isFetching: false,
  items: [],
  mappedItems: {
    routes: [],
    menu: [],
  },
}

const siteFeatures = produce((
  state,
  action,
) => {
  switch (action.type) {
    case actionType.siteFeature.reset:
      return defaultState
    case actionType.siteFeature.fetchRequest:
      state.isFetching = true
      break
    case actionType.siteFeature.fetchSuccess:
      state.noFeatures = !action.payload.mappedItems.menu.length
      state.items = action.payload.items
      state.mappedItems = action.payload.mappedItems
      state.isFetching = false
      break
    case actionType.siteFeature.fetchFailure:
      state.noFeatures = true
      state.isFetching = false
      state.items = defaultState.items
      state.mappedItems = defaultState.mappedItems
      break
    default:
      break
  }
}, defaultState)

export default combineReducers({
  siteFeatures,
})

export const getSiteFeatures = state => state.siteFeatures
