import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import Container from '../../components/Container'
import {
  getTeams,
  getSelectTeam,
  getTeamMembersByTeamId,
} from '../../reducers'
import a from '../../actions'
import language from '../../locale/teamMember'

import SelectCard from '../../components/SelectCard'
import { qsParse } from '../../helpers'
import SwitchList from '../../components/SwitchList'
import CardWithTitle from '../../components/CardWithTitle'

class TeamMember extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
    }
  }

  componentDidMount () {
    const { fetchTeams, selectedTeam, fetchTeamMembers, i18n } = this.props

    i18n.addResourceBundle('en', 'teamMember', language.en)
    i18n.addResourceBundle('cn', 'teamMember', language.cn)

    fetchTeams()

    if (selectedTeam) {
      fetchTeamMembers(selectedTeam)
    }
  }

  componentDidUpdate (props) {
    const { fetchTeamMembers, selectedTeam } = this.props

    if (props.selectedTeam !== selectedTeam) {
      fetchTeamMembers(selectedTeam)
    }
  }

  render () {
    const {
      teams,
      selectTeam,
      selectedTeam,
      members,
      updateTeamMember,
      t,
    } = this.props

    return (
      <>
        <Container>
          <Grid container spacing={24}>
            <Grid item xs={3}>
              <SelectCard
                title={t('team')}
                items={teams}
                handleSelect={selectTeam}
                selected={selectedTeam}
              />
            </Grid>
            <Grid item xs={9}>
              <CardWithTitle title={t('member')}>
                <SwitchList
                  items={members.map(i => ({
                    id: i.userId,
                    title: i.account,
                    checked: i.allocated,
                  }))}
                  handleSelect={member => {
                    updateTeamMember({ selectedTeam, member })
                  }
                  }
                />
              </CardWithTitle>
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const teams = getTeams(state)
  const selectedTeam = getSelectTeam(state)

  const members = selectedTeam
    ? getTeamMembersByTeamId(state, selectedTeam.id)
    : []

  const sortMembers = members.sort((a, b) =>
    a.account.localeCompare(b.account),
  )

  return {
    search,
    teams,
    selectedTeam,
    members: sortMembers,
  }
}

export default connect(
  mapStateToProps,
  {
    selectTeam: a.team.select,
    fetchTeams: a.team.fetch,
    updateTeam: a.team.update,
    fetchTeamMembers: a.teamMember.fetch,
    updateTeamMember: a.teamMember.update,
  },
)(withTranslation('teamMember')(TeamMember))
