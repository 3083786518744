import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'

import Container from '../../components/Container'
import {
  getTeams,
  getSelectTeam,
  getTeamFeaturesByTeamId,
  getTheme,
} from '../../reducers'
import a from '../../actions'

import DialogConfirm from '../../components/DialogConfirm'
import Paper from '../../components/Paper'
import SelectCard from '../../components/SelectCard'
import { qsParse } from '../../helpers'
import language from '../../locale/teamFeature'
import { switchMode } from '../../themes/bo'

import { getTree } from '../../helpers/getTree'
import Switch from '@material-ui/core/Switch'
import './TeamFeature.scss'
import { Typography } from '@material-ui/core'
import DataTree from '../../components/DataTree'

// 權限開關連動邏輯
// - 開啟大項目時，子項目就預設幫全開
// - 子項目全部關閉時，大項目也做關閉
// - 子項目被點擊開啟時，對應的大項目也必須要開啟
// - 關閉大項目時，顯示訊息提醒會將所有子項目全關，確定後就全關
class TeamFeature extends PureComponent {
  state = {
    treeFeature: [],
    rowsPerPage: 30,
  };

  componentDidMount () {
    const { fetchTeams, selectedTeam, fetchTeamFeatures, i18n } = this.props

    i18n.addResourceBundle('en', 'teamFeature', language.en)
    i18n.addResourceBundle('cn', 'teamFeature', language.cn)

    fetchTeams()

    if (selectedTeam) {
      fetchTeamFeatures(selectedTeam)
    }
  }

  componentDidUpdate (props) {
    const { fetchTeamFeatures, selectedTeam, features, teams, selectTeam, search } = this.props

    if (props.selectedTeam !== selectedTeam) {
      fetchTeamFeatures(selectedTeam)
    }

    if (selectedTeam === null && teams?.length) {
      const selected = teams?.find(({ id }) => id === search?.team) || null
      selectTeam(selected)
    }

    if (props.features !== features) {
      this.setState({ treeFeature: getTree(features) })
    }
  }

  handleShowModal = ({ name, ...rest }) => {
    this.setState({
      showModal: { ...this.state.showModal, [name]: { show: true, ...rest } },
    })
  };

  handleCloseModal = ({ name }) => {
    this.setState({
      showModal: { ...this.state.showModal, [name]: { show: false } },
    })
  };

  handleConfirmTeam = values => {
    const { updateTeamFeature, selectedTeam } = this.props
    updateTeamFeature({
      selectedTeam,
      feature: {
        allocated: values.node.allocated,
        id: values.node.id,
      },
    })
    this.handleCloseModal({ name: 'confirm' })
  };

  render () {
    const { treeFeature, showModal } = this.state
    const { themeMode, teams, selectTeam, selectedTeam, updateTeamFeature, t } = this.props

    // 調整父、子層 title 文案：i18n 對應字典檔
    const tf = treeFeature.map(feature => ({
      ...feature,
      title: t(feature.key),
      children: feature.children
        .map(child => ({
          ...child,
          title: t(child.key),
        })),
    }))
    const theme = switchMode(themeMode)

    return (
      <>
        {showModal && showModal.confirm && (
          <DialogConfirm
            open={showModal.confirm.show}
            onClose={() => this.handleCloseModal({ name: 'confirm' })}
            onSubmit={this.handleConfirmTeam}
            initValues={{ ...showModal.confirm }}
            content={t('content')}
            title={t('title')}
          />
        )}

        <MuiThemeProvider theme={switchMode(themeMode)}>

          <Container>

            <Grid container spacing={8} >
              <Grid item xs={12}>
                <Paper>
                  <Typography align="center" >{t('warrning')}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <SelectCard
                  title={t('team')}
                  items={teams}
                  handleSelect={selectTeam}
                  selected={selectedTeam}
                />
              </Grid>
              <Grid item xs>
                <Paper>
                  <DataTree
                    treeData={tf}
                    defaultExpand
                    nodeContent={
                      (node) => {
                        return <span style={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary }}>
                          <Switch
                            checked={node.allocated}
                            onChange={() => {
                            // 若關閉的是大項目，跳出訊息提醒
                              if (node.allocated && !node.parentId) {
                                this.handleShowModal({ name: 'confirm', node, tf })
                                return
                              }

                              return updateTeamFeature({
                                selectedTeam,
                                feature: {
                                  allocated: node.allocated,
                                  id: node.id,
                                },
                              })
                            }}
                            color="primary"
                          />
                          <div style={{ marginLeft: 8 }}>
                            {node.title}
                          </div>
                        </span>
                      }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>

      </>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const teams = getTeams(state)
  const selectedTeam = getSelectTeam(state)
  const themeMode = getTheme(state)

  const features = selectedTeam
    ? getTeamFeaturesByTeamId(state, selectedTeam.id)
    : []

  const sortFeatures = features.sort((a, b) => a.id.localeCompare(b.id))

  return {
    themeMode,
    search,
    teams,
    selectedTeam,
    features: sortFeatures,
  }
}

export default connect(
  mapStateToProps,
  {
    selectTeam: a.team.select,
    fetchTeams: a.team.fetch,
    updateTeam: a.team.update,
    fetchTeamFeatures: a.teamFeature.fetch,
    updateTeamFeature: a.teamFeature.update,
  },
)(withTranslation('teamFeature')(TeamFeature))
