import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch team member
 */
export function * fetch ({ payload: { id } }) {
  try {
    // 1. Fetch team meber request started
    yield put(actions.teamMember.fetchRequest({ teamId: id }))

    // 2. Call API: fetch team member
    const res = yield call(api.get, api.RP, `/teams/${id}/members`)

    // 3. Fetch team meber success
    yield put(actions.teamMember.fetchSuccess({ response: res.data.data, teamId: id }))
  } catch (err) {
    // Fetch team meber failure
    yield put(actions.teamMember.fetchFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update team member
 */
export function * update ({ payload: { selectedTeam: { id: teamId }, member: { checked, id: memberId } } }) {
  try {
    // 1. Update team meber request started
    yield put(actions.teamMember.updateRequest())

    const method = checked ? api.DELETE : api.post
    const url = checked ? `/teams/${teamId}/members/${memberId}` : `/teams/${teamId}/members`

    // 2. Call API: update team member
    const res = yield call(method, api.RP, url, { memberId })

    // 3. Update team meber success
    yield put(actions.teamMember.updateSuccess({ response: res.data.data, teamId }))

    yield call(fetch, { payload: { id: teamId } })
  } catch (err) {
    // Update team meber failure
    yield put(actions.teamMember.updateFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.teamMember.fetch, fetch),
  takeLatest(actionType.teamMember.update, update),
]

export default effects
