import React from 'react'
import { Formik, Form, Field } from 'formik'
import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '../../components/fields/TextField'
import language from '../../locale/dialogUpdateTeamName'

const DialogUpdateTeamName = ({ open, onClose, onSubmit, initValues, ...props }) => {
  const { i18n, t } = props

  i18n.addResourceBundle('en', 'dialogUpdateTeamName', language.en)
  i18n.addResourceBundle('cn', 'dialogUpdateTeamName', language.cn)

  return (
    <Formik
      initialValues={{
        name: '',
        ...initValues,
      }}
      onSubmit={onSubmit}
      enableReinitialize
      render={({ handleSubmit }) => {
        return (
          <Form>
            <Dialog
              open={open}
              onClose={onClose}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
            >
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent>
                <Field
                  name="name"
                  component={TextField}
                  label={t('contentLabel')}
                  margin="dense"
                  InputProps={{
                    autoComplete: 'off',
                    autoFocus: true,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">{t('cancel')}</Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    />
  )
}

export default withTranslation('dialogUpdateTeamName')(DialogUpdateTeamName)
