import { useContext } from 'react'
import { AppDrawerContext } from './provider'

const useDrawer = () => {
  const {
    list,
    menuState,
    setMenuState,
    bookmarkList,
    setBookmarkList,
    isAnyMenuOpen,
    fullBookmarkList,
    ...extraProps
  } = useContext(AppDrawerContext)

  const toggleAllMenu = () => {
    setMenuState(state =>
      Object
        .keys(state)
        .reduce((res, key) => {
          res[key] = !isAnyMenuOpen
          return res
        }, {}),
    )
  }

  /**
   * 玩家管理 -> 就是 key
   *  - 玩家列表
   *  - 注單查詢
   */
  const toggleSingleMenu = (key) => {
    const newMenuState = { ...menuState, [key]: !menuState[key] }
    setMenuState(newMenuState)
  }

  const handleAddBookmark = (key) => {
    if (bookmarkList.includes(key)) return
    const newBookmarkList = [...bookmarkList, key]
    setBookmarkList(newBookmarkList)
  }

  const handleRemoveBookmark = (key) => {
    const index = bookmarkList.indexOf(key)
    if (index === -1) return
    const newBookmarkList = [...bookmarkList]
    newBookmarkList.splice(index, 1)
    setBookmarkList(newBookmarkList)
  }

  return {
    list, // 初始選單資料

    menuState, // menu 開合狀態
    isAnyMenuOpen, // 是否有任何 menu 正開啟
    toggleAllMenu, // 開/關全部 menu
    toggleSingleMenu, // 開/關單一下拉

    bookmarkList, // 書籤列表
    fullBookmarkList, // 書籤列表完整資訊
    handleAddBookmark, // 加入書籤
    handleRemoveBookmark, // 移除書籤

    ...extraProps, // 在provider extra參數裡
  }
}

export default useDrawer
