const rawData = {
  cancel: ['Cancel', '取消'],
  confirm: ['Confirm', '确定'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
