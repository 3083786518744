import actionType from '../../actions/actionType'

const defaultState = {}

const order = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionType.order.fetchOrderSuccess:
      return action.payload
    case actionType.order.fetchOrderFailure:
      return state
    default:
      return state
  }
}

export default order
