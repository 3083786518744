import { combineReducers } from 'redux'
import members, * as fromMembers from './members'
import features, * as fromFeatures from './features'
import actionType from '../../actions/actionType'

const selectTeam = (state = null, action = {}) => {
  switch (action.type) {
    case actionType.team.select:
      return action.payload
    default:
      return state
  }
}

const teams = (
  state = {
    isFetching: false,
    items: [],
  },
  action = {},
) => {
  switch (action.type) {
    case actionType.team.fetchRequest:
      return { ...state, isFetching: true }
    case actionType.team.fetchSuccess:
      return {
        ...state,
        isFetching: false,
        items: action.payload.response,
      }
    default:
      return state
  }
}

export default combineReducers({ teams, selectTeam, members, features })

export const getTeams = state => state.teams.items
export const getSelectTeam = state => state.selectTeam

export const getTeamMembersByTeamId = (state, teamId) =>
  fromMembers.getTeamMembersByTeamId(state.members, teamId)
export const getTeamFeaturesByTeamId = (state, teamId) =>
  fromFeatures.getTeamFeaturesByTeamId(state.features, teamId)

export const getIsTeamFeaturesFetching = (state, teamId) =>
  fromFeatures.getIsTeamFeaturesFetching(state.features, teamId)
