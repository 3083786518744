import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectTextField from '../../components/text-fields/SelectTextField'
import * as Yup from 'yup'
import { compose } from 'redux'
import { Form, withFormik } from 'formik'

import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Tab, Tabs } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { renderFields } from '../../components/forms/helper'

import MultiSelectTextField from '../../components/text-fields/MultiSelectTextField'
import { useSelector } from 'react-redux'

const styles = theme => ({
  tab: {
    color: '#fff',
  },
  info: {
    color: theme.palette.text.secondary,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  selectGroupLabel: {
    fontSize: 18,
    color: theme.palette.primary.main,
    textTransform: 'initial',
  },
})

const yupSchema = Yup.object().shape({
  parentid: Yup.array().when('gamecode', {
    is: (x) => !x?.length,
    then: Yup.array().min(1, 'required'),
  }),
  gamecode: Yup.array().when('parentid', {
    is: (x) => !x?.length,
    then: Yup.array().min(1, 'required'),
  }),
}, [['parentid, gamecode'], ['gamecode', 'parentid']])

const formikEnhancer = withFormik({
  validationSchema: yupSchema,
  handleSubmit: async (values, { props, setSubmitting, setTouched }) => {
    const parentid = []
    const gamecode = []

    values.agentid.forEach(agent => (parentid.push(agent.value)))
    values.parentid.forEach(parent => (parentid.push(parent.value)))
    if (values.gamecode) {
      values.gamecode.forEach(code => (gamecode.push(code.value)))
    }

    try {
      await props.onSubmit({ parentid, gamecode, gamehall: values.brand })
    } catch (error) { console.error(error) }

    setSubmitting(false)
    setTouched({ parentid: false, gamecode: false })
  },
  displayName: 'getBlackList',
  enableReinitialize: true,
})

const GetBlackList = props => {
  const {
    brandOptions,
    classes,
    isSubmitting,
    t,
    setFieldValue,
    isFetching,
    ownerOption,
    parentOption,
    buttonRef,
    fetchAgents,
    removeAgent,
    clearAgent,
    onClear,
    brand,
    onBrandChange,
    onParentIdChange,
    onAgentIdChange,
    role,
    type,
  } = props

  const language = useSelector((state) => state.state.language)
  const allGamesOptions = useSelector((state) => state.games[language])
  const [selectedGameCode, setSelectedGameCode] = useState([])

  // 切換遊戲代碼時，對已選選項做切換語系
  useEffect(() => {
    if (language) {
      setSelectedGameCode(props.values.gamecode.map((item) => ({ label: allGamesOptions.find((option) => option.value === item.value)?.label, value: item.value })))
    }
  }, [allGamesOptions, language, props.values.gamecode])

  const isCS = role === 'cs' && type === 'SYSTEM'

  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'brand',
        label: t('brand'),
        fullWidth: true,
        options: brandOptions,
        onChange: ({ value }) => {
          setFieldValue('brand', value)
          onBrandChange(value)
          onParentIdChange([])
          setFieldValue('parentid', [])
          setFieldValue('agentid', [])
          onAgentIdChange([])
          clearAgent()
        },
        hide: role !== 'admin' && !isCS,
      },
      {
        Field: MultiSelectTextField,
        name: 'parentid',
        label: t('owner'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        applyValue: true,
        onChange: (value, { action, removedValue }) => {
          onParentIdChange(value)
          switch (action) {
            case 'remove-value': {
              // 若刪除已選取的總代，則子代 Select 也要清除該總代的子代
              // 刪除 store 中子代 options
              removeAgent(removedValue)

              // 刪除子代 Selecti values
              const newAgentid = props.values.agentid.filter(agent => {
                return agent.parentID !== removedValue.value
              })

              setFieldValue('agentid', newAgentid)
              onAgentIdChange(newAgentid)
              break
            }
            case 'select-option': {
              const len = value.length
              // 選擇總代理後向 bo 請求該總代理的子代理清單。回傳資料作為子代理 Select options
              fetchAgents({ role: 'parent', account: value[len - 1].label, userID: value[len - 1].value })
              break
            }
            case 'clear':
              // 清除 store 中子代 options
              clearAgent()
              setFieldValue('agentid', [])
              onAgentIdChange([])
              break
            default:
              break
          }

          return setFieldValue('parentid', value)
        },
        InputLabelProps: {
          shrink: true,
        },
        options: ownerOption.filter(({ brand: _brand }) => brand === _brand),
      },
      {
        Field: MultiSelectTextField,
        name: 'agentid',
        label: t('agent'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        applyValue: true,
        formatGroupLabel: data => {
          return (
            <span className={classes.selectGroupLabel}>{data.label}</span>
          )
        },
        onChange: (value) => {
          setFieldValue('agentid', value)
          onAgentIdChange(value)
        },
        InputLabelProps: {
          shrink: true,
        },
        options: parentOption,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'brand',
        label: t('brand'),
        fullWidth: true,
        options: brandOptions,
        onChange: ({ value }) => {
          setFieldValue('brand', value)
          onBrandChange(value)
        },
        hide: role !== 'admin' && !isCS,
      },
      {
        Field: MultiSelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: selectedGameCode,
        onChange: (value) => { setFieldValue('gamecode', value) },
        InputLabelProps: {
          shrink: true,
        },
        options: allGamesOptions,
      },
    ],
  ]
  const tabList = ['byAgent', 'byGamecode']
  const [tabValue, setTabValue] = useState(0)

  const handleTabValue = (_, value) => {
    if (value === 0) {
      setFieldValue('gamecode', [])
    }
    if (value === 1) {
      setFieldValue('parentid', [])
      onParentIdChange([])
      setFieldValue('agentid', [])
      onAgentIdChange([])
    }
    onClear()
    setTabValue(value)
  }

  return (
    <Form>
      <Tabs value={tabValue} onChange={handleTabValue} >
        {tabList.map((tab) => <Tab key={tab} label={t(tab)} classes={{ root: classes.tab }} />)}
      </Tabs>
      {tabValue === 0 && renderFields(fields[0], { t, ...props })}
      {tabValue === 1 && renderFields(fields[1], { t, ...props })}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
        buttonRef={buttonRef}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

GetBlackList.propTypes = {
  agents: PropTypes.array,
  agentid: PropTypes.array,
  parentid: PropTypes.array,
  gamecode: PropTypes.array,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
}

GetBlackList.defaultProps = {
  // 代理帳號清單
  agents: [],
  // renderfield 初始值
  agentid: [],
  // renderfield 初始值
  parentid: [],
  gamecode: [],
  // submit callback
  onSubmit: () => { },
  // 請求狀態
  isFetching: false,
}

export default compose(
  withTranslation('getBlackList'),
  withStyles(styles),
  formikEnhancer,
)(GetBlackList)
