// 代理遊戲分析報表
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import a from '../../actions'
import {
  getUser,
  getReportByFilter,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
  getBrandInfo,
} from '../../reducers'
import { qsParse, formatDateByGroupBy, formatMoney } from '../../helpers'
import language from '../../locale/freeTicketAnalysisReport'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import FreeTicketAnalysisReportFormForParent from '../../components/forms/FreeTicketAnalysisReportFormForParent'
import DataTable from '../../components/tables/DataTable'

class FreeTicketAnalysisReportForParent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      formatSummaryReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'freeTicketAnalysisReport', language.en)
    i18n.addResourceBundle('cn', 'freeTicketAnalysisReport', language.cn)
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲名稱」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language) {
      this.setColumns()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }
  }

  setColumns = () => {
    const { t } = this.props
    const { payload } = this.state

    if (!payload) {
      return
    }

    const columns = {
      all: [
        {
          name: t('datetime'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('gamename'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('tickettype'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('giventype'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('ticketbets'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('totalround'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('wins'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
      ],
    }

    // 找不到就預設為機率遊戲的欄位(老虎機,魚機,街機)
    this.setState({ columns: columns[payload.gameType] || columns.all })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    const { payload } = this.state

    if (!payload) {
      return
    }

    switch (payload.gameType) {
      default:
        this.setState({
          formatReports: data.map(item => [
            item.datetime,
            item.gamename,
            item.currency,
            item.tickettype,
            item.giventype,
            item.ticketbets,
            item.totalround,
            item.wins,
          ]),
        })
        break
    }
  };

  setFormatSummaryReports = data => {
    this.setState({
      formatSummaryReports: data
        ? [
          {
            name: 'TOTAL'.toUpperCase(),
            data: Object.keys(data).map(key => ({
              key,
              value: data[key],
            })),
          },
        ]
        : [],
    })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'freeTicketAnalysisReport',
      fileName: t('freeTicketAnalysisReport', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      gamehall: values.gamehall === 'all' ? '' : values.gamehall,
      startDate: formatDateByGroupBy(values.startDate, values.groupby),
      endDate: formatDateByGroupBy(values.endDate, values.groupby),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) { }
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'freeTicketAnalysisReport' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      history,
      search,
      filterGroupBy,
      canDownload,
      isFetching,
      timezone,
      brandInfo,
    } = this.props
    const {
      formatReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <FreeTicketAnalysisReportFormForParent
              startDate={startDate}
              endDate={endDate}
              groupby={filterGroupBy}
              tickettype=""
              giventype=""
              nowpage={1}
              pagesize={rowsPerPage}
              history={() => history}
              search={() => search}
              onSubmit={this.handleSubmit}
              isFetching={isFetching}
              brand={brandInfo.brand}
            />
          </Paper>
        </GridItemFull>

        {isSearched &&
          <GridItemFull>
            <DataTable
              data={formatReports}
              columns={columns}
              pagination={reportPagination}
              payload={payload}
              canDownload={canDownload}
              downloadReport={this.handleDownload}
              options={{
                rowsPerPage,
                onChangePage: this.handleChangePage,
                onChangeRowsPerPage: this.handleChangeRowsPerPage,
              }}
            />
          </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const user = getUser(state)
  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'freeTicketAnalysisReport')
  const summaryReports = getReportByFilter(
    state,
    'freeTicketAnalysisReportSummary',
  )

  const filterAgentAccount = user.parentAcc
  const filterGroupBy = search.groupBy ? search.groupBy : 'day'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'freeTicketAnalysisReport')

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  // brand
  const brandInfo = getBrandInfo(state)

  return {
    canDownload,
    games: state.games,
    filterAgentAccount,
    filterGroupBy,
    search,
    reports,
    summaryReports,
    language,
    timezone,
    isFetching,
    brandInfo,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchFreeTicketAnalysisReport,
  },
)(
  withTranslation('freeTicketAnalysisReport')(FreeTicketAnalysisReportForParent))
