import React from 'react'
import Switch from '@material-ui/core/Switch'
import classNames from 'classnames'

export const TABLE_COLUMN_DATA = [
  { label: 'agentId', align: 'center' },
  {
    label: 'switch',
    align: 'center',
    switch: true,
    renderOption: (props) => {
      const { isAllSwitchTouched, userid, disabled, classes, checked, onChange } = props

      if (isAllSwitchTouched) {
        return (
          <Switch
            color="primary"
            inputProps={{
              'data-data': [userid, checked],
            }}
            disabled
            checked={checked}
            onChange={onChange}
            className={classes.notAllowed}
          />)
      }
      return (
        <Switch
          color="primary"
          inputProps={{
            'data-data': [userid, checked],
          }}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className={classNames(
            (disabled || isAllSwitchTouched) && classes.notAllowed,
            checked ? classes.enabled : classes.disabled,
          )}
        />)
    },
  },
]
