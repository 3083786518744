import React, { useEffect, useState } from 'react'
import { Collapse, List, ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import ExpandController from './ExpandController'

const SPACING_BASE = 48

const useStyle = makeStyles({
  expandBase: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 2,
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 40,
      backgroundColor: '#6d6d6d',
    },
  },
})

const TreeNode = ({
  children = [], // 子資料
  expand = false, // 是否展開
  spacing = 0, // 巢狀的paddingLeft
  nodeContent = () => {}, // 每一列的內容
  root = false, // 是否為第0曾
  isLastOne = false,
}) => {
  const [mappedChildren, setMappedChildren] = useState([])
  const classes = useStyle()
  useEffect(() => {
    if (children?.length) {
      setMappedChildren(state => {
        return children.map(item => {
          const mappedExapnd = state.find(({ key }) => item.key === key)?.expand // mapped儲存的展開狀態
          return {
            ...item,
            expand: mappedExapnd !== undefined ? mappedExapnd : expand,
          }
        })
      })
    }
  }, [children, expand])

  const handleExpand = (key) => {
    setMappedChildren(state =>
      state.map(item => {
        if (key === item.key) item.expand = !item.expand
        return item
      }))
  }

  return (
    <>
      <List
        className={ (!root && !isLastOne && classes.expandBase) || null }
        style={{ paddingLeft: SPACING_BASE }}
      >
        {mappedChildren.map((node, i, arr) => {
          const isLastOne = arr.length - 1 === i
          const isChildren = !!node?.children.length
          return (
            <React.Fragment key={node.key} >
              <ListItem>
                <ExpandController
                  hasExpand={isChildren}
                  expand={node.expand}
                  onClick={() => { handleExpand(node.key) }}
                  hasLine={!root}
                  isLastOne={isLastOne}
                />
                {nodeContent(node)}
              </ListItem>
              {isChildren && (
                <Collapse in={node.expand} timeout="auto" >
                  <TreeNode
                    children={node.children || []}
                    spacing={spacing + 1}
                    expand={node.expand}
                    nodeContent={nodeContent}
                    isLastOne={isLastOne}
                  />
                </Collapse>
              )}
            </React.Fragment>
          )
        })}
      </List>
    </>
  )
}

TreeNode.propTypes = {
  children: PropTypes.array,
  expand: PropTypes.bool,
  spacing: PropTypes.number,
  nodeContent: PropTypes.func,
}

export default TreeNode
