import produce from 'immer'
import actionType from '../../actions/actionType'

const byId = produce((state, action) => {
  switch (action.type) {
    case actionType.agent.fetchDetailSuccess: {
      state[action.payload.agentId] = state[action.payload.agentId] || {}
      state[action.payload.agentId].info = {
        ...state[action.payload.agentId].info,
        ...action.payload.response,
      }
      if (state[action.payload.agentId].info.stationAccount === undefined) {
        state[action.payload.agentId].info.stationAccount = ''
      }
      break
    }

    case actionType.agent.fetchStationListSuccess: {
      const [{ account }] = action.payload.response || [{}]
      state[action.payload.agentId].info.stationAccount = account
      break
    }

    case actionType.agent.fetchTotalSignUpSuccess:
      state[action.payload.agentId] = state[action.payload.agentId] || {}
      state[action.payload.agentId].totalPlayers = action.payload.response
      break

    // update currency signup
    case actionType.currency.updateSignRequest:
    case actionType.currency.updateSignSuccess:
    case actionType.currency.updateSignFailure:
      if (state[action.payload.agentId]) {
        state[action.payload.agentId].info.currencySign = action.payload.currencySign
      }
      break

    // update status
    case actionType.agent.updateStatusRequest:
    case actionType.agent.updateStatusSuccess:
    case actionType.agent.updateStatusFailure:
      if (state[action.payload.userid]) {
        state[action.payload.userid].info.status = action.payload.action
      }
      break

    // Update betThreshold
    case actionType.betThreshold.updateStatusSuccess:
      if (state[action.payload.response.userid]) {
        state[action.payload.response.userid].info.betThreshold.enabled = (action.payload.response.enabled === 'true')
      }
      break

    case actionType.agent.updateWalletSuccess:
      if (state[action.payload.userid]) {
        state[action.payload.userid].info.wtoken = action.payload.wtoken
        state[action.payload.userid].info.wurl = action.payload.wurl
      }
      break

    default:
      return state
  }
}, {})

export default byId

export const getAgentById = (state, id) =>
  typeof state[id]?.info === 'undefined' ? null : state[id].info

export const getAgentTotalPlayersById = (state, id) =>
  typeof state[id]?.totalPlayers === 'undefined' ? '' : state[id].totalPlayers
