import React from 'react'
import { Field } from 'formik'

import { Checkbox, FormControlLabel } from '@material-ui/core'

function SelectTextField ({
  helperText,
  fullWidth,
  label,
  textFieldProps,
  error,
  theme,
  ...props
}) {
  return (
    <Field
      {...props}
      type="checkbox"
      children={({ field }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name={field.name}
                checked={field.value}
                onChange={field.onChange}
              />}
            label={label}
          />
        )
      }}
    />
  )
}

export default SelectTextField
