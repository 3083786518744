import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../reducers'

import a from '../../actions'
import { formatMoney, qsParse } from '../../helpers'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import CreateAgentForm from '../../components/forms/CreateAgentForm'
import { api } from '../../services'

class CreateAgent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      brandOptions: [],
      currencyOptions: [],
    }
  }

  async componentDidMount () {
    const { data: { data: currencyOptions } } = await api.get(api.BO, '/ratelist')
    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    const currencyOptionsList = currencyOptions.map(({ currency, rate, country }) => ({
      label: `${currency} (${formatMoney(rate)})`,
      value: currency,
      country,
    }))
    this.setState({
      brandOptions: brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    })
    this.setState({ currencyOptions: currencyOptionsList })
  }

  onSubmit = values => {
    return this.props.createAgent(values)
  };

  render () {
    const { brandOptions, currencyOptions } = this.state
    const { role, type } = this.props
    return (
      <Container>
        <GridItem form>
          <Paper>
            <CreateAgentForm
              role={role}
              type={type}
              account=""
              password=""
              nickname=""
              currency="CNY"
              brand={brandOptions?.[0]?.value}
              currencySign="true"
              isTestSS="false"
              commission={0}
              walletType="w"
              wurl=""
              wtoken=""
              stationaccount=""
              stationpwd=""
              currencyOptions={() => currencyOptions }
              brandOptions={brandOptions}
              onSubmit={this.onSubmit}
            />
          </Paper>
        </GridItem>
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const user = getUser(state)
  return {
    search,
    role: user.role,
    type: user.type,
  }
}

export default connect(
  mapStateToProps,
  {
    createAgent: a.agent.createAgent,
  },
)(CreateAgent)
