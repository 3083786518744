import React from 'react'
import { Formik, Form } from 'formik'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '../../components/Button'
import language from '../../locale/dialogDelete'
import { DARK_MODE } from '../../themes/bo'

const styles = theme => ({
  content: {
    color: theme.customer.name === DARK_MODE ? 'white' : '',
  },
  cancel: {
    color: theme.customer.name === DARK_MODE ? 'white' : '',
  },
})

const DialogDelete = ({ open, onClose, onSubmit, initValues, ...props }) => {
  const { i18n, t, classes } = props

  i18n.addResourceBundle('en', 'dialogDelete', language.en)
  i18n.addResourceBundle('cn', 'dialogDelete', language.cn)

  return (
    <Formik
      initialValues={{
        ...initValues,
      }}
      onSubmit={onSubmit}
      enableReinitialize
      render={({ handleSubmit }) => {
        return (
          <Form>
            <Dialog
              open={open}
              onClose={onClose}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
            >
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent className={classes.content}>{t('content')}</DialogContent>
              <DialogActions>
                <Button className={classes.cancel} onClick={onClose} color="primary">{t('cancel')}</Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                  actionType='negative'
                >
                  {t('title')}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    />
  )
}

export default
withStyles(styles)(
  withTranslation('dialogDelete')(DialogDelete),
)
