import { combineReducers } from 'redux'

import { formatMoney } from '../../helpers'
import actionType from '../../actions/actionType'

const list = (
  state = {
    isFetching: false,
    items: [],
  },
  action = {},
) => {
  switch (action.type) {
    case actionType.rate.fetchRequest:
      return { ...state, isFetching: true }
    case actionType.rate.fetchSuccess:
      return {
        ...state,
        isFetching: false,
        items: action.response,
      }
    default:
      return state
  }
}

const currencyOptions = (
  state = {
    isFetching: false,
    items: [],
  },
  action = {},
) => {
  switch (action.type) {
    case actionType.rate.fetchRequest:
      return { ...state, isFetching: true }
    case actionType.rate.fetchSuccess:
      return {
        ...state,
        isFetching: false,
        items: action.payload.response.map(({ currency, rate }) => ({
          label: `${currency} (${formatMoney(rate)})`,
          value: currency,
        })),
      }
    default:
      return state
  }
}

export default combineReducers({ list, currencyOptions })

export const getCurrencyOptions = state => state.currencyOptions.items
