const rawData = {
  redPacketSetting: ['Red Packet Setting', '红包设置'],
  redPacketSearch: ['Red Packet Search', '红包查询'],
  account: ['Account', '玩家'],
  amount: ['Amount', '金额'],
  inputAccount: ['Input Account', '填入玩家帐号'],
  inputAccountPressEnter: ['Input Account Press Enter/TAB', '填入玩家帐号后按 ENTER/TAB'],
  totalAmount: ['Total Amount', '实际总金额'],
  send: ['Send', '送出'],
  redPacketAmountSetting: ['Red packet amount setting', '红包金额设定'],
  confirmToSetRedPacketAmount: ['Whether to set the red envelope amount?', '是否设定红包金额'],
  cancel: ['Cancel', '取消'],
  apply: ['Apply', '执行'],
  required: ['Required', '必填'],
  status: ['Status', '状态'],
  init: ['Init', '未使用'],
  pending: ['Pending', '派发中'],
  success: ['Success', '已派发'],
  expired: ['Expired', '已过期'],
  time: ['Update time', '时间'],
  noExist: ['not Exist', '不存在'],
  'Data not found': ['Data not found', '查无资料'],
  import: ['Import', '汇入'],
  delete: ['Delete', '删除'],
  invalidDate: ['Invalid Date', '失效时间'],
  constraint: ['Constraint', '派发条件'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
