import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MuiPaper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Button from '../../components/Button'
import Divider from '@material-ui/core/Divider'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import { getTeams } from '../../reducers'
import a from '../../actions'

import DialogDelete from '../../components/DialogDelete'
import DialogCreateTeam from '../../components/DialogCreateTeam'
import DialogUpdateTeamName from '../../components/DialogUpdateTeamName'

import language from '../../locale/permissionManager'
import DataTable from '../../components/tables/DataTable'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
  iconButton: {
    height: 24,
    width: 24,
    padding: 0,
    marginRight: 10,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
  currency: {
    cursor: 'pointer',
  },
})

class PermissionManager extends PureComponent {
  constructor (props) {
    super(props)

    const { i18n } = props
    i18n.addResourceBundle('en', 'permissionManager', language.en)
    i18n.addResourceBundle('cn', 'permissionManager', language.cn)

    this.state = {
      rowsPerPage: 30,
    }
  }

  componentDidMount () {
    const { fetchTeams } = this.props
    fetchTeams()
  }

  handleToggle = key => () => {
    this.setState(state => {
      if (state[`opens${key}`]) {
        return { [`opens${key}`]: !state[`opens${key}`] }
      } else {
        return { [`opens${key}`]: true }
      }
    })
  };

  handleClose = (event, key) => {
    if (this[`anchorEls${key}`].contains(event.target)) {
      return
    }

    this.setState({ [`opens${key}`]: false })
  };

  handleCreateTeam = (values, actions) => {
    const { createTeam } = this.props

    createTeam(values, {
      cb: () => {
        actions.resetForm()
        this.handleCloseModal({ name: 'create' })
      },
    })
  };

  handleDeleteTeam = values => {
    const { deleteTeam } = this.props

    deleteTeam({ id: values.id }, {
      cb: () => {
        this.handleCloseModal({ name: 'delete' })
      },
    })
  };

  handleUpdateTeamName = (values, actions) => {
    const { updateTeam } = this.props

    updateTeam(values, {
      cb: () => {
        actions.resetForm()
        this.handleCloseModal({ name: 'update' })
      },
    })
  };

  handleShowModal = ({ name, ...rest }) => {
    this.setState({
      showModal: { ...this.state.showModal, [name]: { show: true, ...rest } },
    })
  };

  handleCloseModal = ({ name }) => {
    this.setState({
      showModal: { ...this.state.showModal, [name]: { show: false } },
    })
  };

  render () {
    const { classes, teams, selectTeam, t } = this.props
    const { showModal } = this.state

    const formatTeams = teams ? teams.map(team => [team.name, team]) : []

    const columns = [
      {
        name: t('teamname'),
        options: {
          setCellProps: () => ({
            style: {
              wordWrap: 'break-word',
              maxWidth: '20vw',
            },
          }),
        },
      },
      {
        name: t('setting'),
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: value => (
            <>
              <IconButton
                buttonRef={node => {
                  this[`anchorEls${value.id}`] = node
                }}
                onClick={this.handleToggle(value.id)}
                className={classes.iconButton}
              >
                <MoreVertIcon />
              </IconButton>
              <Popper
                style={{ zIndex: 101, marginLeft: '5px' }}
                open={this.state[`opens${value.id}`] || false}
                anchorEl={this[`anchorEls${value.id}`]}
                transition
                placement="right"
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: 'left' }}
                  >
                    <MuiPaper>
                      <ClickAwayListener onClickAway={e => this.handleClose(e, value.id)}>
                        <MenuList>
                          <MenuItem
                            component={Link}
                            to={`/teamMembers?team=${value.id}`}
                            onClick={e => {
                              this.handleClose(e, value.id)
                              selectTeam(value)
                            }}
                          >
                            {t('memberManagement')}
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to={`/teamFeatures?team=${value.id}`}
                            onClick={e => {
                              this.handleClose(e, value.id)
                              selectTeam(value)
                            }}
                          >
                            {t('permissionManagement')}
                          </MenuItem>
                          <Divider className={classes.divider} />
                          <MenuItem
                            onClick={e => {
                              this.handleClose(e, value.id)
                              this.handleShowModal({
                                name: 'update',
                                id: value.id,
                              })
                            }}
                          >
                            {t('changeTeamName')}
                          </MenuItem>
                          <MenuItem
                            onClick={e => {
                              this.handleClose(e, value.id)
                              this.handleShowModal({
                                name: 'delete',
                                id: value.id,
                              })
                            }}
                          >
                            {t('delete')}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </MuiPaper>
                  </Grow>
                )}
              </Popper>
            </>
          ),
        },
      },
    ]

    return (
      <>
        {showModal && showModal.delete && (
          <DialogDelete
            open={showModal.delete.show}
            onClose={() => this.handleCloseModal({ name: 'delete' })}
            onSubmit={this.handleDeleteTeam}
            initValues={{ id: showModal.delete.id }}
          />
        )}

        {showModal && showModal.create && (
          <DialogCreateTeam
            open={showModal.create.show}
            onClose={() => this.handleCloseModal({ name: 'create' })}
            onSubmit={this.handleCreateTeam}
            // hack: use different value 'timestamp' let argument 'dirty' change on blur then component will be reinitial value
            initValues={{ timestamp: Date.now() }}
          />
        )}

        {showModal && showModal.update && (
          <DialogUpdateTeamName
            open={showModal.update.show}
            onClose={() => this.handleCloseModal({ name: 'update' })}
            onSubmit={this.handleUpdateTeamName}
            initValues={{ id: showModal.update.id }}
          />
        )}

        <Container>
          <GridItemFull>
            <Paper>
              <Grid item xs={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  actionType="positive"
                  className={classes.submit}
                  onClick={() => {
                    this.handleShowModal({
                      name: 'create',
                    })
                  }}
                >
                  {t('createTeam')}
                </Button>
              </Grid>
            </Paper>
          </GridItemFull>

          <GridItemFull>
            <DataTable
              title={t('titleTable')}
              data={formatTeams}
              columns={columns}
              options={{
                rowsPerPage: this.state.rowsPerPage,
                onChangeRowsPerPage: rowsPerPage => {
                  this.setState({ rowsPerPage })
                },
              }}
            />
          </GridItemFull>
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => {
  const teams = getTeams(state)

  return {
    teams,
  }
}

export default connect(
  mapStateToProps,
  {
    selectTeam: a.team.select,
    fetchTeams: a.team.fetch,
    createTeam: a.team.create,
    deleteTeam: a.team.delete,
    updateTeam: a.team.update,
  },
)(
  withStyles(styles)(
    withTranslation('permissionManager')(PermissionManager)),
)
