import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { InlineDatePicker, InlineDateTimePicker } from 'material-ui-pickers'
import { makeStyles } from '@material-ui/styles'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import Tooltip from '@material-ui/core/Tooltip'
import DateTimeInputField from './ExtendedDateTimeField/ExtendedDateTimeField'
import useClickAway from './../../../hooks/useClickAway'
import classNames from 'classnames'

const useStyles = makeStyles({
  inputLabelTooltip: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  inputLabelIcon: {
    marginLeft: '3px',
  },
  error: {
    color: '#f44336',
    '& fieldset': {
      borderColor: '#f44336!important',
    },
    '& label': {
      color: '#f44336!important',
    },
  },
}, { name: 'BO-DatePickerField' })

const DatePickerField = ({
  field,
  form,
  onChange = () => { },
  inline = true,
  label,
  enableTimeSelector,
  isRange,
  useI18n,
  isExtend = false,
  ...other
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isExtendOpen, setIsExtendOpen] = useState(false)
  const handleFocus = () => { if (isExtend) { setIsExtendOpen(true) } }
  const handleBlur = useCallback((e) => { setIsExtendOpen(false) }, [])

  const wrapperRef = useRef(null)
  useClickAway(wrapperRef, handleBlur)

  const currentError = form.errors?.[field.name]
  const DatePickerComponent = enableTimeSelector ? InlineDateTimePicker : InlineDatePicker

  return (
    <div ref={wrapperRef}>
      <DatePickerComponent
        disabled={isExtendOpen}
        onFocus={handleFocus}
        name={field.name}
        value={field.value}
        format="yyyy/MM/dd HH:mm"
        helperText={<span className={classes.error}>{useI18n ? t(currentError) : currentError}</span>}
        className={classNames(currentError && classes.error)}

        onChange={date => {
          // range 自己實作 setFieldValue
          !isRange && form.setFieldValue(field.name, date, true)

          onChange(date)
        }}
        onError={(reason, error) => {
          const strReason = (typeof reason === 'object' && reason.toString()) || reason
          switch (strReason) {
            case 'Invalid Date':
              form.setFieldError(field.name, 'common:invalidDate')
              break
            default:
              form.setFieldError(field.name, error)
              break
          }
        }}
        keyboard
        // InlineDateTimePicker 才能加上 ampm=false
        // InlineDatePicker 加上 ampm=false 會噴錯 所以才用 underfined
        ampm={enableTimeSelector ? false : undefined}
        label={
          <Tooltip
            className={classes.inputLabelTooltip}
            placement='top'
            title={t('common:noInputTooltip')}
          >
            <div>
              {useI18n ? t(label) : label}
              <ContactSupportIcon className={classes.inputLabelIcon} />
            </div>
          </Tooltip>
        }
        {...other}
      />
      {isExtend && isExtendOpen &&
      <DateTimeInputField
        views={other.views}
        name={field.name}
        date={(field.value)}
        onChange={onChange}
      />}
    </div>
  )
}

DatePickerField.propTypes = {
  // 是否啟用時間選擇器
  enableTimeSelector: PropTypes.bool,
}

export default DatePickerField
