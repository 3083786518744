import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'

import actions from '../../actions'
import { getIsCobrandFetching } from '../../reducers'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import SetCobrandForm from '../../components/forms/SetCobrand'

class SetCobrand extends Component {
  onSubmit = values => {
    return this.props.setStyle(values)
  };

  render () {
    const { isFetching } = this.props

    return (
      <Container>
        <GridItem form>
          <Paper>
            <SetCobrandForm
              gamecode=""
              // Note: request key `style` 與關鍵字重複了，只好 disable
              // eslint-disable-next-line
              style="spin"
              width=""
              height=""
              isFetching={isFetching}
              onSubmit={this.onSubmit}
            />
          </Paper>
        </GridItem>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const isFetching = getIsCobrandFetching(state)

  return {
    isFetching,
  }
}

export default connect(
  mapStateToProps,
  {
    setStyle: actions.cobrand.setStyle,
  },
)(SetCobrand)
