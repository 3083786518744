
import { DARK_MODE } from '../../themes/bo'
import actionType from '../../actions/actionType'

const theme = (state = { mode: DARK_MODE }, action = {}) => {
  switch (action.type) {
    case actionType.ui.switchTheme:
      return { ...state, mode: action.payload.mode }
    default:
      return state
  }
}

export default theme

export const getTheme = state => state.mode
