import React from 'react'
import CreatableSelect from 'react-select/creatable'

const CreatableSelectField = (props) => {
  const { helperText } = props

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 20,
      borderRadius: 4,
      border: '1px solid rgba(255, 255, 255, 0.23)',
      color: '#fff',
    }),
    input: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      border: 'none',
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? 'transparent' : 'rgba(0, 0, 0, 0.42)',
      boxShadow: 'none',
      '&:hover': {
        borderBottom: '1px solid black',
      },
    }),
  }

  return (
    <div>
      <CreatableSelect {...props} styles={customStyles} />
      {helperText && <span style={{ color: 'red', fontSize: 12 }}>{helperText}</span>}
    </div>
  )
}

export default CreatableSelectField
