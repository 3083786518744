import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { getUser, getCustomerServices, getTimeZone } from '../../reducers'
import a from '../../actions'
import { qsParse } from '../../helpers'
import language from '../../locale/customerServiceList'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import MoreButton from '../../components/MoreButton'
import DataTable from '../../components/tables/DataTable'

class CustomerServiceList extends Component {
  state = { rowsPerPage: 30 };

  componentDidMount () {
    const { fetchCustomerServices, i18n } = this.props
    i18n.addResourceBundle('en', 'customerServiceList', language.en)
    i18n.addResourceBundle('cn', 'customerServiceList', language.cn)

    fetchCustomerServices({ pagesize: 1000000000 })
  }

  handleToggle = key => () => {
    this.setState(state => {
      if (state[`opens${key}`]) {
        return { [`opens${key}`]: !state[`opens${key}`] }
      } else {
        return { [`opens${key}`]: true }
      }
    })
  };

  handleClose = key => event => {
    if (this[`anchorEls${key}`].contains(event.target)) {
      return
    }

    this.setState({ [`opens${key}`]: false })
  };

  render () {
    const { customerServices, t, timezone } = this.props

    const formatCustomerServices = customerServices
      ? customerServices.map(customerService => [
        customerService.account,
        customerService.status,
        { time: customerService.signuptime, timezone },
        { time: customerService.lastlogin, timezone },
        customerService,
      ])
      : []

    const columns = [
      {
        name: t('csAcc'),
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            style: {
              wordWrap: 'break-word',
              maxWidth: '20vw',
              minWidth: '280px',
            },
          }),
        },
      },
      {
        name: t('status'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => (
            <div className={`status ${value}`}>{t(value)}</div>
          ),
        },
      },
      {
        name: t('signUpAt'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => moment(value.time * 1000).utcOffset(value.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
        },
      },
      {
        name: t('loginAt'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value =>
            value.time === 0
              ? t('neverLogin')
              : moment(value.time * 1000).utcOffset(value.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
        },
      },
      {
        name: t('more'),
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: value => {
            return (
              <MoreButton
                items={[
                  {
                    label: t('detail'),
                    to: {
                      pathname: `/customerServiceDetail/${value.userid}`,
                      state: { show: 0 },
                    },
                  },
                  {
                    label: t('changePwd'),
                    to: {
                      pathname: `/customerServiceDetail/${value.userid}`,
                      state: { show: 1 },
                    },
                  },
                ]}
              />
            )
          },
        },
      },
    ]

    return (
      <Container>
        <GridItemFull>
          <DataTable
            title={t('titleTable')}
            data={formatCustomerServices}
            columns={columns}
            options={{
              rowsPerPageOptions: [30, 50, 100],
              rowsPerPage: this.state.rowsPerPage,
              onChangeRowsPerPage: rowsPerPage => {
                this.setState({ rowsPerPage })
              },
              search: true,
            }}
          />
        </GridItemFull>
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const user = getUser(state)
  const customerServices = getCustomerServices(state)
  const timezone = getSavedState('timezone') || getTimeZone(state)

  return {
    customerServices,
    user,
    search,
    timezone,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchCustomerServices: a.customerService.fetchCustomerServices,
  },
)(withTranslation('customerServiceList')(CustomerServiceList))
