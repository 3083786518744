import React from 'react'
import { ButtonBase } from '@material-ui/core'
import useStyles from './index.style'
import classNames from 'classnames'

export const ToggleButton = ({
  children,
  disabled = false,
  color = 'primary', // primary
  // size = '', // small, medium, large
  fullWidth = false, // false
  href = '',
  className,
  ...others
}) => {
  const classes = useStyles()
  return (
    <ButtonBase
      disabled={disabled}
      // size={size}
      fullWidth={fullWidth}
      href={href}
      {...others}
      className={classNames(
        className,
        classes.button,
        classes[color],
      )}
    >
      {children}
    </ButtonBase>
  )
}

export const ToggleButtonGroup = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

ToggleButtonGroup.propTypes = {}

export default ToggleButtonGroup
