const rawData = {
  pwdChangeSuccess: ['Change password success .', '密码修改成功'],
  twoPwdDifferent: [
    'Type the same password in both input .',
    '两次输入密码不相同',
  ],
  walletSetSuccess: ['Wallet setting success .', '钱包修改成功'],
  detail: ['Detail', '详细资料'],
  changePwd: ['Change Password', '更改密码'],
  token: ['Token', '密钥'],
  walletInfo: ['Wallet Info', '钱包信息'],
  walletLink: ['Wallet link', '钱包网址'],
  walletToken: ['Wallet token', '钱包密钥'],
  neverLogin: ['Never Login', '未曾登入'],
  betThreshold: ['Bet Threshold', '投注阀值'],
  maximum: ['Maximum', '筹码最大值'],
  minimum: ['Minimum', '筹码最小值'],
  default: ['Default', '筹码预设值'],

  account: ['Account', '帐号'],
  stationAccount: ['Station Account', '站长帐号'],
  ownerAccount: ['Owner Account', '總代理'],
  parentAccount: ['Parent Account', '上级代理帐号'],
  childAccount: ['Parent Account', '子代理'],
  signUpAt: ['Sign Up At', '注册时间'],
  loginAt: ['Last Sign In At', '最近登入时间'],
  totalPlayers: ['Total Players', '玩家总人数'],
  commission: ['Commission(%)', '抽佣(%)'],
  currencySign: ['Display Currency Sign in Game ?', '游戏中是否显示币别符号'],
  show: ['Display', '显示'],
  notShow: ['Hidden', '不显示'],
  status: ['Status', '状态'],
  enable: ['Enable', '启用'],
  disable: ['Disable', '停用'],
  ssToken: ['Token', '密钥'],
  basicInfo: ['Basic Info', '基本资料'],
  testAgent: ['Test Agent', '测试帐号'],
  modifySuccess: ['Modify success', '修改成功'],

  // bet Threshold
  createBetThreshold: ['Add Bet Thresholds', '新增投注阀值'],
  updateBetThresholdTitle: ['Update Bet Threshold', '更新投注阀值'],
  range: ['Range', '界线'],
  name: ['Name', '名称'],
  gameCodes: ['Game List', '游戏清单'],
  gameTypes: ['Game Type', '游戏种类'],
  groupName: ['Group Name', '群组名称'],
  gameList: ['Game List', '游戏清单'],
  action: ['Action', '动作'],

  // input error,
  mustNumber: ['Please enter the correct number.', '请填入正确数字。'],
  moreThenEqualZero: ['Must greater then or equal to 0', '必须大于等于0。'],
  notEmptymoreThenEqualZero: ['If not empty, must be greater or equal to 0.', '若有填数字，数字必须大于等于0'],
  moreThenEqualMinimumValue: ['must be greater than or equal to minimum value.', '需要大于或等于最小值。'],
  lessThenEqualMaxmumValue: ['must be less than or equal to maximum value.', '需要小于或等于最大值。'],
  lessThenMaxmumValue: ['must be less than to maximum value.', '需要小于最大值。'],
  required: ['This is a required field.', '此为必填栏位'],
  mustUrl: ['Must be a valid URL', '必须是有效的网址。'],

  roombet: ['Room Bet', '房间底注'],
  noMoreThanSixNumbers: ['No more than 6 numbers (eg： 1.00,2.11,3.22,4,5,6)', '不能超过6个数字 (范例：1.00,2.11,3.22,4,5,6)'],
  onlyNumbersDigitsAndCommas: ['Only numbers and digits separated by commas are allowed (eg： 1.00,2.11,3.22,4,5,6)', '只能输入数字、小数点以及逗号分隔 (范例：1.00,2.11,3.22,4,5,6)'],

  updateBetThreshold: [' Update Bet Threshold', '更新投注阀值'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
