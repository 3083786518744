import produce from 'immer'
import actionType from '../../actions/actionType'

const defaultState = {
  blackList: [],
  isFetching: false,
}

export default produce((state, action = {}) => {
  switch (action.type) {
    case actionType.game.clearBlackList:
      state.blackList = []
      return state
    case actionType.game.fetchBlackListRequest:
      state.isFetching = true
      return state
    case actionType.game.fetchBlackListSuccess:
      state.isFetching = false
      state.blackList = action.payload.response
      return state
    case actionType.game.fetchBlackListFailure:
      state.isFetching = false
      return state
    default:
      return state
  }
}, defaultState)
