import React, { PureComponent as Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { api } from '../../services'

import actions from '../../actions'
import {
  getBlackList,
  getLanguage,
  getAgentListWithRole,
  getAgentOptions,
  getUser,
} from '../../reducers'
import language from '../../locale/getBlackList'

import Form from './form'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import Content from './content'

class GetBlackList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      map: {},
      brand: '',
      currBrand: '',
      brandOptions: [],
      agentid: [],
      parentid: [],
      isSearched: false,
    }
  }

  async componentDidMount () {
    const { i18n, fetchAgents, clearAgent, getAgentOptions } = this.props

    // 切換頁面之後 parentoption 會保留，所以一開始要先刪除
    clearAgent()
    getAgentOptions()
    fetchAgents({ role: 'owner' })

    i18n.addResourceBundle('en', 'getBlackList', language.en)
    i18n.addResourceBundle('cn', 'getBlackList', language.cn)

    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    this.setState({
      brand: brandOptions?.[0] || '',
      brandOptions: brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    })
  }

  onSubmit = data => {
    const { parentid, gamecode } = data
    this.setState({ isSearched: true, currBrand: this.state.brand })
    if (parentid.length) {
      this.props.fetchBlackList(data)
    } else if (gamecode.length) {
      this.props.fetchBlackListByGameCode(data)
    }
  }

  onClearBlackList = () => this.props.clearBlackList()

  onBrandChange = (value) => {
    this.setState({
      brand: value,
    })
  }

  onParentIdChange = (value) => {
    this.setState({
      parentid: value,
    })
  }

  onAgentIdChange = (value) => {
    this.setState({
      agentid: value,
    })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.brand !== this.state.brand && this.state.brand) {
      this.props.fetchGameList({ brand: this.state.brand })
      const res = await api.get(api.BO, '/gamelistbyrole')
      const list = res?.data?.data?.data || {}
      this.setState({ map: list })
    }
  }

  render () {
    const {
      isFetching,
      theme,
      t,
      language,
      fetchAgents,
      parentOption,
      ownerOption,
      removeAgent,
      clearAgent,
      type,
      allAgentList,
      role,
      blackList,
    } = this.props
    const { brand, brandOptions, parentid, map, agentid } = this.state
    const formatRepost = []
    blackList.forEach(blackList => {
      let ownerAccount
      let agentAccount

      // 根據 id map user account
      allAgentList.forEach(agent => {
        if (blackList.ownerid === agent.value) { ownerAccount = agent.label }
        if (blackList.userid === agent.value) { agentAccount = agent.label }
      })

      if (blackList.list) {
        blackList.list.forEach(list => {
          // 用 gamecoede 來 map game name
          const gamenames = list.gamecode?.map(gamecode => {
            const name = map?.[this.state.currBrand]?.find(({ gamecode: _gamecode }) => _gamecode === gamecode)?.nameset[language === 'cn' ? 'zh-cn' : 'cn']
            return name ? name + ' (' + gamecode + ')' : gamecode
          })

          const gametype = list.gametype?.map(gametype => gametype)
          if (gamenames?.length) {
            formatRepost.push({
              owner: ownerAccount,
              agent: agentAccount,
              gamenames,
              gametype,
              gamehall: list.gamehall,
            })
          }
        })
      }
    })
    return (
      <Container>
        <GridItemFull form>
          <Paper>
            <h3 style={ theme.customer.H3Style }>{t('title')}</h3>
            <Form
              type={type}
              role={role}
              onParentIdChange={this.onParentIdChange}
              onBrandChange={this.onBrandChange}
              onAgentIdChange={this.onAgentIdChange}
              brand={brand}
              brandOptions={brandOptions}
              isFetching={isFetching}
              onSubmit={this.onSubmit}
              fetchAgents={fetchAgents}
              removeAgent={removeAgent}
              clearAgent={clearAgent}
              ownerOption={ownerOption}
              parentOption={parentOption}
              agentid={agentid}
              parentid={parentid}
              gamecode={[]}
              onClear={this.onClearBlackList}
            />
          </Paper>
        </GridItemFull>
        <GridItemFull>
          { (formatRepost.length && this.state.isSearched) ? <Content data={formatRepost} /> : null }
        </GridItemFull>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const language = getLanguage(state)
  const agents = getAgentListWithRole(state)
  const allAgentList = getAgentOptions(state)
  const { blackList, isFetching } = getBlackList(state)
  const user = getUser(state)
  const formatRepost = []

  return {
    language,
    blackList,
    type: user.type,
    role: user.role,
    ownerOption: agents.ownerOptions,
    parentOption: agents.parentOptions,
    formatRepost,
    isFetching,
    allAgentList,
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchGameList: actions.game.fetchGameList,
      clearBlackList: actions.game.clearBlackList,
      fetchBlackList: actions.game.fetchBlackList,
      fetchBlackListByGameCode: actions.game.fetchBlackListByGameCode,
      fetchAgents: actions.agent.fetchAgentListV2,
      removeAgent: actions.agent.removeAgentList,
      clearAgent: actions.agent.clearAgentList,
      getAgentOptions: actions.agent.fetchAgentOptions,
    },
  ),
  withTranslation('getBlackList'),
  withStyles({}, { withTheme: true }),
)(GetBlackList)
