import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

function members (
  state = {
    isFetching: false,
    items: [],
  },
  action = {},
) {
  switch (action.type) {
    case actionType.teamMember.fetchRequest:
      return { ...state, isFetching: true }
    case actionType.teamMember.fetchSuccess:
      return {
        ...state,
        isFetching: false,
        items: action.payload.response,
      }
    default:
      return state
  }
}

const byTeamId = (state = {}, action = {}) => {
  switch (action.type) {
    case actionType.teamMember.fetchRequest:
    case actionType.teamMember.fetchSuccess:
      return {
        ...state,
        [action.payload.teamId]: members(state[action.payload.teamId], action),
      }
    default:
      return state
  }
}

export default combineReducers({
  byTeamId,
})

export const getTeamMembersByTeamId = (state, teamId) => {
  const teamMembers = state.byTeamId[teamId]

  return typeof teamMembers === 'undefined' ? [] : teamMembers.items
}
