import { all } from 'redux-saga/effects'

import agent from './agent'
import auth from './auth'
import betThreshold from './betThreshold'
import brandInfo from './brandInfo'
import client from './client'
import cobrand from './cobrand'
import currency from './currency'
import customerService from './customerService'
import event from './event'
import game from './game'
import liveGame from './liveGame'
import team from './team'
import siteFeature from './siteFeature'
import rate from './rate'
import report from './report'
import language from './language'
import teamMember from './teamMember'
import teamFeature from './teamFeature'
import time from './time'
import user from './user'
import promo from './promo'
import live from './live'
import betThresholdGroup from './betThresholdGroup'
import order from './order'

export default function * rootSaga () {
  yield all([
    ...agent,
    ...auth,
    ...betThreshold,
    ...brandInfo,
    ...client,
    ...cobrand,
    ...currency,
    ...customerService,
    ...event,
    ...game,
    ...liveGame,
    ...siteFeature,
    ...rate,
    ...report,
    ...language,
    ...team,
    ...teamMember,
    ...teamFeature,
    ...time,
    ...user,
    ...promo,
    ...live,
    ...betThresholdGroup,
    ...order,
  ])
}
