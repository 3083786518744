import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to update bet threshould
 */
export function * create ({ payload }) {
  //    const { userID, game }
  try {
    yield put(actions.betThreshold.createGroupRequest())

    const res = yield call(api.post, api.BO, '/ss/betthresholds', { ...payload })

    yield put(actions.betThreshold.createGroupSuccess({ response: res.data.data }))

    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))

    yield call(fetch, { payload: { userID: payload.userID } })
  } catch (err) {
    yield put(actions.betThreshold.createGroupFailure())

    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update bet threshould
 */
export function * update ({ payload }) {
  try {
    yield put(actions.betThreshold.updateGroupRequest())

    const res = yield call(api.put, api.BO, '/ss/betthresholds', { ...payload })

    yield put(actions.betThreshold.updateGroupSuccess({ response: res.data.data }))

    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))

    yield call(fetch, { payload: { userID: payload.userID } })
  } catch (err) {
    yield put(actions.betThreshold.updateGroupFailure())

    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch bet threshould
 */
export function * fetch ({ payload }) {
  try {
    yield put(actions.betThreshold.fetchGroupRequest())

    const res = yield call(api.get, api.BO, '/ss/betthresholds', { params: { ...payload } })

    yield put(actions.betThreshold.fetchGroupSuccess({ response: res.data || [] }))
  } catch (err) {
    yield put(actions.betThreshold.fetchGroupFailure())

    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to remove bet threshould
 */
export function * remove ({ payload }) {
  try {
    yield put(actions.betThreshold.deleteGroupRequest())

    const res = yield call(api.DELETE, api.BO, `/ss/betthresholds?userID=${payload.userID}&id=${payload.id}`)

    yield put(actions.betThreshold.deleteGroupSuccess({ response: res.data.data }))

    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))

    yield call(fetch, { payload: { userID: payload.userID } })
  } catch (err) {
    yield put(actions.betThreshold.deleteGroupFailure())

    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.betThreshold.updateGroup, update),
  takeLatest(actionType.betThreshold.createGroup, create),
  takeLatest(actionType.betThreshold.fetchGroup, fetch),
  takeLatest(actionType.betThreshold.deleteGroup, remove),
]

export default effects
