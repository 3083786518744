import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import OutlinedTextField from '../text-fields/OutlinedTextField'

import { renderFields } from './helper'
import { withTranslation } from 'react-i18next'
import language from '../../locale/changePasswordForm'

import Debug from './Debug'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  // 用 func 包一層才不會有驗證失靈問題
  validationSchema: () =>
    Yup.object().shape({
      password: Yup.string()
        .min(8, 'leastEight')
        .max(16, 'mostSixteen')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_-]{8,16}$/g, 'warning')
        .required('required'),
      confirmPassword: Yup.string()
        .required('required')
        .oneOf([Yup.ref('password')], 'mustEqual'),
    }),
  handleSubmit: async ({ account, password, confirmPassword }, { props, setSubmitting, resetForm }) => {
    try {
      await props.onSubmit({ account, password, confirmPassword })
      resetForm()
    } catch (error) { }

    setSubmitting(false)
  },
  displayName: 'ChangePasswordForm',
})

class ChangePasswordForm extends Component {
  constructor (props) {
    super(props)

    const { i18n, setFieldTouched } = props
    i18n.addResourceBundle('en', 'changePasswordForm', language.en)
    i18n.addResourceBundle('cn', 'changePasswordForm', language.cn)
    i18n.on('languageChanged', () => {
      Object.keys(this.props.errors).forEach(fieldName => {
        if (Object.keys(this.props.touched).includes(fieldName)) {
          setFieldTouched(fieldName)
        }
      })
    })
  }

  render () {
    const { theme, isSubmitting, classes, t } = this.props

    const fields = [
      {
        name: 'account',
        label: t('account'),
        fullWidth: true,
        InputProps: { readOnly: true },
        Field: OutlinedTextField,
      },
      {
        name: 'password',
        label: t('password'),
        type: 'password',
        autoComplete: 'new-password',
        fullWidth: true,
        Field: OutlinedTextField,
      },
      {
        name: 'confirmPassword',
        label: t('confirmPassword'),
        type: 'password',
        autoComplete: 'new-password',
        fullWidth: true,
        Field: OutlinedTextField,
      },
    ]

    return (
      <Form>
        <h3 style={theme.customer.H3Style}>{t('changePwd')}</h3>
        <h5 style={theme.customer.H3Style}>{t('warning')}</h5>

        {renderFields(fields, this.props)}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          {t('save', { ns: 'common' })}
        </Button>

        {process.env.REACT_APP_DEBUG === 'true' && <Debug />}
      </Form>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation('changePasswordForm')(formikEnhancer(ChangePasswordForm)))
