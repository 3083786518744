// 視訊代理財務報表
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { api } from '../../services'
import language from '../../locale/agentFinanceReport'
import moment from 'moment'

import a from '../../actions'
import {
  getReportByFilter,
  getCurrencyOptions,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
} from '../../reducers'
import { qsParse, formatDateByGroupBy, formatMoney } from '../../helpers'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import ReportSummaryCard from '../../components/ReportSummaryCard'
import GridItemFull from '../../components/grids/GridItemFull'
import AgentFinanceReportForAdminForm from '../../components/forms/AgentFinanceReportForAdminForm'
import DataTable from '../../components/tables/DataTable'

class LiveAgentFinanceReportForAdmin extends Component {
  constructor (props) {
    super(props)

    this.state = {
      brandOptions: [],
      rowsPerPage: 30,
      formatReports: [],
      formatSummaryReports: [],
      columns: [],
      payload: null,
      reportPagination: null,
      needFetchOnMount: false,
      isSearched: false,
    }
  }

  async componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'agentFinanceReport', language.en)
    i18n.addResourceBundle('cn', 'agentFinanceReport', language.cn)

    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    this.setState({
      brandOptions: brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    })
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      filterOwnerAccount,
      fetchParents,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setColumns()
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    if (props.filterOwnerAccount !== filterOwnerAccount) {
      fetchParents({ ownerAcc: filterOwnerAccount })
    }

    if (props.language !== language) {
      this.setColumns()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('datetime'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentid'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentincome'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => {
              const v = formatMoney(value)

              return parseFloat(value) < 0
                ? <span style={{ color: 'red' }}>{v}</span>
                : v
            },
          },
        },
        {
          name: t('donate'),
          options: {
            filter: false,
            sort: false,
            display: this.props.reports.showdonate, // 需要和setFormatReports配合顯示欄位
            customBodyRender: value => formatMoney(value),
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    this.setState({
      formatReports: data.map(item => [
        item.datetime,
        item.parentAcc,
        item.currency,
        item.parentincome,
        this.props.reports.showdonate ? item.donate : delete item.donate,
      ]),
    })
  };

  setFormatSummaryReports = data => {
    this.setState({
      formatSummaryReports: data
        ? [
          {
            name: 'TOTAL'.toUpperCase(),
            data: Object.keys(data).map(key => ({
              key,
              value: data[key],
            })),
          },
        ]
        : [],
    })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'liveAgentFinanceReport',
      fileName: t('liveAgentFinanceReport', { ns: 'sidebar' }),
    })
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      startDate: formatDateByGroupBy(values.startDate, values.groupby),
      endDate: formatDateByGroupBy(values.endDate, values.groupby),
    }

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'liveAgentFinanceReport' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      filterOwnerAccount,
      filterParentAccount,
      filterGroupBy,
      currencyOptions,
      isFetching,
      canDownload,
      timezone,
    } = this.props
    const {
      brandOptions,
      formatReports,
      formatSummaryReports,
      reportPagination,
      columns,
      isSearched,
      payload,
      rowsPerPage,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <AgentFinanceReportForAdminForm
              ownerAcc={filterOwnerAccount}
              parentAcc={filterParentAccount}
              currency=""
              brand={brandOptions?.[0]?.value}
              brandOptions={brandOptions}
              groupby={filterGroupBy}
              startDate={startDate}
              endDate={endDate}
              pagesize={rowsPerPage}
              nowpage={1}
              currencyOptions={() => currencyOptions}
              onSubmit={this.handleSubmit}
              isFetching={isFetching}
            />
          </Paper>
        </GridItemFull>

        {payload && payload.currency !== '' && (
          <GridItemFull>
            <ReportSummaryCard data={formatSummaryReports} hiddenList={[this.props.reports.showdonate ? null : 'totaldonate']} />
          </GridItemFull>
        )}

        { isSearched &&
        <GridItemFull>
          <DataTable
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            payload={payload}
            canDownload={canDownload}
            downloadReport={this.handleDownload}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const currencyOptions = getCurrencyOptions(state)
  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'liveAgentFinanceReport')
  const summaryReports = getReportByFilter(
    state,
    'liveAgentFinanceReportSummary',
  )

  const filterOwnerAccount = search.owner ? search.owner : ''
  const filterParentAccount = search.parent ? search.parent : ''
  const filterGroupBy = search.groupBy ? search.groupBy : 'day'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'liveAgentFinanceReport')

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  return {
    canDownload,
    filterOwnerAccount,
    filterParentAccount,
    filterGroupBy,
    currencyOptions,
    search,
    reports,
    language,
    timezone,
    summaryReports,
    isFetching,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchReport,
  },
)(withTranslation('agentFinanceReport')(LiveAgentFinanceReportForAdmin))
