import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({
  component: Component,
  isLogged = false,
  loginPath = '/',
  routePage,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogged ? (
          <Component routePage={routePage} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
