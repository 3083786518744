import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch team
 */
export function * fetch () {
  try {
    // 1. Fetch teams request started
    yield put(actions.team.fetchRequest())

    // 2. Call API: fetch teams
    const res = yield call(api.get, api.RP, '/teams?serviceName=bo')

    // 3. Fetch teams success
    yield put(actions.team.fetchSuccess({ response: res.data.data }))
  } catch (err) {
    // Fetch teams failure
    yield put(actions.team.fetchFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to create team
 */
export function * create ({ payload, meta }) {
  try {
    // 1. Create team request started
    yield put(actions.team.createRequest())

    // 2. Call API: create team
    const res = yield call(api.post, api.RP, '/teams?serviceName=bo', payload)

    // 3. Create team success
    yield put(actions.team.createSuccess({ response: res.data.data }))

    // 4. Call fetch to fetch team list
    yield call(fetch)

    // 5. Call caback
    yield meta.cb && call(meta.cb)

    // 6. Announce fetch success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Create team failure
    yield put(actions.team.createFailure())
    // Announce API error
    yield put(actions.msgs.add({
      replaceWithI18nText: 'fail',
      variant: 'error',
    }))
  }
}

/**
 * Effect to delete team
 */
export function * Delete ({ payload, meta }) {
  try {
    // 1. Delete team request started
    yield put(actions.team.deleteRequest())

    // 2. Call API: Delete team
    const res = yield call(api.DELETE, api.RP, `/teams/${payload.id}`)

    // 3. Delete team success
    yield put(actions.team.deleteSuccess({ response: res.data.data }))

    // 4. Call fetch to fetch team list
    yield call(fetch)

    // 5. Call caback
    yield meta.cb && call(meta.cb)

    // 6. Announce fetch success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // delete team failure
    yield put(actions.team.deleteFailure())
    // Announce API error
    yield put(actions.msgs.add({
      replaceWithI18nText: 'fail',
      variant: 'error',
    }))
  }
}

/**
 * Effect to update team
 */
export function * update ({ payload, meta }) {
  try {
    // 1. Update team request started
    yield put(actions.team.updateRequest())

    // 2. Call API: update team
    const res = yield call(api.patch, api.RP, `/teams/${payload.id}`, payload)

    // 3. Update team success
    yield put(actions.team.updateSuccess({ response: res.data.data }))

    yield call(fetch)

    yield meta.cb && call(meta.cb)

    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Update team failure
    yield put(actions.team.updateFailure())
    // Announce API error
    yield put(actions.msgs.add({
      replaceWithI18nText: 'fail',
      variant: 'error',
    }))
  }
}

const effects = [
  // team
  takeLatest(actionType.team.fetch, fetch),
  takeLatest(actionType.team.create, create),
  takeLatest(actionType.team.delete, Delete),
  takeLatest(actionType.team.update, update),
]

export default effects
