const rawData = {
  ownerAcc: ['General Agent Account', '总代理帐号'],
  parentAcc: ['Agent Account', '代理帐号'],
  startDate: ['Start Date', '开始日期'],
  endDate: ['End Date', '结束日期'],
  cashin: ['Deposit', '存款'],
  cashout: ['Withdraw', '提款'],
  total: ['Subtotal', '小计'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
