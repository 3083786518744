import React, { forwardRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Button from '../../components/Button'
import { CircularProgress } from '@material-ui/core'

import { compose } from 'redux'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const styles = theme => {
  return {
    submit: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
    },
    alert: {
      ...theme.customer.button.negative,
    },
    common: {
      ...theme.customer.button.positive,
    },
  }
}

const AlertDialogSlide = (props) => {
  const {
    // variant 讓使用者可以指定 Dialog 樣式層級，而樣式由元件內部控制，使用者就可以不需要知道實作樣式的細節
    variant,
    isSubmitting,
    actionType,
    classes,
    text,
    handleSubmit,
    isFetching = false,
    handleOpenDialog,
    disabled,
  } = props
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        onClick={() => { handleOpenDialog(handleClickOpen) }}
        className={classes.submit}
        disabled={isSubmitting || disabled}
        actionType={actionType}
      >
        {text.confirm}
      </Button>
      { isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{ text.dialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{ text.dialogDescription }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{ text.dialogDisagree }</Button>
          <Button
            className={classes[variant]}
            onClick={() => {
              handleClose()
              handleSubmit()
            }}
          >{ text.dialogAgree }</Button>

        </DialogActions>
      </Dialog>
    </div>
  )
}

export default compose(
  withStyles(styles),
)(AlertDialogSlide)
