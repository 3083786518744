import React, { useMemo } from 'react'
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core'

import { ArrowDropDown, ArrowDropUp, Bookmark, BookmarkBorder } from '@material-ui/icons'

import useDrawer from './hook'

const AppListItem = ({
  icon,
  title = '',
  onClick = () => { },
  children,

  isActive,
  nested,
}) => {
  const { classes } = useDrawer()

  return (
    <ListItem
      button
      onClick={onClick}
      className={`
        ${classes.bookmarkHover}
        ${nested ? classes.nested : ''}
        ${isActive ? classes.active : ''}
      `}
    >
      {icon && <ListItemIcon classes={{ root: classes.icon }} >{icon}</ListItemIcon>}
      <ListItemText primary={title} classes={{ primary: classes.text }} />
      {children}
    </ListItem>
  )
}

export const ListItemCollapseHead = ({
  title,
  icon,
  onClick,

  open,
}) => {
  return (
    <AppListItem title={title} icon={icon} onClick={onClick}>
      { open
        ? <ArrowDropUp color="action" />
        : <ArrowDropDown color="action" />
      }
    </AppListItem>
  )
}

export const ListItemLink = ({
  title,
  icon,
  onClick,

  isActive,
  nested,
  withBookmark,
  id,
}) => {
  const { classes, bookmarkList, handleRemoveBookmark, handleAddBookmark } = useDrawer()
  const isSaved = useMemo(() => bookmarkList.includes(id), [bookmarkList, id])
  return (
    <AppListItem
      title={title}
      icon={icon}
      onClick={onClick}
      isActive={isActive}
      nested={nested}
    >
      { withBookmark && (
        <div className={!isSaved ? classes.bookmark : ''}>
          <ListItemSecondaryAction>
            <IconButton
              fontSize='small'
              onClick={e => {
                e.stopPropagation()
                isSaved
                  ? handleRemoveBookmark(id)
                  : handleAddBookmark(id)
              }}
            >
              { isSaved
                ? <Bookmark fontSize="small" />
                : <BookmarkBorder fontSize="small" />
              }
            </IconButton>
          </ListItemSecondaryAction>
        </div>
      )}
    </AppListItem>
  )
}
