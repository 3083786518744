import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  positive: {
    ...theme.customer.button.positive,
  },
  negative: {
    ...theme.customer.button.negative,
  },
})

const Button = (props) => {
  const {
    classes,
    actionType = '',
    className,
    ...other
  } = props
  return (
    <MuiButton
      className={`${classes[actionType]} ${className}`}
      {...other}
    />
  )
}

Button.propTypes = {
  // 按鈕類型 positive=綠色, negative=紅色
  actionType: PropTypes.oneOf(['positive', 'negative']),
}

export default withStyles(styles)(Button)
