import React, { useRef } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import { renderFieldsWithGridItem } from '../../components/forms/helper'
import GridContainer from '../../components/grids/GridContainer'

import SelectTextField from '../../components/text-fields/SelectTextField'
import MultiSelectTextField from '../../components/text-fields/MultiSelectTextField'
import RadioTextField from '../../components/text-fields/RadioTextField'
import OutlinedTextField from '../../components/text-fields/OutlinedTextField'
import DateRangePickerTextField from '../../components/text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    agentAcc: Yup.string().required('required'),
    amount: Yup.number()
      .typeError('mustNumber')
      .required('required')
      .min(0, 'amountMinimum'),
    playerAcc: Yup.string().when('fuzzySearch', {
      is: 'true',
      then: Yup.string()
        .required('required')
        .min(4, 'playerAccMinimum'),
    }),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'LiveAgentPlayerReportForAdminForm',
})

const LiveAgentPlayerReportForAdminForm = props => {
  const {
    values,
    isSubmitting,
    classes,
    t,
    isFetching,
    setFieldValue,
    getTableTypeOptions,
    getTableIdOptions,
    getCurrencyOptions,
    onTableTypeChange,
  } = props
  const tableIdRef = useRef(null)

  const fields = [
    [
      {
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        Field: RadioTextField,
        name: 'fuzzySearch',
        label: t('playerAccSearchMethod'),
        fullWidth: true,
        options: [
          { label: t('exactSearch'), value: 'false' },
          { label: t('fuzzySearch'), value: 'true' },
        ],
      },
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'tabletype',
        label: t('tableType'),
        fullWidth: true,
        isMulti: true,
        isClearable: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        options: getTableTypeOptions(),
        onChange: (value) => {
          setFieldValue('tabletype', (value || []).map((v) => v.value))
          onTableTypeChange(value || [])
          tableIdRef.current.select.clearValue()
        },
      },
      {
        Field: MultiSelectTextField,
        name: 'tableid',
        label: t('tableId'),
        fullWidth: true,
        isMulti: true,
        isClearable: true,
        closeMenuOnSelect: false,
        selectRef: tableIdRef,
        options: getTableIdOptions(),
        onChange: (value) => setFieldValue('tableid', (value || []).map((v) => v.value)),
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'criterion',
        label: t('criterion'),
        fullWidth: true,
        options: [
          { label: t('noFilter'), value: '' },
          {
            label: `${t('bet')} ${
              values.type === 'less' ? t('less') : t('more')
            } ${values.amount}`,
            value: 'bet',
          },
          {
            label: `${t('win')} ${
              values.type === 'less' ? t('less') : t('more')
            } ${values.amount}`,
            value: 'win',
          },
        ],
        onChange: (value) => {
          if (value.value === '') {
            setFieldValue('amount', 0)
          }
        },
      },
    ],
    [
      {
        Field: OutlinedTextField,
        name: 'amount',
        label: t('amount'),
        fullWidth: true,
      },
      {
        Field: RadioTextField,
        name: 'type',
        label: '',
        fullWidth: true,
        options: [
          { label: t('less'), value: 'less' },
          { label: t('more'), value: 'more' },
        ],
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'groupby',
        label: t('groupby'),
        fullWidth: true,
        options: [
          { label: t('hour'), value: 'hour' },
          { label: t('day'), value: 'day' },
          { label: t('month'), value: 'month' },
          { label: t('year'), value: 'year' },
        ],
      },
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'currency',
        label: t('currency'),
        fullWidth: true,
        options: [
          { label: t('notConverted', { ns: 'common' }), value: '' },
          ...getCurrencyOptions(),
        ],
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
        {values.criterion !== '' && renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[4], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[5], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching() && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(
    withTranslation('agentPlayerReport')(LiveAgentPlayerReportForAdminForm),
  ),
)
