export const BELIMITCOLUMNS = ['bettype', 'min', 'max']

export const GET_DATA = () => {
  return {
    data: {
      userid: '598038fa4283ac00010cabe7',
      account: 'parentjjj',
      tableid: 'AAT01',
      tabletype: 'baccarat',
      currency: 'CNY',
      betitems: [
        {
          bettype: 'Player',
          min: 10,
          max: 250000,
        },
        {
          bettype: 'Banker',
          min: 10,
          max: 250000,
        },
        {
          bettype: 'Tie',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'P Pair',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'B Pair',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'Perfect Pair',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'Either Pair',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'Super6',
          min: 1,
          max: 10000,
        },
        {
          bettype: 'P/B Bonus',
          min: 1,
          max: 10000,
        },
      ],
    },
    status: {
      code: '0',
      message: 'Success',
      datetime: '2022-02-08T03:10:59-04:00',
      traceCode: '8dF1aeAVBffwPp',
      latency: '143.909µs',
    },
  }
}

export const GET_TABLEID = [
  {
    label: 'AAT01',
    value: 'AAT01',
  },
  {
    label: 'AAT02',
    value: 'AAT02',
  },
  {
    label: 'AAT03',
    value: 'AAT03',
  },
]

export const JPY_BETLIMITROWS = () => [
  {
    key: 'jpyPlayer',
    label: 'PLAYER',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyBanker',
    label: 'BANKER',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyTie',
    label: 'TIE',
    min: 100,
    max: 50000,

  },
  {
    key: 'jpyPPair',
    label: 'P PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyBPair',
    label: 'B Pair',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyPerfectPair',
    label: 'PERFECT PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyEitherPair',
    label: 'EITHER PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpySuperSix',
    label: 'SUPER6',
    min: 100,
    max: 50000,
  },
  {
    key: 'jpyPBBous',
    label: 'P/B BONUS',
    min: 100,
    max: 50000,
  },
]
export const USD_BETLIMITROWS = () => [
  {
    key: 'usdPlayer',
    label: 'PLAYER',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdBanker',
    label: 'BANKER',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdTie',
    label: 'TIE',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdPPair',
    label: 'P PAIR',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdBPair',
    label: 'B Pair',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdPerfectPair',
    label: 'PERFECT PAIR',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdEitherPair',
    label: 'EITHER PAIR',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdSuperSix',
    label: 'SUPER6',
    min: 100,
    max: 50000,

  },
  {
    key: 'usdPBBous',
    label: 'P/B BONUS',
    min: 100,
    max: 50000,

  },
]
export const RMB_BETLIMITROWS = () => [
  {
    key: 'rmbPlayer',
    label: 'PLAYER',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbBanker',
    label: 'BANKER',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbTie',
    label: 'TIE',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbPPair',
    label: 'P PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbBPair',
    label: 'B Pair',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbPerfectPair',
    label: 'PERFECT PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbEitherPair',
    label: 'EITHER PAIR',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbSuperSix',
    label: 'SUPER6',
    min: 100,
    max: 50000,
  },
  {
    key: 'rmbPBBous',
    label: 'P/B BONUS',
    min: 100,
    max: 50000,
  },
]
export const tableIds = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Table01', value: 'Table01' },
  { label: 'Table02', value: 'Table02' },
  { label: 'Table03', value: 'Table03' },
  { label: 'Table04', value: 'Table04' },
  { label: 'Table05', value: 'Table05' },
]
export const singleTableIds = [
  { label: 'Table01', value: 'Table01' },
  { label: 'Table02', value: 'Table02' },
  { label: 'Table03', value: 'Table03' },
  { label: 'Table04', value: 'Table04' },
  { label: 'Table05', value: 'Table05' },
]
