import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const scroll = (state = { status: false, offset: 0 }, action) => {
  switch (action.type) {
    case actionType.ui.setScroll:
      return { status: action.payload.status, offset: action.payload.offset }
    default:
      return state
  }
}

export default combineReducers({ scroll })

export const getScroll = state => state.scroll
