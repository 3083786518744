import React, { useCallback } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: '0.5px solid grey',
    padding: '4px 10px!important',
  },
  border: {
    backgroundColor: '#222',
  },
  buttonDisabled: {
    color: 'red',
  },
  enabled: {
    '& > span:first-of-type': {
      color: '#039be5',
    },
    '& > span:last-of-type': {
      opacity: 0.5,
      backgroundColor: '#039be5!important',
    },
  },
  disabled: {
    '& > span:first-of-type': {
      color: '#bdbdbd',
    },
    '& > span:last-of-type': {
      opacity: 0.3,
      backgroundColor: '#bdbdbd!important',
    },
  },
  checked: {
    '& > span:first-of-type': {
      color: '#039be5',
    },
    '& > span:last-of-type': {
      opacity: 1,
    },
  },
  unChecked: {
    '& > span:first-of-type': {
      color: '#bdbdbd',
    },
  },
}))

const EnhancedTableRow = ({ lang, row, columns, disabled, index, setTablesets }) => {
  const classes = useStyles()
  const handleSwitchChange = useCallback(
    (e, isChecked) => {
      setTablesets((prev) => {
        const temp = prev.map((obj) => ({ ...obj }))
        const selectedIndex = temp.findIndex((item) => item.tableid === row.tableid)
        temp[selectedIndex].viewstatus = isChecked ? 1 : 0
        return temp
      })
    },
    [row, setTablesets],
  )
  const handleCheckboxChange = useCallback(
    (e, isChecked) => {
      setTablesets((prev) => {
        const temp = prev.map((obj) => ({ ...obj }))
        const selectedIndex = temp.findIndex((item) => item.tableid === row.tableid)
        temp[selectedIndex].istopgame = isChecked
        return temp
      })
    },
    [row, setTablesets],
  )

  return (
    <TableRow row={row} className={classes.border}>
      <TableCell align="center" className={classes.tableCell}>
        {++index}
      </TableCell>
      {row &&
        Object.entries(row).map((item, index) => {
          const [key, value] = item
          if (columns[index + 1].renderOption === undefined) {
            return (
              <TableCell key={key} align={columns[index + 1].align} className={classes.tableCell}>
                {value}
              </TableCell>
            )
          }
          return (
            <TableCell key={key} align={columns[index + 1].align} className={classes.tableCell}>
              {columns[index + 1].renderOption({
                classes,
                lang,
                row,
                disabled,
                handleSwitchChange,
                handleCheckboxChange,
              })}
            </TableCell>
          )
        })}
    </TableRow>
  )
}

export default EnhancedTableRow
