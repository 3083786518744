import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to create customer service
 */
export function * create ({ payload }) {
  try {
    // 1. Create customer service request started
    yield put(actions.customerService.createCustomerServiceRequest())

    // 2. Call API: create customer service
    const res = yield call(api.post, api.BO, '/cs/create', payload)

    // 3. Create customer service success
    yield put(actions.customerService.createCustomerServiceSuccess({ response: res.data.data }))

    // 4. Announce create cs success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Create customer service failure
    yield put(actions.customerService.createCustomerServiceFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch cs datail
 */
export function * fetchCustomerServiceDetail ({ payload }) {
  try {
    // 1. Fetch cs datail request started
    yield put(actions.customerService.fetchCustomerServiceDetailRequest())

    // 2. Call API: fetch cs datail
    const res = yield call(api.get, api.BO, `/cs?userid=${payload.customerServiceId}`)

    // 3. Fetch cs datail success
    yield put(actions.customerService.fetchCustomerServiceDetailSuccess({
      customerServiceId: payload.customerServiceId,
      response: res.data.data,
    }))
  } catch (err) {
    // Fetch cs datail failure
    yield put(actions.customerService.fetchCustomerServiceDetailFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update cs status
 */
export function * updateCustomerServiceStatus ({ payload, meta }) {
  try {
    // 1. Update cs status request started
    yield put(actions.customerService.updateCustomerServiceStatusRequest())

    const { cb } = meta

    // 2. Call API: update cs status
    const res = yield call(api.post, api.BO, 'cs/status/update', {
      userid: payload.userid,
      action: payload.status.toLowerCase(),
    })

    // 3. Call API: fetch cs detail
    yield call(fetchCustomerServiceDetail, { payload: { customerServiceId: payload.userid } })

    // 4. Update cs status success
    yield put(actions.customerService.updateCustomerServiceStatusSuccess({ response: res.data.data }))
    // 5. Call extra events
    yield call(cb)

    // 6. Announce update cs status success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Update cs status failure
    yield put(actions.customerService.updateCustomerServiceStatusFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update cs password
 */
export function * updateCustomerServicePassword ({ payload }) {
  try {
    // 1. Update cs password request started
    yield put(actions.customerService.updateCustomerServicePasswordRequest())

    // 2. Call API: update cs password
    const res = yield call(api.post, api.BO, 'cs/pwd', payload)

    // 3. Update cs password success
    yield put(actions.customerService.updateCustomerServicePasswordSuccess({ response: res.data.data }))

    // 4. Announce update cs password success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'pwdChangeSuccess',
      variant: 'success',
    }))
  } catch (err) {
    // Update cs password failure
    yield put(actions.customerService.updateCustomerServicePasswordFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch cs list
 */
export function * fetchCustomerServices ({ payload }) {
  try {
    // 1. Update cs fetch cs list request started
    yield put(actions.customerService.fetchCustomerServicesRequest())

    // 2. Call API: update cs fetch cs list
    const res = yield call(api.get, api.BO, `/cs?pagesize=${payload.pagesize}`)

    // 3. Update cs fetch cs list success
    yield put(actions.customerService.fetchCustomerServicesSuccess({ response: res.data.data.data }))
  } catch (err) {
    // Update fetch cs list failure
    yield put(actions.customerService.fetchCustomerServicesFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.customerService.createCustomerService, create),
  takeLatest(actionType.customerService.fetchCustomerServiceDetail, fetchCustomerServiceDetail),
  takeLatest(actionType.customerService.updateCustomerServiceStatus, updateCustomerServiceStatus),
  takeLatest(actionType.customerService.updateCustomerServicePassword, updateCustomerServicePassword),
  takeLatest(actionType.customerService.fetchCustomerServices, fetchCustomerServices),
]

export default effects
