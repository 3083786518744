import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useStyles from './BetThresholdModification.style'
import language from '../../locale/userProfile'
import Button from '../../components/Button'
import { api } from '../../services'
import OutlinedTextField from '../../components/text-fields/OutlinedTextField'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { GAME_TYPES } from '../../consts'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MultiSelectTextField from '../../components/text-fields/MultiSelectTextField'
import SelectTextField from '../../components/text-fields/SelectTextField'
import Paper from '../../components/Paper'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import { renderFields } from '../../components/forms/helper'
import actions from '../../actions'

const BetThresholdModification = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation('userProfile')
  const formikRef = useRef(null)

  // 選單列表
  const allGamesOptions = useSelector((state) => state.games[state.state.language])
  const allGamesTypes = useSelector((state) => GAME_TYPES[state.state.language])

  // 進入頁面時會戳api -> 找代理列表
  const [isFetched, setIsFetched] = useState(false)
  const [ownerList, setOwnerList] = useState([])

  // 切換標籤用
  const tabList = ['gameCodes', 'gameTypes']
  const [tabValue, setTabValue] = useState(0)
  const handleTabValue = (_, value) => setTabValue(value)

  // 傳給api用的值
  const [parentList, setParentList] = useState([])
  const [parentAccountsValue, setParentAccountsValue] = useState([])
  const [gameCodesValue, setGameCodesValue] = useState([])
  const [gametypeValue, setGametypeValue] = useState([])

  // 過濾代理列表

  const handleReset = useCallback(() => {
    formikRef.current.resetForm()
    setParentList([])
    setParentAccountsValue([])
    setGameCodesValue([])
    setGametypeValue([])
  }, [])

  const handleOwnerSelect = useCallback(async (value) => {
    dispatch(actions.game.fetchGameList({ brand: value.brand }))
    await handleReset()
    const response = await api.get(
      api.BO,
      `/sslist/v2?role=parent&parentID=${value.value}`,
    )
    setParentList(response.data.data.map((item) => ({ label: item.account, value: item.userID })))
  }, [dispatch, handleReset])
  // 重置按鈕

  const handleClearBetThresholdBtnClick = useCallback(async () => {
    try {
      const response = await api.post(api.BO, '/ss/betThresholds/batch/reset', {
        userID: formikRef.current.state.values.userID,
      })
      if (response.data.status.code === '0') {
        dispatch(
          actions.msgs.add({
            variant: 'success',
            replaceWithI18nText: 'modifySuccess',
          }),
        )
      }
    } catch (error) {
      dispatch(
        actions.msgs.add({
          msg: error.data && error.data.status && error.data.status.message,
          variant: 'warning',
        }),
      )
    }
  }, [dispatch])
  // 更新按鈕
  const handleBtnSubmit = useCallback(async (props) => {
    try {
      const response = await api.post(api.BO, '/ss/betThresholds/batch/upsert', {
        userID: props.userID,
        ...props,
      })
      if (response.data.status.code === '0') {
        handleReset()
        dispatch(
          actions.msgs.add({
            variant: 'success',
            replaceWithI18nText: 'modifySuccess',
          }),
        )
      }
    } catch (error) {
      dispatch(
        actions.msgs.add({
          msg: error.data && error.data.status && error.data.status.message,
          variant: 'warning',
        }),
      )
    }
  }, [dispatch, handleReset])

  const initialValues = {
    name: '',
    userID: [],
    minimum: '',
    maximum: '',
    default: '',
    bettype_maximum: '',
    round_maximum: '',
    roombet: '',
    gameCodes: [],
    gameTypes: [],
  }
  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'ownerAccount',
        label: t('ownerAccount'),
        fullWidth: true,
        options: ownerList,
        onChange: (value) => {
          handleOwnerSelect(value)
          formikRef.current.setFieldValue('ownerAccount', value.value)
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'userID',
        label: t('childAccount'),
        fullWidth: true,
        options: parentList,
        InputLabelProps: {
          shrink: true,
        },
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: parentAccountsValue,
        onChange: (value) => {
          setParentAccountsValue(value)
          formikRef.current.setFieldValue('userID', value.map(v => v.value))
        },
      },
      {
        Field: OutlinedTextField,
        name: 'name',
        label: t('name'),
        fullWidth: true,
        type: 'string',
      },
      {
        Field: OutlinedTextField,
        name: 'minimum',
        label: t('minimum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'maximum',
        label: t('maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'default',
        label: t('default'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'bettype_maximum',
        label: t('common:bettype_maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'round_maximum',
        label: t('common:round_maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'roombet',
        label: t('roombet'),
        fullWidth: true,
        type: 'string',
        placeholder: 'eg: 1,2,3,4,5,6',
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'gameCodes',
        label: t('gameCodes'),
        fullWidth: true,
        options: allGamesOptions,
        InputLabelProps: {
          shrink: true,
        },
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: gameCodesValue,
        onChange: (value) => {
          setGameCodesValue(value)
          formikRef.current.setFieldValue('gameCodes', value.map(v => v.value))
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'gameTypes',
        label: t('gameTypes'),
        fullWidth: true,
        options: allGamesTypes,
        InputLabelProps: {
          shrink: true,
        },
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: gametypeValue,
        onChange: (value) => {
          setGametypeValue(value)
          formikRef.current.setFieldValue('gameTypes', value.map(v => v.value))
        },
      },
    ],
  ]
  const schema = Yup.object().shape({
    name: Yup.string().required(t('common:required')),
    userID: Yup.array().min(1, t('common:required')),
    minimum: Yup.number()
      .required(t('common:required'))
      .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
    maximum: Yup.number()
      .required(t('common:required'))
      .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
    default: Yup.number()
      .required(t('common:required'))
      .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
    bettype_maximum: Yup.number()
      .test('notEmptymoreThenEqualZero', 'notEmptymoreThenEqualZero', (value) => value === undefined || value >= 0),
    round_maximum: Yup.number()
      .test('notEmptymoreThenEqualZero', 'notEmptymoreThenEqualZero', (value) => value === undefined || value >= 0),
    roombet: Yup.string().trim().matches(/^[0-9]+(\.[0-9]+)?(,[0-9]+(\.[0-9]+)?)*$/, { message: t('onlyNumbersDigitsAndCommas') })
      .test('noMoreThanSixNumbers', 'noMoreThanSixNumbers', (value) => {
        if (value === undefined) return true
        return value.split(',').length <= 6
      }),
    gameCodes: Yup.array().when('gameTypes', (x) => {
      if (x?.length === 0) {
        return Yup.array().min(1, t('common:gamecodesOrGameTypesIsRequired'))
      }
    }),
    gameTypes: Yup.array().when('gameCodes', (x) => {
      if (!x.length) {
        return Yup.array().min(1, t('common:gamecodesOrGameTypesIsRequired'))
      }
    }),
  }, [['gameCodes', 'gameTypes']])

  // 取得翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'userProfile', language.en)
    i18n.addResourceBundle('cn', 'userProfile', language.cn)
  }, [i18n])

  // 搜索代理列表
  useEffect(() => {
    if (isFetched) return
    api.get(
      api.BO,
      '/sslist/v2?role=owner',
    ).then((response) => {
      setOwnerList(response.data.data.map((item) => ({ label: item.account, value: item.userID, brand: item.brand })))
      setIsFetched(true)
    })
  }, [isFetched])

  return (
    <Formik
      ref={formikRef}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleBtnSubmit}>
      {({ submitForm, ...props }) =>
        (
          <Form style={{ maxWidth: '600px' }}>
            <Container>
              <GridItemFull>
                <Paper>
                  <DialogTitle >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                      {t('updateBetThreshold')}
                    </div>
                  </DialogTitle>
                  <DialogContent className={classes.contentRoot}>
                    {renderFields(fields[0], { t, ...props })}
                    {parentList.length > 0 && <>
                      {renderFields(fields[1], { t, ...props })}
                      <Tabs value={tabValue} onChange={handleTabValue} >
                        {tabList.map((tab) => (
                          <Tab key={tab} label={t(tab)} classes={{
                            root: classes.root,
                          }} />
                        ))}
                      </Tabs>
                      {tabValue === 0 && renderFields(fields[2], { t, ...props })}
                      {tabValue === 1 && renderFields(fields[3], { t, ...props })}
                    </>}
                  </DialogContent>
                  <DialogActions className={classes.actionsRoot}>
                    <Button
                      disabled={!parentAccountsValue.length}
                      onClick={handleClearBetThresholdBtnClick}
                      variant='contained'
                      color="primary"
                    >
                      {t('common:reset')}
                    </Button>
                    <Button
                      disabled={!parentAccountsValue.length}
                      variant='contained'
                      color='primary'
                      actionType={'positive'}
                      onClick={submitForm}
                    >
                      {t('common:modify')}
                    </Button>
                  </DialogActions>
                </Paper>
              </GridItemFull>
            </Container>
          </Form>
        )
      }
    </Formik>
  )
}

export default BetThresholdModification
