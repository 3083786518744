import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'

import a from '../../actions'
import { qsParse } from '../../helpers'
import { getUser } from '../../reducers'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import CreateCustomerServiceForm from '../../components/forms/CreateCustomerServiceForm'

class CreateCustomerService extends Component {
  onSubmit = values => {
    return this.props.createCustomerService(values)
  };

  render () {
    const { user } = this.props

    return (
      <Container>
        <GridItem form>
          <Paper>
            <CreateCustomerServiceForm
              account=""
              password=""
              nickname=""
              user={() => user}
              onSubmit={this.onSubmit}
            />
          </Paper>
        </GridItem>
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const user = getUser(state)

  return {
    search,
    user,
  }
}

export default connect(
  mapStateToProps,
  {
    createCustomerService: a.customerService.createCustomerService,
  },
)(CreateCustomerService)
