import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const styles = theme => ({
  iconButton: {
    height: 24,
    width: 24,
    padding: 0,
  },
})

class TicketLinkButton extends Component {
  render () {
    const { items } = this.props
    return (
      <>
        <MenuList>
          {items.map(
            ({ to, onClick = () => {}, label, ...item }, index) => {
              if (to) {
                return (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={to}
                    onClick={e => {
                      onClick(e)
                    }}
                    {...item}
                  >
                    <FiberManualRecordIcon color="primary" />
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem
                    key={index}
                    onClick={e => {
                      onClick(e)
                    }}
                    {...item}
                  >
                    <FiberManualRecordIcon color="disabled" />
                  </MenuItem>
                )
              }
            },
          )}
        </MenuList>
      </>
    )
  }
}

export default withStyles(styles)(TicketLinkButton)
