import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    boxShadow: ' 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    backgroundColor: '#303030',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '2rem 1rem 1rem 1rem',
    color: 'rgba(255, 255, 255, 0.54)',
  },
  dateContainer: {
    marginright: '10px',
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
    fontSize: '2.125rem',
    position: 'relative',
    display: 'flex',
  },
  number: {
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  yearInput: {
    backgroundColor: 'transparent',
    fontSize: '1rem!important',
    lineHeight: 1.75,
    letterSpacing: '0.01em',
    position: 'absolute',
    top: '-1.5rem',
    left: '0.25rem',
  },
  dateInput: {
    width: '50px',
    fontSize: '2.5rem',
  },
  monthContainer: {
    minWidth: '75px',
    '&::after': {
      content: 'attr(data-month)',
      fontSize: '1.5rem',
    },
  },
  dayContainer: {
    minWidth: '75px',

    '&::after': {
      content: 'attr(data-day)',
      fontSize: '1.5rem',
    },
  },
  timeContainer: {
    lineHeight: 1.04,
    display: 'flex',
  },
  timeInput: {
    width: '60px',
    fontSize: '3rem',
  },
  hours: {
    minWidth: '80px',
    '&::after': {
      content: '":"',
      fontSize: '3rem',
    },
  },
  commonInputStyle: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'rgba(255, 255, 255, 0.54)',
    fontWeight: 400,
    '&:focus': {
      color: '#fff',
      outline: 'none',
    },
  },
}), {
  name: 'DateTimeInputField',
})

export default useStyles
