import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.cobrand.setStyleRequest:
      return true
    case actionType.cobrand.setStyleSuccess:
    case actionType.cobrand.setStyleFailure:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
})

export const getIsFetching = state => state.isFetching
