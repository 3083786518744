// 玩家列表
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import language from '../../locale/agentSum'

import {
  getTimeZone,
} from '../../reducers'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Form from './form'
import Paper from '../../components/Paper'

import Card from './card'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import { useSelector } from 'react-redux'
import { api } from '../../services'

const createDate = (time = '', timezone) => new Date(moment().utcOffset(timezone * 60)[`${time}Of`]('date').format('YYYY/MM/DD HH:mm:ss'))

const AgentSum = () => {
  const { i18n, t } = useTranslation('agentSum')
  const timezone = useSelector(state => getSavedState('timezone') || getTimeZone(state))
  const [isSearch, setIsSearch] = useState(false) // 是否搜尋過
  const [startDate, setStartDate] = useState(createDate('start', timezone)) // 開始日期
  const [endDate, setendDate] = useState(createDate('end', timezone)) // 結束日期
  const [cash, setCash] = useState({ cashin: 0, cashout: 0 }) // 出入金資料

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentSum', language.en)
    i18n.addResourceBundle('cn', 'agentSum', language.cn)
  }, [i18n])

  useEffect(() => {
    if (isSearch) handleSubmit({ startDate, endDate })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone])

  const handleSubmit = async (values) => {
    const { startDate, endDate } = values
    const res = await api.get(api.BO, '/ss/sumbyparent', {
      params: {
        starttime: moment(startDate).format('YYYY/MM/DD HH'),
        stoptime: moment(endDate).format('YYYY/MM/DD HH'),
        timezone: timezone || 0,
      },
    })
    setIsSearch(true)
    setStartDate(startDate)
    setendDate(endDate)
    setCash(res.data.data)
  }

  return (
    <Container>
      <GridItemFull>
        <Paper>
          <Form
            startDate={startDate}
            endDate={endDate}
            onSubmit={handleSubmit}
          />
        </Paper>
      </GridItemFull>
      { isSearch && <>
        <GridItemFull xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card
            Icon={AccountBalanceIcon}
            title={t('cashin')}
            date={moment(startDate).format('YYYY/MM/DD HH')}
            number={cash.cashin}
          />
        </GridItemFull>
        <GridItemFull xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card
            Icon={CardMembershipIcon}
            title={t('cashout')}
            date={moment(endDate).format('YYYY/MM/DD HH')}
            number={cash.cashout}
          />
        </GridItemFull>
      </>}
    </Container>
  )
}

export default AgentSum
