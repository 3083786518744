import { Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './index.style'
import classNames from 'classnames'

const SummaryTable = props => {
  const { t, summaryList, currentAgent } = props
  const classes = useStyles()
  const tableColumn = [t('agent'), t('player'), t('count')]

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1">{t('playerAppearCount')}</Typography>

      <table className={classes.table}>
        <tbody>
          <tr>
            {tableColumn.map((item) => <th className={classes.row}>{item}</th>)}
          </tr>
          {summaryList.length ? summaryList.map((row, index) => {
            const isSelfAgent = currentAgent === row.playerinfo.parentAcc
            return <tr key={`summary-${index}`}>
              <td className={classNames(classes.row, isSelfAgent && classes.selfAgent)}>{row.playerinfo.parentAcc}</td>
              <td className={classNames(classes.row, isSelfAgent && classes.selfAgent)}>{row.playerinfo.account}</td>
              <td className={classes.row}>{row.count}</td>
            </tr>
          }) : ''}
        </tbody>
      </table>
    </div>
  )
}

SummaryTable.propTypes = {}

export default SummaryTable
