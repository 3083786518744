import React, { Component } from 'react'

import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import RadioTextField from '../text-fields/RadioTextField'

import { renderFields } from './helper'
import GridItem from '../grids/GridItem'
import GridContainer from '../grids/GridContainer'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    props.onSubmit(values, setSubmitting)
  },
  displayName: 'CustomerServiceDetailForm',
  enableReinitialize: true,
})

class CustomerServiceDetailForm extends Component {
  render () {
    const {
      theme,
      isRequesting,
      classes,
      resetForm,
      handleEditing,
      handleCancel,
      isEditing,
      inProfile = () => false,
      t,
    } = this.props

    const fields = [
      {
        name: 'account',
        label: t('account'),
        fullWidth: true,
        InputProps: { readOnly: true },
        Field: OutlinedTextField,
      },

      {
        name: 'signUpAt',
        label: t('signUpAt'),
        fullWidth: true,
        InputProps: { readOnly: true },
        Field: OutlinedTextField,
      },
      {
        name: 'loginAt',
        label: t('loginAt'),
        fullWidth: true,
        InputProps: { readOnly: true },
        Field: OutlinedTextField,
      },

      {
        Field: RadioTextField,
        name: 'status',
        label: t('status'),
        fullWidth: true,
        disabled: isRequesting || !isEditing() || inProfile(),
        options: [
          { label: t('enable'), value: 'Enable' },
          { label: t('disable'), value: 'Disable' },
        ],
      },
    ]

    return (
      <Form>
        <h3 style={ theme.customer.H3Style }>{t('basicInfo')}</h3>

        {renderFields(fields, this.props)}

        <GridContainer>
          <GridItem fluid>
            {!inProfile() && (
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleEditing}
                disabled={isRequesting || isEditing()}
              >
                {t('edit', { ns: 'common' })}
              </Button>
            )}
          </GridItem>

          {isEditing() && (
            <>
              <GridItem fluid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isRequesting}
                >
                  {t('save', { ns: 'common' })}
                </Button>
              </GridItem>
              <GridItem fluid>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isRequesting}
                  onClick={() => {
                    resetForm()
                    handleCancel()
                  }}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>
              </GridItem>
            </>
          )}
        </GridContainer>
      </Form>
    )
  }
}

export default formikEnhancer(
  withTranslation('userProfile')(
    withStyles(styles, { withTheme: true })(CustomerServiceDetailForm),
  ),
)
