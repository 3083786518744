import React, { useEffect } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import SelectTextField from '../text-fields/SelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'PlayerPayoffForParentForm',
})

const PlayerPayoffForParentForm = props => {
  const {
    isSubmitting,
    classes,
    t,
    buttonRef,
    isFetching,
    promoList,
    promoPeriod,
    selectedPromo,
    setFieldValue,
    values: formikValues,
  } = props

  const fields = [
    [
      {
        name: 'promoID',
        label: t('promoList'),
        fullWidth: true,
        options: promoList(),
        isClearable: true,
        onChange: e => selectedPromo(e ? e.value : ''),
        Field: SelectTextField,
      },
    ],
    [
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: '1month',
      },
    ],
    [
      {
        name: 'periodID',
        label: t('period'),
        fullWidth: true,
        isClearable: true,
        options: promoPeriod(),
        Field: SelectTextField,
      },
    ],
  ]

  // 當期數列表改變時改變時 要檢查期數是否存在
  useEffect(() => {
    const isPeriodIDExist = promoPeriod().some(({ value }) => value === formikValues.periodID)
    if (!isPeriodIDExist) setFieldValue('periodID', '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoPeriod()])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
        buttonRef={buttonRef}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching() && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withTranslation('playerPayoff')(
    withStyles(styles)(PlayerPayoffForParentForm),
  ),
)
