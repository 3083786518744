const rawData = {
  titleTable: ['Payoff Search', '派彩查询'],

  agentAcc: ['Agent Account', '代理帐号'],
  ownerAcc: ['General Agent Account', '总代理帐号'],
  playerAcc: ['Player Account', '玩家帐号'],
  period: ['Period', '期数'],
  promoname: ['Promo Name', '活动名称'],
  account: ['Account', '玩家帐号'],
  parentAcc: ['Agent Account', '代理帐号'],
  amount: ['Amount', '派彩金额'],
  mtcode: ['Transaction ID', '交易代码'],
  sendtime: ['Payoff time', '派发时间'],
  promoList: ['Promo List', '活动清单'],
  no: ['No.', '序号'],
  ranking: ['Ranking', '名次'],
  totalRanking: ['Total Ranking', '总榜单'],
  all: ['All', '全部'],

  // input error,
  required: ['This is a required field.', '此为必填栏位'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
