import React, { useState, useCallback, useEffect } from 'react'
import useStyles from './ExtendedDateTimeField.style'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { getDaysInMonth, setMonth as DFSetMonth, setDate } from 'date-fns'

const DateTimeInputField = (props) => {
  const { date, onChange, views } = props
  const classes = useStyles()
  const { t } = useTranslation()

  // state統一為字串
  // date統一為數字
  const [year, setYear] = useState(date.getFullYear().toString())
  const [month, setMonth] = useState((date.getMonth() + 1).toString())
  const [day, setDay] = useState(date.getDate().toString())
  const [hours, setHours] = useState(date.getHours().toString())
  const [minutes, setMinutes] = useState(date.getMinutes().toString())

  // 欄位onMount時補零
  useEffect(() => {
    if (year.length < 4) { setYear((prev) => `${prev}`.padStart(4, '0')) }
    if (month.length < 2) { setMonth((prev) => `0${prev}`) }
    if (day.length < 2) { setDay((prev) => `0${prev}`) }
    if (hours.length < 2) { setHours((prev) => `0${prev}`) }
    if (minutes.length < 2) { setMinutes((prev) => `0${prev}`) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 調整欄位直接影響原先日期
  const handleChange = useCallback((e) => {
    const type = e.target.dataset.type
    const normalizedValue = e.target.value.normalize('NFKC')
    const newNumber = Number(normalizedValue)
    let newDate = new Date(date)
    const dateTimeMap = ['year', 'month', 'day', 'hours', 'minutes']
    if (isNaN(newNumber) || !dateTimeMap.includes(type)) return
    const datetimeObj = {
      year: (number) => {
        if (`${newNumber}`.length > 4) {
        } else if (number === 0) {
          setYear('')
          newDate.setFullYear(1)
        } else {
          setYear(number)
          newDate.setFullYear(number)
        }
      },
      month: (number) => {
        if (number === 0) {
          setMonth('')
          newDate.setMonth(0)
        } else if (number > 12) {
          setMonth('12')
          newDate.setMonth(11)
        } else {
          setMonth(`${number}`)
          const daysOfNewDate = date.getDate()
          const daysOfNextDate = getDaysInMonth(new Date(new Date().getFullYear(), number - 1))
          if (daysOfNewDate > daysOfNextDate) {
            newDate = DFSetMonth(setDate(date, daysOfNextDate), number - 1)
            setDay(`${daysOfNextDate}`)
          } else {
            newDate.setMonth(number - 1)
          }
        }
      },
      day: (number) => {
        if (number === 0) {
          setDay('')
          newDate.setDate(1)
        } else if (number > getDaysInMonth(date)) {
          setDay(`${getDaysInMonth(date)}`)
          newDate.setDate(`${getDaysInMonth(date)}`)
        } else {
          setDay(`${number}`)
          newDate.setDate(number)
        }
      },
      hours: (number) => {
        if (number === 0) {
          setHours('')
          newDate.setHours(0)
        } else if (number >= 24) {
          setHours('23')
          newDate.setHours(23)
        } else {
          setHours(`${number}`)
          newDate.setHours(number)
        }
      },
      minutes: (number) => {
        if (number === 0) {
          setMinutes('')
          newDate.setMinutes(0)
        } else if (number >= 60) {
          setMinutes('59')
          newDate.setMinutes(59)
        } else {
          setMinutes(`${number}`)
          newDate.setMinutes(number)
        }
      },
    }
    datetimeObj[type](newNumber)
    onChange(newDate)
  }, [date, onChange])

  // 離開欄位時補零
  const handleBlur = (e) => {
    const type = e.target.dataset.type
    const newNumber = Number(e.target.value)
    const datetimeObj = {
      year: () => { setYear(`${date.getFullYear()}`.padStart(4, '0')) },
      month: () => { setMonth(`${date.getMonth() + 1}`.padStart(2, '0')) },
      day: () => { setDay(`${date.getDate()}`.padStart(2, '0')) },
      hours: () => { setHours(`${date.getHours()}`.padStart(2, '0')) },
      minutes: () => { setMinutes(`${date.getMinutes()}`.padStart(2, '0')) },
    }
    datetimeObj[type](newNumber)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.dateContainer}>
        <input
          type="number"
          autoFocus
          data-type="year"
          value={year}
          maxLength={4}
          className={classNames(classes.number, classes.commonInputStyle, classes.yearInput)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div className={classes.monthContainer} data-month={t('common:dateTimeMonth')}>
          <input
            type="number"
            data-type="month"
            value={month}
            maxLength={2}
            className={classNames(classes.number, classes.commonInputStyle, classes.dateInput)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        {
          views.includes('day') && <div className={classes.dayContainer} data-day={t('common:dateTimeDay')}>
            <input
              type="number"
              data-type="day"
              value={day}
              maxLength={2}
              className={classNames(classes.number, classes.commonInputStyle, classes.dateInput)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        }
      </div>
      {
        views.includes('day') && <div className={classes.timeContainer}>
          <div className={classes.hours}>
            <input
              type="number"
              data-type="hours"
              value={hours}
              maxLength={2}
              className={classNames(classes.number, classes.timeInput, classes.commonInputStyle)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className={classes.minutes}>
            <input
              type="number"
              data-type="minutes"
              value={minutes}
              maxLength={2}
              className={classNames(classes.number, classes.timeInput, classes.commonInputStyle)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default DateTimeInputField
