import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

function features (
  state = {
    isFetching: false,
    items: [],
  },
  action = {},
) {
  switch (action.type) {
    case actionType.teamFeature.fetchRequest:
      return { ...state, isFetching: true }
    case actionType.teamFeature.fetchSuccess:
      return {
        ...state,
        isFetching: false,
        items: action.payload.response,
      }
    default:
      return state
  }
}

const byTeamId = (state = {}, action = {}) => {
  switch (action.type) {
    case actionType.teamFeature.fetchRequest:
    case actionType.teamFeature.fetchSuccess:
      return {
        ...state,
        [action.payload.teamId]: features(state[action.payload.teamId], action),
      }
    default:
      return state
  }
}

export default combineReducers({
  byTeamId,
})

export const getTeamFeaturesByTeamId = (state, teamId) => {
  const teamFeatures = state.byTeamId[teamId]

  return typeof teamFeatures === 'undefined' ? [] : teamFeatures.items
}

export const getIsTeamFeaturesFetching = (state, teamId) => {
  const teamFeatures = state.byTeamId[teamId]

  return typeof teamFeatures === 'undefined' ? [] : teamFeatures.isFetching
}
