import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../services'
import actions from '../actions'
import actionType from '../actions/actionType'

/**
 * Effect to fetch userInfo
 */
export function * fetchInfo ({ payload }) {
  const { userID } = payload
  try {
    // 1. Fetch user info request started
    yield put(actions.user.fetchInfoRequest({ agentId: userID }))

    // 2. Call API:
    // - fetch user info
    // - fetch total sign up
    const userInfoRes = yield call(api.get, api.BO, '/user/info')

    // 3. Fetch user info success
    yield put(actions.user.fetchInfoSuccess({
      response: userInfoRes.data.data,
    }))
  } catch (err) {
    // Fetch user info failure
    yield put(actions.user.fetchInfoFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch user totalSignUp
 */
export function * fetchTotalSignUp ({ payload }) {
  try {
    const { userID } = payload

    // 1. Fetch user totalSignUp request started
    yield put(actions.event.requestStart({ eventName: 'fetchTotalSignUp' }))
    yield put(actions.user.fetchTotalSignUpRequest({ agentId: userID }))
    // 2. Call API:
    // - fetch total sign up
    const totalRes = yield call(api.get, api.BO, `/ss/info/totalSignUp?userid=${userID}`)

    // 3. Fetch user totalSignUp success
    yield put(actions.user.fetchTotalSignUpSuccess({
      agentId: userID,
      response: totalRes.data.data.totalsignup,
    }))
    yield put(actions.event.requestEnd({ eventName: 'fetchTotalSignUp' }))
  } catch (err) {
    console.log('err: ', err)
    // Fetch user totalSignUp failure
    yield put(actions.user.fetchTotalSignUpFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
    yield put(actions.event.requestEnd({ eventName: 'fetchTotalSignUp' }))
  }
}

const effects = [
  takeLatest(actionType.user.fetchInfo, fetchInfo),
  takeLatest(actionType.user.fetchTotalSignUp, fetchTotalSignUp),
]

export default effects
