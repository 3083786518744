import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import a from '../../actions'
import { qsParse } from '../../helpers'
import {
  getAgentById,
  getAgentTotalPlayersById,
  getUser,
  getTimeZone,
  getTheme,
  getIsRequesting,
} from '../../reducers'
import { DARK_MODE } from '../../themes/bo'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import { getSavedState } from '../../helpers/storage'

import AgentDetailForm from '../../components/forms/AgentDetailForm'
import ChangePasswordForm from '../../components/forms/ChangePasswordForm'
import WalletForm from '../../components/forms/WalletForm'
import language from '../../locale/userProfile'

class AgentDetail extends Component {
  state = { show: 0, isEditing: false, isStation: false };

  componentDidMount () {
    const {
      agentId,
      fetchAgentDetail,
      fetchTotalSignUp,
      show,
      i18n,
    } = this.props

    i18n.addResourceBundle('en', 'userProfile', language.en)
    i18n.addResourceBundle('cn', 'userProfile', language.cn)

    this.setState({ show })

    fetchAgentDetail({ userID: agentId })
    fetchTotalSignUp({ userID: agentId })
  }

  componentDidUpdate (prevProps, prevState) {
    const { fetchStationList, agentsById, agentId } = this.props

    if (agentsById[agentId]?.info?.wallettype === 'ce' && this.state.isStation === false) {
      this.setState({ isStation: true })
      fetchStationList({ userID: agentId })
    }
  }

  handleChangeTabs = (_, value) => {
    const { history } = this.props

    this.setState({ show: value })

    const location = {
      state: { show: value },
    }

    history.replace(location)
  };

  handleEditing = () => {
    this.setState({ isEditing: true })
  };

  handleCancel = () => {
    this.setState({ isEditing: false })
  };

  onSubmitProfile = values => {
    const { isAdmin, handleAgentDetailSubmit, agent } = this.props

    const effects = []

    if ((agent.currencySign === true ? 'true' : 'false') !== values.currencySign) {
      effects.push('updateCurrencySign')
    }

    if (agent.status !== values.status) {
      effects.push('updateAgentStatus')
    }

    if (isAdmin && values.betThreshold.enabled === 'true') {
      effects.push('updateBetThreshold')
    }

    handleAgentDetailSubmit(values, {
      effects,
      cb: () => {
        this.handleCancel()
      },
    })
  };

  onSubmitChangePassword = values => {
    this.props.updateAgentPassword(values)
  };

  onSubmitWallet = values => {
    this.props.updateAgentWallet(values)
  };

  onToggleThreshouldStatus = values => {
    const { agent, updateBetThresholdStatus } = this.props

    updateBetThresholdStatus({ userid: agent.userid, enabled: values })
  };

  onCopied = () => {
    this.props.addMsg({
      replaceWithI18nText: 'copiedSuccess',
      variant: 'success',
    })
  }

  render () {
    const {
      agent,
      totalPlayers,
      t,
      isAdmin,
      timezone,
      themeMode,
      isRequesting,
      isTotalSignUpRequesting,
    } = this.props
    const { show, isEditing } = this.state

    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            style={themeMode === DARK_MODE ? { backgroundColor: '#585858' } : {}}
            value={show}
            onChange={this.handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('detail')} />
            <Tab label={t('changePwd')} />
            <Tab
              label={t('walletInfo')}
              style={{ display: agent && agent.sw ? 'flex' : 'none' }}
            />
          </Tabs>
        </AppBar>

        <Container>
          <GridItem form>
            <Paper>
              {agent && show === 0 && (
                <AgentDetailForm
                  userid={agent.userid}
                  account={agent.account}
                  stationAccount={agent.stationAccount}
                  parentAccount={agent.parentacc}
                  signUpAt={moment(agent.signuptime * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
                  loginAt={
                    agent.logintime === 0
                      ? t('neverLogin')
                      : moment(agent.logintime * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss')
                  }
                  totalPlayers={totalPlayers}
                  commission={agent.commission}
                  ssToken={agent.sstoken}
                  status={agent.status}
                  isTestSS={agent.isTestSS === true ? 'true' : 'false'}
                  currencySign={agent.currencySign === true ? 'true' : 'false'}
                  betThreshold={{
                    ...agent.betThreshold,
                    enabled: agent && agent.betThreshold && agent.betThreshold.enabled === true ? 'true' : 'false',
                  }}
                  onSubmit={this.onSubmitProfile}
                  handleToggleBetThreshold={this.onToggleThreshouldStatus}
                  handleEditing={this.handleEditing}
                  handleCancel={this.handleCancel}
                  onCopied={this.onCopied}
                  isEditing={() => isEditing}
                  isAdmin={() => isAdmin}
                  isRequesting={isRequesting}
                  isTotalSignUpRequesting={isTotalSignUpRequesting}
                />
              )}

              {agent && show === 1 && (
                <ChangePasswordForm
                  account={agent.account}
                  password=""
                  confirmPassword=""
                  onSubmit={this.onSubmitChangePassword}
                />
              )}

              {agent && show === 2 && agent.sw && (
                <WalletForm
                  userid={agent.userid}
                  wtoken={agent.wtoken}
                  wurl={agent.wurl}
                  onSubmit={this.onSubmitWallet}
                  handleEditing={this.handleEditing}
                  handleCancel={this.handleCancel}
                  onCopied={this.onCopied}
                  isEditing={isEditing}
                />
              )}
            </Paper>
          </GridItem>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, { location, match }) => {
  const agentsById = state.agents.byId
  const search = location ? qsParse(location.search) : null
  const agentId = match.params.agentId
  const agent = getAgentById(state, agentId)
  const totalPlayers = getAgentTotalPlayersById(state, agentId)
  const show = location.state && location.state.show ? location.state.show : 0
  const user = getUser(state)
  const isAdmin = user && user.permit === 'admin'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const themeMode = getTheme(state)
  const isRequesting = getIsRequesting(state, 'handleAgentDetailSubmit')
  const isTotalSignUpRequesting = getIsRequesting(state, 'fetchTotalSignUp')

  return {
    agentsById,
    themeMode,
    agentId,
    agent,
    totalPlayers,
    search,
    show,
    isAdmin,
    timezone,
    isRequesting,
    isTotalSignUpRequesting,
  }
}

export default connect(
  mapStateToProps,
  {
    // agent
    fetchAgentDetail: a.agent.fetchDetail,
    fetchStationList: a.agent.fetchStationList,
    fetchTotalSignUp: a.agent.fetchTotalSignUp,
    updateAgentStatus: a.agent.updateStatus,
    updateAgentPassword: a.agent.updatePassword,
    updateAgentWallet: a.agent.updateWallet,
    // bet threshould
    updateBetThresholdStatus: a.betThreshold.updateStatus,
    // event
    handleAgentDetailSubmit: a.event.handleAgentDetailSubmit,
    // msgs
    addMsg: a.msgs.add,
  },
)(withTranslation('userProfile')(AgentDetail))
