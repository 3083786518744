import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Help from '@material-ui/icons/HelpOutline'
import Popper from '@material-ui/core/Popper'
import withStyles from '@material-ui/core/styles/withStyles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'

import { withTranslation } from 'react-i18next'
import language from '../locale/userGuide'
import { DARK_MODE } from '../themes/bo'

const styles = theme => {
  const linkColor = theme.customer.name === DARK_MODE ? '#fff' : ''
  return {
    guideTable: {
      color: theme.customer.name === DARK_MODE ? '#aaa' : '',
      maxWidth: '800px',
      td: {
        padding: '16px 0',
        color: 'blue',
      },
    },
    text: {
      padding: '6px 0',
      '&:first-child': {
        padding: '6px',
        verticalAlign: 'top',
      },
    },
    link: {
      color: linkColor,
      '&:link': {
        color: linkColor,
      },
      '&:visited': {
        color: linkColor,
      },
    },
    popper: {
      zIndex: theme.zIndex.drawer + 1,
      marginTop: '-6px',
    },
  }
}

class UserGuide extends React.Component {
  constructor (props) {
    super(props)

    const { i18n } = this.props
    i18n.addResourceBundle('en', 'userGuide', language.en)
    i18n.addResourceBundle('cn', 'userGuide', language.cn)

    const { permit, role } = props.user

    let url = null

    switch (permit) {
      case 'admin':
        if (role === 'cs') {
          url = 'https://hackmd.io/s/S14aHL-84'
        } else {
          url = 'https://hackmd.io/s/B1VYBUbLV'
        }
        break
      case 'owner':
        if (role === 'cs') {
          url = 'https://hackmd.io/s/B1N2nN-I4'
        } else {
          url = 'https://hackmd.io/s/Hy72omA1E'
        }
        break
      case 'parent':
        if (role === 'cs') {
          url = 'https://hackmd.io/s/BJpr976SV'
        } else {
          url = 'https://hackmd.io/s/H16uPXaBE'
        }
        break
      default:
        console.warn(`Can not match user guide URL for ${permit} - ${role} `)
    }

    this.state = {
      anchorEl: null,
      guide_url: url,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick = event => {
    const { currentTarget } = event
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }))
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  };

  render () {
    const { anchorEl } = this.state
    const { t, classes } = this.props
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton
          aria-owns={open ? 'guide-popover' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
        >
          <Help />
        </IconButton>
        <Popper
          id="guide-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleClick}>
              <table className={classes.guideTable}>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td className={classes.text}>{t('guide1')}</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td className={classes.text}>{t('guide2')}</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td className={classes.text}>{t('guide3')}</td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td className={classes.text}>
                      {t('guide4')}
                      <a
                        className={classes.link}
                        href={this.state.guide_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('document')}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    )
  }
}

export default withTranslation('userGuide')(withStyles(styles, { withTheme: true })(UserGuide))
