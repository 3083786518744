import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import OutlinedCopyTextField from '../text-fields/OutlinedCopyTextField'
import RadioTextField from '../text-fields/RadioTextField'

import { renderFields } from './helper'
import GridItem from '../grids/GridItem'
import GridContainer from '../grids/GridContainer'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object({
    betThreshold: Yup.object({
      enabled: Yup.boolean(),
      maximum: Yup.number()
        .when('enabled', {
          is: true,
          then: Yup.number()
            .typeError('mustNumber')
            .test('moreThenEqualZero', 'moreThenEqualZero', value => value >= 0),
        }),
      default: Yup.number().when(['enabled', 'maximum', 'minimum'], (enabled, maximum, minimum) => {
        if (!enabled) {
          return
        }

        return Yup.number()
          .typeError('mustNumber')
          .test('moreThenEqualMinimumValue', 'moreThenEqualMinimumValue', value => value >= minimum)
          .test('lessThenEqualMaxmumValue', 'lessThenEqualMaxmumValue', value => value <= maximum)
          .test('moreThenEqualZero', 'moreThenEqualZero', value => value >= 0)
      }),
      minimum: Yup.number().when(['enabled', 'maximum'], (enabled, max) => {
        if (!enabled) {
          return
        }

        return Yup.number()
          .typeError('mustNumber')
          .test('lessThenMaxmumValue', 'lessThenMaxmumValue', (value) => value <= max)
          .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0)
      }),
    }),
  }),
  handleSubmit: (values, { props }) => props.onSubmit(values),
  displayName: 'AgentDetailFormForAdmin',
  enableReinitialize: true,
})

class AgentDetailFormForAdmin extends Component {
  render () {
    const {
      theme,
      values,
      betThreshold,
      isRequesting,
      isTotalSignUpRequesting,
      classes,
      resetForm,
      handleEditing,
      handleCancel,
      isEditing,
      isAdmin = () => false,
      inProfile = () => false,
      handleToggleBetThreshold = () => false,
      t,
      errors,
    } = this.props

    const fields = [
      [
        {
          name: 'account',
          label: t('account'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
      ],
      [
        {
          name: 'parentAccount',
          label: t('parentAccount'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
      ],
      [
        {
          name: 'signUpAt',
          label: t('signUpAt'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
        {
          name: 'loginAt',
          label: t('loginAt'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
        {
          name: 'totalPlayers',
          label: t('totalPlayers'),
          fullWidth: true,
          InputLabelProps: { shrink: true },
          InputProps: { readOnly: true },
          loading: isTotalSignUpRequesting,
          Field: OutlinedTextField,
        },
        {
          name: 'commission',
          label: t('commission'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
        {
          Field: RadioTextField,
          name: 'currencySign',
          label: t('currencySign'),
          fullWidth: true,
          disabled: isRequesting || !isEditing(),
          options: [
            { label: t('show'), value: 'true' },
            { label: t('notShow'), value: 'false' },
          ],
        },
        {
          Field: RadioTextField,
          name: 'status',
          label: t('status'),
          fullWidth: true,
          disabled: isRequesting || !isEditing() || inProfile(),
          options: [
            { label: t('enable'), value: 'Enable' },
            { label: t('disable'), value: 'Disable' },
          ],
        },
        {
          Field: RadioTextField,
          name: 'isTestSS',
          label: t('testAgent'),
          fullWidth: true,
          disabled: true,
          options: [
            { label: t('true', { ns: 'common' }), value: 'true' },
            { label: t('false', { ns: 'common' }), value: 'false' },
          ],
        },
      ],
      [
        {
          name: 'ssToken',
          label: t('ssToken'),
          fullWidth: true,
          InputProps: {
            readOnly: true,
          },
          Field: OutlinedCopyTextField,
          onCopied: () =>
            this.props.onCopied(),
        },
      ],
      [
        {
          Field: RadioTextField,
          name: 'betThreshold.enabled',
          label: t('status'),
          fullWidth: true,
          disabled: isRequesting || !isEditing() || inProfile() || !isAdmin(),
          onChange: handleToggleBetThreshold,
          options: [
            { label: t('enable'), value: 'true' },
            { label: t('disable'), value: 'false' },
          ],
        },
      ],
      [
        {
          name: 'betThreshold.maximum',
          label: t('maximum'),
          fullWidth: true,
          InputProps: { readOnly: isRequesting || !isEditing() || !isAdmin() },
          Field: OutlinedTextField,
          error:
            !!(errors.betThreshold && errors.betThreshold.maximum),
          helperText:
            errors.betThreshold && errors.betThreshold.maximum
              ? errors.betThreshold.maximum
              : '',
        },
        {
          name: 'betThreshold.default',
          label: t('default'),
          fullWidth: true,
          InputProps: { readOnly: isRequesting || !isEditing() || !isAdmin() },
          Field: OutlinedTextField,
          error:
            !!(errors.betThreshold && errors.betThreshold.default),
          helperText:
            errors.betThreshold && errors.betThreshold.default
              ? errors.betThreshold.default
              : '',
        },
        {
          name: 'betThreshold.minimum',
          label: t('minimum'),
          fullWidth: true,
          InputProps: { readOnly: isRequesting || !isEditing() || !isAdmin() },
          Field: OutlinedTextField,
          error:
            !!(errors.betThreshold && errors.betThreshold.minimum),
          helperText:
            errors.betThreshold && errors.betThreshold.minimum
              ? errors.betThreshold.minimum
              : '',
        },
      ],
      [
        {
          name: 'stationAccount',
          label: t('stationAccount'),
          fullWidth: true,
          InputProps: { readOnly: true },
          Field: OutlinedTextField,
        },
      ],
    ]

    return (
      <Form>
        <h3 style={ theme.customer.H3Style }>{t('basicInfo')}</h3>
        {renderFields(fields[0], this.props)}
        {values.account !== values.parentAccount &&
          renderFields(fields[1], this.props)}
        {renderFields(fields[2], this.props)}
        {values.ssToken && renderFields(fields[3], this.props)}
        {this.props.stationAccount && renderFields(fields[6], this.props)}

        {/* Bet Threshold */}
        <h3 style={ theme.customer.H3Style }>{t('betThreshold')}</h3>
        {renderFields(fields[4], this.props)}
        {betThreshold.enabled === 'true' &&
          renderFields(fields[5], this.props)}

        <GridContainer>
          <GridItem fluid>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleEditing}
              disabled={isRequesting || isEditing()}
            >
              {t('edit', { ns: 'common' })}
            </Button>
          </GridItem>
          {isEditing() && (
            <>
              <GridItem fluid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isRequesting}
                >
                  {t('save', { ns: 'common' })}
                </Button>
              </GridItem>
              <GridItem fluid>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isRequesting}
                  onClick={() => {
                    resetForm()
                    handleCancel()
                  }}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>

                {isRequesting && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

              </GridItem>
            </>
          )}
        </GridContainer>
      </Form>
    )
  }
}

const agentDetailForm = formikEnhancer(
  withStyles(styles, { withTheme: true })(
    withTranslation('userProfile')(AgentDetailFormForAdmin),
  ),
)

export default agentDetailForm
