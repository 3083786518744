import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const promoList = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.promo.fetchPromoListSuccess:
      return action.payload.response
    case actionType.promo.fetchPromoListFailure:
      return []
    default:
      return state
  }
}

const promoPeriod = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.promo.fetchPromoPeriodSuccess:
      return action.payload.response
    case actionType.promo.fetchPromoPeriodFailure:
      return []
    default:
      return state
  }
}

export default combineReducers({
  promoList,
  promoPeriod,
})

export const getPromo = (state, filter) => {
  return state[filter]
}
