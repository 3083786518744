import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getSavedState } from './helpers/storage'
import commonPack from './locale/common'
import announcement from './locale/announcement'

// for all options read: https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next).init({
  lng: getSavedState('language') || 'cn', // language to use (overrides language detection)
  fallbackLng: getSavedState('language') || 'cn', // language to use if translations in user language are not available.
  debug: process.env.REACT_APP_DEBUG === 'true', // logs info level to console output. Helps finding issues with loading not working.
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  defaultNS: 'common', // default namespace
  resources: {
    // language: {namespace: {key1: value1, key2: value2} }
    en: {
      common: commonPack.en,
      announcement: announcement.en,
    },
    cn: {
      common: commonPack.cn,
      announcement: announcement.cn,
    },
  },
})

export default i18n
