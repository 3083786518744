import React from 'react'
import Grid from '@material-ui/core/Grid'

const GridItem = ({ children, sticky, fluid, form, doubleWidth = false, ...props }) => {
  if (sticky) {
    return (
      <Grid item {...props} style={{ position: sticky && 'sticky', top: sticky && 0, width: sticky && '100%', zIndex: 999 }}>
        {children}
      </Grid>
    )
  }
  if (fluid) {
    return (
      <Grid item {...props}>
        {children}
      </Grid>
    )
  }

  if (form) {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={4} {...props}>
        {children}
      </Grid>
    )
  }

  if (doubleWidth) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={8} xl={6} {...props}>
        {children}
      </Grid>
    )
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} {...props}>
      {children}
    </Grid>
  )
}

export default GridItem
