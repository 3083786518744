import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

const styles = theme => ({
  iconButton: {
    height: 24,
    width: 24,
    padding: 0,
  },
})

class MoreButton extends Component {
  state = { open: false };

  handleToggle = () => {
    this.setState(state => {
      if (state.open) {
        return { open: !state.open }
      } else {
        return { open: true }
      }
    })
  };

  handleClose = event => {
    if (this.anchorEls.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  };

  render () {
    const { items, classes } = this.props
    return (
      <>
        <IconButton
          buttonRef={node => {
            this.anchorEls = node
          }}
          onClick={this.handleToggle}
          className={classes.iconButton}
        >
          <MoreVertIcon />
        </IconButton>
        <Popper
          style={{ zIndex: 101 }}
          open={this.state.open || false}
          anchorEl={this.anchorEls}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleClose}>
              <MenuList>
                {items.map(
                  ({ to, onClick = () => {}, label, ...item }, index) => {
                    if (to) {
                      return (
                        <MenuItem
                          key={index}
                          component={Link}
                          to={to}
                          onClick={e => {
                            onClick(e)
                            this.handleClose(e)
                          }}
                          {...item}
                        >
                          {label}
                        </MenuItem>
                      )
                    } else {
                      return (
                        <MenuItem
                          key={index}
                          onClick={e => {
                            onClick(e)
                            this.handleClose(e)
                          }}
                          {...item}
                        >
                          {label}
                        </MenuItem>
                      )
                    }
                  },
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </>
    )
  }
}

export default withStyles(styles)(MoreButton)
