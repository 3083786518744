import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Link from '@material-ui/core/Link'
import cx from 'classnames'
import moment from 'moment'
import a from '../../actions'
import {
  getReportByFilter,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
} from '../../reducers'
import { formatDate } from '../../helpers'
import language from '../../locale/liveTwoSideBet'
import { getSavedState } from '../../helpers/storage'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import LiveTwoSideBetForm from '../../components/forms/LiveTwoSideBetForm'
import DataTable from '../../components/tables/DataTable'

const styles = theme => ({
  row: {
    '&:not(:first-child)': {
      borderTop: '2px solid #515151',
    },
  },
  subCell: {
    lineHeight: 2.5,
    minHeight: '33px',
    marginRight: '-24px',
    paddingRight: '24px',
    whiteSpace: 'nowrap',
    '&:not(:first-child)': {
      borderTop: '1px solid #515151',
    },
  },
  roundId: {
    marginLeft: '-12px',
    paddingLeft: '12px',
    cursor: 'pointer',
  },
})

class LiveTwoSideBet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n, featchTableInfo } = this.props

    i18n.addResourceBundle('en', 'liveTwoSideBet', language.en)
    i18n.addResourceBundle('cn', 'liveTwoSideBet', language.cn)

    featchTableInfo()
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      language,
      timezone,
      featchTableInfo,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲名稱」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
      featchTableInfo()
    }
  }

  setColumns = () => {
    const { t, classes } = this.props

    this.setState({
      columns: [
        {
          name: t('roundNumber'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('tableId'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('roundid'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => value.map(
              item => (
                <Link
                  key={item.roundid}
                  classes={{ root: cx(classes.roundId, classes.subCell, item.gamehall && classes.pointer) }}
                  onClick={item.gamehall ? () => this.handleGameLink({
                    host: '0', // 預設中國線路
                    ...item,
                  }) : undefined}
                  block
                  underline={item.gamehall ? 'hover' : 'none'}
                  color={item.gamehall ? 'primary' : 'textPrimary'}
                >
                  {item.roundid}
                </Link>
              ),
            ),
          },
        },
        {
          name: t('datetime'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => (
                <div key={rowData[2][i].roundid} className={classes.subCell}>
                  {moment(v * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
                </div>
              ),
            ),
          },
        },
        {
          name: t('agent'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => <div key={rowData[2][i].roundid} className={classes.subCell}>{v}</div>,
            ),
          },
        },
        {
          name: t('playerAcc'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => <div key={rowData[2][i].roundid} className={classes.subCell}>{v}</div>,
            ),
          },
        },
        {
          name: t('bets'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => <div key={rowData[2][i].roundid} className={classes.subCell}>{v}</div>,
            ),
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => <div key={rowData[2][i].roundid} className={classes.subCell}>{v}</div>,
            ),
          },
        },
        {
          name: t('bettype'),
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { maxWidth: 'none' } }),
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => (
                <div key={rowData[2][i].roundid} className={classes.subCell}>
                  {v.map((type, i) => `${i !== 0 ? ', ' : ''}${t(type)}`)}
                </div>
              ),
            ),
          },
        },
        {
          name: 'IP',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, { rowData }) => value.map(
              (v, i) => <div key={rowData[2][i].roundid} className={classes.subCell}>{v}</div>,
            ),
          },
        },
      ],
    })
  }

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  }

  setFormatReports = (data = []) => {
    const formatReports = data.map(({ _id, result }) => [
      _id.roundnumber,
      _id.tableid,
      ...Object.values(result.reduce((list, item) => {
        Object.keys(list).forEach(key => {
          if (key === 'roundid') list[key].push({ roundid: item[key], gamehall: item.gamehall })
          else list[key].push(item[key])
        })
        return list
      }, {
        roundid: [],
        date: [],
        parent_account: [],
        user_account: [],
        bets: [],
        currency: [],
        bettype: [],
        ip: [],
      })),
    ])
    this.setState({ formatReports })
  }

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'liveTwoSideBet',
      fileName: t('liveTwoSideBet', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleGameLink = values => {
    return this.props.fetchGameDetailLink(values, { gameType: 'slotAndTable' })
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  }

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state
    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  }

  handleChangePage = page => {
    const { payload } = this.state
    return this.onSubmit({ ...payload, nowpage: page + 1 })
  }

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state
    this.setState({ rowsPerPage })
    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  }

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'liveTwoSideBet' })
  }

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })
    return this.fetchReport(payload)
  }

  render () {
    const {
      canDownload,
      isFetching,
      timezone,
      routePage,
      classes,
    } = this.props
    const {
      formatReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
    } = this.state

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <LiveTwoSideBetForm
              routePage={routePage}
              agentAcc=''
              playerAcc=''
              tableid={[]}
              startDate={new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))}
              endDate={new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))}
              ip=''
              tabletype='baccarat'
              pagesize={rowsPerPage}
              nowpage={1}
              isFetching={isFetching}
              onSubmit={this.handleSubmit}
            />
          </Paper>
        </GridItemFull>
        {isSearched &&
          <GridItemFull>
            <DataTable
              data={formatReports}
              columns={columns}
              pagination={reportPagination}
              payload={payload}
              canDownload={canDownload}
              downloadReport={this.handleDownload}
              options={{
                rowsPerPage,
                onChangePage: this.handleChangePage,
                onChangeRowsPerPage: this.handleChangeRowsPerPage,
                setRowProps: () => ({
                  className: classes.row,
                }),
              }}
            />
          </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'liveTwoSideBet')

  const timezone = getSavedState('timezone') || getTimeZone(state) || 0
  const isFetching = getIsFetchingByFilter(state, 'liveTwoSideBet')

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  return {
    canDownload,
    games: state.games,
    language,
    reports,
    timezone,
    isFetching,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchList,
    featchTableInfo: a.live.fetchTableInfo,
    fetchGameDetailLink: a.game.fetchGameDetailLink,
  },
)(withTranslation('liveTwoSideBet')(withStyles(styles)(LiveTwoSideBet)))
