import React from 'react'
import Button from '@material-ui/core/Button'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { withStyles } from '@material-ui/core/styles'
import { Link, withRouter } from 'react-router-dom'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    border: '1px solid rgba(0,0,0,0.44)',
    borderRadius: '16px',
    padding: '4px 12px 4px 15px',
    color: 'rgba(0,0,0,0.54)',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
    fontSize: 12,
  },
})

class CustomToolbar extends React.Component {
  render () {
    const { classes, url, label, location } = this.props

    return (
      <>
        <Button
          component={Link}
          to={{
            pathname: url,
            state: { show: 0 },
            search: location.search,
          }}
          className={classes.button}
          size="small"
        >
          {label}
          <ArrowForward className={classes.rightIcon} />
        </Button>
      </>
    )
  }
}

export default withRouter(
  withStyles(styles, { name: 'CustomToolbar' })(CustomToolbar),
)
