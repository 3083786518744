import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { renderFieldsWithGridItem } from '../../components/forms/helper'
import GridContainer from '../../components/grids/GridContainer'
import SelectTextField from '../../components/text-fields/SelectTextField'
import OutlinedTextField from '../../components/text-fields/OutlinedTextField'
import DateRangePickerTextField from '../../components/text-fields/DateRangePickerTextField'
import { formatDate } from '../../helpers'
import useDateTime from '../../hooks/useDateTime'
import { ToggleButton, ToggleButtonGroup } from '../../components/ToggleButtonGroup'

const styles = theme => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
  },
  submit: {
    marginLeft: '48px',
  },
  capitalize: {
    textTransform: 'none',
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    agentAcc: Yup.string().required('required'),
    playerAcc: Yup.string().required('required'),
    limit: Yup.string(),
    gt: Yup.string(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const payload = {
      gametype: values.gametype,
      agentAcc: values.agentAcc,
      playerAcc: values.playerAcc,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      limit: values.limit,
      gt: values.gt,
    }
    try {
      await props.onSubmit(payload, values.agentAcc)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'CustomForm',
})

const CustomForm = props => {
  const {
    timezone,
    t,
    classes,
    dateRange,
    isSubmitting,
    tableTypeOptions,
    roundAnalysisOption,
    appearCountOption,
  } = props

  const handleTimeChange = (startDate, endDate) => (e) => {
    props.setFieldValue('startDate', startDate)
    props.setFieldValue('endDate', endDate)
  }

  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'gametype',
        label: t('gametype'),
        fullWidth: true,
        // 目前只有一個選項，暫時寫死
        options: [{ label: t('baccarat'), value: '1' }] || tableTypeOptions,
        isClearable: true,
      },
      {
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        Field: SelectTextField,
        name: 'gt',
        label: t('gt'),
        fullWidth: true,
        options: appearCountOption,
        isClearable: true,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'limit',
        label: t('roundAnalysis'),
        fullWidth: true,
        options: roundAnalysisOption,
        isClearable: true,
      },
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
        specialrange: !!props.specialRange,
      },
    ],
  ]

  const {
    dateList,
    startOfToday,
    endOfToday,
  } = useDateTime({ timezone })

  // 欄位依照時區改變
  useEffect(() => {
    props.setFieldValue('startDate', startOfToday())
    props.setFieldValue('endDate', endOfToday())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <div>
        <div className={classes.buttonGroup}>
          <ToggleButtonGroup>
            {dateList.map((date) =>
              <ToggleButton
                key={date.day}
                value={date.day}
                onClick={handleTimeChange(date.startDate, date.endDate)}
              >
                <span className={classes.capitalize}>{t(date.day)}</span>
              </ToggleButton>)}
          </ToggleButtonGroup>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            {t('search', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('riskControlMemberAnalysisReport')(withStyles(styles)(CustomForm)),
)
