import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from './form.jsx'
import { api } from '../../services'
import Paper from '../../components/Paper'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import { useDispatch, useSelector } from 'react-redux'
import { roundAnalysisOption, appearCountOption } from '../../consts'
import language from '../../locale/liveRiskAssessment'
import useStyles from './index.style'
import SummaryTable from './SummaryTable/index.jsx'
import RoundTable from './RoundTable/index.jsx'
import { getSavedState } from '../../helpers/storage.js'
import { getTimeZone } from '../../reducers/index.js'
import { Typography } from '@material-ui/core'
import actions from '../../actions'
import moment from 'moment'

const LiveRiskAssessMentReportForAdmin = () => {
  const classes = useStyles()
  const state = useSelector((state) => state)
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation('riskControlMemberAnalysisReport')
  const gametypeOption = useSelector((state) => state.live.tableTypeOption)
  const [roundList, setRoundList] = useState([])
  const [summaryList, setSummaryList] = useState([])
  const [currentAgent, setCurrentAgent] = useState('')

  const handleSubmit = async (data, agent) => {
    try {
      const response = await api.get(api.BO, '/live/risk/assessment', {
        params: {
          ...data,
          timezone,
        },
      })
      setCurrentAgent(agent)
      setRoundList(response.data.data.list)
      setSummaryList(response.data.data.summary)
    } catch (err) {
      setCurrentAgent('')
      setRoundList([])
      setSummaryList([])
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(actions.live.fetchTableInfo())
  }, [dispatch])

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'riskControlMemberAnalysisReport', language.en)
    i18n.addResourceBundle('cn', 'riskControlMemberAnalysisReport', language.cn)
  }, [i18n])

  const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
  const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

  return (
    <Container>
      {/* 搜索欄 */}
      <GridItemFull sticky>
        <Paper>
          <Form
            timezone={timezone}
            t={t}
            agentAcc=""
            playerAcc=""
            startDate={startDate}
            endDate={endDate}
            gametype="1"
            limit="10"
            gt="1"
            gametypeOption={gametypeOption}
            roundAnalysisOption={roundAnalysisOption}
            appearCountOption={appearCountOption}
            onSubmit={handleSubmit}
            dateRange="2month"
          />
        </Paper>
        <Paper>
          <div className={classes.wrapper}>
            {/* 玩家出現次數表單 */}
            {summaryList.length > 0 && (
              <div className={classes.countTable}>
                <SummaryTable
                  t={t}
                  currentAgent={currentAgent}
                  summaryList={summaryList}
                />
              </div>
            )}
            {/* 各局詳細表單 */}
            <div>
              {roundList.length > 0 &&
              <>
                <Typography variant="h5">{t('eachRoundDetail')}</Typography>
                {roundList.map((item) => {
                  const { roundnumber, time, result } = item
                  return (
                    <React.Fragment key={roundnumber}>
                      <RoundTable
                        timezone={timezone}
                        t={t}
                        currentAgent={currentAgent}
                        roundnumber={roundnumber}
                        time={time}
                        result={result}
                      />
                    </React.Fragment>
                  )
                })}
              </>
              }
            </div>
          </div>
        </Paper>
      </GridItemFull>
    </Container>
  )
}

LiveRiskAssessMentReportForAdmin.propTypes = {}

export default LiveRiskAssessMentReportForAdmin
