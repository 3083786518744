import React from 'react'
import { Field } from 'formik'
import RadioField from '../fields/RadioField'
import MuiTextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
})

function TextComponent ({
  variant = 'outlined',
  margin = 'normal',
  InputLabelProps = { shrink: true },
  classes,
  field,
  form,
  InputProps,
  inputProps,
  options,
  disabled,
  ...props
}) {
  return (
    <MuiTextField
      variant={variant}
      margin={margin}
      InputLabelProps={InputLabelProps}
      InputProps={{
        className: classes.root,
        inputComponent: RadioField,
        ...InputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ field, form, options, disabled, ...inputProps }}
      {...props}
    />
  )
}

function RadioTextField (props) {
  return <Field {...props} component={TextComponent} />
}

export default withStyles(styles)(RadioTextField)
