import { combineReducers } from 'redux'
import Create, * as fromReport from './createReport'

export default combineReducers({
  // 玩家列表
  playersList: Create('playersList'),
  // 交易查詢
  playersTransactionHistory: Create('playersTransactionHistory'),
  playersTransactionHistorySummary: Create('playersTransactionHistorySummary'),
  // 注單查詢
  playersOrder: Create('playersOrder'),
  playersOrderSummary: Create('playersOrderSummary'),
  // 免费券查詢
  freeTicketSearch: Create('freeTicketSearch'),
  freeTicketSearchSummary: Create('freeTicketSearchSummary'),
  freeTicketAnalysisReport: Create('freeTicketAnalysisReport'),
  freeTicketAnalysisReportSummary: Create('freeTicketAnalysisReportSummary'),
  agentPlayerFreeTicketReport: Create('agentPlayerFreeTicketReport'),
  agentPlayerFreeTicketReportSummary: Create('agentPlayerFreeTicketReportSummary'),
  // 派彩查詢
  playersPayoff: Create('playersPayoff'),

  // 代理列表
  agentList: Create('agentList'),

  // 代理報表
  agentReport: Create('agentReport'),
  agentReportSummary: Create('agentReportSummary'),
  // 代理財務報表
  agentFinanceReport: Create('agentFinanceReport'),
  agentFinanceReportSummary: Create('agentFinanceReportSummary'),
  // 代理遊戲分析報表
  agentGameAnalysisReport: Create('agentGameAnalysisReport'),
  agentGameAnalysisReportSummary: Create('agentGameAnalysisReportSummary'),
  // 代理玩家遊戲報表
  agentPlayerGameReport: Create('agentPlayerGameReport'),
  agentPlayerGameReportSummary: Create('agentPlayerGameReportSummary'),
  // 代理玩家報表
  agentPlayerReport: Create('agentPlayerReport'),
  agentPlayerReportSummary: Create('agentPlayerReportSummary'),
  // 不分代理遊戲列表
  agentlessGameReport: Create('agentlessGameReport'),
  agentlessGameReportSummary: Create('agentlessGameReportSummary'),

  // ## 體彩
  // 体彩代理财务报表
  lottoAgentFinanceReport: Create('lottoAgentFinanceReport'),
  lottoAgentFinanceReportSummary: Create('lottoAgentFinanceReportSummary'),
  // 體彩即時注單
  lottoRealTimeOrder: Create('lottoRealTimeOrder'),
  lottoRealTimeOrderSummary: Create('lottoRealTimeOrderSummary'),
  // 體彩開彩注單
  lottoAwardedOrder: Create('lottoAwardedOrder'),
  lottoAwardedOrderSummary: Create('lottoAwardedOrderSummary'),
  // 體彩代理報表
  lottoAgentReport: Create('lottoAgentReport'),
  lottoAgentReportSummary: Create('lottoAgentReportSummary'),
  // 體彩代理玩家報表
  lottoAgentPlayerReport: Create('lottoAgentPlayerReport'),
  lottoAgentPlayerReportSummary: Create('lottoAgentPlayerReportSummary'),
  // 體彩代理玩家遊戲報表
  lottoAgentPlayerGameReport: Create('lottoAgentPlayerGameReport'),
  lottoAgentPlayerGameReportSummary: Create('lottoAgentPlayerGameReportSummary'),
  // 體彩代理遊戲分析報表
  lottoAgentGameAnalysisReport: Create('lottoAgentGameAnalysisReport'),
  lottoAgentGameAnalysisReportSummary: Create('lottoAgentGameAnalysisReportSummary'),
  // 體彩不分代理遊戲列表
  lottoAgentlessGameReport: Create('lottoAgentlessGameReport'),
  lottoAgentlessGameReportSummary: Create('lottoAgentlessGameReportSummary'),

  lottoPeriodOrder: Create('lottoPeriodOrder'),

  // 真人視訊-代理遊戲分析報表
  liveAgentGameAnalysisReport: Create('liveAgentGameAnalysisReport'),
  liveAgentGameAnalysisReportSummary: Create('liveAgentGameAnalysisReportSummary'),
  // 真人視訊-代理玩家報表
  liveAgentPlayerReport: Create('liveAgentPlayerReport'),
  liveAgentPlayerReportSummary: Create('liveAgentPlayerReportSummary'),
  // 真人視訊-視訊注單查詢
  livePlayersOrder: Create('livePlayersOrder'),
  livePlayersOrderSummary: Create('livePlayersOrderSummary'),
  // 真人視訊-打賞查詢
  livePlayerDonate: Create('livePlayerDonate'),
  livePlayerDonateSummary: Create('livePlayerDonateSummary'),
  // 真人視訊-對壓查詢
  liveTwoSideBet: Create('liveTwoSideBet'),
  // 真人視訊-視訊代理報表
  liveAgentReport: Create('liveAgentReport'),
  liveAgentReportSummary: Create('liveAgentReportSummary'),
  // // 真人視訊-視訊代理財務報表
  liveAgentFinanceReport: Create('liveAgentFinanceReport'),
  liveAgentFinanceReportSummary: Create('liveAgentFinanceReportSummary'),
  // 真人視訊-視訊不分代理遊戲數據列表
  liveAgentlessGameReport: Create('liveAgentlessGameReport'),
  liveAgentlessGameReportSummary: Create('liveAgentlessGameReportSummary'),
  // chicken
  liveSSCockFightReport: Create('liveSSCockFightReport'),
  liveSSCockFightReportSummary: Create('liveSSCockFightReportSummary'),
  // isFetching 可以放在這裡，還不曉得原先設計 filter 是否有其他考量，為了一致性先依原本架構設計 reducer
})

export const getReportByFilter = (state, filter) =>
  fromReport.getReport(state[filter])

export const getIsFetchingByFilter = (state, filter) =>
  fromReport.getIsFetching(state[filter])
