import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DataTable from '../../../components/tables/DataTable'
import Button from '../../../components/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DialogDelete from '../../../components/DialogDelete'
import DialogForm from './form'

const getColumns = (
  t,
  setDeleteData,
  setOpenDelete,
  setFormEditMode,
) => {
  return [
    {
      name: t('id'),
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'threshold',
      label: t('betThreshold'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ whiteSpace: 'nowrap' }}>{value}</div>
        },
      },
    },
    {
      name: 'default',
      label: t('default'),
      options: {
        sort: false,
        download: true,
      },
    },
    {
      name: 'action',
      label: t('action'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              <IconButton
                onClick={() => {
                  setFormEditMode(true, value.fields)
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDeleteData(value)
                  setOpenDelete(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )
        },
      },
    },
  ]
}

function BetThresholdList (props) {
  const {
    data,
    allGamesOptions,
    agent,
    isSubmitting,
    onSubmit,
    onDelete,
  } = props
  const { t } = useTranslation('userProfile')

  const [openDelete, setOpenDelete] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [formData, setFormData] = useState({ open: false, data: {}, mode: '', title: '' })
  const [deleteData, setDeleteData] = useState({ id: '', userID: '' })
  const [rowsPerPage, setrowsPerPage] = useState(30)

  useEffect(() => {
    if (!isSubmitting) {
      if (formData.mode === 'create') {
        setFormCreateMode(false)
      } else {
        setFormEditMode(false)
      }
    }
    // eslint-disable-next-line
  }, [isSubmitting]);

  const setFormCreateMode = (open) => {
    setFormData({ data: {}, mode: 'create', title: 'createBetThreshold' })
    setOpenForm(open)
  }

  const setFormEditMode = (open, data = {}) => {
    setFormData({ data, mode: 'edit', title: 'updateBetThresholdTitle' })
    setOpenForm(open)
  }

  return (
    <>
      <DataTable
        title={t('betThreshold')}
        columns={
          getColumns(
            t,
            setDeleteData,
            setOpenDelete,
            setFormEditMode,
          )
        }
        data={data}
        options={{
          elevation: 0,
          rowsPerPageOptions: [30, 50, 100],
          rowsPerPage: rowsPerPage,
          onChangeRowsPerPage: rowsPerPage => {
            setrowsPerPage(rowsPerPage)
          },
          customToolbar: () => (
            <Button
              variant='contained'
              color='primary'
              actionType='positive'
              onClick={() => {
                setFormCreateMode(true)
              }}
            >
              {t('common:add')}
            </Button>
          ),
        }}
      />
      {
        openForm &&
        <DialogForm
          id={formData.data.id || ''}
          name={formData.data.name || ''}
          minimum={formData.data.minimum}
          maximum={formData.data.maximum}
          default={formData.data.default}
          bettype_maximum={formData.data.bettype_maximum}
          round_maximum={formData.data.round_maximum}
          roombet={formData.data.roombet ? formData.data.roombet.join() : ''}
          gameCodes={formData.data.gameCodes || []}
          gameTypes={formData.data.gameTypes || []}
          // func
          isSubmitting={() => isSubmitting}
          getTitle={() => formData.title}
          getOpen={() => openForm}
          getAgent={() => agent}
          getMode={() => formData.mode}
          allGamesOptions={() => allGamesOptions}
          onClose={() => setOpenForm(false)}
          onSubmit={onSubmit}
        />
      }

      <DialogDelete
        open={openDelete}
        onSubmit={(v) => {
          onDelete(v)
          setOpenDelete(false)
        }}
        onClose={() => setOpenDelete(false)}
        initValues={deleteData}
      />
    </>
  )
}

export default BetThresholdList
