import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment'

import a from '../../actions'
import {
  getReportByFilter,
  getGamesOnlyLottoAndSport,
  getTimeZone,
  getLanguage,
  getBrandInfo,
} from '../../reducers'
import { qsParse, formatDate, formatMoney } from '../../helpers'
import { getSavedState } from '../../helpers/storage'
import language from '../../locale/playerOrder'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import ReportSummaryCard from '../../components/ReportSummaryCard'
import GridItemFull from '../../components/grids/GridItemFull'
import LottoAwardedOrderForAdminForm from '../../components/forms/LottoAwardedOrderForAdminForm'
import DataTable from '../../components/tables/DataTable'
import MoreButton from '../../components/MoreButton'

const styles = theme => ({
  true: {
    color: green[500],
    fontWeight: 900,
  },
  false: {
    color: grey[500],
    fontWeight: 900,
  },
})

class PlayerOrderForAdmin extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      formatSummaryReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'playerOrder', language.en)
    i18n.addResourceBundle('cn', 'playerOrder', language.cn)
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲類型、遊戲名稱、遊戲項目」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('roundid'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('bettime'),
          options: {
            filter: false,
            sort: false,
            // 把 this.props 拿掉會有 scope 問題喔
            customBodyRender: value => moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('finaltime'),
          options: {
            filter: false,
            sort: false,
            // 把 this.props 拿掉會有 scope 問題喔
            customBodyRender: value => moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('gamehall'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('gametype'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => t(value),
          },
        },
        {
          name: t('gamename'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('genre'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('bet'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('validbets'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('win'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('more'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => {
              return (
                <MoreButton
                  items={[
                    {
                      label: t('gameDetail'),
                      onClick: () => {
                        this.handleGameLink({
                          host: '0', // 預設中國線路
                          ...value,
                        })
                      },
                    },
                  ]}
                />
              )
            },
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    this.setState({
      formatReports: data.map(item => [
        item.roundId,
        item.betTime,
        item.finalTime,

        item.gameHall,
        item.gameType,
        item.gameName,
        item.genre,

        item.currency,
        item.bets,
        item.validbets,
        item.wins,
        { roundid: item.roundId, gamehall: item.gameHall },
      ]),
    })
  };

  setFormatSummaryReports = data => {
    this.setState({
      formatSummaryReports: data
        ? [
          {
            name: 'TOTAL'.toUpperCase(),
            data: Object.keys(data).map(key => ({
              key,
              value: data[key],
            })),
          },
        ]
        : [],
    })
  };

  handleGameLink = async values => {
    return this.props.fetchGameDetailLink(values, { gameType: 'sportAndLotto' })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'lottoAwardedOrder',
      fileName: t('lottoAwardedOrder', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      gamehall: values.gamehall === 'all' ? '' : values.gamehall,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'lottoAwardedOrder' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      filterAgentAccount,
      filterPlayerAccount,
      filterGameHall,
      filterGamesOptions,
      canDownload,
      timezone,
      brandInfo,
      dateRange,
    } = this.props
    const {
      formatReports,
      formatSummaryReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
    } = this.state

    const startDate = new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))
    const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <LottoAwardedOrderForAdminForm
              agentAcc={filterAgentAccount}
              playerAcc={filterPlayerAccount}
              gamehall={filterGameHall}
              gametype={'all'}
              gamecode=""
              startDate={startDate}
              endDate={endDate}
              pagesize={rowsPerPage}
              nowpage={1}
              sortKey="bettime"
              sortValue="desc"
              filterGamesOptions={() => filterGamesOptions}
              onSubmit={this.handleSubmit}
              brand={brandInfo.brand}
              dateRange={dateRange}
            />
          </Paper>
        </GridItemFull>

        { isSearched &&
        <GridItemFull>
          <ReportSummaryCard data={formatSummaryReports} />
        </GridItemFull>
        }

        { isSearched &&
        <GridItemFull>
          <DataTable
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            payload={payload}
            canDownload={canDownload}
            downloadReport={this.handleDownload}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const language = getLanguage(state)
  const allGamesOptions = getGamesOnlyLottoAndSport(state, language)
  const reports = getReportByFilter(state, 'lottoAwardedOrder')
  const summaryReports = getReportByFilter(state, 'lottoAwardedOrderSummary')

  const filterAgentAccount = search.agent ? search.agent : ''
  const filterPlayerAccount = search.player ? search.player : ''
  const filterGameHall = search.gameHall ? search.gameHall : 'all'

  const filterGamesOptions = getGamesOnlyLottoAndSport(state, language)

  const timezone = getSavedState('timezone') || getTimeZone(state)

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  // brand
  const brandInfo = getBrandInfo(state)

  // order
  const dateRange = state.order?.orderlottoorder ? `${state.order.orderlottoorder}day` : '1day'

  return {
    canDownload,
    games: state.games,
    language,
    filterAgentAccount,
    filterPlayerAccount,
    filterGameHall,
    allGamesOptions,
    filterGamesOptions,
    search,
    reports,
    timezone,
    summaryReports,
    brandInfo,
    dateRange,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchGameDetailLink: a.game.fetchGameDetailLink,
    downloadReport: a.report.download,
    fetchReport: a.report.fetchReport,
  },
)(withTranslation('playerOrder')(withStyles(styles)(PlayerOrderForAdmin)))
