import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

// 管理 request 週期
const isLogout = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.auth.logoutRequest:
      return true
    case actionType.auth.logoutSuccess:
    case actionType.auth.logoutFailure:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isLogout,
})

export const getIsLogout = state => state.isLogout
