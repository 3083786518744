const actionOptions = [
  {
    label: 'all',
    value: '',
  },

  // - - - - - - - - - - - - - - 出入款
  {
    label: 'deposit', // 存款
    value: 'cashin',
  },
  {
    label: 'withdraw', // 提款
    value: 'cashout',
  },

  // - - - - - - - - - - - - - - 老虎機
  {
    label: 'bet', // 投注
    value: 'bet',
  },
  {
    label: 'win', // 贏分
    value: 'win',
  },
  {
    label: 'refund', // 退款
    value: 'refund',
  },

  // - - - - - - - - - - - - - - 漁機
  {
    label: 'rollin', // 還款
    value: 'rollin',
  },
  {
    label: 'rollout', // 借款
    value: 'rollout',
  },
  {
    label: 'takeall', // 借全部
    value: 'takeall',
  },

  // - - - - - - - - - - - - - - 補扣款
  {
    label: 'credit', // 補款
    value: 'credit',
  },
  {
    label: 'debit', // 扣款
    value: 'debit',
  },

  // - - - - - - - - - - - - - - 派彩
  {
    label: 'payoff', // 派彩
    value: 'payoff',
  },

  // - - - - - - - - - - - - - - 桌機
  {
    label: 'bonus', // 派彩
    value: 'bonus',
  },
]

export default actionOptions
