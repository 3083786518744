import React, { useRef, useState, useEffect } from 'react'
import Select, { createFilter } from 'react-select'
import { withTranslation } from 'react-i18next'
import { DARK_MODE } from '../../../themes/bo'

const SelectField = ({
  position,
  options = [],
  field,
  t,
  selectRef,
  enabledAllOption,
  onChange = () => { },
  ...props
}) => {
  const [insideOptions, setInsideOptions] = useState([])
  const [insideValue, setInsideValue] = useState([])
  const ref = useRef(null)
  const tempMenuOpen = useRef(false)

  useEffect(() => {
    if (enabledAllOption) {
      const newOptions = [...options]
      newOptions.unshift({ label: t('common:all'), value: 'all' })
      setInsideOptions(newOptions)
      setInsideValue([])
      onChange([])
      return
    }
    setInsideOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledAllOption, options])

  const setTempMenuOpen = () => {
    // 因使用 closeMenuOnScroll 判斷 scroll 時關閉 menu
    // 在已選擇大量 option 時開啟 menu 會瞬間觸發 scroll
    // 導致 menu 又被關掉, 因此多個參數判斷
    tempMenuOpen.current = true
    setTimeout(() => {
      tempMenuOpen.current = false
    }, 0)
  }

  return (
    <Select
      menuPlacement={position}
      ref={selectRef || ref}
      styles={{
        menu: base => ({
          ...base,
          zIndex: 200,
        }),
        menuPortal: base => ({
          ...base,
          zIndex: 1300,
        }),
        input: () => ({
          color: props.textFieldProps.theme.name === DARK_MODE ? '#fff' : '',
        }),
        // -- dark theme
        singleValue: () => ({
          color: props.textFieldProps.theme.name === DARK_MODE ? '#fff' : '',
        }),
        // 多選的樣式設定
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: props.textFieldProps.theme.palette.primary.dark,
            color: '#fff',
          }
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          backgroundColor: props.textFieldProps.theme.palette.primary.dark,
          color: '#fff',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          ':hover': {
            backgroundColor: props.textFieldProps.theme.palette.primary.main,
            color: '#fff',
          },
        }),
        groupHeading: (styles) => ({
          ...styles,
          fontSize: '16px',
          color: '#0c0c0d',
          fontWeight: '600',
          padding: '6px 12px',
          margin: '0',
        }),
        option: (styles, { options }) => {
          return {
            ...styles,
            // options 內有 options 就是 group
            paddingLeft: options[0].options ? '20px' : null,
          }
        },
      }}
      options={insideOptions}
      // 啟用所有選項就使用可控 value
      value={enabledAllOption && insideValue}
      name={field.name}
      filterOption={createFilter({ ignoreAccents: false })}
      onChange={(option, item) => {
        if (enabledAllOption) {
          if (item.option?.value === 'all') {
            setInsideValue(options)
            onChange(options, item)
          } else {
            setInsideValue(option)
            onChange(option, item)
          }
        } else {
          onChange(option, item)
        }
        // onChange 有時也會觸發 scroll
        setTempMenuOpen()
      }}
      onBlur={field.onBlur}
      placeholder={t('please_enter')}
      // closeMenuOnScroll={(e) => {
      //   const realRef = selectRef || ref
      //   if (e.target && e.target.classList && realRef.current && realRef.current.select.menuListRef && !tempMenuOpen.current) {
      //     // 觸發 scroll 的元素 class 不等於自身 select menu 的 class 就關閉
      //     return !(e.target.classList.contains(realRef.current.select.menuListRef.className))
      //   }
      // }}
      onMenuOpen={() => {
        setTempMenuOpen()
      }}
      noOptionsMessage={() => t('noOptions')}
      // menuIsOpen
      {...props}
    />
  )
}

export default withTranslation()(SelectField)
