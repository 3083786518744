import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    background: '#333',
    minWidth: '500px',
    color: '#fff',
  },
  row: {
    border: '1px grey solid',
    padding: '8px',
  },
  selfAgent: {
    color: 'orangered',
  },
}), {
  name: 'LiveRiskAssessMentReport',
})

export default useStyles
