import React from 'react'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
// import { FixedSizeList as List } from 'react-window';

import SelectField from '../fields/SelectField'

const styles = theme => ({
  input: {
    display: 'flex',
    padding: theme.spacing.unit * 0.8,
  },
})

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <TextField
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

// class MenuList extends Component {
//   render() {
//     const height = 30;
//     const { options, children, maxHeight, getValue } = this.props;
//     const [value] = getValue();
//     const initialOffset = options.indexOf(value) * height;

//     return (
//       <List
//         height={maxHeight}
//         itemCount={children.length}
//         itemSize={height}
//         initialScrollOffset={initialOffset}
//       >
//         {({ index, style }) => <div style={style}>{children[index]}</div>}
//       </List>
//     );
//   }
// }

function SelectTextField ({
  helperText,
  fullWidth,
  label,
  textFieldProps,
  error,
  theme,
  ...props
}) {
  return (
    <Field
      {...props}
      textFieldProps={{
        label,
        variant: 'outlined',
        margin: 'normal',
        InputLabelProps: {
          shrink: true,
        },
        fullWidth,
        helperText,
        error,
        theme,
        ...textFieldProps,
      }}
      components={{ Control }}
      component={SelectField}
    />
  )
}

export default withStyles(styles, { withTheme: true })(SelectTextField)
