import produce from 'immer'
import actionType from '../../actions/actionType'

const defaultState = {
  cn: [],
  en: [],
}

export const createGame = (lang, list) => {
  return list
    .map(item => {
      if (item.nameset[lang] === undefined || item.nameset[lang].trim() === '') {
        item.nameset[lang] = item.gamename
      }

      return {
        label: `${item.nameset[lang]} (${item.gamecode})`,
        value: item.gamecode,
        type: item.gametype,
        disabled: false,
      }
    })
}

export default produce((state, action = {}) => {
  switch (action.type) {
    case actionType.liveGame.fetchLiveGameListSuccess:
      state.en = createGame('en', action.payload.response)
      state.cn = createGame('zh-cn', action.payload.response)
      break
    case actionType.liveGame.fetchLiveGameListFailure:
      return defaultState
    default:
      break
  }
}, defaultState)

// 遊戲列表（不包含體彩）
export const getLiveGamesWithoutLottoAndSport = (state, language) => {
  return state[language].filter(game => {
    const filterType = ['sport', 'lotto']
    const inType = filterType.map(value => game.type === value)

    return inType.indexOf(true) === -1
  })
}

// 遊戲列表（只有體彩）
export const getLiveGamesOnlyLottoAndSport = (state, language) => {
  return state[language].filter(game => {
    const filterType = ['sport', 'lotto']
    const inType = filterType.map(value => game.type === value)

    return inType.indexOf(true) >= 0
  })
}
