import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import GridContainer from '../grids/GridContainer'

const styles = theme => ({
  root: {
    minWidth: 232,
    padding: theme.spacing.unit * 3,
    margin: '0 auto',
  },
})

const Container = ({ classes, children }) => (
  <main className={classes.root}>
    <GridContainer>{children}</GridContainer>
  </main>
)

export default withStyles(styles)(Container)
