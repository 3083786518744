import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: { 'min-width': 0, color: '#fff' },
  contentRoot: {
    padding: '0 24px',
  },
  actionsRoot: {
    margin: 0,
    padding: '12px 24px 24px',
  },
}), {
  name: 'AgentBetThresholdForAdmin',
})

export default useStyles
