import React, { useRef } from 'react'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import { DARK_MODE } from '../../../themes/bo'

const SelectField = ({
  options = [],
  field,
  form,
  t,
  onChange = () => { },
  ...props
}) => {
  const value = options
    ? options.find(option => option.value === field.value)
    : ''
  const ref = useRef(null)
  return (
    <Select
      ref={ref}
      styles={{
        menu: base => ({
          ...base,
          zIndex: 200,
        }),
        menuPortal: base => ({
          ...base,
          zIndex: 120,
        }),
        input: () => ({
          color: props.textFieldProps.theme.name === DARK_MODE ? '#fff' : '',
        }),
        // -- dark theme
        singleValue: () => ({
          color: props.textFieldProps.theme.name === DARK_MODE ? '#fff' : '',
        }),
      }}
      options={options}
      name={field.name}
      value={value || ''}
      onChange={option => {
        form.setFieldValue(field.name, option ? option.value : '')
        onChange(option)
      }}
      onBlur={field.onBlur}
      placeholder={t('please_enter')}
      // closeMenuOnScroll={(e) => {
      //   if (e.target && e.target.classList && ref.current && ref.current.select.menuListRef) {
      //     // 觸發 scroll 的元素 class 不等於自身 select menu 的 class 就關閉
      //     return !(e.target.classList.contains(ref.current.select.menuListRef.className))
      //   }
      // }}
      noOptionsMessage={() => t('noOptions')}
      {...props}
    />
  )
}

export default withTranslation()(SelectField)
