import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { compose } from 'redux'
import { Form, withFormik } from 'formik'

import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import { renderFields } from '../../components/forms/helper'

import MultiSelectTextField from '../../components/text-fields/MultiSelectTextField'
import RadioTextField from '../../components/text-fields/RadioTextField'
import Dialog from '../../components/Dialogs/Slide'
import SelectTextField from '../../components/text-fields/SelectTextField'
import Checkbox from '../../components/text-fields/Checkbox'

const styles = theme => ({
  info: {
    color: theme.palette.text.secondary,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  selectGroupLabel: {
    fontSize: 18,
    color: theme.palette.primary.main,
    textTransform: 'initial',
  },
})

const yupSchema = Yup.object().shape({
  parentid: Yup.mixed().required('required'),
  agentid: Yup.mixed().when('parentoption', (parentoption) => {
    if (parentoption === 'part') {
      return Yup.array().required('required')
    }
  }),
  gamecode: Yup.string().required('required'),
})

const formikEnhancer = withFormik({
  validationSchema: yupSchema,
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const data = {
      ownerid: values.parentid.value,
      parentoption: values.parentoption,
      hasfuture: values.checked,
      gamecode: values.gamecode,
      gamehall: values.brand,
    }

    if (values.parentoption === 'part') {
      data.parentlist = values.agentid.map(v => v.value)
    }

    try {
      await props.onSubmit(data)
      resetForm()
    } catch (error) { console.log(error) }

    setSubmitting(false)
  },
  displayName: 'specialGame',
  enableReinitialize: true,
})

const SpecialGame = props => {
  const {
    classes,
    isSubmitting,
    t,
    setFieldValue,
    isFetching,
    ownerOption = () => [],
    parentOption = () => [],
    gameOptions = () => [],
    fetchAgents,
    clearAgent,
    values,
    role,
    brandOptions,
    onBrandChange,
    brand,
    type,
  } = props

  const isCS = role === 'cs' && type === 'SYSTEM'

  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'brand',
        label: t('brand'),
        fullWidth: true,
        options: brandOptions,
        onChange: ({ value }) => {
          setFieldValue('brand'.value)
          onBrandChange(value)
        },
        hide: role !== 'admin' && !isCS,
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'parentid',
        label: t('owner'),
        fullWidth: true,
        isClearable: true,
        isSearchable: true,
        value: values.parentid,
        onChange: (value, { action }) => {
          switch (action) {
            case 'select-option': {
              clearAgent()
              setFieldValue('agentid', [])
              // 選擇總代理後向 bo 請求該總代理的子代理清單。回傳資料作為子代理 Select options
              fetchAgents({ role: 'parent', account: value.label, userID: value.value })
              break
            }
            case 'clear':
              // 清除 store 中子代 options
              clearAgent()
              setFieldValue('agentid', [])
              break
            default:
              break
          }

          return setFieldValue('parentid', value)
        },
        InputLabelProps: {
          shrink: true,
        },
        options: ownerOption().filter(({ brand: _brand }) => _brand === brand),
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'parentoption',
        label: t('agent'),
        fullWidth: true,
        onChange: (value) => {
          if (value !== 'part') {
            setFieldValue('agentid', [])
          }
        },
        options: [
          { label: t('none'), value: 'none' },
          { label: t('all'), value: 'all' },
          { label: t('part'), value: 'part' },
        ],
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'agentid',
        label: t('part'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: values.agentid,
        formatGroupLabel: data => {
          return (
            <span className={classes.selectGroupLabel}>{data.label}</span>
          )
        },
        onChange: (value) => {
          return setFieldValue('agentid', value)
        },
        InputLabelProps: {
          shrink: true,
        },
        options: parentOption(),
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        options: gameOptions(),
        isClearable: true,
      },
    ],
    [
      {
        Field: Checkbox,
        value: values.checked,
        name: 'checked',
        label: t('check'),
      },
    ],
  ]

  return (
    <Form>
      {renderFields(fields[0], props)}
      {renderFields(fields[1], props)}
      {renderFields(fields[2], props)}
      {values.parentoption !== 'part' || renderFields(fields[3], props)}
      {renderFields(fields[4], props)}
      {renderFields(fields[5], props)}

      <Dialog
        // level 控制按鈕樣式
        isFetching={isFetching}
        variant="common"
        actionType='positive'
        isSubmitting={isSubmitting}
        text={{
          confirm: t('set', { ns: 'common' }),
          dialogTitle: t('dialogTitle'),
          // parentoption = none || all || part
          dialogDescription: t(`dialogDescription_${values.parentoption}`) + (values.checked ? t('dialogDescriptionChecked') : ''),
          dialogDisagree: t('dialogDisagree'),
          dialogAgree: t('dialogAgree'),
        }}
        handleSubmit={props.handleSubmit}
        handleOpenDialog={openDialog => {
          // 開啟 Dialog 之前先進行 input 驗證，這樣操作體驗比較好
          props.validateForm().then(value => {
            // 若欄位驗證錯誤則提示錯誤訊息
            if (Object.keys(value).length > 0) {
              props.setTouched({
                gamehall: values.brand,
                parentid: !!value.parentid,
                agentid: !!value.agentid,
                gamecode: !!value.gamecode,
              })

              return
            }

            openDialog()
          })
        }}
      />
    </Form>
  )
}

SpecialGame.propTypes = {
  // 總代帳號選項
  ownerOption: PropTypes.func,
  parentid: PropTypes.string,
  // 子代帳號選項
  parentOption: PropTypes.func,
  agentid: PropTypes.array,
  // 遊戲代號選項
  gameOption: PropTypes.func,
  gamecode: PropTypes.string,
  // 子代理 check box 值
  parentoption: PropTypes.oneOf(['none', 'all', 'part']),
  // 未來子代預設開啟遊戲
  checked: PropTypes.bool,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
}

SpecialGame.defaultProps = {
  // 遊戲代號清單
  gamecode: '',
  // renderfield 初始值
  agentid: [],
  // renderfield 初始值
  parentid: '',
  // submit callback
  onSubmit: () => { },
  // 請求狀態
  isFetching: false,
}

export default compose(
  withTranslation('specialGame'),
  withStyles(styles),
  formikEnhancer,
)(SpecialGame)
