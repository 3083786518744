import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import { NavLink } from 'react-router-dom';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  itemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

class SelectList extends PureComponent {
  render () {
    const { classes, items = [], onClick, selected } = this.props

    return (
      <div className={classes.root}>
        <List component="nav">
          {items &&
            items.map(i => (
              <ListItem
                selected={selected && i.name === selected.name}
                key={i.id}
                button
                onClick={() => onClick(i)}
              >
                <ListItemText className={classes.itemText}>
                  {i.name}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </div>
    )
  }
}

SelectList.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectList)
