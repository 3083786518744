import { makeStyles } from '@material-ui/styles'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '& .css-bg1rzq-control': {
      background: 'none',
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '& .css-1szy77t-control': {
      background: 'none',
    },
    '& .css-14ojxsb-control': {
      background: 'none',
      borderColor: 'grey',
      '& div[role=button]': {
        color: 'grey',
      },
    },
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    '& li > div': {
      paddingLeft: 0,
      display: 'flex',
      alignItems: 'center',
    },
  },
  grid: {
    '& > div': {
      display: 'grid',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(10, 1fr)',
      },
    },
  },
  errors: {
    color: 'red',
  },
}), {
  name: 'TableSetting',
})

export default useStyles
