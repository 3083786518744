import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import LockIcon from '@material-ui/icons/LockOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'

import { Formik, Form, Field } from 'formik'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import {
  getIsLogged,
  getBrandInfo,
} from '../../reducers'
import language from '../../locale/login'
import action from '../../actions'

import InputField from '../../components/fields/InputField'
import RadioTextField from '../../components/text-fields/RadioTextField'

import { getSavedState } from '../../helpers/storage'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.light,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
})

class LoginCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPassword: true,
      lastLogin: getSavedState('lastLogin') || {},
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'login', language.en)
    i18n.addResourceBundle('cn', 'login', language.cn)

    this.forceUpdate()
  }

  onSubmit = payload => {
    this.props.login(payload)
  };

  render () {
    const { classes, t, form, isLogged, brandInfo } = this.props
    const { isPassword, lastLogin } = this.state

    if (isLogged && form) {
      return <Redirect to={form} />
    }

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            { process.env.REACT_APP_BRAND === 'bo' && brandInfo.brand.toUpperCase()}
          </Typography>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login')}
          </Typography>
          <Formik
            initialValues={{
              username: lastLogin.username || '',
              password: '',
              type: lastLogin.type || 'ss',
            }}
            onSubmit={this.onSubmit}
            enableReinitialize
            render={() => {
              return (
                <Form className={classes.form}>
                  <RadioTextField
                    name="type"
                    options={[
                      { label: t('systemOffice'), value: 'ss' },
                      { label: 'System', value: 'system' },
                    ]}
                    fullWidth
                  />

                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="username">{t('username')}</InputLabel>
                    <Field component={InputField} name="username" />
                  </FormControl>

                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">{t('password')}</InputLabel>
                    <Field
                      component={InputField}
                      name="password"
                      type={isPassword ? 'password' : 'text'}
                      endAdornment={
                        <InputAdornment>
                          <IconButton onClick={() => { this.setState({ isPassword: !isPassword }) }}>
                            {isPassword ? <VisibilityOff/> : <Visibility/> }
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {t('login')}
                  </Button>
                </Form>
              )
            }}
          />
        </Paper>
      </main>
    )
  }
}

LoginCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { location }) => {
  const from = location.state ? location.state.from : null

  const brandInfo = getBrandInfo(state)

  return {
    from,
    brandInfo,
    isLogged: getIsLogged(state),
  }
}

export default connect(
  mapStateToProps,
  { login: action.auth.login },
)(withTranslation('login')(withStyles(styles)(LoginCard)))
