const rawData = {
  baccarat: ['Baccarat', '百家乐'],
  gametype: ['Live Game Type', '真人游戏名称'],
  agentAcc: ['Agent Account', '代理帐号'],
  playerAcc: ['Player Account', '玩家帐号'],
  gt: ['The number of account occurrences is greater than', '帐号出现次数大于'],
  roundAnalysis: ['Number of rounds analyzed', '分析局数'],
  playerAppearCount: ['Player\'s account (number of occurrence)', '玩家帐号(出现次数)'],
  count: ['Number of times', '次数'],
  eachRoundDetail: ['Details of each round', '各局详细'],

  all: ['All', '全部'],

  hour: ['Hour', '时'],
  day: ['Day', '日'],
  month: ['Month', '月'],
  year: ['Year', '年'],

  today: ['Today', '今天'],
  yesterday: ['Yesterday', '昨天'],
  thisWeek: ['This week', '本週'],
  lastWeek: ['Last week', '上週'],
  thisMonth: ['This month', '本月'],
  lastMonth: ['Last month', '上月'],

  // 真人视讯
  tableType: ['Game Name', '真人游戏名称'],

  // input error,
  required: ['This is a required field.', '此为必填栏位'],

  order: ['Order', '注单'],
  roundid: ['Order Id', '单号'],
  currency: ['Currency', '币别'],
  datetime: ['Date Time', '日期时间'],
  agent: ['Agent', '代理'],
  player: ['Player', '玩家'],
  tableId: ['Table No', '桌号'],
  roundNumber: ['Round Number', '游戏编号'],
  bets: ['Bets', '下注金额'],
  bettype: ['Bet Type', '下注玩法'],
  Player: ['PLAYER', '闲家'],
  'Banker(Comm)': ['BANKER(Comm)', '庄家'],
  'Banker(No Comm)': ['BANKER(No Comm)', '庄家(免佣)'],
  Tie: ['TIE', '和'],
  'Banker Pair': ['B PAIR', '庄对'],
  'Player Pair': ['P PAIR', '闲对'],
  'Super 6': ['SUPER 6', '超级6'],
  'Either Pair': ['EITHER PAIR', '任意对子'],
  'Perfect Pair': ['PERFECT PAIR', '完美对子'],
  Big: ['Big', '大'],
  Small: ['Small', '小'],
  BDL: ['BDL', '庄龙宝例牌'],
  PDL: ['PDL', '闲龙宝例牌'],
  BDB: ['B BONUS', '庄龙宝'],
  PDB: ['P BONUS', '闲龙宝'],

  // 免费券
  yes: ['Yes', '是'],
  no: ['No', '否'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
