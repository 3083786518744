import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  horizon: { display: 'block' },
})

const RadioField = ({
  classes,
  options = [],
  field,
  form,
  align = 'horizon', // horizon || vertical
  onChange = () => {},
  inputRef,
  disabled,
  ...rest
}) => {
  return (
    <RadioGroup
      {...rest}
      ref={inputRef}
      name={field.name}
      value={field.value}
      onChange={(_, selected) => {
        form.setFieldValue(field.name, selected || '')
        onChange(selected)
      }}
      className={`${classes[align]}`}
    >
      {options.map(option => {
        return (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            disabled={disabled || (option.disabled ? option.disabled : false)}
          />
        )
      })}
    </RadioGroup>
  )
}

export default withStyles(styles)(RadioField)
