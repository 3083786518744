import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& th': {
      padding: '4px 24px',
    },
    '& tr': {
      height: 'auto',
    },
    '& td': {
      padding: '5px!important',
    },
  },
  headerCell: {
    backgroundColor: 'darkslateblue',
    border: '1px grey solid',
  },
  bettype: {
    width: '120px',
    textTransform: 'uppercase',
  },
  border: {
    border: '1px grey solid',
    backgroundColor: '#222',
  },
  input: {
    minWidth: '80px',
    '& input': {
      autocomplete: 'off',
      padding: '2px 3px',
      textAlign: 'center',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 'auto',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 'auto',
    },
  },
  borderDisabled: {
    '& fieldset': {
      border: 'none',
    },
  },
  outOfBound: {
    '& fieldset': {
      borderColor: 'red!important',
    },
  },
}), {
  name: 'BetLimitForm',
})

export default useStyles
