import React, { useEffect } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import language from '../../../locale/agentRedRain'
import { withTranslation } from 'react-i18next'
import SelectTextField from '../../../components/text-fields/SelectTextField'
import OutlinedTextField from '../../../components/text-fields/OutlinedTextField'
import { renderFieldsWithGridItem } from '../../../components/forms/helper'
import GridContainer from '../../../components/grids/GridContainer'

import DateRangePickerTextField from '../../../components/text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({ ...values, first: true })
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'AgentRedRainForm',
})

const SearchForm = props => {
  const {
    isSubmitting,
    classes,
    t,
    isFetching,
    dateRange,
    i18n,
  } = props

  const fields = [
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
      },
      {
        name: 'player',
        label: t('account'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        Field: SelectTextField,
        name: 'status',
        label: t('status'),
        fullWidth: true,
        options: [
          { label: t('init'), value: 'init' },
          { label: t('pending'), value: 'pending' },
          { label: t('success'), value: 'success' },
          { label: t('expired'), value: 'expired' },
        ],
        isClearable: true,
      },
    ],
  ]

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRedRain', language.en)
    i18n.addResourceBundle('cn', 'agentRedRain', language.cn)
  }, [])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withTranslation('agentRedRain')(withStyles(styles)(SearchForm)),
)
