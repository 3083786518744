const rawData = {
  title: ['Search Black List', '查询黑名单'],
  // form
  brand: ['Brand', '品牌'],
  parentid: ['Parent ID', '代理帐号'],
  gamecode: ['Game Code', '游戏代号'],
  owner: ['Owner', '总代理帐号'],
  agent: ['Agent', '子代理帐号'],
  gamehall: ['Game Hall', '游戏厂商'],
  gametype: ['Game Type', '黑名单游戏种类'],
  gamenames: ['Game Name', '黑名单游戏名称'],
  byAgent: ['By Agent', '代理查询'],
  byGamecode: ['By Game Code', '游戏查询'],

  // input error,
  required: ['This is a required field.', '此为必填栏位'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
