import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import language from '../../locale/sidebar'
import DrawerBase from './drawer'
import Brand from '../Brand'
import { Drawer } from '@material-ui/core'
import AppDrawerProvider from './provider'
import { useSelector } from 'react-redux'

const styles = theme => ({
  iconsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing.unit * 2,
    backgroundColor: '#373737',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    whiteSpace: 'nowrap',
    width: theme.sidebarWidth,
    flexShrink: 0,
  },
  brand: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100px',
    marginTop: 36,
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  userButton: {
    fontSize: 16,
    maxWidth: 150,
  },
  menu: {
    marginTop: theme.spacing.unit * 1,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  icon: {
    marginRight: 0,
  },
  text: {
    fontSize: 14,
  },
  bookmarkHover: {
    '&:hover $bookmark': {
      display: 'block',
      pointerEvents: 'initial',
    },
  },
  bookmark: {
    display: 'none',
    pointerEvents: 'none',
  },
})

const Base = ({
  brand,
  items = [],

  i18n,
  t,
  classes,
}) => {
  const isCs = useSelector((state) => state.auth.user?.role === 'cs')
  useEffect(() => {
    i18n.addResourceBundle('en', 'sidebar', language.en)
    i18n.addResourceBundle('cn', 'sidebar', language.cn)
  }, [i18n])

  const exceptionsList = [
    'liveAgentDonateForAdmin',
    'liveAgentDonateForOwner',
    'liveOverUnderUISettingForAdmin',
  ]

  const newItems = () => {
    const filteredItems = items.map((category) => {
      if (category.children) {
        const newItems = category.children.filter((item) => {
          return !exceptionsList.includes(item.key)
        })
        return { ...category, children: newItems }
      }
      return []
    })
    return !isCs ? items : filteredItems
  }
  return (
    <AppDrawerProvider list={newItems()} extra={{ i18n, t, classes }}>
      <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
        <Brand brand={brand} />
        <DrawerBase />
      </Drawer>
    </AppDrawerProvider>
  )
}

const AppDrawer = withStyles(styles, { withTheme: true })(withTranslation('sidebar')(Base))
export default AppDrawer
