export default {
  // agent
  agent: {
    // create agent
    createAgent: 'CREATE_AGENT',
    createAgentRequest: 'CREATE_AGENT_REQUEST',
    createAgentSuccess: 'CREATE_AGENT_SUCCESS',
    createAgentFailure: 'CREATE_AGENT_FAILURE',
    // fetch agent list
    fetchAgentList: 'FETCH_AGENT_LIST',
    fetchAgentListRequest: 'FETCH_AGENT_LIST_REQUEST',
    fetchAgentListSuccess: 'FETCH_AGENT_LIST_SUCCESS',
    fetchAgentListFailure: 'FETCH_AGENT_LIST_FAILURE',
    // fetch agent list
    fetchAgentListV2: 'FETCH_AGENT_LIST_V2',
    fetchAgentListV2Request: 'FETCH_AGENT_LIST_V2_REQUEST',
    fetchAgentListV2Success: 'FETCH_AGENT_LIST_V2_SUCCESS',
    fetchAgentListV2Failure: 'FETCH_AGENT_LIST_V2_FAILURE',
    removeAgentList: 'REMOVE_AGENT_LIST', // call reducer
    clearAgentList: 'CLEAR_AGENT_LIST', // call reducer
    // fetch agent options
    fetchAgentOptions: 'FETCH_AGENT_OPTINS',
    fetchAgentOptionsRequest: 'FETCH_AGENT_OPTINS_REQUEST',
    fetchAgentOptionsSuccess: 'FETCH_AGENT_OPTINS_SUCCESS',
    fetchAgentOptionsFailure: 'FETCH_AGENT_OPTINS_FAILURE',
    // fetch agent detail
    fetchDetail: 'FETCH_AGENT_DETAIL',
    fetchDetailRequest: 'FETCH_AGENT_DETAIL_REQEUST',
    fetchDetailSuccess: 'FETCH_AGENT_DETAIL_SUCCESS',
    fetchDetailFailure: 'FETCH_AGENT_DETAIL_FAILURE',
    fetchStationList: 'FETCH_STATION_LIST',
    fetchStationListRequest: 'FETCH_STATION_LIST_REQUEST',
    fetchStationListSuccess: 'FETCH_STATION_LIST_SUCCESS',
    fetchStationListFailure: 'FETCH_STATION_LIST_FAILURE',
    // fetch agent totalSignUp
    fetchTotalSignUp: 'FETCH_AGENT_TOTALSIGNUP',
    fetchTotalSignUpRequest: 'FETCH_AGENT_TOTALSIGNUP_REQEUST',
    fetchTotalSignUpSuccess: 'FETCH_AGENT_TOTALSIGNUP_SUCCESS',
    fetchTotalSignUpFailure: 'FETCH_AGENT_TOTALSIGNUP_FAILURE',
    // Update agent status
    updateStatus: 'UPDATE_AGENT_STATUS',
    updateStatusRequest: 'UPDATE_AGENT_STATUS_REQUEST',
    updateStatusSuccess: 'UPDATE_AGENT_STATUS_SUCCESS',
    updateStatusFailure: 'UPDATE_AGENT_STATUS_FAILURE',
    // Update agent password
    updatePassword: 'UPDATE_AGNET_PASSWORD',
    updatePasswordRequest: 'UPDATE_AGNET_PASSWORD_REQUEST',
    updatePasswordSuccess: 'UPDATE_AGNET_PASSWORD_SUCCESS',
    updatePasswordFailure: 'UPDATE_AGNET_PASSWORD_FAILURE',
    // Update agent wallet
    updateWallet: 'UPDATE_AGNET_WALLET',
    updateWalletRequest: 'UPDATE_AGNET_WALLET_REQUEST',
    updateWalletSuccess: 'UPDATE_AGNET_WALLET_SUCCESS',
    updateWalletFailure: 'UPDATE_AGNET_WALLET_FAILURE',
  },
  // appDrawer
  appDrawer: {
    toggle: 'TOGGLE_APP_DRAWER',
  },
  // auth: 管理使用者登入流程
  auth: {
    // login
    login: 'LOGIN',
    loginRequest: 'LOGIN_REQUEST',
    loginSuccess: 'LOGIN_SUCCESS',
    loginFailure: 'LOGIN_FAILURE',
    // logout
    logout: 'LOGOUT',
    logoutRequest: 'LOGOUT_REQUEST',
    logoutSuccess: 'LOGOUT_SUCCESS',
    logoutFailure: 'LOGOUT_FAILURE',
    // user token
    setUserByToken: 'SET_USER_BY_TOKEN',
    setUserByTokenRequest: 'SET_USER_BY_TOKEN_REQUEST',
    setUserByTokenSuccess: 'SET_USER_BY_TOKEN_SUCCESS',
    setUserByTokenFailure: 'SET_USER_BY_TOKEN_FAILURE',
    // invalid
    invalidToken: 'INVALID_TOKEN',
    fetchParentAccOnLogin: 'FETCH_PARENT_ACC_ON_LOGIN',
    fetchParentAccOnLoginSuccess: 'FETCH_PARENT_ACC_ON_LOGIN_SUCCESSS',
  },
  betThreshold: {
    // update
    update: 'UPDATE_BET_THRESHOLD',
    updateRequest: 'UPDATE_BET_THRESHOLD_REQUEST',
    updateSuccess: 'UPDATE_BET_THRESHOLD_SUCCESS',
    updateFailure: 'UPDATE_BET_THRESHOLD_FAILURE',
    // Update status
    updateStatus: 'UPDATE_BET_THRESHOLD_STATUS',
    updateStatusRequest: 'UPDATE_BET_THRESHOLD_STATUS_REQUEST',
    updateStatusSuccess: 'UPDATE_BET_THRESHOLD_STATUS_SUCCESS',
    updateStatusFailure: 'UPDATE_BET_THRESHOLD_STATUS_FAILURE',
    // Create bet threshould group
    createGroup: 'CREATE_BET_THRESHOLD_GROUP',
    createGroupRequest: 'CREATE_BET_THRESHOLD_GROUP_REQUEST',
    createGroupSuccess: 'CREATE_BET_THRESHOLD_GROUP_SUCCESS',
    createGroupFailure: 'CREATE_BET_THRESHOLD_GROUP_FAILURE',
    // Update bet threshould group
    updateGroup: 'UPDATE_BET_THRESHOLD_GROUP',
    updateGroupRequest: 'UPDATE_BET_THRESHOLD_GROUP_REQUEST',
    updateGroupSuccess: 'UPDATE_BET_THRESHOLD_GROUP_SUCCESS',
    updateGroupFailure: 'UPDATE_BET_THRESHOLD_GROUP_FAILURE',
    // Fetch bet threshould group
    fetchGroup: 'FETCH_BET_THRESHOLD_GROUP',
    fetchGroupRequest: 'FETCH_BET_THRESHOLD_GROUP_REQUEST',
    fetchGroupSuccess: 'FETCH_BET_THRESHOLD_GROUP_SUCCESS',
    fetchGroupFailure: 'FETCH_BET_THRESHOLD_GROUP_FAILURE',
    // Fetch bet threshould group
    deleteGroup: 'DELETE_BET_THRESHOLD_GROUP',
    deleteGroupRequest: 'DELETE_BET_THRESHOLD_GROUP_REQUEST',
    deleteGroupSuccess: 'DELETE_BET_THRESHOLD_GROUP_SUCCESS',
    deleteGroupFailure: 'DELETE_BET_THRESHOLD_GROUP_FAILURE',

  },
  brandInfo: {
    get: 'GET_BRAND_INFO',
    success: 'GET_BRAND_INFO_SUCCESS',
    failure: 'GET_BRAND_INFO_FAILURE',
  },
  // client: 針對已登入角色
  client: {
    // Update client password
    updatePassword: 'UPDATE_CLIENT_PASSWORD',
    updatePasswordRequest: 'UPDATE_CLIENT_PASSWORD_REQUEST',
    updatePasswordSuccess: 'UPDATE_CLIENT_PASSWORD_SUCCESS',
    updatePasswordFailure: 'UPDATE_CLIENT_PASSWORD_FAILURE',
  },
  // cobrand 聯名設定
  cobrand: {
    setStyle: 'SET_COBRAND_STYLE',
    setStyleRequest: 'SET_COBRAND_STYLE_REQUEST',
    setStyleSuccess: 'SET_COBRAND_STYLE_SUCCESS',
    setStyleFailure: 'SET_COBRAND_STYLE_FAILURE',
  },
  currency: {
    updateSign: 'UPDATE_CURRENCY_SIGN',
    updateSignRequest: 'UPDATE_CURRENCY_SIGN_REQUEST',
    updateSignSuccess: 'UPDATE_CURRENCY_SIGN_SUCCESS',
    updateSignFailure: 'UPDATE_CURRENCY_SIGN_FAILURE',
  },
  // 客服
  customerService: {
    // 取得 cs 資料
    fetchCustomerServices: 'FETCH_CUSTOMER_SERVICES',
    fetchCustomerServicesRequest: 'FETCH_CUSTOMER_SERVICES_REQUEST',
    fetchCustomerServicesSuccess: 'FETCH_CUSTOMER_SERVICES_SUCCESS',
    fetchCustomerServicesFailure: 'FETCH_CUSTOMER_SERVICES_FAILURE',
    // 取得 detail
    fetchCustomerServiceDetail: 'FETCH_CUSTOMER_SERVICE_DETAIL',
    fetchCustomerServiceDetailRequest: 'FETCH_CUSTOMER_SERVICE_DETAIL_REQUEST',
    fetchCustomerServiceDetailSuccess: 'FETCH_CUSTOMER_SERVICE_DETAIL_SUCCESS',
    fetchCustomerServiceDetailFailure: 'FETCH_CUSTOMER_SERVICE_DETAIL_FAILURE',
    // 更新 status
    updateCustomerServiceStatus: 'UPDATE_CUSTOMER_SERVICE_STATUS',
    updateCustomerServiceStatusRequest: 'UPDATE_CUSTOMER_SERVICE_STATUS_REQUEEST',
    updateCustomerServiceStatusSuccess: 'UPDATE_CUSTOMER_SERVICE_STATUS_SUCCESS',
    updateCustomerServiceStatusFailure: 'UPDATE_CUSTOMER_SERVICE_STATUS_FAILURE',
    // 更新 password
    updateCustomerServicePassword: 'UPDATE_CUSTOMER_SERVICE_PASSWORD',
    updateCustomerServicePasswordRequest: 'UPDATE_CUSTOMER_SERVICE_PASSWORD_REQUEEST',
    updateCustomerServicePasswordSuccess: 'UPDATE_CUSTOMER_SERVICE_PASSWORD_SUCCESS',
    updateCustomerServicePasswordFailure: 'UPDATE_CUSTOMER_SERVICE_PASSWORD_FAILURE',
    // 建立客服
    createCustomerService: 'CREATE_CUSTOMER_SERVICE',
    createCustomerServiceRequest: 'CREATE_CUSTOMER_SERVICE_REQUEST',
    createCustomerServiceSuccess: 'CREATE_CUSTOMER_SERVICE_SUCCESS',
    createCustomerServiceFailure: 'CREATE_CUSTOMER_SERVICE_FAILURE',
  },
  event: {
    // 事件狀態管理
    requestStart: 'EVENT_REQUEST_START',
    requestEnd: 'EVENT_REQUEST_END',
    // 以下為各種事件
    handleAgentDetailSubmit: 'HANDLE_AGENT_DETAIL_SUBMIT',
  },
  // game
  game: {
    // 細單連結
    fetchGameDetailLink: 'FETCH_GAME_DETAIL_LINK',
    fetchGameDetailLinkRequest: 'FETCH_GAME_DETAIL_LINK_REQUEST',
    fetchGameDetailLinkSuccess: 'FETCH_GAME_DETAIL_LINK_SUCCESS',
    fetchGameDetailLinkFailure: 'FETCH_GAME_DETAIL_LINK_FAILURE',
    // 遊戲列表（會過濾掉黑名單）
    fetchGameList: 'FETCH_GAMES',
    fetchGameListRequest: 'FETCH_GAMES_REQUEST',
    fetchGameListSuccess: 'FETCH_GAMES_SUCCESS',
    fetchGameListFailure: 'FETCH_GAMES_FAILURE',
    // 遊戲列表（全部）
    fetchAllGameList: 'FETCH_ALL_GAMES',
    fetchAllGameListRequest: 'FETCH_ALL_GAMES_REQUEST',
    fetchAllGameListSuccess: 'FETCH_ALL_GAMES_SUCCESS',
    fetchAllGameListFailure: 'FETCH_ALL_GAMES_FAILURE',
    // 新增黑名單
    addBlackList: 'ADD_GAME_BLACK_LIST',
    addBlackListRequest: 'ADD_GAME_BLACK_LIST_REQUEST',
    addBlackListSuccess: 'ADD_GAME_BLACK_LIST_SUCCESS',
    addBlackListFailure: 'ADD_GAME_BLACK_LIST_FAILURE',
    // 移除黑名單
    removeBlackList: 'REMOVE_GAME_BLACK_LIST',
    removeBlackListRequest: 'REMOVE_GAME_BLACK_LIST_REQUEST',
    removeBlackListSuccess: 'REMOVE_GAME_BLACK_LIST_SUCCESS',
    removeBlackListFailure: 'REMOVE_GAME_BLACK_LIST_FAILURE',
    // 取得黑名單
    clearBlackList: 'CLEAR_GAME_BLACK_LIST',
    fetchBlackList: 'FETCH_BLACK_LIST',
    fetchBlackListByGameCode: 'FETCH_BLACK_LIST_BY_GAMECODE',
    fetchBlackListRequest: 'FETCH_BLACK_LIST_REQUEST',
    fetchBlackListSuccess: 'FETCH_BLACK_LIST_SUCCESS',
    fetchBlackListFailure: 'FETCH_BLACK_LIST_FAILURE',
  },
  // liveGame
  liveGame: {
    // 真人遊戲列表（會過濾掉黑名單）
    fetchLiveGameList: 'FETCH_LIVE_GAMES',
    fetchLiveGameListRequest: 'FETCH_LIVE_GAMES_REQUEST',
    fetchLiveGameListSuccess: 'FETCH_LIVE_GAMES_SUCCESS',
    fetchLiveGameListFailure: 'FETCH_LIVE_GAMES_FAILURE',
  },
  // msgs: snack bar 的通知消息
  msgs: {
    add: 'ADD_MESSSAGE',
    remove: 'REMOVE_MESSAGE',
  },
  report: {
    // download report
    download: 'DOWNLOAD_REPORT',
    downloadRequest: 'DOWNLOAD_REPORT_REQUEST',
    downloadSuccess: 'DOWNLOAD_REPORT_SUCCESS',
    downloadFailure: 'DOWNLOAD_REPORT_FAILURE',
    // report
    fetchList: 'FETCH_LIST_REPORT',
    fetchPlayerOrder: 'FETCH_PLAYERS_ORDER_REPORT',
    fetchPlayerTransaction: 'FETCH_PLAYERS_TRANSACTION',
    fetchReport: 'FETCH_REPORT',
    fetchRequest: 'FETCH_REPORT_REQUEST',
    fetchSuccess: 'FETCH_REPORT_SUCCESS',
    fetchFailure: 'FETCH_REPORT_FAILURE',
    fetchFreeTicketSearch: 'FETCH_FREE_TICKET_SEARCH',
    fetchFreeTicketAnalysisReport: 'FETCH_FREE_TICKET_ANALYSIS_REPORT',
    fetchAgentPlayerFreeTicketReport: 'FETCH_AGENT_PLAYER_FREE_TICKET_REPORT',
  },
  rate: {
    fetch: 'FETCH_RATE_LIST',
    fetchRequest: 'FETCH_RATE_LIST_REQUEST',
    fetchSuccess: 'FETCH_RATE_LIST_SUCCESS',
    fetchFailure: 'FETCH_RATE_LIST_FAILURE',
  },
  // site feature:
  siteFeature: {
    reset: 'RESET_SITE_FEATURES',
    fetch: 'FETCH_SITE_FEATURES',
    fetchRequest: 'FETCH_SITE_FEATURES_REQUEST',
    fetchSuccess: 'FETCH_SITE_FEATURES_SUCCESS',
    fetchFailure: 'FETCH_SITE_FEATURES_FAILURE',
  },
  team: {
    select: 'SELECT_TEAM',
    // fetch teams
    fetch: 'FETCH_TEAMS',
    fetchRequest: 'FETCH_TEAMS_REQUEST',
    fetchSuccess: 'FETCH_TEAMS_SUCCESS',
    fetchFailure: 'FETCH_TEAMS_FAILURE',
    // create team
    create: 'CREATE_TEAM',
    createRequest: 'CREATE_TEAM_REQUEST',
    createSuccess: 'CREATE_TEAM_SUCCESS',
    createFailure: 'CREATE_TEAM_FAILURE',
    // delete team
    delete: 'DELETE_TEAM',
    deleteRequest: 'DELETE_TEAM_REQUEST',
    deleteSuccess: 'DELETE_TEAM_SUCCESS',
    deleteFailure: 'DELETE_TEAM_FAILURE',
    // update team:
    update: 'UPDATE_TEAM',
    updateRequest: 'UPDATE_TEAM_REQUEST',
    updateSuccess: 'UPDATE_TEAM_SUCCESS',
    updateFailure: 'UPDATE_TEAM_FAILURE',
  },
  // team feature:
  teamFeature: {
    // fetch
    fetch: 'FETCH_TEAM_FEATURES',
    fetchRequest: 'FETCH_TEAM_FEATURES_REQUEST',
    fetchSuccess: 'FETCH_TEAM_FEATURES_SUCCESS',
    fetchFailure: 'FETCH_TEAM_FEATURES_FAILURE',
    // update
    update: 'UPDATE_TEAM_FEATURES',
    updateRequest: 'UPDATE_TEAM_FEATURES_REQUEST',
    updateSuccess: 'UPDATE_TEAM_FEATURES_SUCCESS',
    updateFailure: 'UPDATE_TEAM_FEATURES_FAILURE',
  },
  // team member:
  teamMember: {
    // fetch
    fetch: 'FETCH_TEAM_MEMBERS',
    fetchRequest: 'FETCH_TEAM_MEMBERS_REQUEST',
    fetchSuccess: 'FETCH_TEAM_MEMBERS_SUCCESS',
    fetchFailure: 'FETCH_TEAM_MEMBERS_FAILURE',
    // update
    update: 'UPDATE_TEAM_MEMBER',
    updateRequest: 'UPDATE_TEAM_MEMBER_REQUEST',
    updateSuccess: 'UPDATE_TEAM_MEMBER_SUCCESS',
    updateFailure: 'UPDATE_TEAM_MEMBER_FAILURE',
  },
  user: {
    fetchInfo: 'FETCH_USER_INFO',
    fetchInfoRequest: 'FETCH_USER_INFO_REQUEST',
    fetchInfoSuccess: 'FETCH_USER_INFO_SUCCESS',
    fetchInfoFailure: 'FETCH_USER_INFO_FAILURE',
    // fetch user totalSignUp
    fetchTotalSignUp: 'FETCH_USER_TOTALSIGNUP',
    fetchTotalSignUpRequest: 'FETCH_USER_TOTALSIGNUP_REQEUST',
    fetchTotalSignUpSuccess: 'FETCH_USER_TOTALSIGNUP_SUCCESS',
    fetchTotalSignUpFailure: 'FETCH_USER_TOTALSIGNUP_FAILURE',
  },
  live: {
    fetchTableInfo: 'FETCH_LIVE_TABLE_INFO',
    fetchTableInfoRequest: 'FETCH_LIVE_TABLE_INFO_REQUEST',
    fetchTableInfoSuccess: 'FETCH_LIVE_TABLE_INFO_SUCCESS',
    fetchTableInfoFailure: 'FETCH_LIVE_TABLE_INFO_FAILURE',
  },
  // languag:
  language: {
    set: 'SET_LANGUAGE',
    setSuccess: 'SET_LANGUAGE_SUCCESS',
  },
  // time: 管理 time 相關資訊
  time: {
    setTimezone: 'SET_TIMEZONE',
  },
  // ui: 管理操作 ui 行為
  ui: {
    setScroll: 'SET_SCROLL',
    switchTheme: 'SWITCH_THEME',
  },
  promo: {
    // fetchPromo: 'FETCH_PROMO',
    fetchPromoList: 'FETCH_PROMO_LIST',
    fetchPromoListSuccess: 'FETCH_PROMO_LIST_SUCCESS',
    fetchPromoListFailure: 'FETCH_PROMO_LIST_FAILURE',
    fetchPromoPeriod: 'FETCH_PROMO_PERIOD',
    fetchPromoPeriodSuccess: 'FETCH_PROMO_PERIOD_SUCCESS',
    fetchPromoPeriodFailure: 'FETCH_PROMO_PERIOD_FAILURE',
  },
  order: {
    fetchOrder: 'FETCH_ORDER',
    fetchOrderRequest: 'FETCH_ORDER_REQUEST',
    fetchOrderSuccess: 'FETCH_ORDER_SUCCESS',
    fetchOrderFailure: 'FETCH_ORDER_FAILURE',
  },
}
