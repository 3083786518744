import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'
import i18n from '../i18n'

/**
 * Effect to fetch game list
 */
export function * fetchPromoList () {
  try {
    // Call API: fetch promo list
    const res = yield call(api.get, api.BO, '/promo/list')

    // Fetch promo list success
    yield put(actions.promo.fetchPromoListSuccess({
      response: res.data.data.map(({ id, name }) => ({ label: name, value: id })),
    }))
  } catch (err) {
    // Fetch promo list failure
    yield put(actions.promo.fetchPromoListFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

export function * fetchPromoPeriod ({ payload }) {
  try {
    let promoPeriodList = []

    // Call API: fetch promo period
    // 如果沒有promoID(沒選擇時) 就不觸發 api 直接返回空陣列
    if (payload.promoID) {
      const res = yield call(api.get, api.BO, `/promo/period?promoID=${payload.promoID}`)
      promoPeriodList = res.data.data
    }

    // Fetch promo period success
    yield put(actions.promo.fetchPromoPeriodSuccess({
      response: promoPeriodList.map(({ id }) => {
        const item = { label: id, value: id }
        // 派彩查詢用
        // 因應壓碼王總榜的id為9999, 需替換為可識別文字
        if (id === 9999) item.label = i18n.t('playerPayoff:totalRanking')
        return item
      }),
    }))
  } catch (err) {
    // Fetch promo period failure
    yield put(actions.promo.fetchPromoPeriodFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.promo.fetchPromoList, fetchPromoList),
  takeLatest(actionType.promo.fetchPromoPeriod, fetchPromoPeriod),
]

export default effects
