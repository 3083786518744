import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  search: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    margin: '24px 0 24px 12px ',
  },
  textfield: {
    marginRight: '30px',
    '& input': {
      padding: '15px 0',
    },
  },
  snackbar: {
    left: '20px',
    bottom: '20px',
    transform: 'none',
    '& > div': {
      background: '#039be5',
      '& > div': {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
}), {
  name: 'ChipsSetting',
})

export default useStyles
