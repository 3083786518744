import qs from 'qs'
import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch game list，會過濾掉「黑名單」
 */
export function * fetchList ({ payload }) {
  try {
    // 1. Fetch game list request started
    yield put(actions.game.fetchGameListRequest({ filter: payload.brand }))

    // 2. Call API: fetch game list
    const res = yield call(api.get, api.BO, '/gamelistbyrole')

    // 如果data回傳空值時使用，以防報Something wrong
    const fakeRes = {
      data: {
        data: {
          data: {
            [payload.brand]: [],
          },
        },
      },
    }
    const temp = Object.values(res.data.data.data)
    // 3. Fetch game list success
    yield put(
      actions.game.fetchGameListSuccess({
        response: temp.flat(),
        filter: payload.brand,
      }),
    )
  } catch (err) {
    // Fetch game list failure
    yield put(actions.game.fetchGameListFailure({ filter: [payload.brand] }))
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

export const detailPath = {
  slotAndTable: '/players/order/detaillink',
  sportAndLotto: '/lotto/players/order/detaillink',
}

/* Effect to fetch game detail
 */
export function * fetchGameDetailLink ({ payload, meta }) {
  try {
    // 1. Fetch game detail request started
    yield put(actions.game.fetchGameDetailLinkRequest())

    // 2. Call API: fetch game detail
    const res = yield call(api.get, api.BO, detailPath[meta.gameType], {
      params: payload,
    })

    // 3. Fetch game detail success
    yield put(actions.game.fetchGameDetailLinkSuccess())

    if (res.data && res.data.data && res.data.data.detaillink) {
      const myWindow = window.open(window.location.href, '_blank')
      myWindow.document.write('Loading ...')
      myWindow.location.href = `${res.data.data.detaillink}`
    }
  } catch (err) {
    // Fetch game detail failure
    yield put(actions.game.fetchGameDetailLinkFailure())
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

export function * addBlackList ({ payload, meta }) {
  try {
    // 1. Add blacklist start
    yield put(actions.game.addBlackListRequest())

    // 2. Call API: add blacklist
    yield call(api.post, api.BO, '/game/black/add', payload)

    // 3. Add blacklist success
    yield put(actions.game.addBlackListSuccess())

    // 4. Announce add blacklist success
    yield put(
      actions.msgs.add({
        replaceWithI18nText: 'success',
        variant: 'info',
      }),
    )
  } catch (err) {
    // Add Blacklist failure
    yield put(actions.game.fetchGameDetailLinkFailure())
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

export function * removeBlackList ({ payload, meta }) {
  try {
    // 1. Remove blacklist start
    yield put(actions.game.removeBlackListRequest())

    // 2. Call API: remove blacklist
    yield call(api.post, api.BO, '/game/black/remove', payload)

    // 3. Remove blacklist success
    yield put(actions.game.removeBlackListSuccess())

    // 4. Announce remove blacklist  success
    yield put(
      actions.msgs.add({
        replaceWithI18nText: 'success',
        variant: 'info',
      }),
    )
  } catch (err) {
    // Remove Blacklist failure
    yield put(actions.game.fetchGameDetailLinkFailure())
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

/**
 * Effect to fetch black list
 */
export function * fetchBlackList ({ payload, meta }) {
  try {
    const query = qs.stringify(payload, { arrayFormat: 'repeat' })

    // 1. Fetch black list start
    yield put(actions.game.fetchBlackListRequest())

    // 2. Call API: fetch black list
    const res = yield call(api.get, api.BO, `/game/black/get?${query}`)

    // 3. Fetch black list success
    yield put(actions.game.fetchBlackListSuccess({ response: res.data.data }))
  } catch (err) {
    // Fetch Black list failure
    yield put(actions.game.fetchBlackListFailure())
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

/**
 * Effect to fetch black list by game code
 */
export function * fetchBlackListByGameCode ({ payload, meta }) {
  try {
    const query = qs.stringify(payload, { arrayFormat: 'repeat' })

    // 1. Fetch black list start
    yield put(actions.game.fetchBlackListRequest())

    // 2. Call API: fetch black list
    const res = yield call(api.get, api.BO, `/game/black/getbygamecode?${query}`)

    // 3. Fetch black list success
    yield put(actions.game.fetchBlackListSuccess({ response: res.data.data || [] }))
  } catch (err) {
    // Fetch Black list failure
    yield put(actions.game.fetchBlackListFailure())
    // Announce API error
    yield put(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant: 'warning',
      }),
    )
  }
}

const effects = [
  takeLatest(actionType.game.fetchGameList, fetchList),
  takeLatest(actionType.game.fetchGameDetailLink, fetchGameDetailLink),
  takeLatest(actionType.game.addBlackList, addBlackList),
  takeLatest(actionType.game.removeBlackList, removeBlackList),
  takeLatest(actionType.game.fetchBlackList, fetchBlackList),
  takeLatest(actionType.game.fetchBlackListByGameCode, fetchBlackListByGameCode),
]

export default effects
