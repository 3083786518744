// IE11 polyfill: https://dotblogs.com.tw/wasichris/2019/06/20/234252
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/Root'
import store from './configureStore'
import './i18n'

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// TODO: HMR
