import React from 'react'
import { Formik, Form } from 'formik'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import language from '../../locale/dialogConfirm'
import { DARK_MODE } from '../../themes/bo'

const styles = theme => ({
  content: {
    color: theme.customer.name === DARK_MODE ? 'white' : '',
  },
  cancel: {
    color: theme.customer.name === DARK_MODE ? 'white' : '',
  },
  alert: {
    ...theme.customer.button.negative,
    color: '#fff',
  },
})

const DialogConfirm = ({
  i18n,
  t,
  classes,

  fullWidth = false,
  open,
  onClose,
  onSubmit,
  initValues,

  content,
  title,
  cancelText = t('cancel'),
  confirmText = t('confirm'),
  confirmColor = 'primary',
}) => {
  i18n.addResourceBundle('en', 'dialogConfirm', language.en)
  i18n.addResourceBundle('cn', 'dialogConfirm', language.cn)

  return (
    <Formik
      initialValues={initValues}
      onSubmit={onSubmit}
      enableReinitialize
      render={({ handleSubmit }) => {
        return (
          <Form>
            <Dialog
              open={open}
              fullWidth={fullWidth}
              onClose={onClose}
              onKeyPress={({ key }) => key === 'Enter' && handleSubmit()}
            >
              {title && <DialogTitle>{title}</DialogTitle>}
              <DialogContent className={classes.content}>{content}</DialogContent>
              <DialogActions>
                {
                  onClose &&
                  <Button
                    className={classes.cancel}
                    onClick={onClose}
                    color="primary"
                  >
                    {cancelText}
                  </Button>
                }
                {
                  onSubmit &&
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    className={classes[confirmColor] || ''}
                    color="primary"
                  >
                    {confirmText}
                  </Button>
                }
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    />
  )
}

export default
withStyles(styles)(
  withTranslation('dialogConfirm')(DialogConfirm),
)
