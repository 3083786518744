import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../components/Button'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import RadioTextField from '../text-fields/RadioTextField'
import SelectTextField from '../text-fields/SelectTextField'

import { renderFields } from './helper'
import { withTranslation } from 'react-i18next'
import language from '../../locale/createAgent'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const walletTypeMap = {
  w: 'transfer',
  sw: 'single',
  // ce: 'ce',
}

const formikEnhancer = withFormik({
  // 用 func 包一層才不會有驗證失靈問題
  validationSchema: () =>
    Yup.object().shape({
      account: Yup.string().required('required'),
      password: Yup.string()
        .min(8, 'leastEight')
        .max(16, 'mostSixteen')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_-]{8,16}$/g, 'warning')
        .required('required'),
      nickname: Yup.string().required('required'),
      currency: Yup.string().required('required'),
      commission: Yup.number()
        .typeError('mustNumber')
        .min(0, 'moreThenZero')
        .max(100, 'lessThenEqualHundred')
        .required('required'),
      wurl: Yup.string()
        .url('mustUrl')
        .when('walletType', {
          is: 'sw',
          then: Yup.string().required('required'),
        }),
      wtoken: Yup.string(),
      // stationaccount: Yup.string()
      //   .when('walletType', {
      //     is: 'ce',
      //     then: Yup.string()
      //       .notOneOf([Yup.ref('account'), null], 'accountAndStationaccountDiff')
      //       .required('required'),
      //   }),
      // stationpwd: Yup.string()
      //   .when('walletType', {
      //     is: 'ce',
      //     then: Yup.string()
      //       .min(8, 'leastEight')
      //       .max(16, 'mostSixteen')
      //       .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_-]{8,16}$/g, 'warning')
      //       .required('required'),
      //   }),
    }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await props.onSubmit({
        account: values.account,
        password: values.password,
        nickname: values.nickname,
        currency: values.currency,
        brand: values.brand,
        currencySign: values.currencySign,
        isTestSS: values.isTestSS,
        commission: values.commission,
        wallettype: walletTypeMap[values.walletType],
        wurl: values.wurl,
        wtoken: values.wtoken,
        // stationaccount: values.stationaccount,
        // stationpwd: values.stationpwd,
        // stationname: values.stationaccount,
      })

      resetForm()
    } catch (error) { }

    setSubmitting(false)
  },
  displayName: 'CreateAgentForm',
  enableReinitialize: true,
})

const CreateAgentForm = props => {
  const {
    theme,
    values,
    isSubmitting,
    setFieldValue,
    classes,
    currencyOptions,
    brandOptions,
    i18n,
    setFieldTouched,
    errors,
    touched,
    role,
    type,
    t,
  } = props

  i18n.addResourceBundle('en', 'createAgent', language.en)
  i18n.addResourceBundle('cn', 'createAgent', language.cn)
  i18n.on('languageChanged', () => {
    Object.keys(errors).forEach(fieldName => {
      if (Object.keys(touched).includes(fieldName)) {
        setFieldTouched(fieldName)
      }
    })
  })

  const isCS = role === 'cs' && type === 'SYSTEM'

  const profileFields = [
    {
      name: 'brand',
      label: t('brand'),
      fullWidth: true,
      options: brandOptions,
      Field: SelectTextField,
      hide: role !== 'admin' && !isCS,
    },
    {
      name: 'account',
      label: t('account'),
      fullWidth: true,
      Field: OutlinedTextField,
      inputProps: { maxLength: 36 },
    },
    {
      name: 'password',
      label: t('password'),
      type: 'password',
      autoComplete: 'new-password',
      fullWidth: true,
      Field: OutlinedTextField,
    },
    {
      name: 'nickname',
      label: t('nickname'),
      fullWidth: true,
      Field: OutlinedTextField,
    },
    {
      name: 'commission',
      label: t('commission'),
      fullWidth: true,
      Field: OutlinedTextField,
    },
    {
      name: 'currency',
      label: t('currency'),
      fullWidth: true,
      options: currencyOptions(),
      Field: SelectTextField,
    },
    {
      Field: RadioTextField,
      name: 'currencySign',
      label: t('currencySign'),
      fullWidth: true,
      options: [
        { label: t('show'), value: 'true' },
        { label: t('notShow'), value: 'false' },
      ],
    },
    {
      Field: RadioTextField,
      name: 'isTestSS',
      label: t('testAgent'),
      fullWidth: true,
      options: [
        { label: t('true', { ns: 'common' }), value: 'true' },
        { label: t('false', { ns: 'common' }), value: 'false' },
      ],
    },
  ]

  const walletFields = [
    {
      name: 'walletType',
      label: t('walletType'),
      fullWidth: true,
      options: [{ label: t('w'), value: 'w' }, { label: t('sw'), value: 'sw' }],
      // options: [{ label: t('w'), value: 'w' }, { label: t('sw'), value: 'sw' }, { label: t('ce'), value: 'ce' }],
      onChange: selected => {
        if (selected !== 'sw') {
          setFieldValue('wurl', '')
          setFieldValue('wtoken', '')
        }
        if (selected !== 'ce') {
          setFieldValue('stationaccount', '')
          setFieldValue('stationpwd', '')
        }
      },
      Field: RadioTextField,
    },
  ]

  const singleWalletFields = [
    {
      name: 'wurl',
      label: t('wurl'),
      fullWidth: true,
      Field: OutlinedTextField,
    },
    {
      name: 'wtoken',
      label: t('wtoken'),
      fullWidth: true,
      Field: OutlinedTextField,
    },
  ]

  // const crytocurrencyExchangeFields = [
  //   {
  //     name: 'stationaccount',
  //     label: t('stationaccount'),
  //     fullWidth: true,
  //     Field: OutlinedTextField,
  //   },
  //   {
  //     name: 'stationpwd',
  //     label: t('stationpwd'),
  //     type: 'password',
  //     fullWidth: true,
  //     Field: OutlinedTextField,
  //   },
  // ]

  return (
    <Form>
      <h3 style={theme.customer.H3Style}>{t('createAgent')}</h3>

      {renderFields(profileFields, props)}

      {renderFields(walletFields, props)}

      {values.walletType === 'sw' && renderFields(singleWalletFields, props)}
      {/* {values.walletType === 'ce' && renderFields(crytocurrencyExchangeFields, props)} */}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        actionType='positive'
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('create')}
      </Button>
    </Form>
  )
}

export default withStyles(styles, { withTheme: true })(withTranslation('createAgent')(formikEnhancer(CreateAgentForm)))
