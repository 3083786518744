import { createMuiTheme } from '@material-ui/core/styles'
import React from 'react'

import WbSunnyIcon from '@material-ui/icons/WbSunny'
import Brightness2Icon from '@material-ui/icons/Brightness2'

const sidebarWidth = 330

export const DARK_MODE = 'dark'
export const LIGHT_MODE = 'light'

const base = {
  typography: { useNextVariants: true },
  sidebarWidth,
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '1rem',
        whiteSpace: 'nowrap',
      },
      root: {
        padding: '4px 0px 4px 24px',
      },
      body: {
        maxWidth: '300px',
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        display: 'flex',
        height: 20,
      },
    },
    MUIDataTable: {
      responsiveStacked: {
        overflow: 'visible',
        overflowX: 'auto',
      },
    },
    MuiDialogContent: {
      root: {
        overflow: 'visible',
        overflowY: 'visible',
      },
    },
  },
}

const darkMode = createMuiTheme({
  ...base,
  palette: {
    type: 'dark',
    // blue
    primary: {
      dark: '#0277bd',
      light: '#80d8ff',
      main: '#039be5',
    },
    // black
    secondary: {
      main: '#616161',
    },
  },
  customer: {
    name: DARK_MODE,
    H3Style: {
      color: 'white',
    },
    button: {
      positive: {
        backgroundColor: '#4caf50',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      },
      negative: {
        backgroundColor: '#d84315',
        '&:hover': {
          backgroundColor: '#bf360c',
        },
      },
    },
    AppBar: {
      Language: {
        ButtonColor: 'default',
      },
      TimeZone: {
        ButtonColor: 'default',
      },
      UserButton: {
        SwitchThemeButtonIcon: <WbSunnyIcon color='inherit'/>,
      },
    },
    TeamFeature: {
      SortTree: {
        backgroundColor: '#424242',
      },
      SortTreeNodeStyle: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: 'none',
        // color: #fff;
      },
      title: {
        color: 'white',
      },
    },
  },
})

const lightMode = createMuiTheme({
  ...base,
  palette: {
    // blue
    primary: {
      main: '#0371E4',
    },
    // white
    secondary: {
      main: '#F5F5f5',
    },
  },
  customer: {
    name: LIGHT_MODE,
    H3Style: {
      color: 'black',
    },
    button: {
      positive: {
        backgroundColor: '#4caf50',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      },
      negative: {
        backgroundColor: '#d84315',
        '&:hover': {
          backgroundColor: '#bf360c',
        },
      },
    },
    AppBar: {
      Language: {
        ButtonColor: 'primary',
      },
      TimeZone: {
        ButtonColor: 'primary',
      },
      UserButton: {
        SwitchThemeButtonIcon: <Brightness2Icon color='primary'/>,
      },
    },
    TeamFeature: {
      SortTree: {
        backgroundColor: 'white',
      },
      SortTreeNodeStyle: {
        fontWeight: 'bold',
        backgroundColor: 'white',
        border: 'solid #bbb 1px',
        borderRadius: '2px',
        boxShadow: '0 2px 2px -2px',
      },
      title: {
        color: 'black',
      },
    },
  },
})

export const switchMode = mode => {
  switch (mode) {
    case DARK_MODE:
      return darkMode
    case LIGHT_MODE:
      return lightMode
    default:
      return darkMode
  }
}
