import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import EnhancedDataTable from './EnhancedDataTable/EnhancedDataTable'
import { TABLE_COLUMN_DATA } from './constant'
import { useTranslation } from 'react-i18next'
import language from '../../locale/gkSetting'
import { api } from '../../services'
import useStyles from './index.style'
import { useSelector } from 'react-redux'
import useDisplayBox from '../../hooks/useDisplayBox'

const LiveDonateSettingForAdmin = () => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('gkSetting')
  const { successHandler, errorHandler } = useDisplayBox()

  const lang = useSelector((state) => state.state.language)
  const userId = useSelector((state) => state.auth.user.userID)
  const account = useSelector((state) => state.auth.user.account)

  const [data, setData] = useState({})
  const [searchId, setAgentId] = useState('')
  const [tablesets, setTablesets] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isDisplay, setIsDisplay] = useState(false)
  const [isAllSwitchOn, setIsAllSwitchOn] = useState(false)
  const [isAllSwitchTouched, setIsAllSwitchTouched] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  const changeRef = useRef({ userid: [], donate: [], isAllSwitchOn: false, currentUserId: userId })

  const clearChangeHandler = useCallback(() => {
    setRowsPerPage(30)
    setIsEdit(false)
    setIsAllSwitchTouched(false)
    changeRef.current = { ...changeRef.current, userid: [], donate: [] }
  }, [])

  const displayHandler = useCallback((isValid) => {
    const isOwner = searchId === account
    ;(isValid && !isOwner) ? (setIsDisplay(true)) : (setIsDisplay(false))
  }, [account, searchId])

  // 搜索
  const handleSearchChange = (e) => setAgentId(e.target.value)
  const handleSearch = useCallback(async (nowpage, pagesize, reset = true) => {
    try {
      const response = await api.get(api.BO, `/ss/donate/list?account=${searchId}&nowpage=${nowpage || ''}&pagesize=${pagesize || ''}`)
      if (response.data?.status?.code === '0') {
        if (reset) { setRowsPerPage(30) }
        setIsAllSwitchOn(response.data.data.data[0].donateall === 'y')
        displayHandler(true)
        searchId ? (changeRef.current.currentUserId = response.data.data.data[0].userid) : (changeRef.current.currentUserId = userId)
        setData(response.data.data || {})
        setTablesets(response.data.data.data.map(el => ({ ...el })))
      }
    } catch (err) {
      displayHandler(false)
      errorHandler(err)
    }
  }, [searchId, displayHandler, userId, errorHandler])

  const handleSwitchChange = useCallback((value) => {
    const [userid, donate] = value.split(',')
    // 先轉換string成boolean後，轉換開關
    const isDonate = !(donate === 'true')
    const index = changeRef.current.userid.indexOf(userid)
    if (index === -1) {
      changeRef.current = { ...changeRef.current, userid: [...changeRef.current.userid, userid], donate: [...changeRef.current.donate, isDonate] }
    } else {
      changeRef.current.donate[index] = isDonate
    }
    setTablesets((prev) => {
      const currentUserId = prev.find((current) => current.userid === userid)
      currentUserId.donate = isDonate
      return [...prev]
    })
  }, [])

  const handleAllSwitchChange = useCallback((isChecked) => {
    setIsAllSwitchOn(isChecked)
    setIsAllSwitchTouched(true)
    setTablesets((prev) => {
      return prev.map((current) => {
        current.donate = isChecked
        return current
      })
    })
  }, [])

  // 編輯、確認、取消
  const handleSave = useCallback(async () => {
    try {
      const updateAllResponse = await api.put(api.BO, 'live/ss/donate/updateall', {
        userid: changeRef.current.currentUserId,
        donateall: isAllSwitchTouched ? (isAllSwitchOn ? 'y' : 'n') : 'e',
      })
      const updateResponse = await api.put(api.BO, 'live/ss/donate/update', {
        userid: isAllSwitchTouched ? [changeRef.current.currentUserId] : [...changeRef.current.userid],
        donate: isAllSwitchTouched ? [isAllSwitchOn] : [...changeRef.current.donate],
      })
      if (updateResponse.data?.status?.code === '0' && updateAllResponse.data?.status?.code === '0') {
        changeRef.current.isAllSwitchOn = isAllSwitchOn
        handleSearch()
        successHandler()
        clearChangeHandler()
      }
    } catch (err) { errorHandler(err) }
  }, [clearChangeHandler, errorHandler, handleSearch, isAllSwitchOn, isAllSwitchTouched, successHandler])
  const handleEdit = useCallback(() => { setIsEdit(true) }, [])
  const handleCancel = useCallback(() => {
    setIsAllSwitchOn(changeRef.current.isAllSwitchOn)
    setTablesets(data.data.map((el) => ({ ...el })))
    clearChangeHandler()
  }, [clearChangeHandler, data.data])

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'gkSetting', language.en)
    i18n.addResourceBundle('cn', 'gkSetting', language.cn)
  }, [i18n])

  // 進入畫面時搜索資料
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { handleSearch() }, [])

  const isModified = (changeRef.current.userid.length && changeRef.current.donate.length) || isAllSwitchTouched

  return (
    <>
      <Container>
        {/* 搜索欄 */}
        <GridItemFull sticky>
          <Paper>
            {/* 表單 */}
            <div className={classes.form}>
              <div>
                <TextField
                  required
                  disabled={isEdit}
                  autoComplete={'off'}
                  name="agentId"
                  label={t('agentId')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={handleSearchChange}
                  className={classes.textfield}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isEdit}
                  className={classes.search}
                  onClick={() => handleSearch()}>
                  {t('apply')}
                </Button>
              </div>
              {/* 搜索後 */}
              <div className={classes.editContainer}>
                {data.data?.length ? (
                  <div className={classes.buttonGroup}>
                    {!isEdit && isDisplay &&
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleEdit}>
                        {t('edit')}
                      </Button>
                    }
                    {isEdit &&
                      <>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={handleSave}>
                          {t('save')}
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={handleCancel}>
                          {t('cancel')}
                        </Button>
                      </>
                    }
                  </div>
                ) : ''}
              </div>
            </div>
          </Paper>
        </GridItemFull>

        {/* 搜索成功，顯示遊戲桌設定開關 */}
        {(isDisplay && data.data?.length) ? (
          <GridItemFull>
            <Paper>
              <EnhancedDataTable
                t={t}
                lang={lang}
                disabled={!isEdit}
                isAllSwitchOn={isAllSwitchOn}
                isAllSwitchTouched={isAllSwitchTouched}
                rows={tablesets}
                columns={TABLE_COLUMN_DATA}
                total={data.pagination?.total}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={setRowsPerPage}
                onPageChange={handleSearch}
                onSwitchChange={handleSwitchChange}
                onAllSwitchChange={handleAllSwitchChange}
              />
            </Paper>
          </GridItemFull>
        ) : ''}
      </Container>
    </>
  )
}

export default LiveDonateSettingForAdmin
