import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const timezone = (state = null, action = {}) => {
  switch (action.type) {
    case actionType.time.setTimezone:
      return action.payload.timezone
    default:
      return state
  }
}

const language = (state = 'cn', action = {}) => {
  switch (action.type) {
    case actionType.language.setSuccess:
      return action.payload.language
    default:
      return state
  }
}

export default combineReducers({ timezone, language })

export const getTimeZone = state => state.timezone
export const getLanguage = state => state.language
