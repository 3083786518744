import { Button, TableRow, TableCell } from '@material-ui/core'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { formatDate } from '../../helpers'
import actions from '../../actions'
import RedSettingForm from './form/RedSettingForm'
import SearchForm from './form/SearchForm'
import {
  getTimeZone,
  getUserInfo,
} from '../../reducers'
import language from '../../locale/agentRedRain'
import { api } from '../../services'
import { getSavedState } from '../../helpers/storage'
import DataTable from '../../components/tables/DataTable'
import withStyles from '@material-ui/core/styles/withStyles'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import React, { useState, useRef, useEffect } from 'react'

const styles = theme => ({
  btn: {
    marginRight: 5,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
})

const ExpandRow = ({ player, status, starttime, endtime, parentid, redrainid }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(api.BO, '/redpacket', {
        params: {
          starttime,
          endtime,
          parentid,
          redrainid,
          nowPage: 1,
          pagesize: 200,
        },
      })
      setData(res.data.data.list)
    }

    fetchData()
  }, [])

  return (
    <>
      {data.filter(el => {
        if (player && el.account !== player) return false
        if (el.status !== status) return false
        return true
      }).map(el => (
        <TableRow key={el.id}>
          <TableCell ></TableCell>
          <TableCell >{el.account}</TableCell>
          <TableCell >{el.updatetime}</TableCell>
          <TableCell >{el.amount}</TableCell>
          <TableCell >{el.status}</TableCell>
        </TableRow>
      ))}
    </>
  )
}

const AgentRedRain = ({ classes, timezone, userInfo, t, i18n }) => {
  const [pagination, setPagination] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const dispatch = useDispatch()
  const oldData = useRef({})
  const [nowPage, setNowPage] = useState(1)
  const [searchData, setSearchData] = useState([])
  const [amountHistory, setAmountHistory] = useState({})
  const [tab, setTab] = useState(1)

  const handleTabChange = (tab) => () => {
    setTab(tab)
  }

  const handleRedSettingSubmit = async (values) => {
    try {
      const { player, totalamount, amount, expiredtime, rule } = values
      const res = await api.post(api.BO, '/redpacket', {
        ownerid: userInfo.ownerid,
        parentid: userInfo.userid,
        account: player,
        totalamount,
        amount,
        expiredtime,
        rule,
      })

      const { data: { status, data: { notfound } } } = res

      if (status?.code === '0') {
        let temp = player.map((el, i) => [el, amount[i], expiredtime[i]])

        if (notfound?.length > 0) {
          temp = temp.filter(([el]) => {
            if (notfound.includes(el)) {
              return false
            }
            return true
          })
        }

        setAmountHistory(prevState => {
          const newValue = { ...prevState }

          temp.forEach(([el, amount, expiredtime, rule]) => {
            newValue[el] = [amount, expiredtime, rule]
          })

          return newValue
        })

        dispatch(
          actions.msgs.add({
            msg: notfound?.length > 0 ? `${notfound} ${t('noExist')}` : t('success'),
            variant: 'info',
          }),
        )
      }
    } catch (e) {
      const { data: { status } } = e

      dispatch(
        actions.msgs.add({
          msg: status?.message,
          variant: 'warning',
        }),
      )
    }
  }

  const handleSearchFormSubmit = async (values) => {
    try {
      const { first, ...others } = values
      if (first) setNowPage(1)
      const res = await api.get(api.BO, '/redrain/list', {
        params: {
          timezone,
          parentid: userInfo.userid,
          starttime: formatDate(values.startDate),
          endtime: formatDate(values.endDate),
          playerid: values.player,
          status: values.status,
          pagesize: rowsPerPage,
          nowpage: first ? 1 : nowPage,
        },
      })
      oldData.current = others
      const { data: { data: { list, pagination } } } = res
      setPagination(pagination)
      setSearchData(
        list.map(({ createtime, totalamount, id }) => [
          id,
          createtime,
          totalamount,
        ]))
    } catch (err) {
      if (!err.data.data) setSearchData([])
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && t(err.data.status.message),
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }

  const handleChangeRowsPerPage = rowsPrePage => {
    setNowPage(1)
    setRowsPerPage(rowsPrePage)
  }

  const handleChangePage = page => {
    setNowPage(page + 1)
  }

  const startDate = new Date(moment().utcOffset(timezone * 60).subtract(7, 'days').startOf('date').format('YYYY/MM/DD HH:mm:ss'))
  const endDate = new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))

  useEffect(() => {
    if (tab === 2 && oldData.current.startDate) { handleSearchFormSubmit(oldData.current) }
  }, [rowsPerPage, nowPage, tab])

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRedRain', language.en)
    i18n.addResourceBundle('cn', 'agentRedRain', language.cn)
  }, [])

  useEffect(() => {
    if (tab === 2 && oldData.current.startDate) handleSearchFormSubmit(oldData.current)
  }, [timezone, tab])

  const createContent = () => {
    switch (tab) {
      case 1:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RedSettingForm
              amounts={{}}
              player={[]}
              amount={0}
              onSubmit={handleRedSettingSubmit}
              amountHistory={amountHistory}
            />
          </div>
        )
      case 2:
        return (
          <div>
            <SearchForm
              player=""
              status="init"
              startDate={startDate}
              endDate={endDate}
              onSubmit={handleSearchFormSubmit}
              rowsPerPage={rowsPerPage}
            />
            <DataTable
              data={searchData}
              columns={[{ name: t('account') }, { name: t('time') }, { name: t('amount') }, { name: t('status') }]}
              pagination={pagination}
              options={{
                rowsPerPage,
                onChangePage: handleChangePage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
                expandableRows: true,
                expandableRowsOnClick: true,
                renderExpandableRow: (rowData, rowMeta) => {
                  return (
                    <ExpandRow
                      player={oldData.current.player}
                      status={oldData.current.status}
                      parentid={userInfo.userid}
                      redrainid={rowData[0]}
                      starttime={formatDate(oldData.current.startDate)}
                      endtime={formatDate(oldData.current.endDate)}
                    />
                  )
                },
              }}
            />
          </div>
        )
      default: return null
    }
  }
  return (
    <Container>
      <GridItemFull>
        <Paper>
          <Button
            className={classes.btn}
            onClick={handleTabChange(1)}
            variant="contained"
            color="primary">
            {t('redPacketSetting')}
          </Button>
          <Button
            className={classes.btn}
            onClick={handleTabChange(2)}
            variant="contained"
            color="primary">
            {t('redPacketSearch')}
          </Button>
        </Paper>
      </GridItemFull>
      <GridItemFull>
        <Paper>
          {createContent()}
        </Paper>
      </GridItemFull>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const userInfo = getUserInfo(state)
  return {
    timezone,
    userInfo,
  }
}

export default compose(
  connect(
    mapStateToProps,
  ),
  withTranslation('agentRedRain'),
  withStyles(styles),
)(AgentRedRain)
