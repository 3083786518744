import React from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import SelectTextField from '../text-fields/SelectTextField'
import RadioTextField from '../text-fields/RadioTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) { }

    setSubmitting(false)
  },
  displayName: 'LiveCockFormForOwner',
})

const LiveCockFormForOwner = props => {
  const {
    isSubmitting,
    classes,
    withGameType,
    currencyOptions,
    t,
    isFetching,
  } = props

  const fields = [
    [
      {
        name: 'parentAcc',
        label: t('parentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'groupby',
        label: t('groupby'),
        fullWidth: true,
        options: [
          { label: t('hour'), value: 'hour' },
          { label: t('day'), value: 'day' },
          { label: t('month'), value: 'month' },
          { label: t('year'), value: 'year' },
        ],
      },
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'currency',
        label: t('currency'),
        fullWidth: true,
        options: [
          { label: t('notScreened', { ns: 'common' }), value: '' },
          ...currencyOptions(),
        ],
      },
    ],
    // [
    //   {
    //     Field: RadioTextField,
    //     name: 'gameType',
    //     label: t('gameType'),
    //     fullWidth: true,
    //     doubleWidth: true,
    //     options: [
    //       { label: t('live'), value: 'live' },
    //     ],
    //   },
    // ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      {
        withGameType && <GridContainer>
          {renderFieldsWithGridItem(fields[2], props)}
        </GridContainer>
      }
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(
    withTranslation('agentFinanceReport')(LiveCockFormForOwner),
  ),
)
