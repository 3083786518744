import produce from 'immer'
import { combineReducers } from 'redux'

import byId, * as fromById from './byId'
import actionType from '../../actions/actionType'

const agents = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.agent.fetchAgentListSuccess:
      return action.payload.response
    case actionType.agent.fetchAgentListFailure:
    default:
      return state
  }
}

const agentOptions = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.agent.fetchAgentOptionsSuccess:
      return action.payload.response.data.map(item => {
        return {
          label: item.account,
          value: item.userid,
          brand: item.brand,
        }
      })
    case actionType.agent.fetchAgentOptionsFailure:
    default:
      return state
  }
}

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.agent.fetchAgentListRequest:
      return true
    case actionType.agent.fetchAgentListSuccess:
    case actionType.agent.fetchAgentListFailure:
      return false
    default:
      return state
  }
}

// 查詢黑名單頁面的 Select 使用
// 現有 API 接口無法擴充、共用，因此需要新的結構
const agentListWithRole = produce((state, action = {}) => {
  switch (action.type) {
    case actionType.agent.fetchAgentListV2Success:
      if (action.payload.requestPayload.role === 'parent') {
        // 轉換為 select 資料結構
        state.parentOptions.push({
          label: action.payload.requestPayload.account,
          parentID: action.payload.requestPayload.userID,
          options: action.payload.response
            // 回傳的資料包含總代，要過濾掉
            .filter(agent => {
              return action.payload.requestPayload.userID !== agent.userID
            })
            // 組成 options
            .map(agent => {
              return {
                parentID: action.payload.requestPayload.userID,
                label: agent.account,
                value: agent.userID,
              }
            }),
        })

        state.all.push(...action.payload.response)

        break
      }

      // TODO: 使用者如果不斷清除又新增最後陣列會很大。
      // 轉換為 select 資料結構
      state.ownerOptions = action.payload.response.map(item => {
        return {
          brand: item.brand,
          label: item.account,
          value: item.userID,
        }
      })

      state.all.push(...action.payload.response)

      break
    case actionType.agent.removeAgentList:

      state.parentOptions.forEach((agent, i) => {
        if (agent.parentID === action.payload.value) {
          state.parentOptions.splice(i, 1)
        };
      })

      break
    case actionType.agent.clearAgentList:
      state.parentOptions = []
      break
    default:
      return state
  }
}, {
  // 總代＋子代的原始回傳資料
  all: [],
  // 總代
  ownerOptions: [],
  // 子代
  parentOptions: [],
})

export default combineReducers({
  agents,
  agentListWithRole,
  agentOptions,
  isFetching,
  byId,
})

export const getAgents = state => state.agents
export const getAgentOptions = state => state.agentOptions
export const getIsFetchingAgents = state => state.isFetching

export const getAgentById = (state, agentId) =>
  fromById.getAgentById(state.byId, agentId)

export const getAgentTotalPlayersById = (state, agentId) =>
  fromById.getAgentTotalPlayersById(state.byId, agentId)

export const getAgentListWithRole = state => state.agentListWithRole
