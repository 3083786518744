import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  countTable: {
    marginRight: '50px',
  },
}), {
  name: 'LiveRiskAssessment',
})

export default useStyles
