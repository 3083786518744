import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import EnhancedDataTable from './EnhancedDataTable/EnhancedDataTable'
import { TABLE_COLUMN_DATA } from './fakeData'
import { useTranslation } from 'react-i18next'
import language from '../../locale/gkSetting'
import { api } from '../../services'
import useStyles from './TableSetting.style'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../actions'

const TableSetting = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation('gkSetting')
  const lang = useSelector((state) => state.state.language)

  const [agentId, setAgentId] = useState('')
  const [data, setData] = useState({})
  const [tablesets, setTablesets] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // 搜索
  const handleSearchChange = (e) => setAgentId(e.target.value)
  const handleSearchClick = useCallback(async () => {
    try {
      const res = await api.get(api.BO, `/live/gk/switch?ssaccount=${agentId}`)
      if (res.data?.status?.code === '0') {
        setData(res.data.data || {})
        setTablesets(
          res.data.data.tablesets.sort((a, b) =>
            a.istopgame === b.istopgame ? 0 : a.istopgame ? -1 : 1,
          ) || [],
        )
      }
    } catch (err) {
      setData({})
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }, [agentId, dispatch])

  // 編輯、確認、取消
  const handleEdit = () => setIsEdit(true)
  const handleSubmit = useCallback(async () => {
    try {
      const res = await api.post(api.BO2, '/live/gk/switch', {
        ssid: data.ssid,
        tablesets: tablesets,
      })
      if (res.data?.status?.code === '0') {
        setIsEdit(false)
        try {
          const res = await api.get(
            api.BO,
            `/live/gk/switch?ssaccount=${agentId}`,
          )
          if (res.data?.status?.code === '0') {
            setIsEdit(false)
            setData(res.data.data)
            setTablesets(
              res.data.data.tablesets.sort((A, B) =>
                A.istopgame === B.istopgame ? 0 : A.istopgame ? -1 : 1,
              ) || [],
            )
          }
          dispatch(
            actions.msgs.add({
              replaceWithI18nText: 'modifySuccess',
              variant: 'success',
            }),
          )
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [data, agentId, tablesets, dispatch])
  const handleCancel = () => {
    setIsEdit(false)
    setTablesets(
      data.tablesets.sort((A, B) =>
        A.istopgame === B.istopgame ? 0 : A.istopgame ? -1 : 1,
      ),
    )
  }

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'gkSetting', language.en)
    i18n.addResourceBundle('cn', 'gkSetting', language.cn)
  }, [i18n])

  // 成功修改資料，更新變數
  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false)
    }
  }, [isSuccess])

  return (
    <>
      <Container>
        {/* 搜索欄 */}
        <GridItemFull sticky>
          <Paper>
            <div className={classes.form}>
              <div>
                <TextField
                  required
                  disabled={isEdit}
                  autoComplete={'off'}
                  name="agentId"
                  label={t('agentId')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={handleSearchChange}
                  className={classes.textfield}
                />
                <Button
                  disabled={!agentId}
                  variant="contained"
                  color="primary"
                  className={classes.search}
                  onClick={handleSearchClick}>
                  {t('apply')}
                </Button>
              </div>
              {data.ssaccount && (
                <div className={classes.buttonGroup}>
                  {!isEdit ? (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}>
                      {t('edit')}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}>
                        {t('save')}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}>
                        {t('cancel')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Paper>
        </GridItemFull>

        {/* 搜索成功，顯示遊戲桌設定開關 */}
        {data.ssaccount && (
          <GridItemFull>
            <Paper>
              <div>
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('agentId')} : {data.ssaccount}
                  </Typography>
                  <EnhancedDataTable
                    lang={lang}
                    disabled={!isEdit}
                    columns={TABLE_COLUMN_DATA}
                    rows={tablesets}
                    setTablesets={setTablesets}
                  />
                </>
              </div>
            </Paper>
          </GridItemFull>
        )}
      </Container>
    </>
  )
}

TableSetting.propTypes = {}

export default TableSetting
