import actionType from './actionType'
import { check } from './devUtilise'
import { DEVELOPMENT } from '../consts/env'

/** createActionCreator
 * @param {object} config action type
 * 直接使用 actionType 來建立 action creator（避免 boilerplate 造成的身心負擔）
 * 如果有特殊的 action creator，請在使用 spread operator 擴充
 *
 * 如果想使用 Flux Standard Action (FSA) 規範或者 libary（如 redux-act 或 redux-actions），
 * 請依照團隊成員組成以及考慮 libary 於台灣求職市場是否普及來評估，以免提高專案維護技術水平
*/
function createActionCreator (config) {
  const obj = {}

  for (const key in config) {
    const type = config[key]
    obj[key] = (payload = {}, meta = {}) => dispatch => dispatch({ type, payload, meta }) // action creator
  }

  return obj
}

export default (configs => {
  const actionCreators = {}

  // 由於 action type 絕對不可重複，所以要在開發時期檢查出來。
  // 但當 action type 變多時有可能會疏忽，因此新增此項檢查機制
  if (process.env.NODE_ENV === DEVELOPMENT) {
    check(configs)
  }

  for (const k in configs) {
    actionCreators[k] = createActionCreator(configs[k])
  }

  return actionCreators
})(actionType)
