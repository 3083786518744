import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import RadioTextField from '../text-fields/RadioTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'

import GridContainer from '../grids/GridContainer'
import { renderFields } from './helper'
import language from '../../locale/setCobrand'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    gamecode: Yup.string().required('required'),
    style: Yup.string().required('required'),
    width: Yup.number()
      .typeError('mustNumber')
      .required('required'),
    height: Yup.number()
      .typeError('mustNumber')
      .required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await props.onSubmit(values)
      resetForm()
    } catch (error) { console.log(error) }

    setSubmitting(false)
  },
  displayName: 'setCobrand',
})

const SetCobrandForm = props => {
  const { i18n, isSubmitting, classes, t, isFetching } = props

  i18n.addResourceBundle('en', 'setCobrand', language.en)
  i18n.addResourceBundle('cn', 'setCobrand', language.cn)

  const fields = [
    [
      {
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'style',
        label: t('style'),
        fullWidth: true,
        options: [
          { label: t('spin'), value: 'spin' },
          { label: t('watermark'), value: 'watermark' },
        ],
      },
    ],
    [
      {
        name: 'width',
        label: t('width'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'height',
        label: t('height'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFields(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFields(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFields(fields[2], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('set', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default compose(
  formikEnhancer,
  withTranslation('setCobrand'),
  withStyles(styles),
)(SetCobrandForm)
