import React from 'react'

import VpnKey from '@material-ui/icons/VpnKey' // 權限管理
import Group from '@material-ui/icons/Group' // 玩家管理
import LocationCity from '@material-ui/icons/LocationCity' // 代理管理
import Poll from '@material-ui/icons/Poll' // 報表中心
import ContactPhone from '@material-ui/icons/ContactPhone' // 客服管理
import PersonAdd from '@material-ui/icons/PersonAdd'
import Receipt from '@material-ui/icons/Receipt'
import AttachMoney from '@material-ui/icons/AttachMoney'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import GamesIcon from '@material-ui/icons/Games'
import CardMembershipIcon from '@material-ui/icons/CardMembership'

// import Dashboard from '../Dashboard';
import CustomerServiceList from '../CustomerServiceList'

import AgentReportForAdmin from '../AgentReportForAdmin'
import AgentReportForOwner from '../AgentReportForOwner'
import AgentReportForParent from '../AgentReportForParent'

import LottoAgentReportForAdmin from '../LottoAgentReportForAdmin'
import LottoAgentReportForOwner from '../LottoAgentReportForOwner'
import LottoAgentReportForParent from '../LottoAgentReportForParent'

import PlayerListForAdmin from '../PlayerListForAdmin'
import PlayerListForOwner from '../PlayerListForOwner'
import PlayerListForParent from '../PlayerListForParent'

import PlayerPayoffForAdmin from '../PlayerPayoffForAdmin'
import PlayerPayoffForOwner from '../PlayerPayoffForOwner'
import PlayerPayoffForParent from '../PlayerPayoffForParent'

import GameDetailLink from '../GameDetailLink'

import AgentListForAdmin from '../AgentListForAdmin'
import AgentListForOwner from '../AgentListForOwner'
import CreateAgent from '../CreateAgent'
import BetThresholdModification from '../BetThresholdModification'
import AgentSumByParent from '../AgentSumByParent'
import AgentRedRain from '../AgentRedRain'
import AgentRTPSetting from '../AgentRTPSetting'
import PermissionManager from '../PermissionManager'
import CreateCustomerService from '../CreateCustomerService'

import AgentlessGameReportForAdmin from '../AgentlessGameReportForAdmin'
import AgentlessGameReportForOwner from '../AgentlessGameReportForOwner'
import AgentlessGameReportForParent from '../AgentlessGameReportForParent'

import PlayerOrderForAdmin from '../PlayerOrderForAdmin'
import PlayerOrderForOwner from '../PlayerOrderForOwner'
import PlayerOrderForParent from '../PlayerOrderForParent'

// 免费券查詢
import FreeTicketSearchForAdmin from '../FreeTicketSearchForAdmin'
import FreeTicketSearchForOwner from '../FreeTicketSearchForOwner'
import FreeTicketSearchForParent from '../FreeTicketSearchForParent'

import LottoRealTimeOrderForAdmin from '../LottoRealTimeOrderForAdmin'
import LottoRealTimeOrderForOwner from '../LottoRealTimeOrderForOwner'
import LottoRealTimeOrderForParent from '../LottoRealTimeOrderForParent'

import LottoAwardedOrderForAdmin from '../LottoAwardedOrderForAdmin'
import LottoAwardedOrderForOwner from '../LottoAwardedOrderForOwner'
import LottoAwardedOrderForParent from '../LottoAwardedOrderForParent'

import PlayerTransactionForAdmin from '../PlayerTransactionForAdmin'
import PlayerTransactionForOwner from '../PlayerTransactionForOwner'
import PlayerTransactionForParent from '../PlayerTransactionForParent'

import AgentFinanceReportForAdmin from '../AgentFinanceReportForAdmin'
import AgentFinanceReportForOwner from '../AgentFinanceReportForOwner'
import AgentFinanceReportForParent from '../AgentFinanceReportForParent'

import AgentGameAnalysisReportForAdmin from '../AgentGameAnalysisReportForAdmin'
import AgentGameAnalysisReportForOwner from '../AgentGameAnalysisReportForOwner'
import AgentGameAnalysisReportForParent from '../AgentGameAnalysisReportForParent'

// 免费券分析列表
import FreeTicketAnalysisReportForAdmin from '../FreeTicketAnalysisReportForAdmin'
import FreeTicketAnalysisReportForOwner from '../FreeTicketAnalysisReportForOwner'
import FreeTicketAnalysisReportForParent from '../FreeTicketAnalysisReportForParent'

import LottoAgentGameAnalysisReportForAdmin from '../LottoAgentGameAnalysisReportForAdmin'
import LottoAgentGameAnalysisReportForOwner from '../LottoAgentGameAnalysisReportForOwner'
import LottoAgentGameAnalysisReportForParent from '../LottoAgentGameAnalysisReportForParent'

import AgentPlayerReportForAdmin from '../AgentPlayerReportForAdmin'
import AgentPlayerReportForOwner from '../AgentPlayerReportForOwner'
import AgentPlayerReportForParent from '../AgentPlayerReportForParent'

import LottoAgentPlayerReportForAdmin from '../LottoAgentPlayerReportForAdmin'
import LottoAgentPlayerReportForOwner from '../LottoAgentPlayerReportForOwner'
import LottoAgentPlayerReportForParent from '../LottoAgentPlayerReportForParent'

import AgentPlayerGameReportForAdmin from '../AgentPlayerGameReportForAdmin'
import AgentPlayerGameReportForOwner from '../AgentPlayerGameReportForOwner'
import AgentPlayerGameReportForParent from '../AgentPlayerGameReportForParent'

// 代理玩家免费券報表
import AgentPlayerFreeTicketReportForAdmin from '../AgentPlayerFreeTicketReportForAdmin'
import AgentPlayerFreeTicketReportForOwner from '../AgentPlayerFreeTicketReportForOwner'
import AgentPlayerFreeTicketReportForParent from '../AgentPlayerFreeTicketReportForParent'

import LottoAgentPlayerGameReportForAdmin from '../LottoAgentPlayerGameReportForAdmin'
import LottoAgentPlayerGameReportForOwner from '../LottoAgentPlayerGameReportForOwner'
import LottoAgentPlayerGameReportForParent from '../LottoAgentPlayerGameReportForParent'

// 體彩代理財務報表
import LottoAgentFinanceReportForAdmin from '../LottoAgentFinanceReportForAdmin'
import LottoAgentFinanceReportForOwner from '../LottoAgentFinanceReportForOwner'
import LottoAgentFinanceReportForParent from '../LottoAgentFinanceReportForParent'

// 體彩不分代理遊戲報表
import LottoAgentlessGameReportForAdmin from '../LottoAgentlessGameReportForAdmin'
import LottoAgentlessGameReportForOwner from '../LottoAgentlessGameReportForOwner'
import LottoAgentlessGameReportForParent from '../LottoAgentlessGameReportForParent'

import LottoPeriodGameForAdmin from '../LottoPeriodGameForAdmin'

// 聯名設定
import SetCobrand from '../SetCobrand'

// 遊戲設定
import AddBlackList from '../AddBlackList'
import RemoveBlackList from '../RemoveBlackList'
import GetBlackList from '../GetBlackList'
import SpecialGame from '../SpecialGame'

// 真人視訊專區
import LiveAgentGameAnalysisReportForAdmin from '../LiveAgentGameAnalysisReportForAdmin'
import LiveAgentGameAnalysisReportForOwner from '../LiveAgentGameAnalysisReportForOwner'
import LiveAgentGameAnalysisReportForParent from '../LiveAgentGameAnalysisReportForParent'
import LiveAgentPlayerReportForAdmin from '../LiveAgentPlayerReportForAdmin'
import LiveAgentPlayerReportForOwner from '../LiveAgentPlayerReportForOwner'
import LiveAgentPlayerReportForParent from '../LiveAgentPlayerReportForParent'
import LivePlayerOrderForAdmin from '../LivePlayerOrderForAdmin'
import LiveOverUnderUISettingForAdmin from '../LiveOverUnderUISettingForAdmin'
import LivePlayerOrderForOwner from '../LivePlayerOrderForOwner'
import LivePlayerOrderForParent from '../LivePlayerOrderForParent'
import LivePlayerDonate from '../LivePlayerDonate'
import LiveTwoSideBet from '../LiveTwoSideBet'
import ChipsSetting from '../ChipsSetting/ChipsSetting'
import TableSetting from '../TableSetting/TableSetting'
import LiveDonateSettingForAdmin from '../LiveDonateSettingForAdmin'
import LiveDonateSettingForOwner from '../LiveDonateSettingForOwner'
import BetLimitSetting from '../BetLimitSetting/BetLimitSetting'
// 視訊代理報表
import LiveAgentReportForAdmin from '../LiveAgentReportForAdmin'
import LiveAgentReportForOwner from '../LiveAgentReportForOwner'
import LiveAgentReportForParent from '../LiveAgentReportForParent'
// 視訊代理財務報表
import LiveAgentFinanceReportForAdmin from '../LiveAgentFinanceReportForAdmin'
import LiveAgentFinanceReportForOwner from '../LiveAgentFinanceReportForOwner'
import LiveAgentFinanceReportForParent from '../LiveAgentFinanceReportForParent'
// 視訊不分代理遊戲數據列表
import LiveAgentlessGameReportForAdmin from '../LiveAgentlessGameReportForAdmin'
import LiveAgentlessGameReportForOwner from '../LiveAgentlessGameReportForOwner'
import LiveAgentlessGameReportForParent from '../LiveAgentlessGameReportForParent'
import StationLink from '../StationLink'

// RiskControl
import LiveRiskAssessmentForAdmin from '../LiveRiskAssessmentForAdmin'
import LiveRiskAssessmentForOwner from '../LiveRiskAssessmentForOwner'
import LiveRiskAssessmentForParent from '../LiveRiskAssessmentForParent'

import LiveSSCockFightReportForAdmin from '../LiveSSCockFightReportForAdmin'
import LiveSSCockFightReportForOwner from '../LiveSSCockFightReportForOwner'
import LiveSSCockFightReportForParent from '../LiveSSCockFightReportForParent'

const features = [
  {
    text: 'donateSetting',
    key: 'liveAgentDonateForAdmin',
    icon: <Poll />,
    component: LiveDonateSettingForAdmin,
    path: '/liveDonateSettingForAdmin',
  },
  {
    text: 'donateSetting',
    key: 'liveAgentDonateForOwner',
    icon: <Poll />,
    component: LiveDonateSettingForOwner,
    path: '/liveDonateSettingForOwner',
  },
  {
    text: 'liveOverUnderUISetting',
    key: 'liveOverUnderUISettingForAdmin',
    component: LiveOverUnderUISettingForAdmin,
    icon: <Poll />,
    path: '/liveOverUnderUISettingForAdmin',
  },
  {
    text: 'tableSetting',
    key: 'ginkgoSwitchForAdmin',
    icon: <Poll />,
    component: TableSetting,
    path: '/tableSetting',
  },
  {
    text: 'tableSetting',
    key: 'ginkgoSwitchForOwner',
    icon: <Poll />,
    component: TableSetting,
    path: '/tableSetting',
  },
  {
    text: 'tableSetting',
    key: 'ginkgoSwitchForParent',
    icon: <Poll />,
    component: TableSetting,
    path: '/tableSetting',
  },
  {
    text: 'chipsSetting',
    key: 'ginkgoChipForAdmin',
    icon: <Poll />,
    component: ChipsSetting,
    path: '/chipsSetting',
  },
  {
    text: 'chipsSetting',
    key: 'ginkgoChipForOwner',
    icon: <Poll />,
    component: ChipsSetting,
    path: '/chipsSetting',
  },
  {
    text: 'chipsSetting',
    key: 'ginkgoChipForParent',
    icon: <Poll />,
    component: ChipsSetting,
    path: '/chipsSetting',
  },
  {
    text: 'betLimitSetting',
    key: 'ginkgoTableLimitForAdmin',
    icon: <Poll />,
    component: BetLimitSetting,
    path: '/betLimitSetting',
  },
  {
    text: 'betLimitSetting',
    key: 'ginkgoTableLimitForOwner',
    icon: <Poll />,
    component: BetLimitSetting,
    path: '/betLimitSetting',
  },
  {
    text: 'betLimitSetting',
    key: 'ginkgoTableLimitForParent',
    icon: <Poll />,
    component: BetLimitSetting,
    path: '/betLimitSetting',
  },
  // ------------------------------------------------------------------------
  {
    text: 'permissionManagement',
    key: 'permissionManager',
    icon: <VpnKey />,
    component: PermissionManager,
    path: '/permissionManager',
  },
  {
    text: 'playerManagement',
    key: 'playerManager',
    menu: true,
  },
  {
    text: 'stationLink',
    key: 'stationLink',
    component: StationLink,
    icon: <Group />,
    path: '/stationLink',
  },
  {
    text: 'playerList',
    key: 'playerListForAdmin',
    component: PlayerListForAdmin,
    icon: <Group />,
    path: '/playerListForAdmin',
  },
  {
    text: 'playerList',
    key: 'playerListForOwner',
    component: PlayerListForOwner,
    icon: <Group />,
    path: '/playerListForOwner',
  },
  {
    text: 'playerList',
    key: 'playerListForParent',
    component: PlayerListForParent,
    icon: <Group />,
    path: '/playerListForParent',
  },
  {
    text: 'orderSearch',
    key: 'playerOrderForAdmin',
    component: PlayerOrderForAdmin,
    icon: <InsertDriveFile />,
    path: '/playerOrderForAdmin',
  },
  {
    text: 'orderSearch',
    key: 'playerOrderForOwner',
    component: PlayerOrderForOwner,
    icon: <InsertDriveFile />,
    path: '/playerOrderForOwner',
  },
  {
    text: 'orderSearch',
    key: 'playerOrderForParent',
    component: PlayerOrderForParent,
    icon: <InsertDriveFile />,
    path: '/playerOrderForParent',
  },
  {
    text: 'freeTicketSearch',
    key: 'playerFreeticketOrderForAdmin',
    component: FreeTicketSearchForAdmin,
    icon: <InsertDriveFile />,
    path: '/playerFreeticketOrderForAdmin',
  },
  {
    text: 'freeTicketSearch',
    key: 'playerFreeticketOrderForOwner',
    component: FreeTicketSearchForOwner,
    icon: <InsertDriveFile />,
    path: '/playerFreeticketOrderForOwner',
  },
  {
    text: 'freeTicketSearch',
    key: 'playerFreeticketOrderForParent',
    component: FreeTicketSearchForParent,
    icon: <InsertDriveFile />,
    path: '/playerFreeticketOrderForParent',
  },
  {
    text: 'transactionSearch',
    key: 'playerTransactionForAdmin',
    component: PlayerTransactionForAdmin,
    icon: <AttachMoney />,
    path: '/playerTransactionForAdmin',
  },
  {
    text: 'transactionSearch',
    key: 'playerTransactionForOwner',
    component: PlayerTransactionForOwner,
    icon: <AttachMoney />,
    path: '/playerTransactionForOwner',
  },
  {
    text: 'transactionSearch',
    key: 'playerTransactionForParent',
    component: PlayerTransactionForParent,
    icon: <AttachMoney />,
    path: '/playerTransactionForParent',
  },
  {
    text: 'gameRecordDetail',
    key: 'gameDetailLink',
    component: GameDetailLink,
    icon: <Receipt />,
    path: '/gameDetailLink',
  },
  {
    text: 'payoffSearch',
    key: 'playerPayoffForAdmin',
    component: PlayerPayoffForAdmin,
    icon: <InsertDriveFile />,
    path: '/playerPayoffForAdmin',
  },
  {
    text: 'payoffSearch',
    key: 'playerPayoffForOwner',
    component: PlayerPayoffForOwner,
    icon: <InsertDriveFile />,
    path: '/playerPayoffForOwner',
  },
  {
    text: 'payoffSearch',
    key: 'playerPayoffForParent',
    component: PlayerPayoffForParent,
    icon: <InsertDriveFile />,
    path: '/playerPayoffForParent',
  },
  {
    text: 'agentManagement',
    key: 'ssManager',
    menu: true,
  },
  {
    text: 'agentList', // 管理員
    key: 'ssListForAdmin',
    component: AgentListForAdmin,
    icon: <LocationCity />,
    path: '/ssListForAdmin',
  },
  {
    text: 'agentList', // 總代理
    key: 'ssListForOwner',
    component: AgentListForOwner,
    icon: <LocationCity />,
    path: '/ssListForOwner',
  },
  {
    text: 'createAgent',
    key: 'ssCreate',
    component: CreateAgent,
    icon: <PersonAdd />,
    path: '/ssCreate',
  },
  {
    text: 'betThresholdModification',
    key: 'ssBetThresholds',
    component: BetThresholdModification,
    icon: <PersonAdd />,
    path: '/ssBetThresholds',
  },
  {
    text: 'ssSumByParent',
    key: 'ssSumByParent',
    component: AgentSumByParent,
    icon: <CardMembershipIcon />,
    path: '/ssSumByParent',
  },
  {
    text: 'ssRedRain',
    key: 'ssRedRain',
    component: AgentRedRain,
    icon: <CardMembershipIcon />,
    path: '/ssRedRain',
  },
  {
    text: 'ssRTP',
    key: 'ssRTP',
    component: AgentRTPSetting,
    icon: <CardMembershipIcon />,
    path: '/ssRTP',
  },
  {
    text: 'reportCenter',
    key: 'reportCenter',
    menu: true,
  },

  // ##  報表中心
  {
    text: 'agentReport',
    key: 'ssReportForAdmin',
    component: AgentReportForAdmin,
    icon: <Poll />,
    path: '/ssReportForAdmin',
  },
  {
    text: 'agentReport',
    key: 'ssReportForOwner',
    component: AgentReportForOwner,
    icon: <Poll />,
    path: '/ssReportForOwner',
  },
  {
    text: 'agentReport',
    key: 'ssReportForParent',
    component: AgentReportForParent,
    icon: <Poll />,
    path: '/ssReportForParent',
  },
  // 視訊代理報表
  {
    text: 'liveAgentReport',
    key: 'liveSSReportForAdmin',
    component: LiveAgentReportForAdmin,
    icon: <Poll />,
    path: '/liveAgentReport',
  },
  {
    text: 'liveAgentReport',
    key: 'liveSSReportForOwner',
    component: LiveAgentReportForOwner,
    icon: <Poll />,
    path: '/liveAgentReport',
  },
  {
    text: 'liveAgentReport',
    key: 'liveSSReportForParent',
    component: LiveAgentReportForParent,
    icon: <Poll />,
    path: '/liveAgentReport',
  },
  // 視訊代理財務報表
  {
    text: 'liveAgentFinanceReport',
    key: 'liveSSFinanceReportForAdmin',
    component: LiveAgentFinanceReportForAdmin,
    icon: <Poll />,
    path: '/liveAgentFinanceReport',
  },
  {
    text: 'liveAgentFinanceReport',
    key: 'liveSSFinanceReportForOwner',
    component: LiveAgentFinanceReportForOwner,
    icon: <Poll />,
    path: '/liveAgentFinanceReport',
  },
  {
    text: 'liveAgentFinanceReport',
    key: 'liveSSFinanceReportForParent',
    component: LiveAgentFinanceReportForParent,
    icon: <Poll />,
    path: '/liveAgentFinanceReport',
  },
  // 視訊不分代理遊戲數據列表
  {
    text: 'liveAgentlessGameReport',
    key: 'liveAgentlessGameReportForAdmin',
    component: LiveAgentlessGameReportForAdmin,
    icon: <Poll />,
    path: '/liveAgentlessGameReport',
  },
  {
    text: 'liveAgentlessGameReport',
    key: 'liveAgentlessGameReportForOwner',
    component: LiveAgentlessGameReportForOwner,
    icon: <Poll />,
    path: '/liveAgentlessGameReport',
  },
  {
    text: 'liveAgentlessGameReport',
    key: 'liveAgentlessGameReportForParent',
    component: LiveAgentlessGameReportForParent,
    icon: <Poll />,
    path: '/liveAgentlessGameReport',
  },
  {
    text: 'agentPlayerReport',
    key: 'ssPlayerSummaryReportForAdmin',
    component: AgentPlayerReportForAdmin,
    icon: <Poll />,
    path: '/ssPlayerSummaryReportForAdmin',
  },
  {
    text: 'agentPlayerReport',
    key: 'ssPlayerSummaryReportForOwner',
    component: AgentPlayerReportForOwner,
    icon: <Poll />,
    path: '/ssPlayerSummaryReportForOwner',
  },
  {
    text: 'agentPlayerReport',
    key: 'ssPlayerSummaryReportForParent',
    component: AgentPlayerReportForParent,
    icon: <Poll />,
    path: '/ssPlayerSummaryReportForParent',
  },
  {
    text: 'agentPlayerGameReport',
    key: 'ssPlayerReportForAdmin',
    component: AgentPlayerGameReportForAdmin,
    icon: <Poll />,
    path: '/ssPlayerReportForAdmin',
  },
  {
    text: 'agentPlayerGameReport',
    key: 'ssPlayerReportForOwner',
    component: AgentPlayerGameReportForOwner,
    icon: <Poll />,
    path: '/ssPlayerReportForOwner',
  },
  {
    text: 'agentPlayerFreeTicketReport',
    key: 'ssPlayerFreeTicketSummaryReportForAdmin',
    component: AgentPlayerFreeTicketReportForAdmin,
    icon: <Poll />,
    path: '/ssPlayerFreeTicketSummaryReportForAdmin',
  },
  {
    text: 'agentPlayerFreeTicketReport',
    key: 'ssPlayerFreeTicketSummaryReportForOwner',
    component: AgentPlayerFreeTicketReportForOwner,
    icon: <Poll />,
    path: '/ssPlayerFreeTicketSummaryReportForOwner',
  },
  {
    text: 'agentPlayerFreeTicketReport',
    key: 'ssPlayerFreeTicketSummaryReportForParent',
    component: AgentPlayerFreeTicketReportForParent,
    icon: <Poll />,
    path: '/ssPlayerFreeTicketSummaryReportForParent',
  },
  {
    text: 'agentPlayerGameReport',
    key: 'ssPlayerReportForParent',
    component: AgentPlayerGameReportForParent,
    icon: <Poll />,
    path: '/ssPlayerReportForParent',
  },
  {
    text: 'agentFinanceReport',
    key: 'ssFinanceReportForAdmin',
    component: AgentFinanceReportForAdmin,
    icon: <Poll />,
    path: '/ssFinanceReportForAdmin',
  },
  {
    text: 'agentFinanceReport',
    key: 'ssFinanceReportForOwner',
    component: AgentFinanceReportForOwner,
    icon: <Poll />,
    path: '/ssFinanceReportForOwner',
  },
  {
    text: 'agentFinanceReport',
    key: 'ssFinanceReportForParent',
    component: AgentFinanceReportForParent,
    icon: <Poll />,
    path: '/ssFinanceReportForParent',
  },
  // 代理遊戲分析報表
  {
    text: 'agentGameAnalysisReport',
    key: 'ssGameAnalyticReportForAdmin',
    component: AgentGameAnalysisReportForAdmin,
    icon: <Poll />,
    path: '/ssGameAnalyticReportForAdmin',
  },
  {
    text: 'agentGameAnalysisReport',
    key: 'ssGameAnalyticReportForOwner',
    component: AgentGameAnalysisReportForOwner,
    icon: <Poll />,
    path: '/ssGameAnalyticReportForOwner',
  },
  {
    text: 'agentGameAnalysisReport',
    key: 'ssGameAnalyticReportForParent',
    component: AgentGameAnalysisReportForParent,
    icon: <Poll />,
    path: '/ssGameAnalyticReportForParent',
  },
  {
    text: 'freeTicketAnalysisReport',
    key: 'ssGameFreeticketAnalyticReportForAdmin',
    component: FreeTicketAnalysisReportForAdmin,
    icon: <Poll />,
    path: '/ssGameFreeticketAnalyticReportForAdmin',
  },
  {
    text: 'freeTicketAnalysisReport',
    key: 'ssGameFreeticketAnalyticReportForOwner',
    component: FreeTicketAnalysisReportForOwner,
    icon: <Poll />,
    path: '/ssGameFreeticketAnalyticReportForOwner',
  },
  {
    text: 'freeTicketAnalysisReport',
    key: 'ssGameFreeticketAnalyticReportForParent',
    component: FreeTicketAnalysisReportForParent,
    icon: <Poll />,
    path: '/ssGameFreeticketAnalyticReportForParent',
  },
  // ### 不分代理遊戲報表
  {
    text: 'agentlessGameReport',
    key: 'agentlessGameReportForAdmin',
    component: AgentlessGameReportForAdmin,
    icon: <Poll />,
    path: '/agentlessGameReportForAdmin',
  },
  {
    text: 'agentlessGameReport',
    key: 'agentlessGameReportForOwner',
    component: AgentlessGameReportForOwner,
    icon: <Poll />,
    path: '/agentlessGameReportForOwner',
  },
  {
    text: 'agentlessGameReport',
    key: 'agentlessGameReportForParent',
    component: AgentlessGameReportForParent,
    icon: <Poll />,
    path: '/agentlessGameReportForParent',
  },

  {
    text: 'Lottery',
    key: 'Lottery',
    menu: true,
  },

  // 体彩即时注单
  {
    text: 'lottoAgentFinanceReport',
    key: 'lottoSSFinanceReportForAdmin',
    component: LottoAgentFinanceReportForAdmin,
    icon: <Poll />,
    path: '/lottoAgentFinanceReportForAdmin',
  },
  {
    text: 'lottoAgentFinanceReport',
    key: 'lottoSSFinanceReportForOwner',
    component: LottoAgentFinanceReportForOwner,
    icon: <Poll />,
    path: '/lottoAgentFinanceReportForOwner',
  },
  {
    text: 'lottoAgentFinanceReport',
    key: 'lottoSSFinanceReportForParent',
    component: LottoAgentFinanceReportForParent,
    icon: <Poll />,
    path: '/lottoAgentFinanceReportForParent',
  },
  {
    text: 'lottoRealTimeOrder',
    key: 'lottoRealTimeOrderForAdmin',
    component: LottoRealTimeOrderForAdmin,
    icon: <InsertDriveFile />,
    path: '/lottoRealTimeOrderForAdmin',
  },
  {
    text: 'lottoRealTimeOrder',
    key: 'lottoRealTimeOrderForOwner',
    component: LottoRealTimeOrderForOwner,
    icon: <InsertDriveFile />,
    path: '/lottoRealTimeOrderForOwner',
  },
  {
    text: 'lottoRealTimeOrder',
    key: 'lottoRealTimeOrderForParent',
    component: LottoRealTimeOrderForParent,
    icon: <InsertDriveFile />,
    path: '/lottoRealTimeOrderForParent',
  },

  // 体彩开彩注单
  {
    text: 'lottoAwardedOrder',
    key: 'lottoAwardedOrderForAdmin',
    component: LottoAwardedOrderForAdmin,
    icon: <InsertDriveFile />,
    path: '/lottoAwardedOrderForAdmin',
  },
  {
    text: 'lottoAwardedOrder',
    key: 'lottoAwardedOrderForOwner',
    component: LottoAwardedOrderForOwner,
    icon: <InsertDriveFile />,
    path: '/lottoAwardedOrderForOwner',
  },
  {
    text: 'lottoAwardedOrder',
    key: 'lottoAwardedOrderForParent',
    component: LottoAwardedOrderForParent,
    icon: <InsertDriveFile />,
    path: '/lottoAwardedOrderForParent',
  },

  // 体彩代理報表
  {
    text: 'lottoAgentReport',
    key: 'lottoSSReportForAdmin',
    component: LottoAgentReportForAdmin,
    icon: <Poll />,
    path: '/lottoSSReportForAdmin',
  },
  {
    text: 'lottoAgentReport',
    key: 'lottoSSReportForOwner',
    component: LottoAgentReportForOwner,
    icon: <Poll />,
    path: '/lottoSSReportForOwner',
  },
  {
    text: 'lottoAgentReport',
    key: 'lottoSSReportForParent',
    component: LottoAgentReportForParent,
    icon: <Poll />,
    path: '/lottoSSReportForParent',
  },

  // 体彩代理玩家报表
  {
    text: 'lottoAgentPlayerReport',
    key: 'lottoSSPlayerReportForAdmin',
    component: LottoAgentPlayerReportForAdmin,
    icon: <Poll />,
    path: '/lottoSSPlayerReportForAdmin',
  },
  {
    text: 'lottoAgentPlayerReport',
    key: 'lottoSSPlayerReportForOwner',
    component: LottoAgentPlayerReportForOwner,
    icon: <Poll />,
    path: '/lottoSSPlayerReportForOwner',
  },
  {
    text: 'lottoAgentPlayerReport',
    key: 'lottoSSPlayerReportForParent',
    component: LottoAgentPlayerReportForParent,
    icon: <Poll />,
    path: '/lottoSSPlayerReportForParent',
  },

  // 体彩代理玩家游戏报表
  {
    text: 'lottoAgentPlayerGameReport',
    key: 'lottoSSPlayerGameReportForAdmin',
    component: LottoAgentPlayerGameReportForAdmin,
    icon: <Poll />,
    path: '/lottoSSPlayerGameReportForAdmin',
  },
  {
    text: 'lottoAgentPlayerGameReport',
    key: 'lottoSSPlayerGameReportForOwner',
    component: LottoAgentPlayerGameReportForOwner,
    icon: <Poll />,
    path: '/lottoSSPlayerGameReportForOwner',
  },
  {
    text: 'lottoAgentPlayerGameReport',
    key: 'lottoSSPlayerGameReportForParent',
    component: LottoAgentPlayerGameReportForParent,
    icon: <Poll />,
    path: '/lottoSSPlayerGameReportForParent',
  },

  // 体彩代理游戏分析报表
  {
    text: 'lottoAgentGameAnalysisReport',
    key: 'lottoSSGameAnalyticReportForAdmin',
    component: LottoAgentGameAnalysisReportForAdmin,
    icon: <Poll />,
    path: '/lottoSSGameAnalyticReportForAdmin',
  },
  {
    text: 'lottoAgentGameAnalysisReport',
    key: 'lottoSSGameAnalyticReportForOwner',
    component: LottoAgentGameAnalysisReportForOwner,
    icon: <Poll />,
    path: '/lottoSSGameAnalyticReportForOwner',
  },
  {
    text: 'lottoAgentGameAnalysisReport',
    key: 'lottoSSGameAnalyticReportForParent',
    component: LottoAgentGameAnalysisReportForParent,
    icon: <Poll />,
    path: '/lottoSSGameAnalyticReportForParent',
  },

  // 體彩期數單據
  {
    text: 'lottoPeriodOrder',
    key: 'lottoPeriodOrderForAdmin',
    component: LottoPeriodGameForAdmin,
    icon: <Poll />,
    path: '/lottoPeriodOrderForAdmin',
  },

  // 體彩不分代理遊戲報表
  {
    text: 'lottoAgentlessGameReport',
    key: 'lottoAgentlessGameReportForAdmin',
    component: LottoAgentlessGameReportForAdmin,
    icon: <Poll />,
    path: '/lottoAgentlessGameReportForAdmin',
  },
  {
    text: 'lottoAgentlessGameReport',
    key: 'lottoAgentlessGameReportForOwner',
    component: LottoAgentlessGameReportForOwner,
    icon: <Poll />,
    path: '/lottoAgentlessGameReportForOwner',
  },
  {
    text: 'lottoAgentlessGameReport',
    key: 'lottoAgentlessGameReportForParent',
    component: LottoAgentlessGameReportForParent,
    icon: <Poll />,
    path: '/lottoAgentlessGameReportForParent',
  },

  {
    text: 'customerServiceManagement',
    key: 'csManager',
    menu: true,
  },
  {
    text: 'customerServiceList',
    key: 'csList',
    component: CustomerServiceList,
    icon: <ContactPhone />,
    path: '/csList',
  },
  {
    text: 'createCustomerService',
    key: 'csCreate',
    component: CreateCustomerService,
    icon: <PersonAdd />,
    path: '/csCreate',
  },
  {
    text: 'createCustomerService',
    key: 'csCreateForAgent',
    component: CreateCustomerService,
    icon: <PersonAdd />,
    path: '/csCreate',
  },

  // # 聯名後台
  {
    text: 'cobrandManager',
    key: 'cobrandManager',
    menu: true,
  },
  {
    text: 'setCobrand',
    key: 'setCobrand',
    component: SetCobrand,
    icon: <ContactPhone />,
    path: '/setCobrand',
  },

  // # 遊戲設定
  {
    text: 'gameManager',
    key: 'gameManager',
    menu: true,
  },
  {
    text: 'getBlackList',
    key: 'getBlackList',
    component: GetBlackList,
    icon: <Poll />,
    path: '/getBlackList',
  },
  {
    text: 'addBlackList',
    key: 'addBlackList',
    component: AddBlackList,
    icon: <AddIcon />,
    path: '/addBlackList',
  },
  {
    text: 'removeBlackList',
    key: 'removeBlackList',
    component: RemoveBlackList,
    icon: <RemoveIcon />,
    path: '/removeBlackList',
  },

  {
    text: 'specialGame',
    key: 'specialGame',
    component: SpecialGame,
    icon: <GamesIcon />,
    path: '/specialGame',
  },

  // # 真人視訊
  {
    text: 'Live',
    key: 'Live',
    menu: true,
  },
  // 代理遊戲分析報表
  {
    text: 'liveAgentGameAnalysisReport',
    key: 'liveReportAnalyticsForAdmin',
    component: LiveAgentGameAnalysisReportForAdmin,
    icon: <Poll />,
    path: '/liveReportAnalyticsForAdmin',
  },
  {
    text: 'liveAgentGameAnalysisReport',
    key: 'liveReportAnalyticsForOwner',
    component: LiveAgentGameAnalysisReportForOwner,
    icon: <Poll />,
    path: '/liveReportAnalyticsForOwner',
  },
  {
    text: 'liveAgentGameAnalysisReport',
    key: 'liveReportAnalyticsForParent',
    component: LiveAgentGameAnalysisReportForParent,
    icon: <Poll />,
    path: '/liveReportAnalyticsForParent',
  },
  // 代理玩家報表
  {
    text: 'liveAgentPlayerReport',
    key: 'livePlayerSummaryReportForAdmin',
    component: LiveAgentPlayerReportForAdmin,
    icon: <Poll />,
    path: '/livePlayerSummaryReportForAdmin',
  },
  {
    text: 'liveAgentPlayerReport',
    key: 'livePlayerSummaryReportForOwner',
    component: LiveAgentPlayerReportForOwner,
    icon: <Poll />,
    path: '/livePlayerSummaryReportForOwner',
  },
  {
    text: 'liveAgentPlayerReport',
    key: 'livePlayerSummaryReportForParent',
    component: LiveAgentPlayerReportForParent,
    icon: <Poll />,
    path: '/livePlayerSummaryReportForParent',
  },
  // Chicken Fight Report
  {
    text: 'liveSSCockFightReport',
    key: 'liveSSCockFightReportForAdmin',
    component: LiveSSCockFightReportForAdmin,
    icon: <Poll />,
    path: '/liveSSCockFightReportForAdmin',
  },
  {
    text: 'liveSSCockFightReport',
    key: 'liveSSCockFightReportForOwner',
    component: LiveSSCockFightReportForOwner,
    icon: <Poll />,
    path: '/liveSSCockFightReportForOwner',
  },
  {
    text: 'liveSSCockFightReport',
    key: 'liveSSCockFightReportForParent',
    component: LiveSSCockFightReportForParent,
    icon: <Poll />,
    path: '/liveSSCockFightReportForParent',
  },
  // 注單查詢
  {
    text: 'livePlayerOrder',
    key: 'livePlayerOrderForAdmin',
    component: LivePlayerOrderForAdmin,
    icon: <Poll />,
    path: '/livePlayerOrderForAdmin',
  },
  {
    text: 'livePlayerOrder',
    key: 'livePlayerOrderForOwner',
    component: LivePlayerOrderForOwner,
    icon: <Poll />,
    path: '/livePlayerOrderForOwner',
  },
  {
    text: 'livePlayerOrder',
    key: 'livePlayerOrderForParent',
    component: LivePlayerOrderForParent,
    icon: <Poll />,
    path: '/livePlayerOrderForParent',
  },
  {
    text: 'livePlayerDonate',
    key: 'livePlayerDonateForAdmin',
    component: LivePlayerDonate,
    icon: <Poll />,
    path: '/livePlayerDonateForAdmin',
  },
  {
    text: 'livePlayerDonate',
    key: 'livePlayerDonateForOwner',
    component: LivePlayerDonate,
    icon: <Poll />,
    path: '/livePlayerDonateForOwner',
  },
  {
    text: 'livePlayerDonate',
    key: 'livePlayerDonateForParent',
    component: LivePlayerDonate,
    icon: <Poll />,
    path: '/livePlayerDonateForParent',
  },
  {
    text: 'liveTwoSideBet',
    key: 'liveTwoSideBetForAdmin',
    component: LiveTwoSideBet,
    icon: <Poll />,
    path: '/liveTwoSideBetForAdmin',
  },
  {
    text: 'liveTwoSideBet',
    key: 'liveTwoSideBetForOwner',
    component: LiveTwoSideBet,
    icon: <Poll />,
    path: '/liveTwoSideBetForOwner',
  },
  {
    text: 'liveTwoSideBet',
    key: 'liveTwoSideBetForParent',
    component: LiveTwoSideBet,
    icon: <Poll />,
    path: '/liveTwoSideBetForParent',
  },
  // ------
  {
    text: 'liveRiskAssessment',
    key: 'liveRiskAssessmentForAdmin',
    component: LiveRiskAssessmentForAdmin,
    icon: <Poll />,
    path: '/LiveRiskAssessmentForAdmin',
  },
  {
    text: 'liveRiskAssessment',
    key: 'liveRiskAssessmentForOwner',
    component: LiveRiskAssessmentForOwner,
    icon: <Poll />,
    path: '/LiveRiskAssessmentForOwner',
  },
  {
    text: 'liveRiskAssessment',
    key: 'liveRiskAssessmentForParent',
    component: LiveRiskAssessmentForParent,
    icon: <Poll />,
    path: '/LiveRiskAssessmentForParent',
  },
  // ------
  {
    text: 'ssSpecial',
    key: 'ssSpecial',
    menu: true,
  },
]

export default features
