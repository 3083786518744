import { withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import actions from '../../actions'
import DataTable from '../../components/tables/DataTable'
import RTPSettingForm from './form/RTPSettingForm'
import language from '../../locale/agentRTPSetting'
import {
  getTimeZone, getUserInfo,
} from '../../reducers'
import { api } from '../../services'
import SearchRTPForm from './form/SearchRTPForm'
import { getSavedState } from '../../helpers/storage'
import withStyles from '@material-ui/core/styles/withStyles'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import React, { useEffect, useRef, useState } from 'react'

const styles = theme => ({
  btn: {
    marginRight: 5,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
})

const AgentRTPSetting = ({ userInfo, i18n, t, timezone }) => {
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [pagination, setPagination] = useState()
  const [nowPage, setNowPage] = useState(1)
  const oldData = useRef({})
  const [searchData, setSearchData] = useState([])

  const dispatch = useDispatch()

  const handleRTPSettingSubmit = async (values) => {
    try {
      const { player, rtp } = values

      const res = await api.post(api.BO, '/rtp', {
        ownerid: userInfo.ownerid,
        parentid: userInfo.userid,
        account: player,
        rtp,
      })

      const { data: { status, data: { notfound } } } = res

      if (status?.code === '0') {
        dispatch(
          actions.msgs.add({
            msg: notfound ? `${notfound} ${t('noExist')}` : t('success'),
            variant: 'info',
          }),
        )
      }
    } catch (e) {
      const { data: { status } } = e

      dispatch(
        actions.msgs.add({
          msg: status?.message,
          variant: 'warning',
        }),
      )
    }
  }

  const handleSearchRTPSubmit = async (values) => {
    try {
      const { first, ...others } = values
      if (first) setNowPage(1)
      const { player } = values
      const res = await api.get(api.BO, '/rtp', {
        params: {
          timezone,
          parentid: userInfo.userid,
          account: player,
          pagesize: rowsPerPage,
          nowpage: first ? 1 : nowPage,
        },
      })
      oldData.current = others
      const { data: { data: { list, pagination } } } = res
      setPagination(pagination)
      setSearchData(list.map(({ account, rtp }) => [
        account,
        rtp,
      ]))
    } catch (err) {
      if (!err.data.data) setSearchData([])
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && t(err.data.status.message),
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }

  const handleChangeRowsPerPage = rowsPrePage => {
    setNowPage(1)
    setRowsPerPage(rowsPrePage)
  }

  const handleChangePage = page => {
    setNowPage(page + 1)
  }

  useEffect(() => {
    handleSearchRTPSubmit(oldData.current)
  }, [rowsPerPage, nowPage])

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRTPSetting', language.en)
    i18n.addResourceBundle('cn', 'agentRTPSetting', language.cn)
  }, [])

  useEffect(() => {
    handleSearchRTPSubmit(oldData.current)
  }, [timezone])

  return (
    <Container>
      <GridItemFull>
        <Paper style={{ marginBottom: 12 }}>
          <RTPSettingForm
            player={[]}
            rtp={95}
            onSubmit={handleRTPSettingSubmit}
          />
        </Paper>
        <Paper>
          <SearchRTPForm
            onSubmit={handleSearchRTPSubmit}
          />
          <DataTable
            data={searchData}
            columns={[{ name: t('account') }, { name: 'RTP' }]}
            pagination={pagination}
            options={{
              rowsPerPage,
              onChangePage: handleChangePage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
            }}
          />
        </Paper>
      </GridItemFull>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const userInfo = getUserInfo(state)
  return {
    timezone,
    userInfo,
  }
}

export default compose(
  connect(
    mapStateToProps,
  ),
  withTranslation('agentRTPSetting'),
  withStyles(styles),
)(AgentRTPSetting)
