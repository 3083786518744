import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'
import { mapSiteFeatures } from '../containers/App/helpers'
import { getTree } from '../helpers/getTree'
import appFeatures from '../containers/App/features'
/**
 * Effect to fetch site feature
 */
export function * fetch () {
  try {
    // 1. Fetch site feature request started
    yield put(actions.siteFeature.fetchRequest())

    // 2. Call API: fetch site feature
    const res = yield call(api.get, api.RP, 'features?serviceName=bo')

    // 若沒有資料 當作取得features失敗處理
    if (!res.data || !res.data.data || !res.data.data.length) throw new Error('')

    // 3. Fetch site feature success
    const items = res.data.data

    const routes = mapSiteFeatures(items, appFeatures)
    const menu = getTree(routes)
    yield put(actions.siteFeature.fetchSuccess({
      items,
      mappedItems: { routes, menu },
    }))
  } catch (err) {
    // Fetch site feature failure
    yield put(actions.siteFeature.fetchFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.siteFeature.fetch, fetch),
]

export default effects
