import React, { } from 'react'
import { IconButton } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const SPACING_BASE = 48

const useStyle = makeStyles({
  lineExpand: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 2,
      height: 72,
      position: 'absolute',
      top: -36,
      left: 24,
      backgroundColor: '#6d6d6d',
    },
    '&:after': {
      content: '""',
      height: 2,
      width: 28,
      position: 'absolute',
      top: -1,
      left: 24,
      backgroundColor: '#6d6d6d',
    },
  },
  lineExpandLast: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 2,
      height: 36,
      position: 'absolute',
      top: -36,
      left: 24,
      backgroundColor: '#6d6d6d',
    },
    '&:after': {
      content: '""',
      height: 2,
      width: 28,
      position: 'absolute',
      top: -1,
      left: 24,
      backgroundColor: '#6d6d6d',
    },
  },
})

const ExpandController = ({
  hasExpand = false,
  expand = false,
  onClick = () => {},
  hasLine = false,
  isLastOne = false,
}) => {
  const classes = useStyle()
  return hasExpand
    ? (
      <IconButton
        style={{
          transform: `rotate(${expand ? 0 : -90}deg)`,
          transition: 'transform 0.15s',
        }}
        onClick={onClick}
      >
        <ExpandMore/>
      </IconButton>
    )
    : <div
      className={ hasLine
        ? (isLastOne
          ? classes.lineExpandLast
          : classes.lineExpand
        )
        : null
      }
      style={{ width: SPACING_BASE }}
    />
}

ExpandController.propTypes = {
  hasExpand: PropTypes.bool,
  expand: PropTypes.bool,
  onClick: PropTypes.func,
  hasLine: PropTypes.bool,
}

export default ExpandController
