import { call, put, takeLatest } from 'redux-saga/effects'
import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch rate list
 */
export function * fetchRateList ({ payload }) {
  const { userId } = payload
  const userid = userId ? `userid=${userId}` : ''
  try {
    // 1. Fetch rate request started
    yield put(actions.rate.fetchRequest())

    // 2. Call API: fetch rate
    const res = yield call(api.get, api.BO, `/ratelist?${userid}`)

    // 3. Fetch rate success
    yield put(actions.rate.fetchSuccess({ response: res.data.data }))
  } catch (err) {
    // Fetch rate failure
    yield put(actions.rate.fetchFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.rate.fetch, fetchRateList),
]

export default effects
