import { call, put, select, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'
import { getIsTeamFeaturesFetching } from '../reducers'

/**
 * Effect to fetch team feature
 */
export function * fetch ({ payload: { id } }) {
  try {
    // 1. Fetch team feature request started
    yield put(actions.teamFeature.fetchRequest({ teamId: id }))

    // 2. Call API: fetch team feature
    const res = yield call(api.get, api.RP, `/teams/${id}/features?serviceName=bo`)

    // 3. Fetch team feature success
    yield put(actions.teamFeature.fetchSuccess({ response: res.data.data, teamId: id }))
  } catch (err) {
    // Fetch team feature failure
    yield put(actions.teamFeature.fetchFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update team feature
 */
export function * update ({ payload: { selectedTeam: { id: teamId }, feature: { allocated, id: featureId } } }) {
  try {
    // 1. Check is fetching
    if (yield select(getIsTeamFeaturesFetching, teamId)) {
      return
    }

    // 2. Update team feature request started
    yield put(actions.teamFeature.updateRequest())

    const method = allocated ? api.DELETE : api.post
    const url = allocated ? `/teams/${teamId}/features/${featureId}` : `/teams/${teamId}/features`

    // 3. Call API: update team feature
    const res = yield call(method, api.RP, url, { featureId })

    // 4. Update team feature success
    yield put(actions.teamFeature.updateSuccess({ response: res.data.data }))

    // 5. Fetch team feature to update view
    yield call(fetch, { payload: { id: teamId } })
  } catch (err) {
    // Update team feature failure
    yield put(actions.teamFeature.updateFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.teamFeature.fetch, fetch),
  takeLatest(actionType.teamFeature.update, update),
]

export default effects
