import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'
import Debug from './Debug'

import SelectTextField from '../text-fields/SelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    playerAcc: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {
      console.error(error)
    }

    setSubmitting(false)
  },
  displayName: 'PlayerTransactionForParentForm',
})

const PlayerTransactionForParentForm = props => {
  const {
    isSubmitting,
    classes,
    actionOptions,
    t,
    isFetching,
    dateRange,
  } = props

  const fields = [
    [
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
        specialrange: !!props.specialRange,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'action',
        label: t('action'),
        fullWidth: true,
        options: actionOptions().map(item => ({
          ...item,
          label: t(item.label),
        })),
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

      {process.env.REACT_APP_DEBUG === 'true' && <Debug />}
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('playerTransaction')(
    withStyles(styles)(PlayerTransactionForParentForm),
  ),
)
