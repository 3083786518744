export const mapSiteFeatures = (siteFeatures, appFeatures) => {
  const array = []
  appFeatures.forEach(item => {
    const isMatch = siteFeatures.find(canItem => canItem.key === item.key)
    if (isMatch) {
      array.push({ ...item, ...isMatch })
    }
  })
  return array
}
