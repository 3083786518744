import React, { useEffect, useState } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { useCSVReader } from 'react-papaparse'
import { useDispatch } from 'react-redux'
import actions from '../../../actions'
import language from '../../../locale/agentRedRain'
import Dialog from '../../../components/Dialogs/Slide'
import { withTranslation } from 'react-i18next'
import DataTable from '../../../components/tables/DataTable'
import * as Yup from 'yup'
import CreatableSelectField from '../../../components/fields/CreatableSelectField'
import { renderFieldsWithGridItem } from '../../../components/forms/helper'
import GridContainer from '../../../components/grids/GridContainer'
import Input from '@material-ui/core/Input'
import { InlineDatePicker } from 'material-ui-pickers'
import { formatISO, isBefore, startOfDay, isValid, endOfDay, parseISO } from 'date-fns'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  input: {
    height: 8,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  browserBtn: {
    backgroundColor: '#66BB6A',
    fontSize: 14,
    fontWeight: 500,
    padding: '6px 16px',
    borderRadius: 4,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  fileName: {
    color: '#fff',
    fontSize: 14,
    margin: '0 8px',
  },
  removeBtn: {
    backgroundColor: '#f44336',
    color: '#fff',
    padding: '4px 12px',
    fontWeight: 500,
    borderRadius: 4,
    border: 'none',
    cursor: 'pointer',
  },
  container: {
    width: 'fit-content',
    margin: '8px 0',
  },
  progressBar: {
    width: '100%',
    margin: '4px 0',
  },
  distrubuteBtn: {
    backgroundColor: '#66BB6A',
    fontSize: 14,
    fontWeight: 500,
    padding: '6px 16px',
    borderRadius: 4,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
  },
})

// 紅包派發條件: kill=打死魚才給(預設值)，bet=下注馬上給，enter=進遊戲馬上給
const constraintsMap = {
  kill: '打死鱼才给',
  bet: '下注马上给',
  enter: '进游戏马上给',
}
const defaultConstraint = 'kill'
// rule for api sending, constraint for local state
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    player: Yup.array().min(1, 'required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      const dateArr = values.player.map(player => {
        const date = endOfDay(new Date(values.date[player]))
        return formatISO(date)
      })
      const amountArr = values.player.map(player => values.amounts[player])
      const constraintArr = values.player.map(player => values.rule[player])

      const res = await props.onSubmit({
        player: values.player,
        amount: amountArr,
        totalamount: values.amount === 0 ? undefined : values.amount,
        expiredtime: dateArr,
        rule: constraintArr,
      })
      resetForm()
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'AgentRedRainForm',
  enableReinitialize: true,
})

const RedSettingForm = (props) => {
  const dispatch = useDispatch()
  const { CSVReader } = useCSVReader()
  const [amountWithPlayer, setAmountWithPlayer] = useState({})
  const [dateWithPlayer, setDateWithPlayer] = useState({})
  const [constraintWithPlayer, setConstraintWithPlayer] = useState({})

  const {
    isSubmitting,
    classes,
    t,
    isFetching,
    setFieldValue,
    values,
    amountHistory,
    i18n,
  } = props

  const handleInputChange = (player) => (e) => {
    const value = e.target.value
    const amountArr = JSON.parse(JSON.stringify(amountWithPlayer))
    const regex = /^[0-9]*(\.[0-9]+)?$/ // This regex matches valid numbers

    if (!regex.test(value)) return

    amountArr[player] = Number(value)
    setAmountWithPlayer((prev) => ({
      ...prev,
      [player]: Number(value),
    }))
  }

  const handleDateChange = (player) => (date) => {
    const isoDate = parseISO(date)
    if (isBefore(startOfDay(isoDate), startOfDay(new Date()))) return
    const dateArr = JSON.parse(JSON.stringify(dateWithPlayer))

    dateArr[player] = date
    setDateWithPlayer((prev) => ({
      ...prev,
      [player]: date,
    }))
  }

  const handleConstraintChange = (player) => (e) => {
    const constraintArr = JSON.parse(JSON.stringify(constraintWithPlayer))

    constraintArr[player] = e.target.value
    setConstraintWithPlayer((prev) => ({
      ...prev,
      [player]: e.target.value,
    }))
  }

  const manualFields = [
    [
      {
        Field: CreatableSelectField,
        name: 'player',
        label: t('account'),
        isMulti: true,
        placeholder: t('inputAccount'),
        isClearable: true,
        noOptionsMessage: () => t('inputAccountPressEnter'),
        fullWidth: true,
        onChange: (value) => {
          setFieldValue('player', (value || []).map((v) => v.value))
          const newAmountData = {}
          const newDateData = {}
          const newConstraintData = {}
          value.forEach((v) => {
            if (amountWithPlayer[v.value] !== undefined) {
              newAmountData[v.value] = amountWithPlayer[v.value]
            } else {
              newAmountData[v.value] = 0
            }
          })
          value.forEach((v) => {
            if (dateWithPlayer[v.value] !== undefined) {
              newDateData[v.value] = dateWithPlayer[v.value]
            } else {
              newDateData[v.value] = new Date()
            }
          })
          value.forEach((v) => {
            if (constraintWithPlayer[v.value] !== undefined) {
              newConstraintData[v.value] = constraintWithPlayer[v.value]
            } else {
              newConstraintData[v.value] = defaultConstraint
            }
          })
          setAmountWithPlayer(newAmountData)
          setDateWithPlayer(newDateData)
          setConstraintWithPlayer(newConstraintData)
        },
      },
    ],
  ]

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRedRain', language.en)
    i18n.addResourceBundle('cn', 'agentRedRain', language.cn)
  }, [])

  useEffect(() => {
    setFieldValue('amounts', amountWithPlayer)
    setFieldValue('date', dateWithPlayer)
    setFieldValue('rule', constraintWithPlayer)
  }, [dateWithPlayer, amountWithPlayer, constraintWithPlayer])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(manualFields[0], props)}
      </GridContainer>
      <CSVReader
        onUploadAccepted={(results) => {
          try {
            const nPlayer = []
            const playerAmount = {}
            const playerDate = {}
            const playerConstraint = {}
            const regex = /^[0-9]*(\.[0-9]+)?$/

            results.data.forEach(([player, amount, date, constraint]) => {
              if (!player) return
              if (player) nPlayer.push(player)
              if (regex.test(amount) && parseFloat(amount) > 0) {
                playerAmount[player] = Math.floor(Number(amount))
              } else {
                playerAmount[player] = 0
              }

              if (date && isValid(new Date(date))) {
                playerDate[player] = new Date(date)
              } else {
                playerDate[player] = new Date()
              }

              if (Object.keys(constraintsMap).includes(constraint)) {
                playerConstraint[player] = constraint
              } else {
                playerConstraint[player] = defaultConstraint
              }
            })

            setFieldValue('player', nPlayer)
            setAmountWithPlayer(playerAmount)
            setDateWithPlayer(playerDate)
            setConstraintWithPlayer(playerConstraint)
          } catch (e) {
            console.error(e)
            dispatch(
              actions.msgs.add({
                msg: t('formatError'),
                variant: 'warning',
              }),
            )
          }
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }) => {
          return (
            <div className={classes.container}>
              <div className={classes.root}>
                <button type='button' {...getRootProps()} className={classes.browserBtn}>
                  {t('import')}
                </button>
                {
                  acceptedFile && (
                    <>
                      {acceptedFile.name && (
                        <div className={classes.fileName}>
                          {acceptedFile.name}
                        </div>
                      )}
                      <button {...getRemoveFileProps()} className={classes.removeBtn}>
                        {t('delete')}
                      </button>
                    </>
                  )
                }
              </div>
              <ProgressBar className={classes.progressBar} />
            </div>
          )
        }}
      </CSVReader>
      <DataTable
        data={values.player.map(el =>
          [el,
            <Input
              classes={{ input: classes.input }}
              size="small" value={amountWithPlayer[el]}
              error={!amountWithPlayer[el]}
              onChange={handleInputChange(el)} />,
            <InlineDatePicker
              name='date picker'
              value={dateWithPlayer[el]}
              format="yyyy/MM/dd"
              onChange={handleDateChange(el)}
              error={isBefore(startOfDay(parseISO(dateWithPlayer[el])), startOfDay(new Date()))}
              minDate={new Date()}
              ampm={undefined}
            />,
            <Select
              value={constraintWithPlayer[el]}
              onChange={handleConstraintChange(el)}
              inputProps={{
                name: 'constraint',
                id: 'constraint-input',
              }}
            >
              {Object.entries(constraintsMap).map(([key, displayName], index) => <MenuItem key={index} value={key}>{displayName}</MenuItem>)}
            </Select>,
          ])}
        columns={[{ name: t('account') }, { name: t('amount') }, { name: t('invalidDate') }, { name: t('constraint') }]}
        options={{
          rowsPerPage: 30,
        }}
      />
      <div style={{ color: '#fff', marginTop: 8 }}>{`${t('totalAmount')}: ${Object.values(amountWithPlayer).reduce((acc, cur) => acc + Number(cur), 0)}`}</div>
      <Dialog
        variant="common"
        isSubmitting={isSubmitting}
        actionType='positive'
        disabled={
          Object.values(amountWithPlayer).findIndex(value => value === 0) !== -1 ||
          Object.values(amountWithPlayer).length === 0 ||
          Object.values(dateWithPlayer).length === 0 ||
          Object.values(dateWithPlayer).some(value => isBefore(startOfDay(parseISO(value)), startOfDay(new Date())))
        }

        text={{
          confirm: t('send'),
          dialogTitle: t('redPacketAmountSetting'),
          dialogDescription: t('confirmToSetRedPacketAmount'),
          dialogDisagree: t('cancel'),
          dialogAgree: t('apply'),
        }}
        handleSubmit={props.handleSubmit}
        handleOpenDialog={openDialog => {
          // 開啟 Dialog 之前先進行 input 驗證，這樣操作體驗比較好
          props.validateForm().then(value => {
            // 若欄位驗證錯誤則提示錯誤訊息
            if (Object.keys(value).length > 0) {
              props.setTouched({
                player: !!value.player,
              })
              return
            }

            openDialog()
          })
        }}
      />
      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
      <DataTable
        data={Object.entries(amountHistory).map(([key, [amount, date, constraint]]) => [key, amount, date, constraint])}
        columns={[{ name: t('account') }, { name: t('amount') }, { name: t('invalidDate') }, { name: t('constraint') }]}
        options={{
          rowsPerPage: 30,
        }}
      />
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('agentRedRain')(withStyles(styles)(RedSettingForm)),
)
