import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to get brand info
 */
export function * getBrandInfo () {
  try {
    // 1. Call API: get brand info
    const res = yield call(api.get, api.BO, '/brand')

    // 2. Get brand info success
    yield put(actions.brandInfo.success({ response: res.data.data }))
  } catch (err) {
    // Get brand info failure
    yield put(actions.brandInfo.failure())
    // Announce API error
    yield put(actions.msgs.add({
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.brandInfo.get, getBrandInfo),
]

export default effects
