import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import RadioTextField from '../text-fields/RadioTextField'
import SelectTextField from '../text-fields/SelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    playerAcc: Yup.string().required('required'),
    agentAcc: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'LottoAwardedOrderForAdminForm',
})

const LottoAwardedOrderForAdminForm = props => {
  const {
    isSubmitting,
    classes,
    filterGamesOptions,
    t,
    isFetching,
    brand,
    dateRange,
  } = props

  const fields = [
    [
      {
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'roundid',
        label: t('roundid'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'gamehall',
        label: t('gamehall'),
        fullWidth: true,
        options: [
          { label: t('all'), value: 'all' },
          { label: t(brand), value: brand },
        ],
      },
      {
        Field: SelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        options: filterGamesOptions(),
        isClearable: true,
      },
      {
        Field: RadioTextField,
        name: 'gametype',
        label: t('gametype'),
        fullWidth: true,
        options: [
          { label: t('all'), value: 'all' },
          { label: t('sport'), value: 'sport' },
          { label: t('lotto'), value: 'lotto' },
        ],
        isClearable: true,
      },
    ],
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
        specialrange: !!props.specialRange,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'sortKey',
        label: t('sort'),
        fullWidth: true,
        options: [
          { label: t('bettime'), value: 'bettime' },
          { label: t('finaltime'), value: 'createtime' },
        ],
      },
      {
        Field: RadioTextField,
        name: 'sortValue',
        label: t('sort'),
        fullWidth: true,
        options: [
          { label: t('asc'), value: 'asc' },
          { label: t('desc'), value: 'desc' },
        ],
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withTranslation('playerOrder')(withStyles(styles)(LottoAwardedOrderForAdminForm)),
)
