import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import a from '../../actions'
import { qsParse } from '../../helpers'
import { getCustomerServiceById, getTimeZone, getTheme } from '../../reducers'
import language from '../../locale/userProfile'
import { getSavedState } from '../../helpers/storage'
import { DARK_MODE } from '../../themes/bo'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import CustomerServiceDetailForm from '../../components/forms/CustomerServiceDetailForm'
import ChangePasswordForm from '../../components/forms/ChangePasswordForm'

class CustomerServiceDetail extends Component {
  state = { show: 0, isEditing: false };

  componentDidMount () {
    const {
      customerServiceId,
      show,
      fetchCustomerServiceDetail,
      i18n,
    } = this.props

    i18n.addResourceBundle('en', 'userProfile', language.en)
    i18n.addResourceBundle('cn', 'userProfile', language.cn)

    this.setState({ show })

    fetchCustomerServiceDetail({ customerServiceId })
  }

  handleChangeTabs = (_, value) => {
    const { history } = this.props

    this.setState({ show: value })

    const location = {
      state: { show: value },
    }

    history.replace(location)
  };

  handleEditing = () => {
    this.setState({ isEditing: true })
  };

  handleCancel = () => {
    this.setState({ isEditing: false })
  };

  onSubmitProfile = values => {
    return this.props.updateCustomerServiceStatus(values, {
      cb: () => {
        this.handleCancel()
      },
    })
  };

  onSubmitChangePassword = values => {
    const { updateCustomerServicePassword } = this.props

    if (values.password === values.confirmPassword) {
      return updateCustomerServicePassword(values)
    }
  };

  render () {
    const { customerService, t, timezone, themeMode } = this.props
    const { show, isEditing } = this.state

    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            style={ themeMode === DARK_MODE ? { backgroundColor: '#585858' } : {} }
            value={show}
            onChange={this.handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('detail')} />
            <Tab label={t('changePwd')} />
          </Tabs>
        </AppBar>

        <Container>
          <GridItem form>
            <Paper>
              {customerService && customerService.data && customerService.data[0] && show === 0 && (
                <CustomerServiceDetailForm
                  userid={customerService.data[0].userid}
                  account={customerService.data[0].account}
                  signUpAt={ moment(customerService.data[0].signuptime * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss') }
                  loginAt={
                    customerService.data[0].lastlogin === 0
                      ? t('neverLogin')
                      : moment(customerService.data[0].lastlogin * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss')
                  }
                  status={customerService.data[0].status}
                  onSubmit={this.onSubmitProfile}
                  handleEditing={this.handleEditing}
                  handleCancel={this.handleCancel}
                  isEditing={() => isEditing}
                />
              )}

              {customerService && customerService.data && customerService.data[0] && show === 1 && (
                <ChangePasswordForm
                  account={customerService.data[0].account}
                  password=""
                  confirmPassword=""
                  onSubmit={this.onSubmitChangePassword}
                />
              )}
            </Paper>
          </GridItem>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, { location, match }) => {
  const search = location ? qsParse(location.search) : null
  const customerServiceId = match.params.customerServiceId
  const customerService = getCustomerServiceById(state, customerServiceId)
  const show = location.state && location.state.show ? location.state.show : 0
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const themeMode = getTheme(state)

  return {
    themeMode,
    customerServiceId,
    customerService:
      typeof customerService === 'undefined' ? null : customerService,
    search,
    show,
    timezone,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchCustomerServiceDetail: a.customerService.fetchCustomerServiceDetail,
    updateCustomerServiceStatus: a.customerService.updateCustomerServiceStatus,
    updateCustomerServicePassword: a.customerService.updateCustomerServicePassword,
  },
)(withTranslation('userProfile')(CustomerServiceDetail))
