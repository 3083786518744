import React, { Component } from 'react'
import { Form } from 'formik'
import FormPaper from '../../components/FormPaper'
import RadioTextField from '../../components/text-fields/RadioTextField'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'

class Debug extends Component {
  render () {
    return (
      <Container>
        <GridItemFull>
          <FormPaper
            initialValues={{
              currencySign: 'true',
            }}
            render={() => {
              return (
                <Form>
                  <RadioTextField
                    label="幣別符號"
                    name="currencySign"
                    helperText="遊戲中是否顯示幣別符號"
                    inputProps={{
                      options: [
                        { label: '顯示', value: 'true' },
                        { label: '不顯示', value: 'false' },
                      ],
                    }}
                  />
                </Form>
              )
            }}
          />
        </GridItemFull>
      </Container>
    )
  }
}

export default Debug
