const rawData = {
  noMatch: ['Sorry, no matching records found', '没有找到匹配的资料'],
  datetime: ['Date', '日期'],
  parentid: ['Agent Account', '代理帐号'],
  ownerAcc: ['Owner Account', '总代理帐号'],
  parentAcc: ['Parent Account', '代理帐号'],
  gamehall: ['Game Hall', '游戏厂商'],
  gametype: ['Game Type', '游戏类型'],
  gamename: ['Game Name', '游戏名称'],
  gamecode: ['Game Name', '游戏名称'],
  genre: ['Genre', '游戏项目'],
  currency: ['Currency', '币别'],
  bets: ['Bet', '总投注额'],
  wins: ['Win', '总赢分'],
  jackpots: ['Jackpot', '总彩池'],
  validbet: ['Valid bet', '有效投注额'],
  roomfee: ['Room fee', '开房费'],
  parentincome: ['Agent Profit', '代理损益金额'],
  rtp: ['RTP', '返还率'],
  playertraffics: ['Player Flow', '会员流量'],
  totalround: ['Total Game Round', '总游戏场次'],
  betavg: ['Average Bets', '平均投注额'],
  tableBets: ['Total Bet', '总押注'],
  validbets: ['Total Valid bets', '总有效投注额'],
  tableWins: ['Total Win', '总输赢'],
  rakes: ['Total Rake', '总抽水'],
  groupby: ['Settlement Interval', '结算周期'],
  none: ['None', '无'],
  hour: ['Hour', '时'],
  day: ['Day', '日'],
  month: ['Month', '月'],
  year: ['Year', '年'],
  all: ['All', '全部'],
  cq9: ['CQ9', 'CQ9'],
  ae: ['AE', 'AE'],
  gameType: ['Game Type', '游戏类型'],
  allGame: ['Probability Game', '机率游戏'],
  table: ['Table Battle', '牌桌对战'],
  slot: ['Slot', '老虎机'],
  fish: ['Fish', '渔机'],
  arcade: ['Arcade', '街机'],
  sport: ['Sport Game', '体育游戏'],
  lotto: ['Lotto Game', '彩票游戏'],

  tableType: ['Game Name', '真人游戏名称'],
  tableId: ['Table No', '桌号'],

  required: ['This is a required field.', '此为必填栏位'],

  // 免费券
  yes: ['Yes', '是'],
  no: ['No', '否'],
  freeTicket: ['Free Ticket', '免费券'],
  freeTicketRecord: ['Free Ticket Record', '免费券纪录'],
  tickettype: ['Free Ticket Type', '免费券种类'],
  giventype: ['Free Ticket Source', '免费券来源'],
  freeGameTicket: ['Free Game Ticket', 'Free Game 券'],
  freeSpinTicket: ['Free Spin Ticket', 'Free Spin 券'],
  eventGiveAway: ['Event Give Away', '活动赠送'],
  agentGiveAway: ['Agent Give Away', '代理赠送'],
  treasureGiveAway: ['Treasure Give Away', '宝箱赠送'],
  buyFromShop: ['Buy from shop', '商城购买'],
  ticketId: ['Free Ticket Id', '免费券编号'],
  ticketDetail: ['Free Ticket Detail', '免费券明细'],
  ticketbets: ['Total Ticket Bets', '免费券总底注'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
