import React, { useEffect, useCallback } from 'react'
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core'
import { BELIMITCOLUMNS } from '../fakeData'
import classNames from 'classnames'
import useStyles from './BetLimitForm.style'

const BetLimitForm = (props) => {
  const {
    t,
    disabled,
    rows,
    ceilings,
    currency,
    setBetitems,
    isSubmittable,
    setIsSubmittable,
  } = props
  const classes = useStyles()

  const convertToNumber = (input) => {
    const removeNoneDigitalCharaterAndLeadingZero = input.replace(/[^0-9.]+/g, '').replace(/^[0]*/, '') // replace none-digit, leading zero
    const dotIndex = removeNoneDigitalCharaterAndLeadingZero.indexOf('.') // find first dot
    const dot = dotIndex === -1 ? '' : '.' // check if number contains dot
    let counter = 0
    input.split('').forEach(dot => (dot === '.' ? counter++ : counter))

    // check if . is the only value
    const removeDots = removeNoneDigitalCharaterAndLeadingZero.replace(/[.]/g, '')
    if (dot === '.' && removeDots === '') return '0.'

    // check if only have one . and at the last position
    if (input[input.length - 1] === '.' && counter === 1) return `${removeNoneDigitalCharaterAndLeadingZero}`

    const start = removeDots.slice(0, dotIndex)
    const end = removeDots.slice(dotIndex) === '' ? '0' : removeDots.slice(dotIndex)
    const results = `${start}${dot}${end}`

    return Number(results[0] === '.' ? `0${results.slice()}` : results)
  }

  const handleChange = useCallback(
    (bettype, key) => (e) => {
      const newValue = e.target.value
      setBetitems((prev) => {
        const temp = prev.map(obj => ({ ...obj }))
        const selectedIndex = temp.findIndex(item => item.bettype === bettype)
        if (temp[selectedIndex].bettype === 'playerbonus') {
          const bankerbonusIndex = temp.findIndex((item) => item.bettype === 'bankerbonus')
          // playerbonus 和 bankerbonus 的欄位是一樣的，所以在前端做合併處理。
          temp[selectedIndex][key] = convertToNumber(newValue)
          temp[bankerbonusIndex][key] = convertToNumber(newValue)
          return temp
        }
        temp[selectedIndex][key] = convertToNumber(newValue)
        return temp
      })
    },
    [setBetitems])

  /**
   * Check for：
   * min < max
   * min > default
   * max < default
   * */

  useEffect(() => {
    setIsSubmittable({
      default: rows.every(item => item.max > item.min),
      floor: rows.every(item => {
        const index = ceilings.findIndex(ceiling => ceiling.bettype === item.bettype)
        return index === -1 ? true : ceilings[index].min <= item.min
      }),
      ceiling: rows.every(item => {
        const index = ceilings.findIndex(ceiling => ceiling.bettype === item.bettype)
        return index === -1 ? true : ceilings[index].max >= item.max
      }),
      productOfPointFive: rows.every(item => item.min % 0.5 === 0 && item.max % 0.5 === 0),
    })
  }, [rows, ceilings, setIsSubmittable])

  return (
    <div className={classes.wrapper}>
      <div>
        <Typography variant="subtitle1" gutterBottom>
          {currency}
        </Typography>
        {!isSubmittable.default && (
          <Typography variant="subtitle1" color="error" gutterBottom>
            {t('minEqualOrGreaterThanMax')}
          </Typography>
        )}
        {!isSubmittable.floor && (
          <Typography variant="subtitle1" color="error" gutterBottom>
            {t('minLesserThanDefaultMin')}
          </Typography>
        )}
        {!isSubmittable.ceiling && (
          <Typography variant="subtitle1" color="error" gutterBottom>
            {t('maxGreaterThanDefaultMax')}
          </Typography>
        )}
        {!isSubmittable.productOfPointFive && (
          <Typography variant="subtitle1" color="error" gutterBottom>
            {t('mustBeProductOfPointFive')}
          </Typography>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {BELIMITCOLUMNS.map((column) => (
                <TableCell
                  key={column}
                  align="center"
                  className={classes.headerCell}>
                  {t(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              // 防止betitems和ceilingitems沒對到或是總數不對
              const ceilingIndex = ceilings.findIndex(ceiling => row.bettype === ceiling.bettype)
              const minError =
                ceilingIndex === -1
                  ? false
                  : row.min < ceilings[ceilingIndex].min ||
                    row.min >= ceilings[ceilingIndex].max ||
                    row.min >= row.max

              const maxError =
                ceilingIndex === -1
                  ? false
                  : row.max > ceilings[ceilingIndex].max
              const minNumError = row.min % 0.5 !== 0
              const maxNumError = row.max % 0.5 !== 0

              // 因playerbonus和bankerbonus同一欄位，所以合併做處理。
              if (row.bettype === 'bankerbonus') return null
              if (!row.isshow) return null
              return (
                <TableRow key={row.bettype} id={row.bettype}>
                  <TableCell
                    align="center"
                    className={classNames(classes.bettype, classes.border)}>
                    {t(row.bettype)}
                  </TableCell>
                  <TableCell align="center" className={classes.border}>
                    <TextField
                      variant="outlined"
                      disabled={!disabled}
                      align="center"
                      inputProps={{ autoComplete: 'off', min: 0 }}
                      className={classNames(
                        classes.input,
                        (minNumError && classes.outOfBound) ||
                        (minError && classes.outOfBound),
                        !disabled && classes.borderDisabled,
                      )}
                      value={row.min}
                      onChange={handleChange(row.bettype, 'min')}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.border}>
                    <TextField
                      variant="outlined"
                      disabled={!disabled}
                      align="center"
                      inputProps={{ autoComplete: 'off', min: 0 }}
                      className={classNames(
                        classes.input,
                        (maxNumError && classes.outOfBound) ||
                        (maxError && classes.outOfBound),
                        !disabled && classes.borderDisabled,
                      )}
                      value={row.max}
                      onChange={handleChange(row.bettype, 'max')}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default BetLimitForm
