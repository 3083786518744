import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, withFormik } from 'formik'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'
import Debug from './Debug'
import MultiSelectTextField from '../text-fields/MultiSelectTextField'
import SelectTextField from '../../components/text-fields/SelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'
import { getLiveTableIdOption } from '../../reducers'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
  },
})

const formikEnhancer = withFormik({
  validationSchema: ({ routePage }) => Yup.object().shape({
    agentAcc: routePage === 'liveTwoSideBetForAdmin' ? Yup.string().required('required') : Yup.string(),
    tabletype: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({
        agentAcc: values.agentAcc,
        playerAcc: values.playerAcc,
        tableid: values.tableid,
        roundnumber: values.roundnumber,
        startDate: values.startDate,
        endDate: values.endDate,
        ip: values.ip,
        pagesize: values.pagesize,
        nowpage: values.nowpage,
        tabletype: values.tabletype,
      })
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'LiveTwoSideBetForm',
})

const LiveTwoSideBetForm = props => {
  const {
    routePage,
    isSubmitting,
    classes,
    t,
    isFetching,
    setFieldValue,
    dateRange,
    tableIdAllOptions,
  } = props

  const tableTypeOption = [
    {
      label: t('baccarat'),
      value: 'baccarat',
    },
    {
      label: t('marble'),
      value: 'marble',
    },
  ]

  const fields = [
    [
      {
        Field: OutlinedTextField,
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'tableid',
        label: t('tableId'),
        fullWidth: true,
        isMulti: true,
        isClearable: true,
        closeMenuOnSelect: false,
        options: tableIdAllOptions,
        onChange: (value) => setFieldValue('tableid', (value || []).map((v) => v.value)),
      },
      {
        Field: OutlinedTextField,
        name: 'roundnumber',
        label: t('roundNumber'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    ],
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
      },
    ],
    [
      {
        Field: OutlinedTextField,
        name: 'ip',
        label: 'IP',
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      {
        Field: SelectTextField,
        name: 'tabletype',
        label: t('tabletype'),
        fullWidth: true,
        options: tableTypeOption,
        onChange: (value) => setFieldValue('tabletype', value.value),
      },
    ],
  ]
  if (routePage !== 'liveTwoSideBetForParent') {
    fields[0] = [
      {
        Field: OutlinedTextField,
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      ...fields[0],
    ]
  }

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>
      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
      {process.env.REACT_APP_DEBUG === 'true' && <Debug />}
    </Form>
  )
}

const mapStateToProps = (state) => {
  const tableIdAllOptions = getLiveTableIdOption(state)
  const dateRange = state.order?.liveorder ? `${state.order.liveorder}day` : '1day'
  return {
    tableIdAllOptions,
    dateRange,
  }
}

export default connect(mapStateToProps)(formikEnhancer(
  withTranslation('liveTwoSideBet')(withStyles(styles)(LiveTwoSideBetForm)),
))
