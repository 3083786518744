const rawData = {
  apply: ['Search', '搜索'],
  edit: ['Edit', '编辑'],
  save: ['Save', '确认'],
  cancel: ['Cancel', '取消'],
  copy: ['Copy', '复制'],
  quickSetup: ['Quick Setup', '快速设定'],
  noDataFound: ['No Data Found', '无取得资料'],
  agentId: ['Agent ID', '代理帐号'],
  playerId: ['Player ID', '玩家帐号'],
  contactAgent: ['* Please contact your upper agent to turn on the unavailable table', '* 请联络你的上级代理将不可用的游戏桌开启'],
  customizedChips: ['Customized Chips (Please select 12 chips)', '自订筹码（请选择12个筹码)'],
  defaultChips: ['Default Chips (Please select 6 chips)', '基本筹码（请选择6个筹码)'],
  lessThanTwelveChips: ['Less than 12 chips', '少于12个筹码'],
  moreThanTwelveChips: ['Greater than 12 chips', '超过12个筹码'],
  lessThanSixChips: ['Less than 6 chips', '少于6个筹码'],
  moreThanSixChips: ['Greater than 6 chips', '超过6个筹码'],
  tableId: ['Table ID', '游戏桌'],
  fieldEmpty: ['* The fields cannot be empty', '* 游戏桌不得空白'],
  requiredField: ['This field is required', '此栏位为必填'],
  noTableOpen: ['ALL TABLES ARE DISABLE. PLEASE ENTER THE PAGE OF [TABLE SETTING] TO ENABLE IT.', '所有游戏桌均未开启，请先至 [游戏桌设定] 开启。'],
  minEqualOrGreaterThanMax: ['* The Minimum value cannot be equal nor greater than the maximum value', '* 最小值不能等于或超过最大值'],
  minLesserThanDefaultMin: ['* The Minimum value cannot be lesser than the default minimum value', '* 最小值不能小于预设最小值'],
  maxGreaterThanDefaultMax: ['* The Maximum value cannot be greater than the default maximum value', '* 最大值不能超过预设最大值'],
  mustBeProductOfPointFive: ['* The value must be the product of 0.5', '* 数值只能是0.5的倍数'],
  successfullyModified: ['Successfully modified', '修改成功'],

  ownerSwitch: ['Owner Switch', '总代理开关'],
  switch: ['Switch', '开关'],
  settingClosed: ['* Setting is closed, please contact upper agent, * 設定已關閉，請通知上層代理'],

  // 限额设定
  bettype: ['BET TYPE', '投注种类'],
  min: ['MIN', '最小值'],
  max: ['MAX', '最大值'],
  player: ['PLAYER', '闲家'],
  banker: ['BANKER', '庄家'],
  tie: ['TIE', '和局'],
  super6: ['SUPER 6', '超级6'],
  playerpair: ['P PAIR', '闲一对'],
  bankerpair: ['B PAIR', '庄一对'],
  perfectpair: ['PERFECT PAIR', '完美对子'],
  eitherpair: ['EITHER PAIR', '任一对子'],
  playerbonus: ['P/B BONUS', '闲龙宝/庄龙宝'],
  playerover: ['P.Over', 'P.Over'],
  playerunder: ['P.Under', 'P.Under'],
  bankerover: ['B.Over', 'B.Over'],
  bankerunder: ['B.Under', 'B.Under'],
  over: ['Over', 'Over'],
  under: ['Under', 'Under'],
  win: ['WIN', '當局勝出'],
  '16top4': ['16TOP4', '16強賽-晉4強'],
  '16top2': ['16TOP2', '16強賽-晉決賽'],
  '16top': ['16TOP', '16強賽-冠軍'],
  '8top2': ['8TOP2', '8強賽-晉決賽'],
  '8top': ['8TOP', '8強賽-冠軍'],
  '4top': ['4TOP', '4強賽-冠軍'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
