import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const createReport = filter => {
  return (state = {}, action = {}) => {
    if (action.payload && action.payload.filter !== filter) {
      return state
    }
    switch (action.type) {
      case actionType.report.fetchSuccess:
        return action.payload.response
      case actionType.report.fetchFailure:
        return {}
      default:
        return state
    }
  }
}

const isFetching = filter => {
  return (state = false, action = {}) => {
    if (action.payload && action.payload.filter !== filter) {
      return state
    }
    switch (action.type) {
      case actionType.report.fetchRequest:
        return true
      case actionType.report.fetchSuccess:
      case actionType.report.fetchFailure:
        return false
      default:
        return state
    }
  }
}

export const Create = filter => {
  return combineReducers({ reports: createReport(filter), isFetching: isFetching(filter) })
}

export const getReport = state => state.reports
export const getIsFetching = state => state.isFetching

export default Create
