export const GAME_TYPES = {
  en: [
    { label: 'arcade', value: 'arcade' },
    { label: 'fish', value: 'fish' },
    { label: 'slot', value: 'slot' },
    { label: 'table', value: 'table' },
    // { label: 'live', value: 'live' },
    // { label: 'sport', value: 'sport' },
    // { label: 'lottery', value: 'lottery' },
  ],
  cn: [
    { label: '街机', value: 'arcade' },
    { label: '渔机', value: 'fish' },
    { label: '老虎机', value: 'slot' },
    { label: '牌桌对战', value: 'table' },
    // { label: '真人游戏', value: 'live' },
    // { label: '体育游戏', value: 'sport' },
    // { label: '彩票游戏', value: 'lottery' },
  ],
}

export const roundAnalysisOption = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
]
export const appearCountOption = [
  { label: '1', value: '1' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
]
