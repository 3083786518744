import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'

import { withTranslation } from 'react-i18next'
import language from '../locale/appBar'
import moment from 'moment'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textColor: {
    color: '#ffffff',
  },
  popper: {
    zIndex: theme.zIndex.drawer + 1,
  },
})

class Timezone extends React.Component {
  constructor (props) {
    super(props)

    const { timezone } = props

    this.state = {
      anchorEl: null,
      time: moment().utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.resetTime = this.resetTime.bind(this)
  }

  handleClick = event => {
    const { currentTarget } = event
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }))
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  };

  handleSwitch = timezone => {
    this.props.handleSetTimezone(timezone)
    this.resetTime(timezone)
  };

  resetTime (value) {
    const { timezone } = this.props

    this.setState({
      time: moment().utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  componentDidMount () {
    const { i18n } = this.props
    i18n.addResourceBundle('en', 'appBar', language.en)
    i18n.addResourceBundle('cn', 'appBar', language.cn)

    this.intervalId = setInterval(this.resetTime, 1000)
  }

  componentWillUnmount () {
    clearInterval(this.intervalId)
  }

  render () {
    const { classes, timezone, t, theme } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <Button
          aria-owns={open ? 'timezone-popover' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.textColor}
        >
          {`${this.state.time} (UTC${timezone >= 0 ? '+' : ''}${timezone})`}
        </Button>
        <Popper
          id="timezone-popover"
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleClick}>
              <div>
                <Button
                  variant={timezone === -4 ? 'contained' : 'outlined'}
                  color={theme.customer.AppBar.TimeZone.ButtonColor}
                  className={classes.button}
                  onClick={() => {
                    this.handleSwitch(-4)
                  }}
                >
                  UTC-4 { t('europeLondon') }
                </Button>
                <Button
                  variant={timezone === 0 ? 'contained' : 'outlined'}
                  color={theme.customer.AppBar.TimeZone.ButtonColor}
                  className={classes.button}
                  onClick={() => {
                    this.handleSwitch(0)
                  }}
                >
                  UTC+0 { t('americaNewYork') }
                </Button>
                <Button
                  variant={timezone === 8 ? 'contained' : 'outlined'}
                  color={theme.customer.AppBar.TimeZone.ButtonColor}
                  className={classes.button}
                  onClick={() => {
                    this.handleSwitch(8)
                  }}
                  text={t('asiaShanhai')}
                >
                  UTC+8 <span>{ t('asiaShanhai') }</span>
                </Button>
              </div>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    )
  }
}

Timezone.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTranslation('appBar')(withStyles(styles, { withTheme: true })(Timezone))
