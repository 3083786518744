import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to update client password
 */
export function * updatePassword ({ payload }) {
  try {
    // 1. Update client password request started
    yield put(actions.client.updatePasswordRequest())

    // 2. Call API: update client password
    const res = yield call(api.post, api.BO, '/pwd', payload)

    // 3. Update client password success
    yield put(actions.client.updatePasswordSuccess({ response: res.data.data }))

    // 4. Announce update client password success
    yield put(actions.msgs.add({
      replaceWithI18nText: 'pwdChangeSuccess',
      variant: 'success',
    }))
  } catch (err) {
    // Update client password failure
    yield put(actions.client.updatePasswordFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.client.updatePassword, updatePassword),
]

export default effects
