/* eslint-disable react/prop-types, react/jsx-handler-names */
import React, { useEffect, useCallback } from 'react'
import classNames from 'classnames'
import Select from 'react-select'
import { Typography, NoSsr, Paper, Chip, MenuItem, Checkbox } from '@material-ui/core/'
import useStyles from './EnhancedMultiSelect.style'
import { useTheme } from '@material-ui/styles'

const XMultiHorizontalSelect = ({
  filter = false,
  grid = false,
  disabled = false,
  name = '',
  label = '',
  errorMessage = '',
  options = [],
  values = [],
  renderOption = null,
  requiredLength = 0,
  setFieldValue = () => {},
  setValues = () => {},
  ...others
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const convertChipsFormat = (array) => array.map((chip) => ({ label: chip, value: chip }))
  const convertBackToArray = (data) => data.map((item) => item.value)

  const handleSelectedValues = useCallback(
    (values) => {
      setFieldValue(
        name,
        values.map((value) => value.value),
      )
      setValues((prev) => values.sort((A, B) => A.value - B.value))
    },
    [name, setFieldValue, setValues],
  )

  const removeValuesBasedOnOptions = useCallback(
    (values) => {
      const temp = convertBackToArray(values)
        .map((value) => (convertBackToArray(options).includes(value) ? value : null))
        .filter((item) => item !== null)
      return convertChipsFormat(temp)
    },
    [options],
  )

  useEffect(() => {
    if (filter && options) {
      setValues(removeValuesBasedOnOptions(values))
      setFieldValue(removeValuesBasedOnOptions(values))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, options, setValues, setFieldValue, removeValuesBasedOnOptions])

  return (
    <div className={classes.root}>
      <NoSsr>
        <Typography color="textSecondary" inline>
          {label}{' '}
        </Typography>
        <Typography color="error" inline>
          {' '}
          {requiredLength > values.length && `* ${errorMessage[0]}`} {requiredLength < values.length && `* ${errorMessage[1]}`}
        </Typography>
        <Select
          isMulti
          grid={grid}
          name={name}
          options={options}
          value={values}
          onChange={handleSelectedValues}
          isDisabled={disabled}
          classes={classes}
          styles={{
            input: (base) => ({
              ...base,
              color: theme.palette.text.primary,
              '& input': {
                font: 'inherit',
              },
            }),
          }}
          textFieldProps={{
            label: label,
            InputLabelProps: {
              shrink: true,
            },
          }}
          components={{
            Menu,
            MultiValue,
            NoOptionsMessage,
            Option: renderOption !== null ? renderOption : Option,
            ValueContainer,
          }}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          placeholder="Select multiple options"
          {...others}
        />
      </NoSsr>
    </div>
  )
}

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}
// Options Container
function Menu(props) {
  return (
    <Paper
      square
      className={classNames(props.selectProps.classes.paper, props.selectProps.grid && props.selectProps.classes.grid)}
      {...props.innerProps}>
      {props.children}
    </Paper>
  )
}
// Each Option
function Option(props) {
  const classes = useStyles()
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        minWidth: '80px',
      }}
      {...props.innerProps}>
      <div className={classes.checkbox}>
        <Checkbox disabled={props.disabled} checked={props.isSelected} color="primary" />
        <div>{props.label}</div>
      </div>
    </MenuItem>
  )
}
// Chips
function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      style={{ borderRadius: '8px' }}
      // onDelete={props.removeProps.onClick}
      // deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}
function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

export default XMultiHorizontalSelect
