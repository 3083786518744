import React from 'react'
import { Field } from 'formik'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress } from '@material-ui/core'

const styles = theme => ({
  valueHidden: {
    visibility: 'hidden',
  },
})

function TextComponent ({
  variant = 'outlined',
  margin = 'normal',
  InputLabelProps = {},
  classes,
  field,
  form,
  InputProps,
  isClearable,
  loading,
  ...props
}) {
  return (
    <TextField
      variant={variant}
      margin={margin}
      autoComplete="off"
      InputLabelProps={InputLabelProps}
      InputProps={{
        classes: { input: loading && classes.valueHidden },
        margin: 'dense',
        startAdornment:
          loading && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ position: 'absolute', top: '13px', left: '14px' }} />,
        endAdornment:
          field && field.value && isClearable
            ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    form.setFieldValue(field.name, '')
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
            : null,
        ...InputProps,
      }}
      {...field}
      {...props}
    />
  )
}

function OutlinedTextField (props) {
  return <Field {...props} component={TextComponent} />
}

export default withStyles(styles)(OutlinedTextField)
