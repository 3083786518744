import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSavedState, saveState } from '../../helpers/storage'

export const AppDrawerContext = createContext({})

const AppDrawerProvider = ({ list = [], extra = {}, children }) => {
  const [menuState, setMenuState] = useState({})
  const [bookmarkList, setBookmarkList] = useState([])

  const [init, setInit] = useState(false)
  const user = useSelector(s => s.auth.user)

  const isAnyMenuOpen = useMemo(() => Object.values(menuState).includes(true), [menuState])

  // COMPUTED: get details with bookmarks
  const fullBookmarkList = useMemo(() => {
    if (!list.length) return []
    const flatList = list.reduce((res, item) => {
      res.push(item)
      if (item.children) res.push(...item.children)
      return res
    }, [])
    return bookmarkList.map(key => flatList.find(item => item.key === key) || {})
  }, [bookmarkList, list])

  // INIT: sync storage data
  useEffect(() => {
    const account = user?.account
    if (!account || !list.length) return

    // 1: 設定 Menu 狀態
    const savedMenuState = getSavedState('menuState')?.[account]
    setMenuState(list.reduce((res, item) => {
      // ?? mean undefined is true
      res[item.key] = savedMenuState?.[item.key] ?? true
      return res
    }, { bookmark: savedMenuState?.bookmark ?? true }))

    // 2: 設定 Bookmark 狀態
    const savedBookmarkList = getSavedState('bookmark')?.[account] || []
    setBookmarkList(savedBookmarkList)

    setInit(true)
  }, [user, list, setMenuState, setBookmarkList])

  // save menu open state in localStorage
  useEffect(() => {
    const account = user?.account
    if (!account || !Object.keys(menuState).length) return
    saveState('menuState', { ...getSavedState('menuState'), [account]: menuState })
  }, [user, menuState])

  // save bookmark open state in localStorage
  useEffect(() => {
    const account = user?.account
    if (!account || !init) return
    saveState('bookmark', { ...getSavedState('bookmark'), [account]: bookmarkList })
  }, [user, bookmarkList, init])

  return (
    <AppDrawerContext.Provider
      value={{
        ...extra,
        list,
        menuState,
        setMenuState,
        bookmarkList,
        setBookmarkList,
        isAnyMenuOpen,
        fullBookmarkList,
      }}
      children={children}
    />
  )
}

export default AppDrawerProvider
