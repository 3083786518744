import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  constructor (props) {
    super(props)

    this.ref = React.createRef()
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onScroll()
    }

    if (
      this.props.scroll.status !== prevProps.scroll.status &&
      this.props.scroll.status === true
    ) {
      this.onScroll(this.props.scroll.offset)
    }
  }

  onScroll = offset => {
    // 內部測試時出現 undefined 問題，暫時註解
    // this.ref.current.scrollTo({ top: offset, behavior: 'smooth' });
    this.props.onScroll()
  };

  render () {
    return (
      <div
        ref={this.ref}
        style={{
          position: 'relative',
          flexGrow: 1,
          top: 48,
          height: 'calc(100vh - 48px)',
          overflow: 'auto',
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(ScrollToTop)
