const rawData = {
  brand: ['Brand', '品牌'],
  account: ['Account', '帐号'],
  password: ['Password', '密码'],
  nickname: ['Nickname', '昵称'],
  commission: ['Commission(％)', '抽佣(％)'],
  currency: ['Currency', '币别'],
  currencySign: ['Display Currency Sign in Game ?', '游戏中是否显示币别符号'],
  show: ['Display', '显示'],
  notShow: ['Hidden', '不显示'],
  walletType: [
    'Wallet Type (Can Not Change After Created)',
    '钱包类型 (新增后不能修改钱包类型)',
  ],
  w: ['Transfer Wallet', '转帐钱包'],
  sw: ['Seamless Wallet', '单一(无缝)钱包'],
  ce: ['Crytocurrency Exchange', '虚拟币钱包'],
  wurl: ['Wallet Url (Domain Name)', '钱包网址 (Domain Name)'],
  wtoken: ['Token (Optional)', '金钥 (选填)'],

  stationaccount: ['Station Account', '站长帐号'],
  stationpwd: ['Station Password', '站长密码'],

  createAgent: ['Create Agent', '新增代理'],
  create: ['Create', '新增'],

  testAgent: ['Test Agent', '测试帐号'],

  // input error,
  warning: ['Password must be a combination of 8 to 16 numbers and letters ( - or _ is allow ).', '密码需为 8〜16 码英文，数字组合 ( 可含 - or _ )'],
  leastEight: ['Password must be at least 8 characters.', '密码最少 8 码'],
  mostSixteen: ['Password must be at most 16 characters.', '密码最多 16 码'],
  required: ['This is a required field.', '此为必填栏位'],
  mustNumber: ['Must be a `number` type. ', '必须为数字。'],
  moreThenZero: ['Must be greater than or equal to 0.', '需要大于 0。'],
  lessThenEqualHundred: ['Must be less than or equal to 100.', '需要小于或等于 100'],
  mustUrl: ['Must be a valid URL', '必须是有效的网址。'],
  accountAndStationaccountDiff: ['Account and station account must be different', '帐号和站长帐号必须不一样'],

}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
