import actionType from '../../actions/actionType'

const defaultBrand = {
  brand: '',
}

const brandInfo = (state = defaultBrand, action = {}) => {
  switch (action.type) {
    case actionType.brandInfo.success:
      return { ...state, ...action.payload.response }
    default:
      return state
  }
}

export default brandInfo

export const getBrandInfo = state => state.brandInfo
