// 代理列表（owner）
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import {
  getUser,
  getCurrencyOptions,
  getReportByFilter,
  getAgents,
  getLanguage,
  getTimeZone,
  getIsFetchingAgents,
} from '../../reducers'
import a from '../../actions'
import { qsParse } from '../../helpers'
import language from '../../locale/agentList'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import MoreButton from '../../components/MoreButton'
import AgentListForAdminForm from '../../components/forms/AgentListForAdminForm'
import DataTable from '../../components/tables/DataTable'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
  iconButton: {
    height: 24,
    width: 24,
    padding: 0,
  },
  currency: {
    cursor: 'pointer',
  },
})

class AgentList extends Component {
  state = {
    rowsPerPage: 30,
    columns: [],
    payload: null,
    formatReports: [],
    needFetchOnMount: true,
    isSearched: false,
  }

  componentDidMount () {
    const { i18n } = this.props
    i18n.addResourceBundle('en', 'agentList', language.en)
    i18n.addResourceBundle('cn', 'agentList', language.cn)

    this.setColumns()

    this.fetchReport()
    this.setState({ isSearched: true })
  }

  componentDidUpdate (props, prevState) {
    const {
      language,
      reports = {},
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (reports.data && (props.reports !== reports || props.timezone !== timezone)) {
      this.setFormatReports(reports.data)
    }

    if (props.reports !== reports) {
      this.setReportPagination(reports.pagination)
    }

    if (props.language !== language) {
      this.setColumns()
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('agentAcc'),
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({
              style: {
                wordWrap: 'break-word',
                maxWidth: '20vw',
              },
            }),
          },
        },
        {
          name: t('brand'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('status'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => (
              <div className={`status ${value}`}>{t(value)}</div>
            ),
          },
        },
        {
          name: t('testAgent'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => (
              <div className={`status ${value}`}>
                {t(value, { ns: 'common' })}
              </div>
            ),
          },
        },
        {
          name: t('signUpAt'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => moment(value.time * 1000).utcOffset(value.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('currencySign'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => {
              return value ? t('show') : t('notShow')
            },
          },
        },
        {
          name: t('wt'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => {
              if (value.wallettype === 'single') {
                return value.newsw ? <span>{t('swV2')}</span> : <span>{t('sw')}</span>
              } else if (value.wallettype === 'transfer') {
                return <span>{t('w')}</span>
              } else if (value.wallettype === 'ce') {
                return <span>{t('ce')}</span>
              }
            },
          },
        },
        {
          name: t('more'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => {
              return (
                <MoreButton
                  items={[
                    {
                      label: t('detail'),
                      to: {
                        pathname: `/agentDetail/${value.userid}`,
                        state: { show: 0 },
                      },
                    },
                    {
                      label: t('changePwd'),
                      to: {
                        pathname: `/agentDetail/${value.userid}`,
                        state: { show: 1 },
                      },
                    },
                  ]}
                />
              )
            },
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    const { timezone } = this.props

    this.setState({
      formatReports: data.map(item => [
        item.account,
        item.brand,
        item.status,
        item.isTestSS,
        { time: item.signuptime, timezone },
        item.currency,
        item.currencySign,
        item,
        item,
      ]),
    })
  };

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
    }

    return this.onSubmit(payload)
  };

  fetchReport = payload => {
    this.props.fetchAgents({ ...payload })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true })

    return this.fetchReport(payload)
  }

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) { }
  };

  render () {
    const { t, filterAgentAccount, isFetching, currencyOptions } = this.props
    const { columns, formatReports, rowsPerPage, isSearched, reportPagination } = this.state

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <AgentListForAdminForm
              account={filterAgentAccount}
              pagesize={rowsPerPage}
              nowpage={1}
              onSubmit={this.handleSubmit}
              isFetching={isFetching}
              currencyOptions={() => currencyOptions}
            />
          </Paper>
        </GridItemFull>
        {isSearched &&
          <GridItemFull>
            <DataTable
              title={t('titleTable')}
              data={formatReports}
              columns={columns}
              pagination={reportPagination}
              options={{
                rowsPerPage,
                onChangePage: this.handleChangePage,
                onChangeRowsPerPage: this.handleChangeRowsPerPage,
              }}
            />
          </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const user = getUser(state)
  const agents = getAgents(state)
  const language = getLanguage(state)
  const currencyOptions = getCurrencyOptions(state)
  const reports = getReportByFilter(state, 'agentList')

  const filterAgentAccount = search.agent ? search.agent : ''
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingAgents(state)

  return {
    filterAgentAccount,
    currencyOptions,
    agents,
    user,
    search,
    language,
    reports,
    timezone,
    isFetching,
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      fetchAgents: a.agent.fetchAgentList,
    },
  )(withTranslation('agentList')(AgentList)),
)
