import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

import language from '../../locale/freeTicketReportSummary'
import Paper from '../Paper'
import GridItemFull from '../grids/GridItemFull'
import GridItem from '../grids/GridItem'
import GridContainer from '../grids/GridContainer'
import { LIGHT_MODE } from '../../themes/bo'
import { formatMoney } from '../../helpers'

const styles = theme => ({
  row: {
    paddingTop: theme.spacing.unit * 0.8,
    paddingBottom: theme.spacing.unit * 0.8,
  },
  title: {
    fontWeight: 500,
    color: theme.customer.name === LIGHT_MODE ? grey[700] : '#94e185',
  },
  text: {
    fontWeight: 500,
    // color: grey[500]
  },
  balance: {
    //
  },
  danger: {
    color: red[500],
  },
})

class FreeTicketReportSummaryCard extends Component {
  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'freeTicketReportSummary', language.en)
    i18n.addResourceBundle('cn', 'freeTicketReportSummary', language.cn)
  }

  render () {
    const { data, classes, t } = this.props

    return (
      <Paper><GridItemFull className={classes.row}>
        <Typography variant="subtitle1" gutterBottom>
          {t('FREETICKETTOTAL')}
        </Typography>
      </GridItemFull>
      {data.map(groupData => (
        <GridItemFull key={groupData.name} className={classes.row}>
          <GridContainer alignItems="center">
            <GridItem fluid>
              <Typography variant="subtitle1" gutterBottom>
                {t(groupData.name)}
              </Typography>
            </GridItem>
            <GridItem fluid>
              <GridContainer>
                {groupData.data.map(item => {
                  return (
                    <GridItem fluid key={item.key}>
                      <Typography
                        align="right"
                        variant="subtitle1"
                        className={classes.title}
                      >
                        {t(item.key)}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="right"
                        className={`${classes.text} ${
                          item.value < 0 ? classes.danger : classes.balance
                        }`}
                      >
                        {
                          item.key === 'totalgamelogin' || item.key === 'totalrounds' || item.key === ' totalwins'
                            ? (<NumberFormat
                              value={item.value}
                              displayType={'text'}
                              thousandSeparator={true}
                            />)
                            : (<NumberFormat
                              value={formatMoney(item.value)}
                              displayType={'text'}
                              thousandSeparator={true}
                            />)
                        }
                      </Typography>
                    </GridItem>
                  )
                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItemFull>
      ))}
      </Paper>
    )
  }
}

export default withTranslation('freeTicketReportSummary')(
  withStyles(styles)(FreeTicketReportSummaryCard),
)
