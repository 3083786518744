import React, { Fragment } from 'react'
import { Collapse, Divider, List, Tooltip } from '@material-ui/core'

import DragHandleIcon from '@material-ui/icons/DragHandle'
import ClearAllIcon from '@material-ui/icons/ClearAll'

import { Star } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { ListItemCollapseHead, ListItemLink } from './listItem'
import { push } from 'connected-react-router'
import useDrawer from './hook'

const DrawerBase = () => {
  const { menuState, bookmarkList, fullBookmarkList, list, toggleAllMenu, toggleSingleMenu, classes, isAnyMenuOpen, t } = useDrawer()
  const dispatch = useDispatch()
  const location = useSelector(s => s.router.location)
  return (
    <>
      <div className={classes.iconsGroup}>
        <Tooltip title={isAnyMenuOpen ? t('closeAll') : t('openAll')}>
          <div style={{ cursor: 'pointer', display: 'flex', margin: 'auto 0' }} onClick={toggleAllMenu}>
            { isAnyMenuOpen
              ? <ClearAllIcon color="action" fontSize="small" />
              : <DragHandleIcon color="action" fontSize="small" />
            }
          </div>
        </Tooltip>
      </div>
      { !!bookmarkList.length && (
        <List>
          <ListItemCollapseHead
            title={t('bookmark')}
            onClick={() => toggleSingleMenu('bookmark')}
            icon={<Star color="action" />}
            open={menuState.bookmark}
          />
          <Collapse in={menuState.bookmark} timeout="auto">
            { fullBookmarkList.map(({ text, icon, path, key }) =>
              <ListItemLink
                title={t(text)}
                icon={icon}
                key={key}
                id={key}
                onClick={() => dispatch(push({ pathname: path }))}
                nested
                isActive={location.pathname === path}
              />,
            )}
          </Collapse>
        </List>
      )}
      <List>
        { list.map(({ text, icon, path, children, key, root }) => {
          return (
            <Fragment key={key} >
              {root && <Divider/>}
              { children.length
                ? (
                  <>
                    <ListItemCollapseHead
                      open={menuState[key]}
                      title={t(text)}
                      onClick={() => toggleSingleMenu(key)}
                    />
                    <Collapse in={menuState[key]} timeout="auto">
                      <List disablePadding>
                        { children.map(({ text, icon, path, key }) => (
                          <ListItemLink
                            id={key}
                            key={key}
                            title={t(text)}
                            icon={icon}
                            onClick={() => dispatch(push({ pathname: path }))}
                            isActive={location.pathname === path}
                            nested
                            withBookmark
                          />
                        ))
                        }
                      </List>
                    </Collapse>
                  </>
                )
                : (
                  <ListItemLink
                    id={key}
                    title={t(text)}
                    icon={icon}
                    onClick={() => dispatch(push({ pathname: path }))}
                    isActive={location.pathname === path}
                    withBookmark
                  />
                )
              }
            </Fragment>
          )
        })}
      </List>
    </>
  )
}

export default DrawerBase
