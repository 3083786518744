import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import SelectTextField from '../text-fields/SelectTextField'
import RadioTextField from '../text-fields/RadioTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    agentAcc: Yup.string().required('required'),
    playerAcc: Yup.string().when('fuzzySearch', {
      is: 'true',
      then: Yup.string()
        .required('required')
        .min(4, 'playerAccMinimum'),
    }),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'AgentPlayerFreeTicketReportFormForAdmin',
})

const AgentPlayerFreeTicketReportFormForAdmin = props => {
  const {
    isSubmitting,
    classes,
    filterGamesOptions,
    t,
    isFetching,
    brand,
  } = props

  const fields = [
    [
      {
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'fuzzySearch',
        label: t('playerAccSearchMethod'),
        fullWidth: true,
        options: [
          { label: t('exactSearch'), value: 'false' },
          { label: t('fuzzySearch'), value: 'true' },
        ],
      },
      {
        Field: SelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        options: filterGamesOptions(),
        isClearable: true,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'groupby',
        label: t('groupby'),
        fullWidth: true,
        options: [
          { label: t('hour'), value: 'hour' },
          { label: t('day'), value: 'day' },
          { label: t('month'), value: 'month' },
          { label: t('year'), value: 'year' },
        ],
      },
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'gameType',
        label: t('gameType'),
        fullWidth: true,
        doubleWidth: true,
        options: [
          { label: t('allGame'), value: 'all' },
          { label: t('slot'), value: 'slot' },
          { label: t('fish'), value: 'fish' },
          { label: t('arcade'), value: 'arcade' },
        ],
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'tickettype',
        label: t('tickettype'),
        fullWidth: true,
        options: [
          { label: t('freeGameTicket'), value: '1' },
          { label: t('freeSpinTicket'), value: '2' },
        ],
        isClearable: true,
      },
      {
        Field: SelectTextField,
        name: 'giventype',
        label: t('giventype'),
        fullWidth: true,
        options: [
          { label: t('eventGiveAway'), value: '1' },
          { label: t('agentGiveAway'), value: '101' },
          { label: t('treasureGiveAway'), value: '111' },
          { label: t('buyFromShop'), value: '112' },
        ],
        isClearable: true,
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[4], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(
    withTranslation('agentPlayerFreeTicketReport')(AgentPlayerFreeTicketReportFormForAdmin),
  ),
)
