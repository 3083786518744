const rawData = {
  accountid: ['Account ID', '玩家ID'],
  account: ['Account', '玩家帐号'],
  inputAccount: ['Input Account', '填入玩家帐号'],
  inputAccountPressEnter: ['Input Account Press Enter/TAB', '填入玩家帐号后按 ENTER/TAB'],
  formatError: ['Format error', '格式错误'],
  import: ['Import', '汇入'],
  delete: ['Delete', '删除'],
  send: ['Send', '送出'],
  search: ['Search', '搜寻'],
  required: ['Required', '必填'],
  success: ['Success', '成功'],
  noExist: ['not Exist', '不存在'],
  'Data not found': ['Data not found', '查无资料'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
