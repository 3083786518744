import React from 'react'
import GridItem from './GridItem'

const GridItemFull = ({ children, sticky, ...props }) => (
  <GridItem item sticky={sticky} xs={12} sm={12} md={12} lg={12} xl={12} {...props}>
    {children}
  </GridItem>
)

export default GridItemFull
