import React from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from '../../components/forms/helper'
import GridContainer from '../../components/grids/GridContainer'
import DateRangePickerTextField from '../../components/text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'PlayerListForAdminAndOwnerForm',
})

const AgentSumForm = props => {
  const {
    isSubmitting,
    classes,
    t,
    buttonRef,
    isFetching,
  } = props

  const fields = [
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        range: '3day',
        enableTimeSelector: true,
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>{renderFieldsWithGridItem(fields[0], props)}</GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
        buttonRef={buttonRef}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(
    withTranslation('agentSum')(AgentSumForm),
  ),
)
