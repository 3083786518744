import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const items = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.customerService.fetchCustomerServicesSuccess:
      return action.payload.response
    case actionType.customerService.fetchCustomerServicesFailure:
    default:
      return state
  }
}

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.customerService.fetchCustomerServicesRequest:
      return true
    case actionType.customerService.fetchCustomerServicesSuccess:
    case actionType.customerService.fetchCustomerServicesFailure:
      return false
    default:
      return state
  }
}

const byId = (state = {}, action = {}) => {
  switch (action.type) {
    case actionType.customerService.fetchCustomerServiceDetailSuccess:
      return { ...state, [action.payload.customerServiceId]: action.payload.response }
    default:
      return state
  }
}

export default combineReducers({
  items,
  isFetching,
  byId,
})

export const getCustomerServices = state => state.items
export const getCustomerServiceById = (state, id) => state.byId[id]
export const getIsFetching = state => state.isFetching
