import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SelectList from './SelectList'

const styles = {
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}

function SelectCard (props) {
  const { title = '', classes, items, handleSelect, match, selected } = props

  return (
    <Card >
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {title}
        </Typography>

        <SelectList
          onClick={handleSelect}
          items={items}
          match={match}
          selected={selected}
        />
      </CardContent>
    </Card>
  )
}

SelectCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectCard)
