import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import RadioTextField from '../text-fields/RadioTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    parentAcc: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'PlayerListForAdminAndOwnerForm',
})

const PlayerListForAdminAndOwnerForm = props => {
  const {
    isSubmitting,
    classes,
    t,
    buttonRef,
    isFetching,
  } = props

  const fields = [
    [
      {
        name: 'parentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'sortKey',
        label: t('sort'),
        fullWidth: true,
        options: [
          { label: t('signUpAt'), value: 'createat' },
          { label: t('loginAt'), value: 'lastlogintime' },
        ],
      },
      {
        Field: RadioTextField,
        name: 'sortValue',
        label: t('sort'),
        fullWidth: true,
        options: [
          { label: t('asc'), value: 'asc' },
          { label: t('desc'), value: 'desc' },
        ],
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>{renderFieldsWithGridItem(fields[0], props)}</GridContainer>
      <GridContainer>{renderFieldsWithGridItem(fields[1], props)}</GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
        buttonRef={buttonRef}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(
    withTranslation('playerList')(PlayerListForAdminAndOwnerForm),
  ),
)
