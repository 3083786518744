import React, { Component } from 'react'
import Paper from '../Paper'
import { Formik } from 'formik'

class FormPaper extends Component {
  render () {
    return (
      <Paper>
        <Formik {...this.props} />
      </Paper>
    )
  }
}

export default FormPaper
