import React, { forwardRef } from 'react'
import { Form, Formik } from 'formik'
import Typography from '@material-ui/core/Typography'
import GridContainer from '../../../components/grids/GridContainer'
import GridItemFull from '../../../components/grids/GridItemFull'
import EnhancedMultiSelect from '../../../components/fields/EnhancedMultiSelect/EnhancedMultiSelect'
import { api } from '../../../services'
import { useDispatch } from 'react-redux'
import actions from '../../../actions'

const ChipsForm = forwardRef((props, ref) => {
  const {
    t,
    disabled,
    setIsEdit,
    ssid,
    tabletype,
    currency,
    globalallchips,
    chipamounts,
    setChipamounts,
    current,
    setCurrent,
    setData,
    convertChipsFormat,
    idRef,
  } = props
  const dispatch = useDispatch()
  const convertBackToArray = (data) => data.map((item) => item.value)

  return (
    <Formik
      ref={ref}
      initialValues={{
        chipamounts: convertBackToArray(chipamounts),
        current: convertBackToArray(current),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const res = await api.post(api.BO2, '/live/gk/chip', {
            ssid: ssid,
            tabletype: tabletype,
            currency: currency,
            chipamounts: convertBackToArray(chipamounts),
            current: convertBackToArray(current),
          })
          if (res.data?.status?.code === '0') {
            setSubmitting(false)
            setIsEdit(false)
            const res = await api.get(api.BO, `/live/gk/chip?ssaccount=${idRef}`)
            if (res.data?.status?.code === '0') {
              setData(res.data.data || {})
              setChipamounts(convertChipsFormat(res.data.data.chipamounts) || [])
              setCurrent(convertChipsFormat(res.data.data.current) || [])
            }
            dispatch(
              actions.msgs.add({
                replaceWithI18nText: 'modifySuccess',
                variant: 'success',
              }),
            )
          }
        } catch (error) {
          console.error(error)
        }
      }}>
      {({ setFieldValue, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItemFull>
                <Typography variant="h6">{currency}</Typography>
                <div>
                  <EnhancedMultiSelect
                    grid
                    disabled={disabled}
                    name="chipamounts"
                    label={t('customizedChips')}
                    options={globalallchips}
                    values={chipamounts}
                    setFieldValue={setFieldValue}
                    setValues={setChipamounts}
                    requiredLength={12}
                    errorMessage={[t('lessThanTwelveChips'), t('moreThanTwelveChips')]}
                  />
                  <br />
                  <EnhancedMultiSelect
                    filter
                    grid
                    disabled={disabled}
                    name="current"
                    label={t('defaultChips')}
                    options={chipamounts}
                    values={current}
                    setFieldValue={setFieldValue}
                    setValues={setCurrent}
                    requiredLength={6}
                    errorMessage={[t('lessThanSixChips'), t('moreThanSixChips')]}
                  />
                </div>
              </GridItemFull>
            </GridContainer>
          </Form>
        )
      }}
    </Formik>
  )
})

export default ChipsForm
