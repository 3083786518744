import React from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import SelectTextField from '../text-fields/SelectTextField'
import RadioTextField from '../text-fields/RadioTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } catch (error) { }

    setSubmitting(false)
  },
  displayName: 'AgentGameAnalysisReportForOwnerForm',
})

const AgentGameAnalysisReportForOwnerForm = props => {
  const {
    isSubmitting,
    classes,
    filterGamesOptions,
    currencyOptions,
    t,
    isFetching,
    brand,
  } = props

  const fields = [
    [
      {
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'gamehall',
        label: t('gamehall'),
        fullWidth: true,
        options: [
          { label: t('all'), value: 'all' },
          { label: t(brand), value: brand },
        ],
      },
      {
        Field: SelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        options: filterGamesOptions(),
        isClearable: true,
      },
      {
        Field: RadioTextField,
        name: 'gametype',
        label: t('gametype'),
        fullWidth: true,
        options: [
          { label: t('all'), value: 'all' },
          { label: t('sport'), value: 'sport' },
          { label: t('lotto'), value: 'lotto' },
        ],
        isClearable: true,
      },
    ],
    // 2020/5/19 Wesley
    // 結算週期欄位較偏向「群組」概念
    // ex: 如選擇「日」就將該期間「同代理＆同遊戲＆同日」的資料群組成一筆
    // ex: 如選擇「無」就將該期間「同代理＆同遊戲」的資料群組成一筆
    // 且由於目前使用者已熟悉此標題, 因此標題不做修改
    [
      {
        Field: RadioTextField,
        name: 'groupby',
        label: t('groupby'),
        fullWidth: true,
        options: [
          { label: t('none'), value: 'none' },
          { label: t('hour'), value: 'hour' },
          { label: t('day'), value: 'day' },
          { label: t('month'), value: 'month' },
          { label: t('year'), value: 'year' },
        ],
      },
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
      },
    ],
    [
      {
        Field: SelectTextField,
        name: 'currency',
        label: t('currency'),
        fullWidth: true,
        options: [
          { label: t('notConverted', { ns: 'common' }), value: '' },
          ...currencyOptions(),
        ],
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withTranslation('agentGameAnalysisReport')(
    withStyles(styles)(AgentGameAnalysisReportForOwnerForm),
  ),
)
