import React from 'react'
import PropTypes from 'prop-types'
import TreeNode from './TreeNode'

const DataTree = ({
  treeData = [],
  nodeContent,
  defaultExpand = false,
}) => (
  <TreeNode
    nodeContent={nodeContent}
    children={treeData}
    expand={defaultExpand}
    root={true}
  />
)

DataTree.propTypes = {
  treeData: PropTypes.array,
  nodeContent: PropTypes.func,
  defaultExpand: PropTypes.bool,
}

export default DataTree
