const rawData = {
  roundid: ['Order Id', '单号'],
  currency: ['Currency', '币别'],
  finaltime: ['Time', '时间'],
  donate: ['Donate', '打赏金额'],
  sort: ['Sort', '排序'],
  asc: ['ASC', '递增'],
  desc: ['DESC', '递减'],
  playerAcc: ['Player Account', '玩家帐号'],
  agentAcc: ['Agent Account', '代理帐号'],
  tableType: ['Live Game Type', '真人游戏类型'],
  tableId: ['Table No', '桌号'],
  required: ['This is a required field.', '此为必填栏位'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
