import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to update bet threshould
 */
export function * update ({ payload }) {
  const { userid, betThreshold } = payload

  try {
    // 1. Fetch bet threshould request started
    yield put(actions.betThreshold.updateRequest())

    // 2. Call API: fetch bet threshould
    const res = yield call(api.post, api.BO, '/ss/betthreshold', { userid, ...betThreshold })

    // 3. Fetch bet threshould success
    yield put(actions.betThreshold.updateSuccess({ response: res.data.data }))
  } catch (err) {
    // Fetch bet threshould failure
    yield put(actions.betThreshold.updateFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch bet threshould status
 */
export function * updateStatus ({ payload }) {
  try {
    // 1. Fetch bet threshould request started status
    yield put(actions.betThreshold.updateStatusRequest())

    // 2. Call API: fetch bet threshould status
    yield call(api.post, api.BO, '/ss/betthreshold/toggle', payload)

    // 3. Fetch bet threshould success status
    yield put(actions.betThreshold.updateStatusSuccess({ response: payload }))

    // 4. Announce update bet threshould successed
    yield put(actions.msgs.add({
      replaceWithI18nText: 'modifySuccess',
      variant: 'info',
    }))
  } catch (err) {
    // Fetch bet threshould failure status
    yield put(actions.betThreshold.updateStatusFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.betThreshold.updateStatus, updateStatus),
  takeLatest(actionType.betThreshold.update, update),
]

export default effects
