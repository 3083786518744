import { makeStyles } from '@material-ui/styles'
import CrownImage from '../dark_icon_crown.png'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    maxWidth: '1200px',
  },
  headerRow: {
    backgroundColor: 'darkslateblue',
  },
  tableCell: {
    border: '0.5px solid grey',
    padding: '4px 10px!important',
    width: '50%',
  },
  border: {
    backgroundColor: '#222',
  },
  enabled: {
    cursor: 'pointer',
    '& > span:first-of-type': {
      color: '#039be5',
    },
    '& > span:last-of-type': {
      opacity: 0.5,
      backgroundColor: '#039be5!important',
    },
  },
  disabled: {
    '& > span:first-of-type': {
      color: '#bdbdbd',
    },
    '& > span:last-of-type': {
      opacity: 0.3,
    },
  },
  notAllowed: {
    cursor: 'not-allowed',
  },
  ownerIcon: {
    position: 'relative',
    '&:before': {
      content: "''",
      width: 20,
      height: 20,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: -13,
      left: -18,
      backgroundImage: `url(${CrownImage})`,
    },
  },
}), {
  name: 'EnhancedDataTable',
})

export default useStyles
