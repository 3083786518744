import React, { useState, useCallback } from 'react'
import {
  Table as DefaultTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Switch,
} from '@material-ui/core'
import useStyles from './EnhancedDataTable.style'
import MaybeLoading from './components/MaybeLoading'
import EnhancedTableRow from './components/EnhancedTableRow'
import TablePaginationActions from './components/TablePaginationActions'

const EnhancedDataTable = ({
  columns = [],
  rows = [],
  isLoading = false,
  disabled = true,
  autoPageDivide = false,
  lang = null,
  setTablesets = () => {},
}) => {
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [isAll, setIsAll] = useState(false)

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage)
  }, [])
  const handleChangeRowsPerPage = useCallback((e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }, [])
  const pageDivide = () => {
    if (!autoPageDivide) return rows
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }
  const handleSwitchAllOnClick = () => {
    if (isAll) {
      setIsAll(false)
      setTablesets((prev) => {
        const temp = prev.map((item) => ({ ...item }))
        temp.forEach((item) => { item.viewstatus = 0 })
        return temp
      })
    } else {
      setIsAll(true)
      setTablesets((prev) => {
        const temp = prev.map((item) => ({ ...item }))
        temp.forEach((item) => { item.viewstatus = 1 })
        return temp
      })
    }
  }

  return (
    <>
      <DefaultTable>
        <TableHead >
          <TableRow className={classes.headerRow}>
            {columns &&
            columns.map((column) => (
              <TableCell key={column.label} align={column.align} className={classes.tableCell}>
                {column.label}
                {column.switch && <Switch color="primary" className={isAll ? classes.enabled : classes.disabled} disabled={disabled} checked={isAll} onClick={handleSwitchAllOnClick} />}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading && <MaybeLoading />}
        <TableBody>
          {rows &&
          pageDivide().map((row, index) => {
            return <EnhancedTableRow lang={lang} setTablesets={setTablesets} key={row.tableid} row={row} columns={columns} disabled={disabled} index={index} />
          })}
        </TableBody>
        <TableFooter>
          {autoPageDivide && <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              backIconButtonProps={{
                color: 'secondary',
              }}
              nextIconButtonProps={{ color: 'secondary' }}
              labelRowsPerPage={<span>Rows:</span>}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>}
        </TableFooter>
      </DefaultTable>
    </>
  )
}

export default EnhancedDataTable
