import React from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../components/Button'
import InputAdornment from '@material-ui/core/InputAdornment'

import OutlinedTextField from '../text-fields/OutlinedTextField'

import { renderFields } from './helper'
import { withTranslation } from 'react-i18next'
import language from '../../locale/createCustomerService'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  // 用 func 包一層才不會有驗證失靈問題
  validationSchema: () =>
    Yup.object().shape({
      account: Yup.string().required('required'),
      password: Yup.string()
        .min(8, 'leastEight')
        .max(16, 'mostSixteen')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_-]{8,16}$/g, 'warning')
        .required('required'),
      nickname: Yup.string().required('required'),
    }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      await props.onSubmit({
        account: values.account,
        password: values.password,
        nickname: values.nickname,
      })

      resetForm()
    } catch (error) { }

    setSubmitting(false)
  },
  displayName: 'CreateCustomerServiceForm',
  enableReinitialize: true,
})

const CreateCustomerServiceForm = props => {
  const { theme, isSubmitting, classes, user, i18n, t, setFieldTouched, errors, touched } = props

  i18n.addResourceBundle('en', 'createCustomerService', language.en)
  i18n.addResourceBundle('cn', 'createCustomerService', language.cn)
  i18n.on('languageChanged', () => {
    Object.keys(errors).forEach(fieldName => {
      if (Object.keys(touched).includes(fieldName)) {
        setFieldTouched(fieldName)
      }
    })
  })

  const accountPrefix = (user().role === 'cs' ? user().parentAcc : user().account) + '_'
  const maxLenOfAccountSuffix = 36 - accountPrefix.length

  const profileFields = [
    {
      name: 'account',
      label: t('account'),
      fullWidth: true,
      Field: OutlinedTextField,
      inputProps: { maxLength: maxLenOfAccountSuffix },
      InputProps: {
        startAdornment: <InputAdornment position="start">{accountPrefix}</InputAdornment>,
      },
    },
    {
      name: 'password',
      label: t('password'),
      type: 'password',
      autoComplete: 'new-password',
      fullWidth: true,
      Field: OutlinedTextField,
    },
    {
      name: 'nickname',
      label: t('nickname'),
      fullWidth: true,
      Field: OutlinedTextField,
    },
  ]
  return (
    <Form>
      <h3 style={theme.customer.H3Style}>{t('createCustomerService')}</h3>

      {renderFields(profileFields, props)}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        actionType='positive'
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('create')}
      </Button>
    </Form>
  )
}

export default withStyles(styles, { withTheme: true })(withTranslation('createCustomerService')(formikEnhancer(CreateCustomerServiceForm)))
