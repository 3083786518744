import React from 'react'
import Grid from '@material-ui/core/Grid'

const GridContainer = ({ children, ...props }) => (
  <Grid container spacing={24} {...props} style={{ minHeight: 30 }}>
    {children}
  </Grid>
)

export default GridContainer
