import React, { useRef } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'
import Debug from './Debug'

import RadioTextField from '../text-fields/RadioTextField'
import MultiSelectTextField from '../text-fields/MultiSelectTextField'
import OutlinedTextField from '../text-fields/OutlinedTextField'
import DateRangePickerTextField from '../text-fields/DateRangePickerTextField'
import {
  getLiveTableTypeOption,
  getLiveTableIdOption,
} from '../../reducers'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: ({ routePage }) => Yup.object().shape({
    agentAcc: routePage === 'livePlayerDonateForAdmin' ? Yup.string().required('required') : Yup.string(),
    playerAcc: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({
        agentAcc: values.agentAcc,
        playerAcc: values.playerAcc,
        roundid: values.roundid,
        tabletype: values.tabletype,
        tableid: values.tableid,
        startDate: values.startDate,
        endDate: values.endDate,
        sortValue: values.sortValue,
        pagesize: values.pagesize,
        nowpage: values.nowpage,
      })
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'LivePlayerDonateForm',
})

const LivePlayerDonateForm = props => {
  const {
    routePage,
    isSubmitting,
    classes,
    t,
    isFetching,
    setFieldValue,
    dateRange,
    tableTypeOptions,
    tableIdAllOptions,
  } = props
  const tableIdRef = useRef(null)

  const fields = [
    [
      {
        Field: OutlinedTextField,
        name: 'playerAcc',
        label: t('playerAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      {
        Field: OutlinedTextField,
        name: 'roundid',
        label: t('roundid'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'tabletype',
        label: t('tableType'),
        fullWidth: true,
        isMulti: true,
        isClearable: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        options: tableTypeOptions,
        onChange: (value) => {
          setFieldValue('tabletype', (value || []).map((v) => v.value))
          tableIdRef.current.select.clearValue()
        },
      },
      {
        Field: MultiSelectTextField,
        name: 'tableid',
        label: t('tableId'),
        fullWidth: true,
        isMulti: true,
        isClearable: true,
        closeMenuOnSelect: false,
        selectRef: tableIdRef,
        options: tableIdAllOptions,
        onChange: (value) => setFieldValue('tableid', (value || []).map((v) => v.value)),
      },
    ],
    [
      {
        Field: DateRangePickerTextField,
        doubleWidth: true,
        enableTimeSelector: true,
        range: dateRange,
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'sortValue',
        label: t('sort'),
        fullWidth: true,
        options: [
          { label: t('asc'), value: 'asc' },
          { label: t('desc'), value: 'desc' },
        ],
      },
    ],
  ]
  if (routePage !== 'livePlayerDonateForParent') {
    fields[0] = [
      {
        Field: OutlinedTextField,
        name: 'agentAcc',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      ...fields[0],
    ]
  }

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[1], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[2], props)}
      </GridContainer>
      <GridContainer>
        {renderFieldsWithGridItem(fields[3], props)}
      </GridContainer>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>
      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
      {process.env.REACT_APP_DEBUG === 'true' && <Debug />}
    </Form>
  )
}

const mapStateToProps = (state) => {
  const tableTypeOptions = getLiveTableTypeOption(state)
  const tableIdAllOptions = getLiveTableIdOption(state)
  const dateRange = state.order?.liveorder ? `${state.order.liveorder}day` : '1day'
  return {
    tableTypeOptions,
    tableIdAllOptions,
    dateRange,
  }
}

export default connect(mapStateToProps)(formikEnhancer(
  withTranslation('livePlayerDonate')(withStyles(styles)(LivePlayerDonateForm)),
))
