import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  searchBar: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  fullWidth: { width: '100%', margin: '12px', padding: '16px 24px' },
  quickSetup: { padding: '0 12px' },
  textfield: {
    marginRight: '30px',
    '& input': {
      padding: '15px 0',
    },
  },
  button: {
    margin: '24px 0 24px 12px ',
  },
  tableBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    background: '#039be5',
    borderRadius: '4px 4px 0 0',
  },
  selectField: {
    marginLeft: '20px',
    width: '200px',
  },
  formWrapper: { borderRadius: '0 0 4px 4px', padding: '16px' },
  seperator: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '10px',
    margin: '0 12px',
  },
  copyWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'none',
    padding: '20px 12px',
  },
  copyField: {
    width: '180px',
    margin: '10px',
  },
  copyButton: {
    marginLeft: '10px',
  },
}), {
  name: 'BetLimitSetting',
})

export default useStyles
