import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const tableTypeOption = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.live.fetchTableInfoSuccess:
      return action.payload.response.map(({ tabletype, tablename }) => ({
        label: tablename,
        value: tabletype,
      }))
    default:
      return state
  }
}

const tableIdOption = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.live.fetchTableInfoSuccess:
      return action.payload.response.map(({ tabletype, tablename, tableid }) => ({
        tabletype,
        label: tablename,
        options: tableid.map(id => ({ label: id, value: id })),
      }))
    default:
      return state
  }
}
export default combineReducers({
  tableTypeOption,
  tableIdOption,
})
