import { all, call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import actions from '../actions'
import { updateSign as currencyUpdateSign } from './currency'
import { updateStatus as agentUpdateStatus } from './agent'
import { update, updateStatus as betThresholdUpdateStatus } from './betThreshold'

const agentDetailEvent = {
  updateCurrencySign: currencyUpdateSign,
  updateAgentStatus: agentUpdateStatus,
  updateBetThresholdStatus: betThresholdUpdateStatus,
  updateBetThreshold: update,
}

/**
 * Effect to handle agent detail submit event
 */
export function * handleAgentDetailSubmit ({ payload, meta }) {
  try {
    // 1. Submit start
    yield put(actions.event.requestStart({ eventName: 'handleAgentDetailSubmit' }))

    const { effects, cb } = meta

    const arr = []

    effects.forEach(v => {
      agentDetailEvent[v] && arr.push(call(agentDetailEvent[v], { payload }))
    })

    // 2. Execute submit events
    yield all(arr)

    // TODO: 中間層處理兩種類型 effect（event action and normal action）
    // 這個操作是原有流程設計，初步猜測是為了降低 reducer 複雜度，而在將 action 拆到 effect 後發現資料欄位狀態上的錯誤，
    // 由於有多個請求，這時如果在 request 成功後各別設定，在成功回應前會各自更新，導致 component 重新渲染導致欄位資料錯亂，
    // 若要減少這個動作，就在抽象中間件來處理 effect，分離 effect 發起請求、通知的動作。
    if (arr.length > 0) {
      // 3. Refresh data
      yield put(actions.agent.fetchDetail({ userID: payload.userid }))
    }

    // 4. Call extra events
    yield call(cb)

    // 5. Submit end
    yield put(actions.event.requestEnd({ eventName: 'handleAgentDetailSubmit' }))

    // 6. Announce submit agent password successed
    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // TODO: 需要捕捉錯誤處理
    // 增加一中間層包裝 effect，這個 effect 捕捉 child effect 於 catch 時拋出的 error object。
    console.log('Error in handle agent detail submit: ', err)
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
    yield put(actions.event.requestEnd({ eventName: 'handleAgentDetailSubmit' }))
  }
}

const effects = [
  takeLatest(actionType.event.handleAgentDetailSubmit, handleAgentDetailSubmit),
]

// event effect 與一般的 effect 定義上不同，用來管理多個請求組合而成的事件
export default effects
