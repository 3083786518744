import qs from 'qs'
import { format } from 'date-fns'
import numeral from 'numeral'

export const qsParse = value => {
  try {
    return qs.parse(value, { ignoreQueryPrefix: true })
  } catch (error) {
    return null
  }
}

export const qsStringify = obj => {
  try {
    return qs.stringify(obj)
  } catch (error) {
    return null
  }
}

export const formatDate = (date, formatRule = 'yyyy/MM/dd HH:mm') => {
  try {
    return format(date, formatRule)
  } catch (error) {
    return date
  }
}

export const formatDateByGroupBy = (date, groupBy) => {
  try {
    switch (groupBy) {
      case 'none':
      case 'hour':
        return format(date, 'yyyy/MM/dd HH')
      case 'month':
        return format(date, 'yyyy/MM')
      case 'year':
        return format(date, 'yyyy')
      case 'day':
      default:
        return format(date, 'yyyy/MM/dd')
    }
  } catch (error) {
    return date
  }
}

export const formatMoney = (number = 0) => {
  if (number >= 0) {
    return numeral(number).format('0,0.0000')
  } else {
    // 需求：負數浮點數金額顯示邏輯 -99996.88905 -> -99996.8891（卡片：https://trello.com/c/43qSBuo9）
    // - 正確： '-' + numeral(Math.abs(-99996.88905)).format('0,0.0000') = -99996.8891
    // - 直接由套件轉不符需求：numeral(-99996.88905).format('0,0.0000') = -99996.8890
    return '-' + numeral(Math.abs(number)).format('0,0.0000')
  }
}
