import axios from 'axios'
import qs from 'qs'
import { replace } from 'connected-react-router'

import { removeState } from '../helpers/storage'
import actions from '../actions'
import store from '../configureStore'
import wsClient from './ws'

export function setHeaders (instance, configs) {
  for (const key in configs) {
    switch (key) {
      // Auth for RP
      case 'Authorization':
        instance.defaults.headers.Authorization = configs[key] ? `Bearer ${configs[key]}` : ''
        break
      // Auth for BO
      case 'usertoken':
        instance.defaults.headers.usertoken = configs[key] ? configs[key] : ''
        break
      case 'lang':
        instance.defaults.headers['x-lang'] = configs[key] === 'cn' ? 'zh-cn' : configs[key]
        break
      default:
        console.warn(`${key} is not avaliable for setHeaders`)
    }
  }
}

export const post = (instance, url, payload = {}) => {
  return instance.post(url, payload).then(
    async response => response,
    err => Promise.reject(err),
  )
}

export const get = (instance, url, payload = {}) => {
  return instance.get(url, payload).then(
    async response => response,
    err => Promise.reject(err),
  )
}

export const put = (instance, url, payload = {}) => {
  return instance.put(url, payload).then(
    async response => response,
    err => Promise.reject(err),
  )
}

export const patch = (instance, url, payload = {}) => {
  return instance.patch(url, payload).then(
    async response => response,
    err => Promise.reject(err),
  )
}

export const DELETE = (instance, url) => {
  return instance.delete(url).then(
    async response => response,
    err => Promise.reject(err),
  )
}

export const all = (iterable) => {
  return Promise.all([...iterable]).then(
    response => Promise.resolve(response),
    err => Promise.reject(err),
  )
}

function blobToJSON (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function () {
      resolve(JSON.parse(reader.result))
    }
    reader.onerror = reject

    reader.readAsText(blob)
  })
}

function setInterceptor (instance) {
  instance.interceptors.response.use(
    async response => {
      // responseType: 'blob' 時(下載檔案)
      // API有錯誤時(如 timeout )會回傳 JSON
      // 但這邊仍會是 Blob, 所以要在轉成 JSON 後再去下面判斷 code
      // 正常情況不會進入 true
      if (response.data instanceof Blob && response.data.type === 'application/json') {
        try {
          response.data = await blobToJSON(response.data)
        } catch (error) {
          console.error('blob to json error: ', error)
          return Promise.reject(error)
        }
      }

      if (response.data && response.data.status && response.data.status.code) {
        switch (response.data.status.code) {
          case '0':
          case 0:
            return response
          case '1199:auth':
            removeState('token')

            store.dispatch(actions.auth.invalidToken())

            store.dispatch(replace('/'))

            wsClient.Disconnect()

            return Promise.reject(response)
          case '1100:bo':
            store.dispatch(actions.msgs.add({
              replaceWithI18nText: 'serviceFailed',
              variant: 'error',
            }))

            return Promise.reject(response)
          case '1003:bo':
            // store.dispatch(actions.msgs.add({
            //     msg: 'Permissions Denied. 权限不足。',
            //     variant: 'error'
            // }))

            return Promise.reject(response)
          case '1001': // timeout
            store.dispatch(actions.msgs.add({
              replaceWithI18nText: 'timeout',
              variant: 'error',
            }))

            return Promise.reject(response)
          case '3007:gksetting':
          case '3003:gksetting': // no data in database
            store.dispatch(actions.msgs.add({
              replaceWithI18nText: 'noData',
              variant: 'info',
            }))

            return Promise.reject(response)
          case '3011:gksetting':
          case '3012:gksetting':
            store.dispatch(actions.msgs.add({
              replaceWithI18nText: 'chipError',
              variant: 'error',
            }))

            return Promise.reject(response)
          default:
            // enqueueSnackbar(response.data.status.message, {
            //   anchorOrigin: { vertical: 'top', horizontal: 'center' },
            //   autoHideDuration: 2600,
            //   variant: 'warning'
            // });
            return Promise.reject(response)
        }
      }

      return response
    },
    error => {
      switch (error && error.response && error.response.status) {
        case 401:
          removeState('user')

          store.dispatch(actions.auth.invalidToken())

          store.dispatch(replace('/'))

          wsClient.Disconnect()

          break
        case 502:
          store.dispatch(actions.msgs.add({
            replaceWithI18nText: 'serviceFailed',
            variant: 'error',
          }))

          break
        case 429:
          store.dispatch(actions.msgs.add({
            replaceWithI18nText: 'tooManyRequests',
            variant: 'error',
          }))

          break
        default:
          console.error(error)

          break
      }

      return Promise.reject(error)
    },
  )

  return instance
}

const defaultHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
}

export const BO = setInterceptor(
  axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    transformRequest: [data => qs.stringify(data, { indices: false })],
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: { ...defaultHeaders },
  }),
)

export const BO2 = setInterceptor(
  axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    transformRequest: [data => JSON.stringify(data, null, 2)],
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: { 'Content-Type': 'application/json' },
  }),
)

export const RP = setInterceptor(
  axios.create({
    baseURL: process.env.REACT_APP_RP_API_HOST,
    transformRequest: [data => qs.stringify(data, { indices: false })],
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: { ...defaultHeaders },
  }),
)
