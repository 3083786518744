import { combineReducers } from 'redux'
import login, * as fromLogin from './login'
import logout, * as fromLogout from './logout'
import actionType from '../../actions/actionType'

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

function getPermit (user) {
  if (user.type === 'SYSTEM') {
    return 'admin'
  }
  if (user.role === 'cs') {
    if (user.parent === user.owner) {
      return 'owner'
    }
    return 'parent'
  }
  if (user.role === 'owner') {
    return 'owner'
  }
  return 'parent'
}

function decodeUserInfoByJwtWithParentAcc (jwtToken, parentAcc) {
  const userInfo = parseJwt(jwtToken)

  return {
    isgroupowner: userInfo.userInfo,
    usertoken: jwtToken,
    userID: userInfo.userid,
    account: userInfo.account,
    parentAcc,
    type: userInfo.type,
    role: userInfo.role,
    permit: getPermit(userInfo),
  }
}

const user = (state = null, action = {}) => {
  switch (action.type) {
    case actionType.auth.loginSuccess:
    case actionType.auth.setUserByTokenSuccess:
      return decodeUserInfoByJwtWithParentAcc(action.payload.token, action.payload.parentAcc)
    case actionType.auth.logoutSuccess:
    case actionType.auth.loginFailure:
    case actionType.auth.invalidToken:
      return null
    default:
      return state
  }
}

const userInfo = (state = null, action = {}) => {
  switch (action.type) {
    case actionType.user.fetchInfoSuccess:
      return { ...state, ...action.payload.response }
    case actionType.currency.updateSignSuccess:
    case actionType.currency.updateSignRequest:
    case actionType.currency.updateSignFailure:
      return { ...state, currencySign: action.payload.currencySign }
    case actionType.agent.updateWalletSuccess:
      return { ...state, ...action.payload }
    case actionType.auth.logoutSuccess:
      return null
    default:
      return state
  }
}

const totalPlayers = (state = '', action) => {
  switch (action.type) {
    case actionType.user.fetchTotalSignUpSuccess:
      return action.payload.response
    default:
      return state
  }
}

const isLogged = (state = false, action) => {
  switch (action.type) {
    case actionType.auth.loginSuccess:
    case actionType.auth.setUserByTokenRequest:
    case actionType.auth.setUserByTokenSuccess:
      return true
    case actionType.auth.loginFailure:
    case actionType.auth.invalidToken:
    case actionType.auth.logoutSuccess:
    case actionType.auth.setUserByTokenFailure:
      return false
    default:
      return state
  }
}

export default combineReducers({
  user,
  userInfo,
  totalPlayers,
  isLogged,
  logout,
  login,
})

export const getUser = state => {
  if (state.user && state.user.usertoken) {
    const user = parseJwt(state.user.usertoken)

    return { ...state.user, permit: getPermit(user) }
  }

  return state.user
}

export const getIsGroupOwner = state => state.auth.user.isgroupowner
export const getUserInfo = state => state.userInfo
export const getUserTotalPlayers = state => state.totalPlayers
export const getIsLogged = state => state.isLogged

export const getIsLogin = state => fromLogin.getIsLogin(state.login)
export const getErrorMessage = state => fromLogin.getErrorMessage(state.login)

export const getIsLogout = state => fromLogout.getIsLogout(state.logout)
