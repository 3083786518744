import React from 'react'
import GridItem from '../grids/GridItem'

export const renderFields = (fields, { errors, touched, t, values }) =>
  fields.map(({ Field, name, helperText, hide, applyValue = false, ...others }) => {
    if (hide) return null
    const error = !!touched[name] && !!errors[name]
    if (applyValue) {
      return (
        <Field
          value={values[name]}
          key={name}
          name={name}
          error={error}
          helperText={t(error ? errors[name] : helperText)}
          {...others}
        />
      )
    }

    return (
      <Field
        key={name}
        name={name}
        error={error}
        helperText={t(error ? errors[name] : helperText)}
        {...others}
      />
    )
  })

export const renderFieldsWithGridItem = (fields, { errors, touched, t, values, setFieldValue }) =>
  fields.map(({ Field, name, helperText, doubleWidth, ...others }, i) => {
    const error = errors[name] && touched[name]
    return (
      <GridItem
        key={name || `renderFields${i}`}
        doubleWidth={doubleWidth}
      >
        {
          name
            // 一般 field 使用
            ? <Field
              name={name}
              error={error}
              helperText={t(error ? errors[name] : helperText)}
              {...others}
            />
            // 給 dateRangePicker 使用
            : <Field
              values={values}
              setFieldValue={setFieldValue}
              {...others}
            />
        }

      </GridItem>
    )
  })
