import React, { useEffect, useState } from 'react'
import { Form, withFormik } from 'formik'
import { useCSVReader } from 'react-papaparse'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import language from '../../../locale/agentRTPSetting'
import actions from '../../../actions'
import CreatableSelectField from '../../../components/fields/CreatableSelectField'
import DataTable from '../../../components/tables/DataTable'
import * as Yup from 'yup'
import SelectTextField from '../../../components/text-fields/SelectTextField'
import { renderFieldsWithGridItem } from '../../../components/forms/helper'
import GridContainer from '../../../components/grids/GridContainer'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  browserBtn: {
    backgroundColor: '#66BB6A',
    fontSize: 14,
    fontWeight: 500,
    padding: '6px 16px',
    borderRadius: 4,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  fileName: {
    color: '#fff',
    fontSize: 14,
    margin: '0 8px',
  },
  removeBtn: {
    backgroundColor: '#f44336',
    color: '#fff',
    padding: '4px 12px',
    fontWeight: 500,
    borderRadius: 4,
    border: 'none',
    cursor: 'pointer',
  },
  container: {
    width: 'fit-content',
    margin: '8px 0',
  },
  progressBar: {
    width: '100%',
    margin: '4px 0',
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    player: Yup.array().min(1, 'required'),
    rtp: Yup.string().required('required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, resetForm, setFieldValue }) => {
    try {
      const res = await props.onSubmit(values)
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'AgentRedRainForm',
  enableReinitialize: true,
})

const RTPSettingForm = (props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { CSVReader } = useCSVReader()
  const {
    t,
    i18n,
    isSubmitting,
    classes,
    isFetching,
    setFieldValue,
    values,
  } = props

  const fields = [
    [
      {
        Field: CreatableSelectField,
        name: 'player',
        label: t('account'),
        isMulti: true,
        placeholder: t('inputAccount'),
        isClearable: true,
        noOptionsMessage: () => t('inputAccountPressEnter'),
        fullWidth: true,
        onChange: (value) => setFieldValue('player', (value || []).map((v) => v.value)),
      },
      {
        Field: SelectTextField,
        name: 'rtp',
        label: 'RTP',
        fullWidth: true,
        options: [
          { label: '95%', value: 95 },
          { label: '96%', value: 96 },
          { label: '97%', value: 97 },
          { label: '98%', value: 98 },
        ],
        isClearable: true,
      },
    ],
  ]
  // console.log(values)

  useEffect(() => {
    setData(values.player.map(el => [el, values.rtp]))
  }, [values])

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRTPSetting', language.en)
    i18n.addResourceBundle('cn', 'agentRTPSetting', language.cn)
  }, [])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>
      <CSVReader
        onUploadAccepted={(results) => {
          try {
            const nPlayer = []
            let nRTP

            results.data.forEach(([player, rtp]) => {
              if (player) nPlayer.push(player)
              if (!nRTP) nRTP = rtp
            })
            setFieldValue('player', nPlayer)
            setFieldValue('rtp', nRTP)
          } catch (e) {
            console.error(e)
            dispatch(
              actions.msgs.add({
                msg: t('formatError'),
                variant: 'warning',
              }),
            )
          }

          setData(results.data)
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }) => {
          return (
            <div className={classes.container}>
              <div className={classes.root}>
                <button type='button' {...getRootProps()} className={classes.browserBtn}>
                  {t('import')}
                </button>
                {
                  acceptedFile && (
                    <>
                      {acceptedFile.name && (
                        <div className={classes.fileName}>
                          {acceptedFile.name}
                        </div>
                      )}
                      <button {...getRemoveFileProps()} className={classes.removeBtn}>
                        {t('delete')}
                      </button>
                    </>
                  )
                }
              </div>
              <ProgressBar className={classes.progressBar} />
            </div>
          )
        }}
      </CSVReader>
      <DataTable
        data={data}
        columns={[{ name: t('account') }, { name: 'RTP' }]}
        options={{
          rowsPerPage: 30,
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('send')}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('agentRTPSetting')(withStyles(styles)(RTPSettingForm)),
)
