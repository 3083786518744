import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import OutlinedTextField from '../../../components/text-fields/OutlinedTextField'
import MultiSelectTextField from '../../../components/text-fields/MultiSelectTextField'
import { renderFields } from '../../../components/forms/helper'
import { withTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import { GAME_TYPES } from '../../../consts/index'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const styles = {
  root: { 'min-width': 0, color: '#fff' },
  contentRoot: {
    padding: '0 24px',
  },
  actionsRoot: {
    margin: 0,
    padding: '12px 24px 24px',
  },
}

const formikEnhancer = withFormik({
  validationSchema: ({ t }) => {
    return Yup.object().shape({
      name: Yup.string().required(t('common:required')),
      minimum: Yup.number()
        .required(t('common:required'))
        .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
      maximum: Yup.number()
        .required(t('common:required'))
        .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
      default: Yup.number()
        .required(t('common:required'))
        .test('moreThenEqualZero', 'moreThenEqualZero', (value) => value >= 0),
      bettype_maximum: Yup.number()
        .test('notEmptymoreThenEqualZero', 'notEmptymoreThenEqualZero', (value) => value === undefined || value >= 0),
      round_maximum: Yup.number()
        .test('notEmptymoreThenEqualZero', 'notEmptymoreThenEqualZero', (value) => value === undefined || value >= 0),
      roombet: Yup.string().trim().matches(/^[0-9]+(\.[0-9]+)?(,[0-9]+(\.[0-9]+)?)*$/, { message: t('onlyNumbersDigitsAndCommas') })
        .test('noMoreThanSixNumbers', 'noMoreThanSixNumbers', (value) => {
          if (value === undefined) return true
          return value.split(',').length <= 6
        }),
      gameCodes: Yup.array().when('gameTypes', (x) => {
        if (x?.length === 0) {
          return Yup.array().min(1, 'common:gamecodesOrGameTypesIsRequired')
        }
      }),
      gameTypes: Yup.array().when('gameCodes', (x) => {
        if (!x.length) {
          return Yup.array().min(1, 'common:gamecodesOrGameTypesIsRequired')
        }
      }),
    }, [['gameCodes', 'gameTypes']])
  },
  handleSubmit: async (values, { props }) => {
    try {
      const roombet = values.roombet ? values.roombet.split(',') : []
      const newValues = { ...values, roombet: roombet }
      await props.onSubmit(newValues, props.getMode() === 'create')
    } catch (err) { }
  },
  displayName: 'BetThresholdForm',
})

const BetThresholdForm = props => {
  const language = useSelector((state) => state.state.language)
  const
    {
      t,
      allGamesOptions,
      setFieldValue,
      gameCodes = [],
      gameTypes = [],
      getOpen,
      getTitle,
      onClose,
      getMode,
      roombet,
      classes,
    } = props

  const [gameCodesValue, setGameCodesValue] = useState(() => {
    // 用 gameCode 去找出 allGamesOptions 對應的 object
    return gameCodes.map(gameCode => allGamesOptions().find(option => option.value === gameCode))
  })
  const [gametypeValue, setGametypeValue] = useState(gameTypes.map(gameType => GAME_TYPES[language].find(option => option.value === gameType)))
  const [tabValue, setTabValue] = useState(0)
  const handleTabValue = (_, value) => setTabValue(value)

  const [roombetValues, setRoombetValues] = useState(roombet || '')
  const fields = [
    [
      {
        Field: OutlinedTextField,
        name: 'name',
        label: t('name'),
        fullWidth: true,
      },
    ],
    [
      {
        Field: OutlinedTextField,
        name: 'minimum',
        label: t('minimum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'maximum',
        label: t('maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'default',
        label: t('default'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'bettype_maximum',
        label: t('common:bettype_maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'round_maximum',
        label: t('common:round_maximum'),
        fullWidth: true,
        type: 'number',
      },
      {
        Field: OutlinedTextField,
        name: 'roombet',
        label: t('roombet'),
        fullWidth: true,
        type: 'string',
        placeholder: 'eg: 1,2,3,4,5,6',
        value: roombetValues,
        onChange: (e) => {
          setRoombetValues(e.target.value)
          setFieldValue('roombet', e.target.value)
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'gameCodes',
        label: t('gameCodes'),
        fullWidth: true,
        options: allGamesOptions(),
        InputLabelProps: {
          shrink: true,
        },
        position: 'top',
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: gameCodesValue,
        onChange: (value) => {
          setGameCodesValue(value)
          setFieldValue('gameCodes', value.map(v => v.value))
        },
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'gameTypes',
        label: t('gameTypes'),
        fullWidth: true,
        options: GAME_TYPES[language],
        InputLabelProps: {
          shrink: true,
        },
        position: 'top',
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        value: gametypeValue,
        onChange: (value) => {
          setGametypeValue(value)
          setFieldValue('gameTypes', value.map(v => v.value))
        },
      },
    ],
  ]
  return (
    <Dialog
      disableBackdropClick
      PaperProps={{ style: { overflowY: 'scroll', maxHeight: '100%' } }}
      open={getOpen()}
      onClose={onClose}
    >
      <Form>
        <DialogTitle >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div>
              {t(getTitle())}
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.contentRoot}>
          {renderFields(fields[0], props)}
          {renderFields(fields[1], props)}
          <Tabs value={tabValue} onChange={handleTabValue} >
            <Tab label={t('gameCodes')} classes={{
              root: classes.root,
            }} />
            <Tab label={t('gameTypes')} classes={{
              root: classes.root,
            }} />
          </Tabs>
          {tabValue === 0 && renderFields(fields[2], props)}
          {tabValue === 1 && renderFields(fields[3], props)}
        </DialogContent>
        <DialogActions className={classes.actionsRoot}>
          <Button
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            actionType={getMode() === 'create' ? 'positive' : 'negative'}
          >
            {t(getMode() === 'create' ? 'common:add' : 'common:save')}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default withStyles(styles)(withTranslation('userProfile')(
  formikEnhancer(BetThresholdForm),
))
