import React, { Component } from 'react'
import RootRef from '@material-ui/core/RootRef'
import BaseDataTable from './BaseDataTable'

class DataTable extends Component {
  constructor (props) {
    super(props)

    this.tableRef = React.createRef()
  }

  render () {
    const { data, columns, pagination, options, ...other } = this.props

    let page, count
    if (pagination) {
      page = pagination.nowpage ? pagination.nowpage - 1 : 0
      count = pagination.total ? pagination.total : 0
    }

    return (
      <RootRef rootRef={this.tableRef}>
        <BaseDataTable
          data={data}
          columns={columns}
          options={{
            // 有給 pagination 代表分頁從 api 給, 沒給則是給組件處理
            serverSide: !!pagination,
            page,
            count,
            ...options,
          }}
          {...other}
        />
      </RootRef>
    )
  }
}

export default DataTable
