import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

const listPath = {
  playersList: '/players',
  liveTwoSideBet: '/live/twosidebet',
}
/**
 * Effect to fetch player list
 */
export function * fetchList ({ payload, meta }) {
  try {
    // 1. Fetch player list request started
    yield put(actions.report.fetchRequest({ filter: meta.filter }))

    // 2. Call API: fetch player list
    const res = yield call(api.get, api.BO, listPath[meta.filter], { params: { ...payload } })
    // 3. Fetch player list success
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: res.data.data }))
  } catch (err) {
    // Fetch player list failure
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

const playerPath = {
  // 視訊打賞查詢
  livePlayerDonate: '/live/players/donate',
}
const playerOrderPath = {
  // 注單查詢
  playersOrder: '/players',
  // 視訊注單查詢
  livePlayersOrder: '/live/players',
  // 免費券查詢
  freeTicketSearch: 'players/freeticket',
}
const summaryPath = { ...playerOrderPath, ...playerPath }

/**
 * Effect to fetch player order
 */
export function * fetchPlayerOrder ({ payload, meta }) {
  try {
    // 1. Fetch player order request started
    yield put(actions.report.fetchRequest({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchRequest({ filter: meta.filter }))

    const apiPathes = [`${summaryPath[meta.filter]}/summary`]
    if (playerPath[meta.filter]) apiPathes.push(`${playerPath[meta.filter]}`)
    if (playerOrderPath[meta.filter]) apiPathes.push(`${playerOrderPath[meta.filter]}/order`)
    const iterable = apiPathes.map(path => api.get(api.BO, path, { params: { ...payload.reportPayload } }))

    // 2. Fetch Report and Summary
    const [playerSummaryRes, playerOrderRes] = yield call(api.all, iterable)

    // 3. Fetch player order success
    yield put(actions.report.fetchSuccess({ filter: `${meta.filter}Summary`, response: playerSummaryRes.data.data }))
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: playerOrderRes.data.data }))
  } catch (err) {
    // Fetch player order failure
    yield put(actions.report.fetchFailure({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

/**
 * Effect to freeTicketSearch
 */
export function * fetchFreeTicketSearch ({ payload, meta }) {
  try {
    // 1. Fetch player order request started
    yield put(actions.report.fetchRequest({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchRequest({ filter: meta.filter }))
    const removeFalsy = (obj) => {
      const newObj = {}
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) { newObj[prop] = obj[prop] }
      })
      return newObj
    }
    const iterable = [
      api.get(api.BO, `${playerOrderPath[meta.filter]}/order/summary`, { params: { ...removeFalsy(payload.summaryReportPayload) } }),
      api.get(api.BO, `${playerOrderPath[meta.filter]}/order`, { params: { ...removeFalsy(payload.reportPayload) } }),
    ]
    // 2. Fetch Report and Summary
    const [freeTicketSummaryRes, freeTicketOrderRes] = yield call(api.all, iterable)
    // 3. Fetch player order success
    yield put(actions.report.fetchSuccess({ filter: `${meta.filter}Summary`, response: freeTicketSummaryRes.data.data }))
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: freeTicketOrderRes.data.data }))
  } catch (err) {
    // Fetch player order failure
    yield put(actions.report.fetchFailure({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

/**
 * Effect to fetch player tranaction history
 */
export function * fetchPlayerTransaction ({ payload }) {
  try {
    // 1. Fetch player tranaction history request started
    yield put(actions.report.fetchRequest({ filter: 'playersTransactionHistorySummary' }))
    yield put(actions.report.fetchRequest({ filter: 'playersTransactionHistory' }))

    const iterable = [
      api.get(api.BO, '/players/trans/history', { params: { ...payload.reportPayload } }),
      api.get(api.BO, '/players/trans/summary', { params: { ...payload.summaryReportPayload } }),
    ]

    // 2. Fetch Report and Summary
    const [playerOrderRes, playerSummaryRes] = yield call(api.all, iterable)

    // 3. Fetch player tranaction history success
    yield put(actions.report.fetchSuccess({ filter: 'playersTransactionHistorySummary', response: playerSummaryRes.data.data }))
    yield put(actions.report.fetchSuccess({ filter: 'playersTransactionHistory', response: playerOrderRes.data.data }))
  } catch (err) {
    // Fetch player tranaction history failure
    yield put(actions.report.fetchFailure({ filter: 'playersTransactionHistorySummary' }))
    yield put(actions.report.fetchFailure({ filter: 'playersTransactionHistory' }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

const reportPath = {
  // 代理報表
  agentReport: '/parent',
  // 代理玩家報表
  agentPlayerReport: '/parent/players/summary',
  // 代理玩家遊戲報表
  agentPlayerGameReport: '/parent/players',
  // 代理財務報表
  agentFinanceReport: '/report/finance',
  // 代理遊戲分析報表
  agentGameAnalysisReport: '/report/analytics',
  // 不分代理遊戲報表
  agentlessGameReport: '/report/agentless',
  // 代理玩家免費券報表
  agentPlayerFreeTicketReport: '/freeticket/parent/players/summary',
  // 免費券分析報表
  freeTicketAnalysisReport: '/report/freeticket/analytics',
  // 體彩代理財務報表
  lottoAgentFinanceReport: '/lotto/report/finance',
  // 體彩即時注單
  lottoRealTimeOrder: '/lotto/players/lottoRealtimeOrder',
  // 體彩開彩注單
  lottoAwardedOrder: '/lotto/players/lottoAwardedOrder',
  // 體彩代理報表
  lottoAgentReport: '/lotto/parent',
  // 體彩代理玩家報表
  lottoAgentPlayerReport: '/lotto/parent/players/summary',
  // 體彩代理玩家遊戲報表
  lottoAgentPlayerGameReport: '/lotto/parent/players/genre',
  // 體彩代理遊戲分析報表
  lottoAgentGameAnalysisReport: '/lotto/report/analytics',
  // 體彩其數單據查詢
  lottoPeriodOrder: '/lotto/players/lottoPeriodOrder',
  // 體彩不分代理遊戲列表
  lottoAgentlessGameReport: '/lotto/report/agentless',
  // 活動派彩查詢
  playersPayoff: '/players/payoff',
  // 真人視訊-代理遊戲分析報表
  liveAgentGameAnalysisReport: '/live/report/analytics',
  // 真人視訊-代理玩家報表
  liveAgentPlayerReport: '/live/parent/players/summary',
  // 真人視訊-視訊代理報表
  liveAgentReport: '/live/parent',
  // 真人視訊-視訊代理財務報表
  liveAgentFinanceReport: '/live/report/finance',
  // 真人視訊-視訊不分代理遊戲數據列表
  liveAgentlessGameReport: '/live/report/agentless',
  // chicken
  liveSSCockFightReport: '/live/cockfight',
}

/**
 * Effect to fetch player tranaction history
 */
export function * fetchReport ({ payload, meta }) {
  try {
    // 1. Fetch player tranaction history request started
    yield put(actions.report.fetchRequest({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchRequest({ filter: meta.filter }))

    // 2. Fetch Report and Summary
    const res = yield call(api.get, api.BO, reportPath[meta.filter], { params: { ...payload } })
    if (res.data.data.data.length === 0) {
      yield put(actions.msgs.add({
        replaceWithI18nText: 'noMatchData',
        variant: 'info',
      }))
    }

    // 3. Fetch player tranaction history success
    yield put(actions.report.fetchSuccess({ filter: `${meta.filter}Summary`, response: res.data.data.summary ? res.data.data.summary : {} }))
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: res.data.data }))
  } catch (err) {
    // Fetch player tranaction history failure
    yield put(actions.report.fetchFailure({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

/**
 * Effect to fetchAgentPlayerFreeTicketReport
 */
export function * fetchAgentPlayerFreeTicketReport ({ payload, meta }) {
  try {
    // 1. request started
    yield put(actions.report.fetchRequest({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchRequest({ filter: meta.filter }))
    // 2. Fetch Report and Summary
    const res = yield call(api.get, api.BO, reportPath[meta.filter], { params: { ...payload } })
    if (res.data.data.data.length === 0) {
      yield put(actions.msgs.add({
        replaceWithI18nText: 'noMatchData',
        variant: 'info',
      }))
    }

    // 3. Fetch player tranaction history success
    yield put(actions.report.fetchSuccess({ filter: `${meta.filter}Summary`, response: res.data.data.data }))
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: res.data.data }))
  } catch (err) {
    // Fetch player tranaction history failure
    yield put(actions.report.fetchFailure({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

/**
 * Effect to fetch player tranaction history
 */
export function * fetchFreeTicketAnalysisReport ({ payload, meta }) {
  try {
    // 1. Fetch player tranaction history request started
    yield put(actions.report.fetchRequest({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchRequest({ filter: meta.filter }))

    // 2. Fetch Report and Summary
    const res = yield call(api.get, api.BO, reportPath[meta.filter], { params: { ...payload } })

    if (res.data.data.data.length === 0) {
      yield put(actions.msgs.add({
        replaceWithI18nText: 'noMatchData',
        variant: 'info',
      }))
    }

    // 3. Fetch player tranaction history success
    // yield put(actions.report.fetchSuccess({ filter: `${meta.filter}Summary`, response: res.data.data.summary ? res.data.data.summary : {} }))
    yield put(actions.report.fetchSuccess({ filter: meta.filter, response: res.data.data }))
  } catch (err) {
    // Fetch player tranaction history failure
    yield put(actions.report.fetchFailure({ filter: `${meta.filter}Summary` }))
    yield put(actions.report.fetchFailure({ filter: meta.filter }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

export function handleDownload (data, fileName) {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = url

  link.setAttribute('download', `${fileName}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// downloadPath 儲存所有下載報表的 path
export const downloadPath = {
  // 注單查詢
  playersOrder: '/players/order/excel',
  // 交易查詢
  playersTransactionHistory: '/players/trans/history/excel',
  // 代理報表
  agentReport: '/parent/excel',
  // 代理玩家報表
  agentPlayerReport: '/parent/players/summary/excel',
  // 代理玩家遊戲報表
  agentPlayerGameReport: '/parent/players/excel',
  // 代理財務報表
  agentFinanceReport: '/report/finance/excel',
  // 代理遊戲分析報表
  agentGameAnalysisReport: '/report/analytics/excel',
  // 不分代理遊戲報表
  agentlessGameReport: '/report/agentless/excel',
  // 免費券查詢
  freeTicketSearch: '/players/freeticket/order/excel',
  // 代理玩家免費券報表
  agentPlayerFreeTicketReport: '/freeticket/parent/players/summary/excel',
  // 免費券分析報表
  freeTicketAnalysisReport: '/report/freeticket/analytics/excel',
  // 體彩代理財務報表
  lottoAgentFinanceReport: '/lotto/report/finance/excel',
  // 體彩即時注單
  lottoRealTimeOrder: '/lotto/players/lottoRealtimeOrder/excel',
  // 體彩開彩注單
  lottoAwardedOrder: '/lotto/players/lottoAwardedOrder/excel',
  // 體彩代理報表
  lottoAgentReport: '/lotto/parent/excel',
  // 體彩代理玩家報表
  lottoAgentPlayerReport: '/lotto/parent/players/summary/excel',
  // 體彩代理玩家遊戲報表
  lottoAgentPlayerGameReport: '/lotto/parent/players/genre/excel',
  // 體彩代理遊戲分析報表
  lottoAgentGameAnalysisReport: '/lotto/report/analytics/excel',
  // 體彩其數單據查詢
  lottoPeriodOrder: '/lotto/players/lottoPeriodOrder/excel',
  // 體彩不分代理遊戲列表
  lottoAgentlessGameReport: '/lotto/report/agentless/excel',
  // 活動派彩查詢
  playersPayoff: '/players/payoff/excel',

  // 真人視訊-代理遊戲分析報表
  liveAgentGameAnalysisReport: '/live/report/analytics/excel',
  // 真人視訊-代理玩家報表
  liveAgentPlayerReport: '/live/parent/players/summary/excel',
  // 真人視訊-注單查詢
  livePlayersOrder: '/live/players/order/excel',
  // 真人視訊-打賞查詢
  livePlayerDonate: '/live/players/donate/excel',
  // 真人視訊-視訊代理報表
  liveAgentReport: '/live/parent/excel',
  // 真人視訊-視訊代理財務報表
  liveAgentFinanceReport: '/live/report/finance/excel',
  // 真人視訊-視訊不分代理遊戲數據列表
  liveAgentlessGameReport: '/live/report/agentless/excel',
  // chicken
  liveSSCockFightReport: '/live/cockfight/excel',
}

/**
 * Effect to download report
 */
let savedData
let isSuccess
let fileName
export function * download ({ payload, meta }) {
  try {
    let res
    // 1. Download report request
    yield put(actions.report.downloadRequest({ filter: meta.filter }))

    // 2. Call download API
    meta.isDownloaded && isSuccess && fileName === meta.fileName ? res = savedData : res = savedData = yield call(api.get, api.BO, downloadPath[meta.filter], { params: payload, responseType: 'blob' })
    fileName = meta.fileName
    // 3. Download report success
    yield put(actions.report.downloadSuccess({ filter: meta.filter, response: res }))
    isSuccess = true

    // 4. Create download event
    yield call(handleDownload, res.data, meta.fileName)
  } catch (err) {
    // Download failure
    yield put(actions.report.downloadFailure({ filter: meta.filter }))
    isSuccess = false
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: err.data && err.data.status && err.data.status.code === '0' ? 'info' : 'error',
    }))
  }
}

const effects = [
  takeLatest(actionType.report.fetchList, fetchList),
  takeLatest(actionType.report.fetchPlayerOrder, fetchPlayerOrder),
  takeLatest(actionType.report.fetchPlayerTransaction, fetchPlayerTransaction),
  takeLatest(actionType.report.fetchReport, fetchReport),
  takeLatest(actionType.report.download, download),
  takeLatest(actionType.report.fetchFreeTicketSearch, fetchFreeTicketSearch),
  takeLatest(actionType.report.fetchFreeTicketAnalysisReport, fetchFreeTicketAnalysisReport),
  takeLatest(actionType.report.fetchAgentPlayerFreeTicketReport, fetchAgentPlayerFreeTicketReport),
]

export default effects
