import React, { useEffect } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import language from '../../../locale/agentRTPSetting'
import OutlinedTextField from '../../../components/text-fields/OutlinedTextField'
import { renderFieldsWithGridItem } from '../../../components/forms/helper'
import GridContainer from '../../../components/grids/GridContainer'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting, resetForm, setFieldValue }) => {
    try {
      const res = await props.onSubmit({ ...values, first: true })
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'SearchRTPForm',
  enableReinitialize: true,
})

const RTPSettingForm = (props) => {
  const {
    isSubmitting,
    classes,
    isFetching,
    i18n,
    t,
  } = props

  const fields = [
    [
      {
        name: 'player',
        label: t('account'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
    ],
  ]

  useEffect(() => {
    i18n.addResourceBundle('en', 'agentRTPSetting', language.en)
    i18n.addResourceBundle('cn', 'agentRTPSetting', language.cn)
  }, [])

  return (
    <Form className={classes.form}>
      <GridContainer>
        {renderFieldsWithGridItem(fields[0], props)}
      </GridContainer>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search')}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}
    </Form>
  )
}

export default formikEnhancer(
  withTranslation('agentRTPSetting')(withStyles(styles)(RTPSettingForm)),
)
