import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'

import a from '../../actions'
import {
  getReportByFilter,
  getGamesOnlyLottoAndSport,
  getTimeZone,
  getLanguage,
} from '../../reducers'
import { formatDate, formatMoney } from '../../helpers'
import { getSavedState } from '../../helpers/storage'
import language from '../../locale/playerOrder'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import LottoPeriodGameForAdminForm from '../../components/forms/LottoPeriodGameForAdminForm'
import DataTable from '../../components/tables/DataTable'

const styles = theme => ({
  true: {
    color: green[500],
    fontWeight: 900,
  },
  false: {
    color: grey[500],
    fontWeight: 900,
  },
})

class LottoPeriodGame extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: {},
      formatReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
    }
  }

  componentDidMount () {
    const { i18n } = this.props

    i18n.addResourceBundle('en', 'playerOrder', language.en)
    i18n.addResourceBundle('cn', 'playerOrder', language.cn)
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲類型、遊戲名稱、遊戲項目」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
    }
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('ownerAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('parentAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('playerAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('roundid'),
          options: {
            filter: false,
            sort: false,
          },
        },

        {
          name: t('betAmount'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('validbet'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('preWin'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        {
          name: t('curWin'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => value === '' ? '-' : formatMoney(value),
          },
        },
        {
          name: t('lastAmendTime'),
          options: {
            filter: false,
            sort: false,
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    this.setState({
      formatReports: data.map(item => [
        item.ownerAcc,
        item.parentAcc,
        item.account,
        item.roundID,

        item.betAmount,
        item.validBet,
        item.preWin,
        // curWin 即使沒有 lastAmentTime 也會有值（後端會去抓 event 裡面最後一個 curWin 的值），所以這裡用 lastAmentTime 的時間來判斷是否該顯示重派金額
        item.lastAmendTime !== '' ? item.curWin : '',
        item.lastAmendTime || '-',
      ]),
    })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'lottoPeriodOrder',
      fileName: t('lottoPeriodOrder', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      gamehall: values.gamehall === 'all' ? '' : values.gamehall,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props
    return fetchReport({ ...payload, timezone }, { filter: 'lottoPeriodOrder' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      canDownload,
      filterGamesOptions,
    } = this.props
    const {
      formatReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
    } = this.state

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <LottoPeriodGameForAdminForm
              gamecode=""
              period=""
              filterGamesOptions={() => filterGamesOptions}
              onSubmit={this.handleSubmit}
              // page
              nowpage={1}
              pagesize={rowsPerPage}
            />
          </Paper>
        </GridItemFull>

        { isSearched &&
        <GridItemFull>
          <DataTable
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            payload={payload}
            canDownload={canDownload}
            downloadReport={this.handleDownload}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const language = getLanguage(state)
  const filterGamesOptions = getGamesOnlyLottoAndSport(state, language)

  const reports = getReportByFilter(state, 'lottoPeriodOrder')
  const timezone = getSavedState('timezone') || getTimeZone(state)

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  return {
    canDownload,
    games: state.games,
    language,
    filterGamesOptions,
    reports,
    timezone,
  }
}

export default connect(
  mapStateToProps,
  {
    downloadReport: a.report.download,
    fetchReport: a.report.fetchReport,
  },
)(
  withTranslation('playerOrder')(
    withSnackbar(withStyles(styles)(LottoPeriodGame)),
  ),
)
