import React, { PureComponent as Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import actions from '../../actions'
import {
  getLanguage,
  getAgentListWithRole,
  getBrandInfo,
  getUser,
} from '../../reducers'
import { createGame } from '../../reducers/games'
import language from '../../locale/specialGame'

import Form from './form'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItemFull from '../../components/grids/GridItemFull'
import { api } from '../../services'

class SpecialGame extends Component {
  constructor (props) {
    super(props)

    this.state = {
      brand: '',
      brandOptions: [],
      gamelist: {
        en: [],
        cn: [],
      },
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.brand !== this.state.brand && this.state.brand) {
      this.fetchGameList(this.state.brand)
    }
  }

  async componentDidMount () {
    const { i18n, fetchAgents, clearAgent, brandInfo } = this.props

    // 切換頁面之後 parentoption 會保留，所以一開始要先刪除
    clearAgent()

    fetchAgents({ role: 'owner' })

    if (brandInfo?.brand) {
      this.fetchGameList(brandInfo.brand)
    }

    i18n.addResourceBundle('en', 'specialGame', language.en)
    i18n.addResourceBundle('cn', 'specialGame', language.cn)

    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    this.setState({
      brand: brandOptions?.[0] || '',
      brandOptions: brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    })
  }

  // 「遊戲清單」與「user 預設黑名單的遊戲清單」的交集
  fetchGameList = async (brand) => {
    const { addMsg } = this.props

    try {
      const res = await api.get(api.BO, '/gamelistbyrole?isspecial=true')
      const list = res?.data?.data?.data[brand] || []
      this.setState({
        gamelist: {
          en: createGame('en', list),
          cn: createGame('zh-cn', list),
        },
      })
    } catch (error) {
      const msg = error?.data?.status?.message
      msg ? addMsg({ msg, variant: 'warning' }) : console.error(error)
    }
  }

  updateSpecialGame = async (payload) => {
    const { addMsg } = this.props
    try {
      await api.post(api.BO, '/game/special', payload)
      addMsg({ replaceWithI18nText: 'success', variant: 'info' })
    } catch (error) {
      const msg = error?.data?.status?.message
      msg ? addMsg({ msg, variant: 'warning' }) : console.error(error)
    }
    this.setState({ isFetching: false })
  }

  onSubmit = data => {
    this.updateSpecialGame(data)
    this.setState({ isFetching: true })
  }

  onBrandChange = (brand) => {
    this.setState({
      brand,
    })
  }

  render () {
    const {
      theme,
      t,
      fetchAgents,
      parentOption,
      ownerOption,
      clearAgent,
      language,
      role,
      type,
    } = this.props
    const {
      isFetching,
      gamelist,
      brand,
      brandOptions,
    } = this.state

    return (
      <Container>
        <GridItemFull form>
          <Paper>
            <h3 style={ theme.customer.H3Style }>{t('title')}</h3>
            <Form
              onBrandChange={this.onBrandChange}
              role={role}
              type={type}
              brand={brand}
              brandOptions={brandOptions}
              isFetching={isFetching}
              onSubmit={this.onSubmit}
              // 總代理
              ownerOption={() => ownerOption}
              parentid=''
              // 子代理 check box 值
              parentoption='none'
              // 手動子代理
              parentOption={() => parentOption}
              agentid={[]}
              // 遊戲代號
              gameOptions={() => gamelist[language].filter(el => !el.disabled)}
              gamecode=''
              // 未來子代預設開啟遊戲
              checked={false}

              fetchAgents={fetchAgents}
              clearAgent={clearAgent}
            />
          </Paper>
        </GridItemFull>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const language = getLanguage(state)
  const agents = getAgentListWithRole(state)
  const brandInfo = getBrandInfo(state)

  const formatRepost = []
  const user = getUser(state)
  return {
    role: user.role,
    type: user.type,
    ownerOption: agents.ownerOptions,
    parentOption: agents.parentOptions,
    formatRepost,
    brandInfo,
    language,
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchAgents: actions.agent.fetchAgentListV2,
      clearAgent: actions.agent.clearAgentList,
      // brand
      getBrandInfo: actions.brandInfo.get,
      // msg
      addMsg: actions.msgs.add,
    },
  ),
  withTranslation('specialGame'),
  withStyles({}, { withTheme: true }),
)(SpecialGame)
