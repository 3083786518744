import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

// 管理 request 週期
const isLogin = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.auth.loginRequest:
      return true
    case actionType.auth.loginSuccess:
    case actionType.auth.loginFailure:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action = {}) => {
  switch (action.type) {
    case actionType.auth.loginFailure:
      return 'Unauthorized！'
    case actionType.auth.loginSuccess:
      return null
    default:
      return state
  }
}

export default combineReducers({
  isLogin,
  errorMessage,
})

export const getIsLogin = state => state.isLogin
export const getErrorMessage = state => state.errorMessage
