import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'

import UserButton from '../UserButton'
import Timezone from '../Timezone'
import Language from '../Language'
import UserGuide from '../UserGuide'

const styles = theme => ({
  appBar: {
    marginLeft: theme.sidebarWidth,
    // maxHeight: 48
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    // maxHeight: 48,
    // [theme.breakpoints.up('sm')]: {
    //   maxHeight: 48
    // }
  },
  appBarResponsive: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.sidebarWidth}px)`,
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  colorWhite: {
    color: '#ffffff',
  },
})

class AppBar extends Component {
  componentDidMount () {
    this.forceUpdate()
  }

  render () {
    const {
      user,
      timezone,
      language,
      classes,
      toggleDrawer,
      handleLogout,
      handleChangeLanguage,
      handleSetTimezone,
      handleSwitchTheme,
    } = this.props

    return (
      <MuiAppBar
        className={`${classes.appBar} ${user && classes.appBarResponsive}`}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          {user && (
            // Menu Icon 看起來沒有用到（待移除）
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => toggleDrawer({ status: true })}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.title} />
          <Timezone timezone={timezone} handleSetTimezone={handleSetTimezone} />
          <Language
            language={language}
            handleChangeLanguage={handleChangeLanguage}
          />
          {user && <UserGuide user={user} />}
          {user && <UserButton handleSwitchTheme={handleSwitchTheme} user={user} handleLogout={handleLogout} />}
        </Toolbar>
      </MuiAppBar>
    )
  }
}
AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppBar)
