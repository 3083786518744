import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import qs from 'qs'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch agent list
 */
export function * fetchAgentList ({ payload }) {
  try {
    // 1. Fetch agent list request started
    yield put(actions.report.fetchRequest({ filter: 'agentList' }))

    // 2. Call API: fetch agent list
    const res = yield call(api.get, api.BO, '/sslist', { params: { ...payload } })

    // 3. Fetch agent list success
    yield put(actions.report.fetchSuccess({ filter: 'agentList', response: res.data.data }))
  } catch (err) {
    // Fetch agent list failure
    yield put(actions.report.fetchFailure({ filter: 'agentList' }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch agent list v2
 */
export function * fetchAgentListV2 ({ payload }) {
  try {
    const query = qs.stringify({
      role: payload.role,
      parentID: payload.userID,
    })
    // 1. Fetch agent list request started
    yield put(actions.agent.fetchAgentListV2Request())

    // 2. Call API: fetch agent list
    const res = yield call(api.get, api.BO, `/sslist/v2?${query}`, payload)

    // 3. Fetch agent list success
    yield put(actions.agent.fetchAgentListV2Success({ response: res.data.data, requestPayload: payload }))
  } catch (err) {
    // Fetch agent list failure
    yield put(actions.agent.fetchAgentListV2Failure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch agent options
 */
export function * fetchAgentOptions ({ payload }) {
  try {
    // 1. Fetch agent options request started
    yield put(actions.agent.fetchAgentOptionsRequest())

    // 2. Call API: fetch agent options
    const res = yield call(api.get, api.BO, '/sslist/ux?detail=true')

    // 3. Fetch agent options success
    yield put(actions.agent.fetchAgentOptionsSuccess({ response: res.data }))
  } catch (err) {
    // Fetch agent options failure
    yield put(actions.agent.fetchAgentOptionsFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to create agent
 */
export function * createAgent ({ payload }) {
  try {
    // 1. Create agent request started
    yield put(actions.agent.createAgentRequest())

    // 2. Call API: create agent
    const res = yield call(api.post, api.BO, '/ss/create', payload)

    // 3. Create agent success
    yield put(actions.agent.createAgentSuccess({ response: res.data.data }))

    yield put(actions.msgs.add({
      replaceWithI18nText: 'success',
      variant: 'info',
    }))
  } catch (err) {
    // Fetch agent list failure
    yield put(actions.agent.createAgentFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch agent detail
 */
export function * fetchDetail ({ payload }) {
  const { userID } = payload

  try {
    // 1. Fetch agent detail request started
    yield put(actions.agent.fetchDetailRequest({ agentId: userID }))

    // 2. Call API:
    // - fetch agent detail
    const detailRes = yield call(api.get, api.BO, `/ss/info?userid=${userID}`)

    // 3. Fetch agent detail success
    yield put(actions.agent.fetchDetailSuccess({
      agentId: userID,
      response: detailRes.data.data,
    }))
  } catch (err) {
    // Fetch agent detail failure
    yield put(actions.agent.fetchDetailFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

export function * fetchStationList ({ payload }) {
  const { userID } = payload
  try {
    // // 1. Fetch agent detail request started
    // yield put(actions.agent.fetchStationListRequest({ agentId: userID }))

    // 2. Call API:
    // - fetch agent detail
    const detailRes = yield call(api.get, api.BO, `/sslist/v2?role=station&parentID=${userID}`)

    if (detailRes.data.status.code === '0' && detailRes.data.data[0]) {
      const { userID: stationId } = detailRes.data.data[0]
      const res = yield call(api.get, api.BO, `ss/info?userid=${stationId}`)
      if (res.data.status.code === '0') {
      // 3. Fetch agent detail success
        yield put(actions.agent.fetchStationListSuccess({
          agentId: userID,
          response: detailRes.data.data,
        }))
      }
    }
  } catch (err) {
    // Fetch agent detail failure
    yield put(actions.agent.fetchStationListFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to fetch agent totalSignUp
 */
export function * fetchTotalSignUp ({ payload }) {
  try {
    const { userID } = payload

    // 1. Fetch agent totalSignUp request started
    yield put(actions.event.requestStart({ eventName: 'fetchTotalSignUp' }))
    yield put(actions.agent.fetchTotalSignUpRequest({ agentId: userID }))
    // 2. Call API:
    // - fetch total sign up
    const totalRes = yield call(api.get, api.BO, `/ss/info/totalSignUp?userid=${userID}`)

    // 3. Fetch agent totalSignUp success
    yield put(actions.agent.fetchTotalSignUpSuccess({
      agentId: userID,
      response: totalRes.data.data.totalsignup,
    }))
    yield put(actions.event.requestEnd({ eventName: 'fetchTotalSignUp' }))
  } catch (err) {
    // Fetch agent totalSignUp failure
    yield put(actions.agent.fetchTotalSignUpFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
    yield put(actions.event.requestEnd({ eventName: 'fetchTotalSignUp' }))
  }
}

/**
 * Effect to update agent status
 */
export function * updateStatus ({ payload }) {
  const { userid, status } = payload
  try {
    // 1. Update agent request started
    yield put(actions.agent.updateStatusRequest({
      userid,
      action: status,
    }))
    // 2. Call API: update agent
    yield call(api.post, api.BO, '/ss/status/update', {
      userid,
      action: status.toLowerCase(),
    })
    // 3. Update agent success
    yield put(actions.agent.updateStatusSuccess({
      userid,
      action: status,
    }))
  } catch (err) {
    // Update agent status failure
    yield put(actions.agent.updateStatusFailure({
      userid,
      action: status === 'Enable' ? 'Enable' : 'Disable',
    }))
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update agent password
 */
export function * updatePassword ({ payload }) {
  try {
    // 1. Update agent password request started
    yield put(actions.agent.updatePasswordRequest())

    // 2. Call API: update agent password
    const res = yield call(api.post, api.BO, '/ss/pwd', payload)

    // 3. Update agent password success
    yield put(actions.agent.updatePasswordSuccess({ response: res.data.data }))

    // 4. Announce update agent password successed
    yield put(actions.msgs.add({
      replaceWithI18nText: 'modifySuccess',
      variant: 'success',
    }))
  } catch (err) {
    // Update agent password failure
    yield put(actions.agent.updatePasswordFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

/**
 * Effect to update agent wallet
 */
export function * updateWallet ({ payload }) {
  try {
    // 1. Update agent wallet request started
    yield put(actions.agent.updateWalletRequest())

    // 2. Call API: update agent wallet
    yield call(api.post, api.BO, '/wurl/update', payload)

    // 3. Update agent wallet success
    yield put(actions.agent.updateWalletSuccess(payload))

    // 4. Announce update agent wallet successed
    yield put(actions.msgs.add({
      replaceWithI18nText: 'modifySuccess',
      variant: 'success',
    }))
  } catch (err) {
    // Update agent wallet failure
    yield put(actions.agent.updateWalletFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeEvery(actionType.agent.fetchAgentListV2, fetchAgentListV2),
  takeLatest(actionType.agent.fetchAgentList, fetchAgentList),
  takeLatest(actionType.agent.fetchAgentOptions, fetchAgentOptions),
  takeLatest(actionType.agent.createAgent, createAgent),
  takeLatest(actionType.agent.fetchDetail, fetchDetail),
  takeLatest(actionType.agent.fetchStationList, fetchStationList),
  takeLatest(actionType.agent.fetchTotalSignUp, fetchTotalSignUp),
  takeLatest(actionType.agent.updateStatus, updateStatus),
  takeLatest(actionType.agent.updatePassword, updatePassword),
  takeLatest(actionType.agent.updateWallet, updateWallet),
]

export default effects
