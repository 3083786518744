const rawData = {
  roundid: ['Order Id', '单号'],
  currency: ['Currency', '币别'],
  datetime: ['Date Time', '日期时间'],
  playerAcc: ['Player Account', '玩家帐号'],
  agentAcc: ['Agent Account', '代理帐号'],
  agent: ['Agent', '代理'],
  tableId: ['Table No', '桌号'],
  tabletype: ['Live Game Type', '真人游戏类型'],
  roundNumber: ['Round Number', '局号'],
  bets: ['Bets', '下注金额'],
  bettype: ['Bet Type', '下注玩法'],
  Player: ['PLAYER', '闲家'],
  'Banker(Comm)': ['BANKER(Comm)', '庄家'],
  'Banker(No Comm)': ['BANKER(No Comm)', '庄家(免佣)'],
  Tie: ['TIE', '和'],
  'Banker Pair': ['B PAIR', '庄对'],
  'Player Pair': ['P PAIR', '闲对'],
  'Super 6': ['SUPER 6', '超级6'],
  'Either Pair': ['EITHER PAIR', '任意对子'],
  'Perfect Pair': ['PERFECT PAIR', '完美对子'],
  Big: ['Big', '大'],
  Small: ['Small', '小'],
  BDL: ['BDL', '庄龙宝例牌'],
  PDL: ['PDL', '闲龙宝例牌'],
  BDB: ['B BONUS', '庄龙宝'],
  PDB: ['P BONUS', '闲龙宝'],
  required: ['This is a required field.', '此为必填栏位'],
  baccarat: ['Baccarat', '百家乐'],
  marble: ['Marble', '弹珠锦标赛'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
