import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    maxWidth: '1200px',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  search: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    margin: '24px 0 24px 12px ',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textfield: {
    marginRight: '30px',
    '& input': {
      padding: '15px 0',
    },
  },
  helper: {
    marginTop: '10px',
  },

  errorIcon: {
    marginRight: '10px',
  },
  enabled: {
    '& > span:first-of-type': {
      color: '#039be5',
    },
    '& > span:last-of-type': {
      opacity: 0.5,
      backgroundColor: '#039be5!important',
    },
  },
  disabled: {
    '& > span:first-of-type': {
      color: '#bdbdbd',
    },
    '& > span:last-of-type': {
      opacity: 0.3,
    },
  },
  notAllowed: {
    cursor: 'not-allowed',
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}), {
  name: 'TableSetting',
})

export default useStyles
