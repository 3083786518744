import React, { useState, useRef, useEffect, useCallback } from 'react'
import ChipsForm from './Form/ChipsForm'
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core'
import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import useStyles from './ChipsSetting.style'
import { useTranslation } from 'react-i18next'
import language from '../../locale/gkSetting'
import { api } from '../../services'
import { useDispatch } from 'react-redux'
import actions from '../../actions'

const ChipsSetting = () => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('gkSetting')
  const dispatch = useDispatch()
  const [agentId, setAgentId] = useState('')
  const [data, setData] = useState({})
  const [chipamounts, setChipamounts] = useState([])
  const [current, setCurrent] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isSubmittable, setIsSubmittable] = useState(false)
  const formikRef = useRef(null)
  const idRef = useRef({})

  const convertChipsFormat = (array) =>
    array.map((chip) => ({ label: chip, value: chip }))
  const handleAgentIdChange = (e) => setAgentId(e.target.value)
  const handleSearchClick = useCallback(async () => {
    try {
      const res = await api.get(api.BO, `/live/gk/chip?ssaccount=${agentId}`)
      if (res.data?.status?.code === '0') {
        setData(res.data.data || {})
        setChipamounts(convertChipsFormat(res.data.data.chipamounts) || [])
        setCurrent(convertChipsFormat(res.data.data.current) || [])
        idRef.current.agentId = agentId
      }
    } catch (err) {
      setData({})
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant:
            err.data && err.data.status && err.data.status.code === '0'
              ? 'info'
              : 'error',
        }),
      )
    }
  }, [agentId, dispatch])

  const handleEdit = () => setIsEdit(true)
  const handleSubmit = useCallback(async () => {
    try {
      await formikRef.current.submitForm()
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleCancel = useCallback(
    (e) => {
      setIsEdit(false)
      setChipamounts(convertChipsFormat(data.chipamounts))
      setCurrent(convertChipsFormat(data.current))
    },
    [setIsEdit, setChipamounts, setCurrent, data],
  )

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'gkSetting', language.en)
    i18n.addResourceBundle('cn', 'gkSetting', language.cn)
  }, [i18n])

  // 取得資料後，若chipamounts和currents沒有滿足條件，則無法按下確認
  useEffect(() => {
    setIsSubmittable(chipamounts.length === 12 && current.length === 6)
  }, [chipamounts, current])

  return (
    <>
      <Container>
        {/* 搜索 */}
        <GridItemFull>
          <Paper>
            <div className={classes.form}>
              <div>
                <TextField
                  required
                  disabled={isEdit}
                  autoComplete={'off'}
                  name="agentId"
                  label={t('agentId')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={handleAgentIdChange}
                  className={classes.textfield}
                />
                <Button
                  disabled={isEdit || !agentId}
                  variant="contained"
                  color="primary"
                  className={classes.search}
                  onClick={handleSearchClick}>
                  {t('apply')}
                </Button>
              </div>
              {data.ssaccount && (
                <div className={classes.buttonGroup}>
                  {!isEdit ? (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}>
                      {t('edit')}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        type="submit"
                        disabled={!isSubmittable}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}>
                        {t('save')}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}>
                        {t('cancel')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Paper>
        </GridItemFull>

        {/* 取得資料，顯示籌碼設定選項 */}
        {data.ssaccount && (
          <GridItemFull>
            <Paper>
              <Typography variant="subtitle1" gutterBottom>
                {t('agentId')}: {data.ssaccount}
              </Typography>
              <ChipsForm
                t={t}
                ref={formikRef}
                idRef={idRef.current.agentId}
                disabled={!isEdit}
                setIsEdit={setIsEdit}
                ssid={data.ssid}
                tabletype={data.tabletype}
                currency={data.currency}
                globalallchips={convertChipsFormat(data.globalallchips)}
                chipamounts={chipamounts}
                current={current}
                setData={setData}
                setChipamounts={setChipamounts}
                setCurrent={setCurrent}
                convertChipsFormat={convertChipsFormat}
              />
            </Paper>
          </GridItemFull>
        )}
      </Container>
    </>
  )
}

export default ChipsSetting
