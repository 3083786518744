const rawData = {
  title: ['Management System', '后台管理系统'],

  success: ['Success', '成功'],
  fail: ['Fail', '失败'],
  failed: ['Failed', '失败'],
  pending: ['Pending', '等候'],
  refund: ['Refund', '退款'],
  refundCancel: ['Cancel', '取消退款'],

  add: ['Add', '新增'],
  remove: ['Remove', '移除'],
  search: ['Search', '搜索'],
  edit: ['Edit', '编辑'],
  save: ['Save', '储存'],
  cancel: ['Cancel', '取消'],
  confirm: ['Confirm', '确定'],
  modify: ['Modify', '修改'],
  create: ['Create', '建立'],
  please_enter: ['Please Enter ...', '请输入 ...'],
  noOptions: ['No options', '无选项'],
  playerOrder: ['Order Search', '注单查询'],
  playerTransaction: ['Transaction Search', '交易查询'],

  admin: ['Admin', '管理员'],
  owner: ['General Agent', '总代理'],
  parent: ['Agent', '代理'],
  cs: ['Customer Service', '客服'],
  adminCs: ['Admin Customer Service', '系统客服'],

  accountManager: ['Account', '帐户管理'],
  logout: ['Sign out', '登出'],

  // Data Table
  noMatch: ['Sorry, no matching records found.', '没有找到匹配的资料'],
  rowsPerPage: ['Rows per page:', '每页行数：'],
  downloadExcel: ['Export Excel file', '汇出 Excel 文件'],

  startDate: ['Start Date', '开始日期'],
  endDate: ['End Date', '结束日期'],
  invalidDate: ['Invalid date format', '日期格式无效'],

  true: ['Yes', '是'],
  false: ['No', '否'],

  // 币值
  notConverted: ['Not Converted', '不转换'],
  notScreened: ['Not Screened', '不筛选'],

  // 设定
  set: ['Set', '设定'],

  // validate
  required: ['is a Required Field', '必填栏位'],
  gamecodesOrGameTypesIsRequired: ['game codes or game types cannot be empty', '游戏清单或游戏种类不能空白'],
  gt: ['Must be Greater Than ', '必须大于'],
  gte: ['Greater Than or Equal', '必须大于等于'],
  lt: ['Must be Less Than', '必须小于'],
  lte: ['Less Than or Equal', '必须小于等于'],
  maximum: ['Maximum ', '最大值'],
  minimum: ['Minimum ', '最小值'],
  default: ['default', '预设值'],
  reset: ['Reset', '重置'],
  test: ['test', '测试'],
  bettype_maximum: ['Single Region Maximun', '单区最大限额'],
  round_maximum: ['Single Round Total', '单场总限注额'],

  // datepicker
  noInputTooltip: ['If the date is not entered, the last selected date will be used.', '如未填入日期，将使用上次选择日期'],
  mustStartNotLaterThanEnd: ['Must have a start time not later than end time.', '开始时间不可晚于结束时间。'],
  mustNotMoreThanOneDay: ['Must not be greater than one day.', '查询区间不可大于一天。'],
  mustNotMoreThanThreeDay: ['Must not be greater than three days.', '查询区间不可大于三天。'],
  mustNotMoreThanSevenDay: ['Must not be greater than seven days.', '查询区间不可大于七天。'],
  mustNotMoreThanOneMonth: ['Must not be more than a month.', '查询区间不可大于一个月。'],
  mustNotMoreThanTwoMonth: ['Must not be more than two months.', '查询区间不可大于二个月。'],
  mustNotLesserThan1900: ['Year must be greater than 1900', '年份需大于1900年'],

  // no feature dialog
  error: ['Error!', '错误！'],
  noFeaturesContent: ['There are currently no feature, please contact the administrator or superior account.', '目前暂时没有功能及权限，请联系管理员或上级帐号。'],

  all: ['All', '全部'],
  wallettype: [' Wallet Type', '钱包类型'],
  transferWallet: [' Transfer Wallet', '转帐钱包'],
  singleWallet: [' Single Wallet', '单一(无缝)钱包'],
  singleWalletV2: [' Single Wallet V2', '单一(无缝)钱包(V2)'],
  dateTimeMonth: ['/', '月'],
  dateTimeDay: ['', '日'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
