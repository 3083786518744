const rawData = {
  title: ['Add Black List', '新增游戏至黑名单'],
  // form
  brand: ['Brand', ' 品牌'],
  parentid: ['Parent ID', '代理帐号'],
  gametype: ['Game Type', '游戏类型'],
  gamecode: ['Game Code', '游戏代号'],
  all: ['All', '全部'],
  slot: ['Slot', '老虎机'],
  arcade: ['Arcade', '街机'],
  fish: ['Fish', '渔机'],
  table: ['Table Battle', '牌桌对战'],
  sport: ['Sport Game', '体育游戏'],
  lotto: ['Lotto Game', '彩票游戏'],
  live: ['Live', '真人视讯'],

  // dialog
  dialogTitle: ['Add game to black list', '新增游戏至黑名单'],
  dialogDescription: ['All sub accounts under the account will be affected!', '所有代理的子代皆会被影响！'],
  dialogDisagree: ['Cancel', '取消'],
  dialogAgree: ['Confirm', '执行'],

  // input error,
  required: ['This is a required field.', '此为必填栏位'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
