import React from 'react'
import { connect } from 'react-redux'
import Container from '../../components/Container'

const Dashboard = ({ history }) => {
  return <Container>Welcome !</Container>
}

const mapStateToProps = state => {
  return state
}

export default connect(mapStateToProps)(Dashboard)
