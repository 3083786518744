import React from 'react'
import { withTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DownloadButton from '@material-ui/icons/CloudDownload'
import { withStyles } from '@material-ui/core/styles'

const defaultToolbarStyles = {
  iconButton: {},
}

class CustomToolbar extends React.Component {
  render () {
    const { classes, onClick, t } = this.props
    return (
      <React.Fragment>
        <Tooltip title={t('downloadExcel')}>
          <IconButton onClick={onClick} className={classes.iconButton}>
            <DownloadButton />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  withTranslation()(
    CustomToolbar,
  ),
)
