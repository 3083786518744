// 玩家列表
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import {
  getUser,
  getLanguage,
  getReportByFilter,
  getTimeZone,
  getIsFetchingByFilter,
} from '../../reducers'
import actions from '../../actions'
import { qsParse, qsStringify } from '../../helpers'
import { api } from '../../services'
import language from '../../locale/playerList'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import GridItemFull from '../../components/grids/GridItemFull'
import PlayerListForAdminForm from '../../components/forms/PlayerListForAdminForm'
import DataTable from '../../components/tables/DataTable'
import MoreButton from '../../components/MoreButton'
import DisplayButton from '../../components/DisplayButton'
import Paper from '../../components/Paper'

class PlayerListForAdmin extends PureComponent {
  state = {
    rowsPerPage: 30,
    columns: [],
    payload: null,
    formatReports: [],
    reportPagination: null,
    needFetchOnMount: false,
    isSearched: false,
    // agent account, 用於從「玩家列表」切換至「注單查詢」、「交易查詢頁面使用」
    // admin 權限與 owner 不同在於 admin 需要手動填入底下代理所以無法從 store 及 child component 中獲取 agent
    // 因此增加 state 欄位處理此邏輯
    forPushAgent: '',
  };

  buttonRef = React.createRef();

  componentDidMount () {
    const { filterAgentAccount, i18n } = this.props

    i18n.addResourceBundle('en', 'playerList', language.en)
    i18n.addResourceBundle('cn', 'playerList', language.cn)

    if (filterAgentAccount) {
      this.buttonRef.click()
    }

    this.setColumns()
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      language,
      timezone,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (reports.data && (props.reports !== reports || props.timezone !== timezone)) {
      this.setFormatReports(reports.data)
    }

    if (props.reports !== reports) {
      this.setReportPagination(reports.pagination)
    }

    if (props.language !== language) {
      this.setColumns()
    }
  }

  getSearch = value => {
    const { search } = this.props

    return {
      ...search,
      ...value,
    }
  };

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        {
          name: t('playerAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('signUpAt'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => moment(value.time * 1000).utcOffset(value.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('loginAt'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value =>
              value.time === 0
                ? t('neverLogin')
                : moment(value.time * 1000).utcOffset(value.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
            download: false,
          },
        },
        {
          name: t('balance'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => (
              <DisplayButton
                item={value}
                handleFetch={this.handleFetchBalance}
              />
            ),
          },
        },
        {
          name: t('more'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => (
              <MoreButton
                items={[
                  {
                    label: t('orderSearch'),
                    to: {
                      pathname: '/playerOrderForAdmin',
                      search: qsStringify(
                        this.getSearch({
                          player: value.account,
                          agent: this.state.forPushAgent, // 小心閉包
                        }),
                      ),
                    },
                  },
                  {
                    label: t('transactionSearch'),
                    to: {
                      pathname: '/playerTransactionForAdmin',
                      search: qsStringify(
                        this.getSearch({
                          player: value.account,
                          agent: this.state.forPushAgent, // 小心閉包
                        }),
                      ),
                    },
                  },
                ]}
              />
            ),
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    const { timezone } = this.props

    // 沒有加 account 驗證，先擋掉會錯誤的狀況，後續再進行調整
    if (data === undefined) {
      return
    }

    this.setState({
      formatReports: data.map(item => [
        item.account,
        { time: item.signuptime, timezone },
        { time: item.lastlogin, timezone },
        item.currency,
        item,
        item,
      ]),
    })
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  handleFetchBalance = value => {
    return api.get(api.BO, `/players/balance?userid=${value.userid}`)
      .then(
        response => {
          return Promise.resolve(response.data.data.balance)
        },
        error => {
          if (error.data.status.code === '1006:wallet') {
            return Promise.resolve(0)
          }

          return Promise.resolve('Load Failed')
        },
      )
      .catch(() => {
        return Promise.resolve('Load Failed')
      })
  };

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
    }

    return this.onSubmit(payload)
  };

  fetchReport = payload => {
    return this.props.fetchReport(payload, { filter: 'playersList' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true, forPushAgent: payload.parentAcc }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      t,
      filterPlayerAccount,
      filterAgentAccount,
      isFetching,
    } = this.props
    const {
      columns,
      formatReports,
      rowsPerPage,
      isSearched,
      reportPagination,
    } = this.state

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <PlayerListForAdminForm
              parentAcc={filterAgentAccount}
              playerAcc={filterPlayerAccount}
              pagesize={rowsPerPage}
              nowpage={1}
              onSubmit={this.handleSubmit}
              sortKey="createat"
              sortValue="desc"
              buttonRef={submit => (this.buttonRef = submit)}
              isFetching={isFetching}
            />
          </Paper>
        </GridItemFull>

        { isSearched &&
        <GridItemFull>
          <DataTable
            title={t('titleTable')}
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const user = getUser(state)
  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'playersList')

  const filterAgentAccount = search.agent ? search.agent : ''
  const filterPlayerAccount = search.player ? search.player : ''
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'playersList')

  return {
    filterAgentAccount,
    filterPlayerAccount,
    user,
    language,
    reports,
    timezone,
    isFetching,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchReport: actions.report.fetchList,
  },
)(withTranslation('playerList')(PlayerListForAdmin))
