import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Paper from '../../components/Paper'

const styles = () => ({
  paper: {
    padding: 0,
    flex: 1,
    height: '350px',
  },
  root: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  iconRoot: {
    position: 'absolute',
    left: '60px',
    top: '50px',
    padding: '20px',
    borderRadius: '100px',
    background: '#636363',
    display: 'flex',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  icon: {
    color: 'white',
    width: '80px',
    height: '80px',
  },
  title: {
    paddingRight: '50px',
    color: 'rgb(255, 255, 255)',
    height: '130px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '40px',
    justifyContent: 'flex-end',
  },
  line: {
    border: '1px solid #636363',
  },
  content: {
    padding: '50px',
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    whiteSpace: 'nowrap',
  },
  date: {
    color: 'white',
    fontSize: '28px',
    alignSelf: 'center',
    marginBottom: '30px',
  },
  numberRoot: {
    position: 'absolute',
    right: '50px',
    bottom: '50px',
  },
  total: {
    color: 'white',
    marginRight: '18px',
    fontSize: '22px',
  },
  number: {
    fontSize: '46px',
    color: 'white',
  },
})

const AgentSumCard = (props) => {
  const {
    classes,
    t,
    Icon,
    title,
    date,
    number,
  } = props

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.iconRoot}>
          <Icon className={classes.icon} />
        </div>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.line}></div>
        <div className={classes.content}>
          <div className={classes.date}>
            {date}
          </div>
          <div className={classes.numberRoot}>
            <span className={classes.total}>
              {`${t('total')}：`}
            </span>
            <span className={classes.number}>
              {number}
            </span>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(
  withTranslation('agentSum')(AgentSumCard),
)
