import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    '& button:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& button:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '& a:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& a:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  button: {
    color: '#fff',
    padding: '6px 16px',
    fontSize: '0.875rem',
    minWidth: '64px',
    boxSizing: 'border-box',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    lineHeight: 1.75,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  primary: {
    color: '#fff',
    backgroundColor: '#039be5',
    '&:hover': {
      backgroundColor: '#0277bd',
    },
  },
  medium: {

  },
  contained: {
    boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
  },
}), {
  name: 'LiveRiskAssessment',
})

export default useStyles
