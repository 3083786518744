import React, { PureComponent as Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { api } from '../../services'

import actions from '../../actions'
import {
  getLanguage,
  getGameList,
  getAgentOptions,
  getIsCobrandFetching,
  getUser,
} from '../../reducers'
import language from '../../locale/removeBlackList'

import Form from './form'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'

class RemoveBlackList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      parentid: [],
      brand: '',
      brandOptions: [],
      gameOptions: props.allGamesOptions || [],
      currentGameType: 'all',
    }
  }

  async componentDidMount () {
    const { i18n, getAgentOptions } = this.props

    i18n.addResourceBundle('en', 'removeBlackList', language.en)
    i18n.addResourceBundle('cn', 'removeBlackList', language.cn)

    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    this.setState({
      brand: brandOptions?.[0] || '',
      brandOptions: brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    })

    getAgentOptions()
    this.onGameTypeChange('all')
  }

  componentDidUpdate (prevProps, prevState) {
    const { allGamesOptions } = this.props
    if (allGamesOptions !== prevProps.allGamesOptions) {
      this.onGameTypeChange(this.state.currentGameType)
    }
    if (prevState.brand !== this.state.brand && this.state.brand) {
      this.props.fetchGameList({ brand: this.state.brand })
    }
  }

  setGameOptions = (options = [], gametype) => {
    this.setState({ gameOptions: options, currentGameType: gametype })
  }

  onGameTypeChange = (changedType) => {
    if (changedType !== 'all') {
      const newOptions = this.props.allGamesOptions.filter(({ type }) => changedType === type)
      this.setGameOptions(newOptions, changedType)
      return
    }
    this.setGameOptions(this.props.allGamesOptions, 'all')
  }

  onBrandChange = (brand) => {
    this.setState({ brand })
  }

  onParentIdChange = (values) => {
    this.setState({
      parentid: values,
    })
  }

  render () {
    const { agents, isFetching, theme, t, removeBlackList, role, type } = this.props
    const { gameOptions, brandOptions, brand, parentid, currentGameType } = this.state

    return (
      <Container>
        <GridItem doubleWidth>
          <Paper>
            <h3 style={theme.customer.H3Style}>{t('title')}</h3>
            <Form
              onParentIdChange={this.onParentIdChange}
              role={role}
              type={type}
              brand={brand}
              onBrandChange={this.onBrandChange}
              brandOptions={brandOptions}
              isFetching={isFetching}
              onSubmit={removeBlackList}
              gameOptions={gameOptions}
              agents={agents}
              gamecode=''
              gametype={currentGameType}
              parentid={parentid}
              onGameTypeChange={this.onGameTypeChange}
            />
          </Paper>
        </GridItem>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const isFetching = getIsCobrandFetching(state)
  const language = getLanguage(state)
  const allGamesOptions = getGameList(state, language)
    .filter(item => !item.disabled)
  const agents = getAgentOptions(state)
  const user = getUser(state)
  return {
    role: user.role,
    type: user.type,
    agents,
    isFetching,
    allGamesOptions,
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchGameList: actions.game.fetchGameList,
      removeBlackList: actions.game.removeBlackList,
      getAgentOptions: actions.agent.fetchAgentOptions,
    },
  ),
  withTranslation('removeBlackList'),
  withStyles({}, { withTheme: true }),
)(RemoveBlackList)
