import actionType from '../../actions/actionType'

// array element: {
//  replaceWithI18nText string
//  msg string
//  variant string
// }
const defaultState = []

// msgs 用於增/減 snack bar 消息
const msgs = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionType.msgs.add:
      return [...state, action.payload]
    case actionType.msgs.remove:
      return action?.payload.num
        ? state.filter((_, i) => i >= action.payload.num)
        : state
    default:
      return state
  }
}

export default msgs
