import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { addDays, addMonths, startOfDay, startOfMonth, startOfYear, endOfDay, endOfMonth, endOfYear } from 'date-fns'
import { getSavedState } from '../../helpers/storage'
import GridContainer from '../grids/GridContainer'
import GridItem from '../grids/GridItem'
import DatePickerField from '../fields/DatePickerField'

const getDateRange = (range, startDate, multiplier = 1) => {
  let dateRange
  switch (range) {
    case '1day':
      dateRange = {
        rule: addDays(startDate, 1 * multiplier),
        message: 'mustNotMoreThanOneDay',
      }
      break
    case '3day':
      dateRange = {
        rule: addDays(startDate, 3 * multiplier),
        message: 'mustNotMoreThanThreeDay',
      }
      break
    case '7day':
      dateRange = {
        rule: addDays(startDate, 7 * multiplier),
        message: 'mustNotMoreThanSevenDay',
      }
      break
    case '1month':
      dateRange = {
        rule: addMonths(startDate, 1 * multiplier),
        message: 'mustNotMoreThanOneMonth',
      }
      break
    case '2month':
      dateRange = {
        rule: addMonths(startDate, 2 * multiplier),
        message: 'mustNotMoreThanTwoMonth',
      }
      break
    default:
      break
  }
  return dateRange
}

const useDateVariant = (groupby) => {
  const [dateVariant, setDateVariant] = useState({})
  const [startOf, setStartOf] = useState(() => { return date => date })
  const [endOf, setEndOf] = useState(() => { return date => date })

  useEffect(() => {
    switch (groupby) {
      case 'year':
        setDateVariant({
          views: ['year'],
          format: 'yyyy',
        })
        setStartOf(() => startOfYear)
        setEndOf(() => endOfYear)
        break
      case 'month':
        setDateVariant({
          views: ['year', 'month'],
          format: 'yyyy/MM',
        })
        setStartOf(() => startOfMonth)
        setEndOf(() => endOfMonth)
        break
      case 'day':
        setDateVariant({
          views: ['year', 'month', 'day'],
          format: 'yyyy/MM/dd',
        })
        setStartOf(() => startOfDay)
        setEndOf(() => endOfDay)
        break
      case 'hour':
      case 'none':
        setDateVariant({
          format: 'yyyy/MM/dd HH',
          openTo: 'hours',
          views: ['year', 'month', 'day', 'hour'],
        })
        setStartOf(() => { return date => date })
        setEndOf(() => { return date => date })
        break
      default:
        setDateVariant({
          views: ['year', 'month', 'day', 'hour'],
        })
        setStartOf(() => { return date => date })
        setEndOf(() => { return date => date })
    }
  }, [groupby])

  return [dateVariant, startOf, endOf]
}

const DateRangePickerTextField = (props) => {
  const {
    variant = 'outlined',
    margin = 'normal',
    InputProps,
    values = {},
    enableTimeSelector,
    range,
    setFieldValue,
    // specialrange 客變了結束時間，影響報表：「注單查詢」
    // 原邏輯：初始的結束時間是當日的 23:59
    // 邏輯變更為：初始的結束時間顯示隔日的 00:00
    // redmine issue number: http://redmine.ny.corp/issues/46729
    specialrange,
    ...otherProps
  } = props
  const timezone = useSelector(s => getSavedState('timezone') || s.state.timezone)
  const [dateVariant, startOf, endOf] = useDateVariant(values.groupby)
  const timeSelector = enableTimeSelector || values.groupby === 'hour' || values.groupby === 'none'

  useEffect(() => {
    // 週期與 timezone 改變時 將日期重設成以當前時間為基準的區間
    if (values.groupby) {
      setFieldValue('startDate', startOf(new Date(moment().utcOffset(timezone * 60).format('YYYY/MM/DD HH:mm:ss'))))
      setFieldValue('endDate', endOf(new Date(moment().utcOffset(timezone * 60).format('YYYY/MM/DD HH:mm:ss'))))
    }
  // eslint-disable-next-line
  }, [timezone, values.groupby, startOf, endOf])

  useEffect(() => {
    if (specialrange) {
      const now = new Date()
      const tomorrowMidnight = new Date(moment(now).utcOffset(timezone * 60).add(1, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss'))
      const todayMidnight = new Date(moment(now).utcOffset(timezone * 60).startOf('day').get().format('YYYY/MM/DD HH:mm:ss'))

      setFieldValue('startDate', todayMidnight)
      setFieldValue('endDate', tomorrowMidnight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialrange, timezone])

  return (
    <GridContainer>
      <GridItem fluid xs={12} md={6} >
        <Field
          isExtend
          validate={async (value) => {
            try {
              let schema = Yup.date().min(1900, 'mustNotLesserThan1900').max(values.endDate, 'mustStartNotLaterThanEnd')
              const dateRange = getDateRange(range, values.endDate, -1)
              if (dateRange) {
                schema = schema.min(dateRange.rule, dateRange.message)
              }
              await schema.validate(value)
              return ''
            } catch (error) {
              return error.message
            }
          }}
          name='startDate'
          label='startDate'
          fullWidth
          variant={variant}
          margin={margin}
          InputProps={{
            margin: 'dense',
            autoComplete: 'off',
            ...InputProps,
          }}
          enableTimeSelector={timeSelector}
          component={DatePickerField}
          useI18n
          isRange
          onChange={date => setFieldValue('startDate', startOf(date))}
          {...dateVariant}
          {...otherProps}
        />
      </GridItem>
      <GridItem fluid xs={12} md={6} >
        <Field
          isExtend
          validate={async (value) => {
            try {
              let schema = Yup.date().test('mustNotLesserThan1900', 'mustNotLesserThan1900', (value) => new Date(value).getFullYear() > 1900).min(values.startDate, 'mustStartNotLaterThanEnd')
              const dateRange = getDateRange(range, values.startDate, 1)
              if (dateRange) {
                schema = schema.max(dateRange.rule, dateRange.message)
              }
              await schema.validate(value)
              return ''
            } catch (error) {
              return error.message
            }
          }}
          name='endDate'
          label='endDate'
          fullWidth
          variant={variant}
          margin={margin}
          InputProps={{
            margin: 'dense',
            autoComplete: 'off',
            ...InputProps,
          }}
          enableTimeSelector={timeSelector}
          component={DatePickerField}
          useI18n
          isRange
          onChange={date => setFieldValue('endDate', endOf(date))}
          {...dateVariant}
          {...otherProps}
        />
      </GridItem>
    </GridContainer>
  )
}

DateRangePickerTextField.propTypes = {
  // 是否指定選取範圍
  range: PropTypes.oneOf(['1day', '3day', '7day', '1month', '2month']),
  // 是否啟用時間選擇器
  enableTimeSelector: PropTypes.bool,
}

export default DateRangePickerTextField
