import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import actions from '../actions'

const useDisplayBox = () => {
  const dispatch = useDispatch()
  const successHandler = useCallback(() => {
    dispatch(
      actions.msgs.add({
        replaceWithI18nText: 'modifySuccess',
        variant: 'success',
      }),
    )
  }, [dispatch])
  const errorHandler = useCallback((err) => {
    dispatch(
      actions.msgs.add({
        msg: err.data && err.data.status && err.data.status.message,
        variant:
          err.data && err.data.status && err.data.status.code === '0'
            ? 'info'
            : 'error',
      }),
    )
  }, [dispatch])
  return (
    { successHandler, errorHandler }
  )
}

export default useDisplayBox
