import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch tableInfo
 */
export function * fetchTableInfo () {
  try {
    // 1. Fetch tableInfo request started
    yield put(actions.live.fetchTableInfoRequest())

    // 2. Call API: fetch tableInfo
    const res = yield call(api.get, api.BO, '/live/tableid')

    // 3. Fetch tableInfo success
    yield put(actions.live.fetchTableInfoSuccess({ response: res.data.data.list }))
  } catch (err) {
    // Fetch tableInfo failure
    yield put(actions.live.fetchTableInfoFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.live.fetchTableInfo, fetchTableInfo),
]

export default effects
