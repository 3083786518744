import { call, put, takeLatest } from 'redux-saga/effects'

import actionType from '../actions/actionType'
import { api } from '../services'
import actions from '../actions'

/**
 * Effect to fetch tableInfo
 */
export function * fetchOrder () {
  try {
    // 1. Fetch tableInfo request started
    yield put(actions.order.fetchOrderRequest())

    // 2. Call API: fetch tableInfo
    const res = yield call(api.get, api.BO, '/query/limit')

    // 3. Fetch tableInfo success
    yield put(actions.order.fetchOrderSuccess({ ...res.data.data }))
  } catch (err) {
    // Fetch tableInfo failure
    yield put(actions.order.fetchOrderFailure())
    // Announce API error
    yield put(actions.msgs.add({
      msg: err.data && err.data.status && err.data.status.message,
      variant: 'warning',
    }))
  }
}

const effects = [
  takeLatest(actionType.order.fetchOrder, fetchOrder),
]

export default effects
