import { combineReducers } from 'redux'
import actionType from '../../actions/actionType'

const betThresholdGroup = (state = [], action = {}) => {
  switch (action.type) {
    case actionType.betThreshold.fetchGroupSuccess:
      return action.payload.response.data || []

    case actionType.betThreshold.fetchGroupFailure:
      return []
    default:
      return state
  }
}

const isSubmitting = (state = false, action = {}) => {
  switch (action.type) {
    case actionType.betThreshold.createGroupRequest:
    case actionType.betThreshold.updateGroupRequest:
      return true

    case actionType.betThreshold.createGroupSuccess:
    case actionType.betThreshold.updateGroupSuccess:
      return false
    default:
      return state
  }
}

export default combineReducers({
  betThresholdGroup,
  isSubmitting,
})
