import { Button, InputAdornment, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GridItemFull from '../../components/grids/GridItemFull'
import Paper from '../../components/Paper'
import { TABLE_COLUMN_DATA } from './constant'
import EnhancedDataTable
  from './EnhancedDataTable/EnhancedDataTable'
import useDisplayBox from '../../hooks/useDisplayBox'
import language from '../../locale/gkSetting'
import { api } from '../../services'
import useStyles from './index.style'
import Container from '../../components/Container'

const LiveOverUnderUISettingForAdmin = () => {
  const classes = useStyles()

  const { i18n, t } = useTranslation('gkSetting')
  const { successHandler, errorHandler } = useDisplayBox()

  const lang = useSelector((state) => state.state.language)
  const userId = useSelector((state) => state.auth.user.userID)

  const [data, setData] = useState({})
  const [searchId, setAgentId] = useState('')
  const [tablesets, setTablesets] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isAllSwitchOn, setIsAllSwitchOn] = useState(false)
  const [isAllSwitchTouched, setIsAllSwitchTouched] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  const changeRef = useRef({ userid: [], overUnder: [], isAllSwitchOn: false, currentUserId: userId })

  const clearChangeHandler = useCallback(() => {
    setRowsPerPage(30)
    setIsEdit(false)
    setIsAllSwitchTouched(false)
    changeRef.current = { ...changeRef.current, userid: [], overUnder: [] }
  }, [])

  // 搜索
  const handleSearchChange = (e) => setAgentId(e.target.value)
  const handleSearch = useCallback(async (nowpage, pagesize, reset = true) => {
    try {
      const response = await api.get(api.BO, `/ss/baccarat/playmode/list?account=${searchId}&nowpage=${nowpage || ''}&pagesize=${pagesize || ''}`)
      if (response.data?.status?.code === '0') {
        if (reset) { setRowsPerPage(30) }
        setIsAllSwitchOn(response.data.data.data[0]?.overunderall === 'y')
        searchId ? (changeRef.current.currentUserId = response.data.data.data[0]?.userid) : (changeRef.current.currentUserId = userId)
        setData(response.data.data || {})
        setTablesets([...response.data.data.data.map(el => ({ ...el }))])
      }
    } catch (err) {
      errorHandler(err)
    }
  }, [searchId, userId, errorHandler])

  const handleSwitchChange = useCallback((value) => {
    const [userid, overUnder] = value.split(',')
    const isOverUnder = !(overUnder === 'true')
    const index = changeRef.current.userid.indexOf(userid)
    if (index === -1) {
      changeRef.current = { ...changeRef.current, userid: [...changeRef.current.userid, userid], overUnder: [...changeRef.current.overUnder, isOverUnder] }
    } else {
      changeRef.current.overUnder[index] = isOverUnder
    }
    setTablesets((prev) => {
      const currentUserId = prev.find((current) => current.userid === userid)
      currentUserId.overunder = isOverUnder
      return [...prev]
    })
  }, [])

  const handleAllSwitchChange = useCallback((isChecked) => {
    setIsAllSwitchOn(isChecked)
    setIsAllSwitchTouched(true)
    setTablesets((prev) => {
      return prev.map((current) => {
        current.overunder = isChecked
        return current
      })
    })
  }, [])

  // 編輯、確認、取消
  const handleSave = useCallback(async () => {
    try {
      const updateAllResponse = await api.put(api.BO, 'live/ss/baccarat/playmode/updateall', {
        userid: changeRef.current.currentUserId,
        overunderall: isAllSwitchTouched ? (isAllSwitchOn ? 'y' : 'n') : 'e',
      })
      let codeForUpdateRes
      if (!isAllSwitchTouched && changeRef.current.userid.length) {
        const updateResponse = await api.put(api.BO, 'live/ss/baccarat/playmode/update', {
          userid: [...changeRef.current.userid],
          overunder: [...changeRef.current.overUnder],
        })
        codeForUpdateRes = updateResponse.data?.status?.code
      }

      if (updateAllResponse.data?.status?.code === '0' && (codeForUpdateRes === undefined || codeForUpdateRes === '0')) {
        changeRef.current.isAllSwitchOn = isAllSwitchOn
        handleSearch()
        successHandler()
        clearChangeHandler()
      }
    } catch (err) { console.log(err); errorHandler(err) }
  }, [clearChangeHandler, errorHandler, handleSearch, isAllSwitchOn, isAllSwitchTouched, successHandler])

  const handleEdit = useCallback(() => { setIsEdit(true) }, [])

  const handleCancel = useCallback(() => {
    setIsAllSwitchOn(changeRef.current.isAllSwitchOn)
    setTablesets(data.data.map((el) => ({ ...el })))
    clearChangeHandler()
  }, [clearChangeHandler, data.data])

  // 翻譯
  useEffect(() => {
    i18n.addResourceBundle('en', 'gkSetting', language.en)
    i18n.addResourceBundle('cn', 'gkSetting', language.cn)
  }, [i18n])

  // 進入畫面時搜索
  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {/* 搜索欄 */}
      <GridItemFull sticky>
        <Paper>
          {/* 表單 */}
          <div className={classes.form}>
            <div>
              <TextField
                required
                disabled={isEdit}
                autoComplete={'off'}
                name="agentId"
                label={t('agentId')}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                onChange={handleSearchChange}
                className={classes.textfield}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={isEdit}
                className={classes.search}
                onClick={() => handleSearch()}>
                {t('apply')}
              </Button>
            </div>
            {/* 搜索後 */}
            <div className={classes.editContainer}>
              {
                <div className={classes.buttonGroup}>
                  {!isEdit &&
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}>
                      {t('edit')}
                    </Button>
                  }
                  {isEdit &&
                    <>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleSave}>
                        {t('save')}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}>
                        {t('cancel')}
                      </Button>
                    </>
                  }
                </div>
              }
            </div>
          </div>
        </Paper>
      </GridItemFull>
      {/* 搜索成功，顯示遊戲桌設定開關 */}
      {data.data?.length ? (
        <GridItemFull>
          <Paper>
            <EnhancedDataTable
              t={t}
              lang={lang}
              disabled={!isEdit}
              isAllSwitchOn={isAllSwitchOn}
              isAllSwitchTouched={isAllSwitchTouched}
              rows={tablesets}
              columns={TABLE_COLUMN_DATA}
              total={data.pagination?.total}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={setRowsPerPage}
              onPageChange={handleSearch}
              onSwitchChange={handleSwitchChange}
              onAllSwitchChange={handleAllSwitchChange}
            />
          </Paper>
        </GridItemFull>
      ) : ''}
    </Container>
  )
}

export default LiveOverUnderUISettingForAdmin
